import React, {useEffect} from "react";
import AuthCard from "../../../components/auth/auth-card";
import {useActions} from "../../../hooks/useActions";
import {layoutList} from "../../../store/reducers/layout";

/**
 * It's a function that returns a fragment that contains an AuthCard component
 * @returns A function that returns a component
 */
const SignIn = () => {
  const {setLayout} = useActions();

  useEffect(() => {
    setLayout(layoutList.AUTH);
  }, []);
  return (
    <>
      <AuthCard/>
    </>
  );
};

export default SignIn;
