import React, {useEffect} from "react";
import {HeadCell} from "../../../types/table";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Container, Grid,
  Paper, Skeleton,
  Table, TableBody,
  TableCell, TableContainer,
  TableHead,
  TableRow, Typography
} from "@mui/material";
import {Can} from "@casl/react";
import ability from "../../../utils/can";
import {useTranslation} from "react-i18next";
import {useTypedSelector} from "../../../hooks/useTypedSelector";
import {IMerchant} from "../../../types/store/merchant";
import {useNavigate} from "react-router-dom";
import {useActions} from "../../../hooks/useActions";
import Error404 from "../../error-pages/error-404";
import {LoadingButton} from "@mui/lab";

/** A React component that renders a table of merchants. */
const merchantList = () => {
  const {t} = useTranslation();
  const {isLoading, merchants} = useTypedSelector(state => state.merchantList);
  const {fetchMerchantList, updateMerchantBlockStatus} = useActions();
  const navigate = useNavigate();

  const headCells: HeadCell<IMerchant>[] = [
    {
      id: "id",
      numeric: true,
      label: t("merchant.table.id"),
      disablePadding: false,
    },
    {
      id: "name",
      numeric: false,
      label: t("merchant.table.name"),
      disablePadding: false,
    },
    {
      id: "type",
      numeric: false,
      label: t("merchant.table.type.title"),
      disablePadding: false,
    },
    {
      id: "eWallet",
      numeric: false,
      label: t("merchant.table.eWallet"),
      disablePadding: false,
    }
  ];

  const handleViewMerchant = (id: number) => () => {
    navigate(`/merchants/${id}`);
  };

  const handleSetBlockStatusMerchant = (id: number, block: 0 | 1) => () => {
    updateMerchantBlockStatus(id, block);
  };

  const handleEditMerchant = (id: number) => () => {
    navigate(`/merchant/edit/${id}`);
  };

  useEffect(() => {
    if (ability.can("get", "merchants")) {
      fetchMerchantList();
    }
  }, []);

  if (ability.cannot("get", "merchants"))
    return <Error404/>;

  return (
    <Container maxWidth="xl">
      <Can I={"get"} a={"merchants"} ability={ability}>
        <Card>
          <CardHeader title={t("merchant.table.title")}/>
          <CardContent>
            <TableContainer component={Paper}>
              <Table sx={{minWidth: 650}} aria-label="Merchants list table" size={"small"}>
                <TableHead>
                  <TableRow sx={{"& .MuiTableCell-head": {fontWeight: 600}}}>
                    {headCells.map((headCell) => {
                      return (
                        <TableCell
                          key={headCell.id}
                        >
                          {headCell.label}
                        </TableCell>
                      );
                    })}
                    <TableCell><Typography sx={{color: "transparent"}}></Typography></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {isLoading && Array(10).fill("").map((row, index) => (
                    <TableRow
                      key={index}
                      sx={{"&:last-child td, &:last-child th": {border: 0}}}
                    >
                      {headCells.map((headCell, index) => {
                        return (
                          <TableCell key={index}><Skeleton/></TableCell>
                        );
                      })}
                      <TableCell><Skeleton height={30}/></TableCell>
                    </TableRow>
                  ))}
                  {!isLoading && merchants.map((merchant) => (
                    <TableRow
                      key={merchant.id}
                      sx={{"&:last-child td, &:last-child th": {border: 0}}}
                      hover
                    >
                      <TableCell>{merchant.id}</TableCell>
                      <TableCell>{merchant.name}</TableCell>
                      <TableCell>{t(`merchant.table.type.${merchant.type}`)}</TableCell>
                      <TableCell>{merchant.ewallet}</TableCell>
                      <TableCell>
                        <Grid container spacing={2}>
                          <Grid item>
                            <Button
                              variant={"contained"}
                              size="small"
                              onClick={handleViewMerchant(merchant.id)}
                              disabled={ability.cannot("get", "merchant")}
                            >
                              {t("merchant.table.details")}
                            </Button>
                          </Grid>
                          <Grid item>
                            <Button
                              variant={"contained"}
                              size="small"
                              onClick={handleEditMerchant(merchant.id)}
                              disabled={ability.cannot("update", "merchant")}
                            >
                              {t("merchant.table.edit")}
                            </Button>
                          </Grid>
                          <Grid item>
                            {!!(merchant.status) && (
                              <LoadingButton
                                variant={"contained"}
                                size="small"
                                color="error"
                                loading={merchant.isUpdating}
                                onClick={handleSetBlockStatusMerchant(merchant.id, 0)}
                                disabled={ability.cannot("update", "merchant")}
                              >
                                {t("merchant.table.block")}
                              </LoadingButton>
                            )}
                            {!(merchant.status) && (
                              <LoadingButton
                                variant={"contained"}
                                size="small"
                                color="success"
                                loading={merchant.isUpdating}
                                onClick={handleSetBlockStatusMerchant(merchant.id, 1)}
                                disabled={ability.cannot("update", "merchant")}
                              >
                                {t("merchant.table.unblock")}
                              </LoadingButton>
                            )}
                          </Grid>
                        </Grid>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </CardContent>
        </Card>
      </Can>
    </Container>
  );
};

export default merchantList;