import React, {FC, useContext} from "react";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Stack, Typography,
} from "@mui/material";
import {useTypedSelector} from "../../../hooks/useTypedSelector";
import {ModalImageWindowContext} from "../../../providers/modalImageWindowProvider";
import {useTranslation} from "react-i18next";

/**
 interface of Account Photos Props
 */
interface AccountPhotosProps {
  cardHeader?: string;
}

/**
 * A React Component for showing the list of photos by account
 */
const AccountPhotos: FC<AccountPhotosProps> = ({ cardHeader }) => {
  const {photos} = useTypedSelector(state => state.accountDetails);
  const {handleOpenImage} = useContext(ModalImageWindowContext);
  const { t } = useTranslation();

  return (
    <Card sx={{mb: 2}}>
      {cardHeader && (<CardHeader title={cardHeader}/>)}
      <CardContent>
        <Stack
          direction={{ xs: "column", sm: "row" }}
          alignItems={{ xs: "center" }}
          spacing={{ xs: 1, sm: 2, md: 4 }}
          sx={{
            width: "100%"
          }}
        >
          {!!photos && photos.map((photo, index) => (
            <Box sx={{border: "none", boxShadow: "unset"}} key={index}>
              <Box
                component={"img"}
                src={photo.s3Link}
                alt={"photo " + (index + 1)}
                loading="lazy"
                sx={{
                  maxWidth: "200px",
                  height: "100px",
                  objectFit: "contain",
                  lineHeight: "100%",
                  cursor: "pointer",
                  marginRight: "2rem",
                }}
                onClick={handleOpenImage && handleOpenImage(photo.s3Link)}
              />
            </Box>
          ))}
          {(!photos || photos.length === 0) && (
            <Typography>
              {t("account.details.nodata")}
            </Typography>
          )}
        </Stack>
      </CardContent>
    </Card>
  );
};

export default AccountPhotos;
