import {
  AccountDetailsActions,
  IAccountDetail,
  IAccountDetailsCorporate,
  IAccountDetailsIndividual,
  IAccountDetailsState, IAccountIdentityVerificationInfo, IAccountSatchelWalletsAllTypes,
  TAccountsDetailsAction,
} from "../../../types/store/account/account-details";

const initialState: IAccountDetailsState = {
  account: {} as IAccountDetail,
  individualDetails: {} as IAccountDetailsIndividual,
  corporateDetails: {} as IAccountDetailsCorporate,
  identityVerification: {} as IAccountIdentityVerificationInfo,
  documents: [],
  photos: [],
  wallets: [],
  satchelWallets: {} as IAccountSatchelWalletsAllTypes,
  ondatoDetails: [],
  signedContracts: [],
  isLoading: false,
};

export const accountDetailsReducer = (
  state: IAccountDetailsState = initialState,
  action: TAccountsDetailsAction
): IAccountDetailsState => {
  switch (action.type) {
  case AccountDetailsActions.SET_ACCOUNT_DETAILS:
    return {
      ...state,
      account: action.account,
    };
  case AccountDetailsActions.SET_ACCOUNT_DETAILS_LOADING:
    return { ...state, isLoading: action.loading };
  case AccountDetailsActions.SET_ACCOUNT_DETAILS_ERROR:
    return {
      ...state,
      errorCode: action.payload.errorCode,
      errorMessage: action.payload.errorMessage,
    };
  case AccountDetailsActions.CLEAR_ACCOUNT_DETAILS:
    return { ...initialState };
  case AccountDetailsActions.SET_ACCOUNT_DETAILS_INDIVIDUAL:
    return {
      ...state,
      individualDetails: action.accountIndividual
    };
  case AccountDetailsActions.SET_ACCOUNT_DETAILS_CORPORATE:
    return {
      ...state,
      corporateDetails: action.accountCorporate
    };
  case AccountDetailsActions.SET_ACCOUNT_DOCUMENTS:
    return {
      ...state,
      documents: action.documents,
    };
  case AccountDetailsActions.SET_ACCOUNT_PHOTOS:
    return {
      ...state,
      photos: action.photos,
    };
  case AccountDetailsActions.SET_ACCOUNT_IDENTITY_VERIFICATION_INFO:
    return {
      ...state,
      identityVerification: action.identityVerification,
    };
  case AccountDetailsActions.SET_ACCOUNT_WALLETS:
    return {
      ...state,
      wallets: action.wallets,
    };
  case AccountDetailsActions.SET_ACCOUNT_SATCHEL_WALLETS:
    return {
      ...state,
      satchelWallets: action.satchelWallets,
    };
  // case AccountDetailsActions.SET_ACCOUNT_SATCHEL_STATUS:
  //   return {
  //     ...state,
  //     account: {
  //       ...state.account,
  //       statusSatchel: action.status
  //     }
  //   };
  case AccountDetailsActions.SET_ACCOUNT_ARBIPAY_STATUS:
    return {
      ...state,
      account: {
        ...state.account,
        statusAml: action.status
      }
    };
  case AccountDetailsActions.SET_ACCOUNT_ONDATA_STATUS:
    return {
      ...state,
      account: {
        ...state.account,
        statusOndata: action.status
      }
    };
  case AccountDetailsActions.SET_ACCOUNT_ONDATO_DETAILS:
    return {
      ...state,
      ondatoDetails: action.ondatoDetails.map(item => {
        item.isLoadingImages = true;
        return item;
      }),
    };
  case AccountDetailsActions.SET_ACCOUNT_ONDATO_IMAGES:
    return {
      ...state,
      ondatoDetails: state.ondatoDetails.map((item) => {
        if (item.id === action.ondatoSessionId){
          return {
            ...item,
            docImages: action.docImages,
            photoImages: action.photoImages,
            isLoadingImages: false,
          };
        } else return item;
      })
    };
  case AccountDetailsActions.SET_ACCOUNT_SIGNED_CONTRACTS:
    return {
      ...state,
      signedContracts: action.signedContracts
    };
  default:
    return state;
  }
};
