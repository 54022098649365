import React, { Fragment } from "react";
import {
  FieldArrayWithId,
  UseFieldArrayAppend,
  UseFieldArrayRemove,
  useFormContext,
} from "react-hook-form";
import { Button } from "@mui/material";
import TreeView from "@mui/lab/TreeView";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import TreeItem from "@mui/lab/TreeItem";
import { IBeneficiariesForm } from "./types";
import { BeneficiaryForm } from "./beneficiary-form";
import { TApplicationStatus } from "../../../../types/store/applications";

interface IProps {
  beneficiaries: FieldArrayWithId<IBeneficiariesForm, "beneficiaries", "id">[];
  applicationStatus: TApplicationStatus;
  addBeneficiary: UseFieldArrayAppend<IBeneficiariesForm, "beneficiaries">;
  removeBeneficiary: UseFieldArrayRemove;
}

export function Beneficiaries({
  beneficiaries,
  applicationStatus,
  addBeneficiary,
  removeBeneficiary,
}: IProps) {
  const { formState } = useFormContext<IBeneficiariesForm>();

  return (
    <Fragment>
      <TreeView
        defaultCollapseIcon={<ExpandMoreIcon />}
        defaultExpandIcon={<ChevronRightIcon />}
      >
        {beneficiaries.map((beneficiary, index) => (
          <TreeItem
            key={beneficiary.id}
            nodeId={beneficiary.id}
            label={`Beneficiary ${index + 1}`}
            sx={{
              my: 1,
              "& .MuiTreeItem-label": {
                color: formState?.errors?.beneficiaries?.[index]
                  ? "red"
                  : "unset",
              },
            }}
          >
            <BeneficiaryForm
              index={index}
              removeBeneficiary={removeBeneficiary}
              disabled={applicationStatus !== "received"}
            />
          </TreeItem>
        ))}
        <Button
          color={"info"}
          variant={"contained"}
          disabled={applicationStatus !== "received"}
          onClick={() =>
            addBeneficiary({
              firstName: "",
              lastName: "",
              nationality: "",
              dateOfBirthday: new Date(),
              documents: [
                {
                  type: "ID",
                  number: "",
                  issueCountry: "",
                },
              ],
              address: {
                countryCode: "",
                city: "",
                zipCode: "",
                streetAddress: "",
              },
              phoneNumber: "",
              emailAddress: "",
              personalNumber: "",
            })
          }
          sx={{ my: 2 }}
        >
          Add beneficiary
        </Button>
      </TreeView>
    </Fragment>
  );
}
