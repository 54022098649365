import {theme} from "../../../theme";
import i18n from "i18next";

export interface ICard {
    readonly id: string; //15,
    readonly user_id: string; //60,
    readonly uuid: string; //"46f592c9-235a-4aee-8a39-eeaf9a942712",
    readonly order_uuid: string; //"string",
    readonly balance: string; //"0",
    readonly currency: string; //"EUR",
    readonly pan: string; //"5871451313362524",
    readonly cvc2: string; //"264",
    readonly expiry_date: string | null; //null,
    readonly account_to_charge: string; //"410045860612",
    readonly processing_type: string; //"mastercard",
    readonly embossing: string; //"",
    readonly cardholder_phone: string; //"+421940824657",
    readonly status: number; //0,
    readonly created_at: string; //"2022-11-14T17:06:30.000Z",
    readonly updated_at: string; //"2022-11-14T17:06:30.000Z"
}

export enum CardStatus {
    NEW_REQUEST,
    CARD_ASSIGNED,
    CARD_SENT,
    REQUEST_CANCELED
}

export type TCardStatus =
    CardStatus.NEW_REQUEST
    | CardStatus.CARD_ASSIGNED
    | CardStatus.CARD_SENT
    | CardStatus.REQUEST_CANCELED

export interface ICardState {
    card: ICard,
    isLoading: boolean,
    errorCode?: number,
    errorMessage?: string,
    formErrors?: ICardFormFields
}

export enum CardActions {
    SET_CARD = "SET_CARD",
    SET_CARD_LOADING = "SET_CARD_LOADING",
    SET_CARD_ERROR = "SET_CARD_ERROR",
    SET_CARD_FORM_ERRORS = "SET_CARD_FORM_ERRORS",
    CLEAR_CARD = "CLEAR_CARD",
}

export interface ISetCard {
    type: CardActions.SET_CARD,
    card: ICard
}

export interface IClearCard {
    type: CardActions.CLEAR_CARD,
}

export interface ISetCardLoading {
    type: CardActions.SET_CARD_LOADING,
    loading: boolean
}

export interface ISetCardError {
    type: CardActions.SET_CARD_ERROR,
    errorCode: number,
    errorMessage: string,
}

export interface ISetCardFormErrors {
    type: CardActions.SET_CARD_FORM_ERRORS,
    formErrors: ICardFormFields
}

export type TCardAction = ISetCard | ISetCardError | ISetCardLoading | IClearCard | ISetCardFormErrors;

export interface ICardFormFields{
    cardNumber: string
}

export const getCardStatusName = (status: TCardStatus): string => {
  if (i18n.exists("card.statuses." + CardStatus[status])){
    return i18n.t("card.statuses." + CardStatus[status]);
  }
  return CardStatus[status]?.replace(/_/g, " ");
};

export const getCardStatusColor = (status: TCardStatus): string => {
  switch (status){
  case CardStatus.NEW_REQUEST:
    return theme.palette.primary.main;
  case CardStatus.CARD_ASSIGNED:
    return theme.palette.warning.main;
  case CardStatus.CARD_SENT:
    return theme.palette.success.main;
  case CardStatus.REQUEST_CANCELED:
  default:
    return theme.palette.error.main;

  }
};