import { IAccountWallet } from "../account/account-details";

export interface IWalletAccountData extends Partial<IAccountWallet> {
    id: string;
    accountName: string;
    accountNumber: string;
}

export interface IWalletAccountsState {
    wallets: IWalletAccountData[],
    isTech: boolean,
    isLoading: boolean
}

export enum WalletAccountsActions {
    SET_WALLET_ACCOUNTS = "SET_WALLET_ACCOUNTS",
    SET_WALLET_ACCOUNTS_LOADING = "SET_WALLET_ACCOUNTS_LOADING",
    CLEAR_WALLET_ACCOUNTS = "CLEAR_WALLET_ACCOUNTS",
    UPDATE_WALLET_ACCOUNT = "UPDATE_WALLET_ACCOUNT",
}

export interface ISetWalletAccounts {
    type: WalletAccountsActions.SET_WALLET_ACCOUNTS,
    wallets: IWalletAccountData[],
    isTech: boolean
}

export interface ISetWalletAccountsLoading {
    type: WalletAccountsActions.SET_WALLET_ACCOUNTS_LOADING,
    loading: boolean
}

export interface IClearWalletAccounts {
    type: WalletAccountsActions.CLEAR_WALLET_ACCOUNTS
}

export interface IUpdateWalletAccount {
    type: WalletAccountsActions.UPDATE_WALLET_ACCOUNT,
    wallet: IWalletAccountData
}

export type TWalletAccountsAction = ISetWalletAccounts | IClearWalletAccounts | IUpdateWalletAccount | ISetWalletAccountsLoading;