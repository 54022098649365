export interface IHeaderMenuState {
    switchStatus: boolean,
    isSwitchDisabled: boolean,
}

export enum HeaderMenuActionList {
    SET_HEADER_MENU_SWITCH_STATUS = "SET_HEADER_MENU_SWITCH_STATUS",
    SET_HEADER_MENU_SWITCH_DISABLING = "SET_HEADER_MENU_SWITCH_DISABLING"
}

export interface ISetHeaderMenuSwitchStatus {
    type: HeaderMenuActionList.SET_HEADER_MENU_SWITCH_STATUS,
    switchStatus: boolean
}

export interface ISetHeaderMenuSwitchDisabling {
    type: HeaderMenuActionList.SET_HEADER_MENU_SWITCH_DISABLING,
    isSwitchDisabled: boolean
}

export type THeaderMenuAction = ISetHeaderMenuSwitchStatus | ISetHeaderMenuSwitchDisabling;