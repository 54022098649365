export interface ISanctionCountriesItem {
    // id: number,
    countryName?: string,
    countryIsoCode2?: string,
    countryIsoCode3?: string,
}

export interface ISanctionCountriesState {
    sanctions: ISanctionCountriesItem[],
    isLoading: boolean
}

export enum SanctionCountriesActions {
    SET_SANCTION_COUNTRIES = "SET_SANCTION_COUNTRIES",
    CLEAR_SANCTION_COUNTRIES = "CLEAR_SANCTION_COUNTRIES",
    SET_SANCTION_COUNTRIES_LOADING = "SET_SANCTION_COUNTRIES_LOADING",
}

export interface ISetSanctionCountries {
    type: SanctionCountriesActions.SET_SANCTION_COUNTRIES,
    sanctions: ISanctionCountriesItem[],
}

export interface IClearSanctionCountries {
    type: SanctionCountriesActions.CLEAR_SANCTION_COUNTRIES
}

export interface ISetSanctionCountriesLoading {
    type: SanctionCountriesActions.SET_SANCTION_COUNTRIES_LOADING,
    loading: boolean
}

export type TSanctionCountriesAction = ISetSanctionCountries | IClearSanctionCountries | ISetSanctionCountriesLoading;