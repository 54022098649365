import React, {createContext} from "react";
import {useModalImageWindow} from "../hooks/useModalImageWindow";
import {ModalImageWindow} from "../components/common/modal-image-window";

export const ModalImageWindowContext = createContext<ReturnType<typeof useModalImageWindow>>({} as ReturnType<typeof useModalImageWindow>);

interface IModalImageWindowProviderProps {
  children: React.ReactNode
}

function ModalImageWindowProvider({children}: IModalImageWindowProviderProps) {
  const modalImageWindow = useModalImageWindow();
  const {modalImageData, openModalImage, handleCloseImage} = modalImageWindow;
  return (
    <ModalImageWindowContext.Provider value={modalImageWindow}>
      {children}
      <ModalImageWindow imageData={modalImageData} openModal={openModalImage} handleClose={handleCloseImage}/>
    </ModalImageWindowContext.Provider>
  );
}

export default ModalImageWindowProvider;