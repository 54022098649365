import {Action} from "redux";
import {store} from "../../index";
import {TOrder} from "../../../types/table";
import {USE_SERVER} from "../../../constants/constants";
import {handleGeneralError} from "../error-handler";
import {ThunkDispatch} from "redux-thunk";
import {IApplication, IApplicationInfo, TApplicationStatus} from "../../../types/store/applications";
import {
  ApplicationsListActions,
  IClearApplicationsList,
  ISetApplicationDetailInfo,
  ISetApplicationsList,
  ISetApplicationsListError,
  ISetApplicationsListItemsPerPage,
  ISetApplicationsListLoading,
  ISetApplicationsListPage,
  ISetApplicationsListSort,
  ISetApplicationsListStatus
} from "../../../types/store/applications/applications-list";
import {
  IApplicationsListByAccountIdRequestParams,
  IApplicationsListRequestParams
} from "../../../types/service/request/applications-request";
import ApplicationsService from "../../../sevices/applications-service";
import ApplicationsTestService from "../../../sevices/test/applications-test-service";

/**
 * It returns an object with a type property and a payload property
 * @param {IApplication[]} applications - IApplication[] - the list of applications to be set
 * @param {number} totalPages - number - the total pages of applications in the database
 */
export const setApplicationsList = (applications: IApplication[], totalPages: number): ISetApplicationsList => ({
  type: ApplicationsListActions.SET_APPLICATIONS_LIST,
  payload: {
    applications,
    totalPages,
  }
});

/**
 * It returns an object with a type property and a loading property
 * @param {boolean} loading - boolean
 */
export const setApplicationsListLoading = (loading: boolean): ISetApplicationsListLoading => ({
  type: ApplicationsListActions.SET_APPLICATIONS_LIST_LOADING,
  loading
});

/**
 * It returns an object with a type property and a page property
 * @param {number} page - number
 */
export const setApplicationsListPage = (page: number): ISetApplicationsListPage => ({
  type: ApplicationsListActions.SET_APPLICATIONS_LIST_PAGE,
  page
});

/**
 * It returns an object with a type property and an itemsPerPage property
 * @param {number} perPage - number - The number of items per page to set.
 */
export const setApplicationsListItemsPerPage = (perPage: number): ISetApplicationsListItemsPerPage => ({
  type: ApplicationsListActions.SET_APPLICATIONS_LIST_ITEMS_PER_PAGE,
  perPage
});

/**
 * It returns an object with a type property and a sort property
 * @param {TOrder} sort - TOrder
 */
export const setApplicationsListSort = (sort: TOrder): ISetApplicationsListSort => ({
  type: ApplicationsListActions.SET_APPLICATIONS_LIST_SORT,
  sort
});

/**
 * It returns an object with a type property and a status property
 * @param {number} [status] - number - The status of the transaction list.
 */
export const setApplicationsListStatus = (status: TApplicationStatus): ISetApplicationsListStatus => ({
  type: ApplicationsListActions.SET_APPLICATIONS_LIST_STATUS,
  status
});

/**
 * It returns an object with a type property and two other properties
 * @param {number} errorCode - number - The error code that was returned from the API.
 * @param {string} errorMessage - string - The error message to display to the user.
 */
export const setApplicationsListError = (errorCode: number, errorMessage: string): ISetApplicationsListError => ({
  type: ApplicationsListActions.SET_APPLICATIONS_LIST_ERROR,
  errorCode,
  errorMessage
});

export const clearApplicationsList = (): IClearApplicationsList => ({
  type: ApplicationsListActions.CLEAR_APPLICATIONS_LIST,
});

/**
 * It returns an object with the current page, items per page
 * @returns A function that returns an object.
 */
const getFetchApplicationsListParams = (): IApplicationsListRequestParams => {
  const {page, perPage, status } = store.getState().applicationsList;
  return {
    cur_page: page,
    per_page: perPage,
    status: "received",
  } as IApplicationsListRequestParams;
};

export const setApplicationDetails = (detailInfo: IApplicationInfo): ISetApplicationDetailInfo => ({
  type: ApplicationsListActions.SET_APPLICATION_DETAIL_INFO,
  detailInfo
});

/**
 * It fetches applications list from the server and dispatches the result to the store
 * @param {IApplicationsListRequestParams} params - ApplicationsListRequestParams = getFetchApplicationsListParams()
 * @returns It is a function that returns a function that returns a promise.
 */
export const fetchApplicationsList = (params: IApplicationsListRequestParams = getFetchApplicationsListParams()) => async (dispatch: ThunkDispatch<unknown, unknown, Action>) => {
  try {
    dispatch(setApplicationsListLoading(true));

    const response =
            USE_SERVER
              ?
              await ApplicationsService.applicationsList(params)
              :
              await ApplicationsTestService.applicationsList(params);

    const {
      list,
      totalPages,
    } = response.data.data;

    dispatch(setApplicationsList(list, totalPages));
  } catch (e: unknown){
    await dispatch(handleGeneralError(e));
  } finally {
    dispatch(setApplicationsListLoading(false));
  }
};

export const fetchApplicationsListByAccountType = (params: IApplicationsListByAccountIdRequestParams) => async (dispatch: ThunkDispatch<unknown, unknown, Action>) => {
  try {
    dispatch(setApplicationsListLoading(true));
    dispatch(clearApplicationsList());

    const response =
      USE_SERVER
        ?
        await ApplicationsService.applicationsListByAccountId(params)
        :
        await ApplicationsTestService.applicationsListByAccountId(params);

    const {
      list
    } = response.data.data;

    dispatch(setApplicationsList(list, 1));
  } catch (e: unknown){
    await dispatch(handleGeneralError(e));
  } finally {
    dispatch(setApplicationsListLoading(false));
  }
};