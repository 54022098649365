import {Box, Card, CardContent, CardHeader, Stack} from "@mui/material";
import React, {FC, useContext} from "react";
import AccountDetailGridRow from "../account-detail-grid-row";
import {useTranslation} from "react-i18next";
import {ModalImageWindowContext} from "../../../providers/modalImageWindowProvider";
import {IAccountOndatoImageData} from "../../../types/store/account/account-details";

interface IProps {
  cardHeader?: string;
  documents: IAccountOndatoImageData[],
}

const AccountOndatoDocuments: FC<IProps> = ({cardHeader, documents}) => {
  const {handleOpenImage} = useContext(ModalImageWindowContext);

  const {t} = useTranslation();

  return (
    <Card sx={{mb: 2}}>
      {cardHeader && (<CardHeader title={cardHeader}/>)}
      {!cardHeader && documents.length > 0 && <CardHeader title={documents[0].type}/> }
      <CardContent>
        <Stack direction={"row"}>
          {documents.map((document, documentIndex) => (
            <Box key={documentIndex}>
              <Box
                component={"img"}
                src={document.s3Link}
                alt={"Photo"}
                loading="lazy"
                sx={{
                  maxWidth: "200px",
                  height: "100px",
                  objectFit: "contain",
                  lineHeight: "100%",
                  cursor: "pointer",
                  marginRight: "2rem",
                }}
                onClick={handleOpenImage && handleOpenImage(document.s3Link)}
              />
              {("side" in document) && <AccountDetailGridRow title={document.side || ""} value={t("account.details.document.side") as string}/>}
            </Box>
          ))}
        </Stack>
      </CardContent>
    </Card>
  );
};

export default AccountOndatoDocuments;
