import {Action} from "redux";
import AccountService from "../../../sevices/account-service";
import {
  AccountDetailsActions,
  IAccountDetail,
  IAccountDetailsCorporate,
  IAccountDetailsIndividual,
  IAccountDocument,
  IAccountPhoto,
  IAccountWallet,
  IClearAccountDetails,
  ISetAccountArbipayStatus,
  ISetAccountDetails,
  ISetAccountDetailsCorporate,
  ISetAccountDetailsError,
  ISetAccountDetailsIndividual,
  ISetAccountDetailsLoading,
  ISetAccountDocuments,
  ISetAccountOndatoDetails,
  ISetAccountOndatoImages,
  ISetAccountOndataStatus,
  ISetAccountPhotos,
  ISetAccountSatchelStatus,
  ISetAccountWallets,
  TStatusOndata,
  IAccountOndatoDetail,
  IAccountOndatoImageData,
  IAccountSignedContract,
  ISetAccountSignedContracts,
  IAccountIdentityVerificationInfo,
  ISetAccountIdentityVerificationInfo,
  IAccountSatchelWalletsAllTypes,
  ISetAccountSatchelWallets,
} from "../../../types/store/account/account-details";
import {USE_SERVER} from "../../../constants/constants";
import AccountTestService from "../../../sevices/test/account-test-service";
import {ThunkDispatch} from "redux-thunk";
import {checkErrorResponseCodeMessage} from "../../../utils/error-helper";
import {AxiosError} from "axios";
import {AccountDetailsResponse} from "../../../types/service/response/accounts-response";
import {handleGeneralError} from "../error-handler";
import {AccountStatusEnum} from "../../../components/account/account-statuses";

/**
 * It takes an account object and returns an object with a type and the account object
 * @param {IAccountDetail} account - IAccountDetail - this is the interface that defines the shape of the account object.
 */
export const setAccountDetails = (
  account: IAccountDetail
): ISetAccountDetails => ({
  type: AccountDetailsActions.SET_ACCOUNT_DETAILS,
  account,
});

export const setAccountDetailsIndividual = (
  accountIndividual: IAccountDetailsIndividual
): ISetAccountDetailsIndividual => ({
  type: AccountDetailsActions.SET_ACCOUNT_DETAILS_INDIVIDUAL,
  accountIndividual,
});

export const setAccountDetailsCorporate = (
  accountCorporate: IAccountDetailsCorporate
): ISetAccountDetailsCorporate => ({
  type: AccountDetailsActions.SET_ACCOUNT_DETAILS_CORPORATE,
  accountCorporate,
});

export const setAccountDocuments = (
  documents: IAccountDocument[]
): ISetAccountDocuments => ({
  type: AccountDetailsActions.SET_ACCOUNT_DOCUMENTS,
  documents,
});

export const setAccountPhotos = (
  photos: IAccountPhoto[]
): ISetAccountPhotos => ({
  type: AccountDetailsActions.SET_ACCOUNT_PHOTOS,
  photos,
});

export const setAccountIdentityVerification = (
  identityVerification: IAccountIdentityVerificationInfo
): ISetAccountIdentityVerificationInfo => ({
  type: AccountDetailsActions.SET_ACCOUNT_IDENTITY_VERIFICATION_INFO,
  identityVerification,
});

export const setAccountWallets = (
  wallets: IAccountWallet[]
): ISetAccountWallets => ({
  type: AccountDetailsActions.SET_ACCOUNT_WALLETS,
  wallets,
});

export const setAccountSatchelWallets = (
  satchelWallets: IAccountSatchelWalletsAllTypes
): ISetAccountSatchelWallets => ({
  type: AccountDetailsActions.SET_ACCOUNT_SATCHEL_WALLETS,
  satchelWallets,
});

/**
 * It returns an object with a type and a loading property
 * @param {boolean} status - boolean - This is the status of the loading.
 */
export const setAccountDetailsLoading = (
  status: boolean
): ISetAccountDetailsLoading => ({
  type: AccountDetailsActions.SET_ACCOUNT_DETAILS_LOADING,
  loading: status,
});

/**
 * It returns an object with a type property set to CLEAR_ACCOUNT_DETAILS
 */
export const clearAccountDetails = (): IClearAccountDetails => ({
  type: AccountDetailsActions.CLEAR_ACCOUNT_DETAILS,
});

export const setAccountSatchelStatus = (status: AccountStatusEnum): ISetAccountSatchelStatus => ({
  type: AccountDetailsActions.SET_ACCOUNT_SATCHEL_STATUS,
  status
});

export const setAccountArbipayStatus = (status: AccountStatusEnum): ISetAccountArbipayStatus => ({
  type: AccountDetailsActions.SET_ACCOUNT_ARBIPAY_STATUS,
  status
});

export const setAccountOndataStatus = (status: TStatusOndata): ISetAccountOndataStatus => ({
  type: AccountDetailsActions.SET_ACCOUNT_ONDATA_STATUS,
  status
});

const setAccountOndatoDetails = (ondatoDetails: IAccountOndatoDetail[]): ISetAccountOndatoDetails => ({
  type: AccountDetailsActions.SET_ACCOUNT_ONDATO_DETAILS,
  ondatoDetails
});

const setAccountOndatoImages = (
  ondatoSessionId: string,
  docImages: IAccountOndatoImageData[] | undefined,
  photoImages: IAccountOndatoImageData[] | undefined): ISetAccountOndatoImages => ({

  type: AccountDetailsActions.SET_ACCOUNT_ONDATO_IMAGES,
  ondatoSessionId,
  docImages,
  photoImages
});

const setAccountSignedContracts = (signedContracts: IAccountSignedContract[]): ISetAccountSignedContracts => ({
  type: AccountDetailsActions.SET_ACCOUNT_SIGNED_CONTRACTS,
  signedContracts
});

/**
 * It returns an object with a type property and a payload property.
 *
 * The type property is a string that is the same as the string in the AccountDetailsActions.ts file.
 *
 * The payload property is an object that contains the errorCode and errorMessage.
 *
 * The errorCode and errorMessage are passed in as parameters to the function.
 * @param {number} errorCode - number - The error code that was returned from the API
 * @param {string} errorMessage - string - The error message to display to the user.
 */
export const setAccountDetailsError = (
  errorCode: number,
  errorMessage: string
): ISetAccountDetailsError => ({
  type: AccountDetailsActions.SET_ACCOUNT_DETAILS_ERROR,
  payload: {
    errorCode,
    errorMessage,
  },
});

/**
 * It fetches account details from the server and dispatches the result to the redux store
 * @param {string} accountId - string - the account id
 * @returns An object with a type of FETCH_ACCOUNT_DETAILS and a payload of accountId.
 */
export const fetchAccountDetails =
  (accountId: string) =>
    async (dispatch: ThunkDispatch<unknown, unknown, Action>) => {
      try {
        dispatch(clearAccountDetails());
        dispatch(setAccountDetailsLoading(true));

        const response = USE_SERVER
          ? await AccountService.accountDetails(accountId)
          : await AccountTestService.accountDetails(accountId);

        const { result: account } = response.data;
        
        //TODO: crutch from RBP-1280
        const statusOndata = account.statusOndata !== "CROSS_CHECKED" ? null : account.statusOndata;
        
        await dispatch(setAccountDetails({ ...account, statusOndata }));
        // if (account.type === "individual") {
        await Promise.allSettled([
        //   dispatch(fetchAccountDetailsIndividual(accountId)),
          dispatch(fetchAccountDocuments(accountId)),
          dispatch(fetchAccountPhotos(accountId)),
          dispatch(fetchAccountIdentityVerificationInfo(accountId)),
          dispatch(fetchAccountSatchelWallets(accountId)),
        ]);
        // } else if (account.type === "corporate") {
        //   await Promise.allSettled([
        //     dispatch(fetchAccountDetailsCorporate(accountId)),
        //     dispatch(fetchAccountDocuments(accountId)),
        //     dispatch(fetchAccountWallets(accountId)),
        //   ]);
        // }
      } catch (e: unknown) {
        await dispatch(handleGeneralError(e));
        const { response } = e as AxiosError<AccountDetailsResponse>;
        const { errorCode, errorMessage } = checkErrorResponseCodeMessage(
          response?.status,
          response?.data?.errors?.[0]
        );
        dispatch(setAccountDetailsError(errorCode, errorMessage));
      } finally {
        dispatch(setAccountDetailsLoading(false));
      }
    };

/**
 * Function to send status of account to server.
 * @param {string} accountId - string - the account id
 * @param {string} hmac - The HMAC of the account.
 * @param {boolean} verificationResult - boolean - true if the account is verified, false if it's not
 * @param {string} reason - string - the reason for the verification result
 */
export const setVerificationResult =
  (
    accountId: string,
    hmac: string,
    verificationResult: boolean,
    reason: string
  ) =>
    async (dispatch: ThunkDispatch<unknown, unknown, Action>) => {
      try {
        dispatch(setAccountDetailsLoading(true));
        await AccountService.setVerificationResult(
          accountId,
          hmac,
          verificationResult,
          reason
        );
        await dispatch(fetchAccountDetails(accountId));
      } catch (e: unknown) {
        await dispatch(handleGeneralError(e));
      } finally {
        dispatch(setAccountDetailsLoading(false));
      }
    };

export const fetchAccountDetailsIndividual =
  (accountId: string) =>
    async (dispatch: ThunkDispatch<unknown, unknown, Action>) => {
      try {
        const response = USE_SERVER
          ? await AccountService.accountDetailsIndividual(accountId)
          : await AccountTestService.accountDetailsIndividual(accountId);

        const { result: account } = response.data;
        dispatch(setAccountDetailsIndividual(account));
      } catch (e: unknown) {
        await dispatch(handleGeneralError(e));
      }
    };

export const fetchAccountDetailsCorporate =
  (accountId: string) =>
    async (dispatch: ThunkDispatch<unknown, unknown, Action>) => {
      try {
        const response = USE_SERVER
          ? await AccountService.accountDetailsCorporate(accountId)
          : await AccountTestService.accountDetailsCorporate(accountId);

        const { data } = response.data;
        dispatch(setAccountDetailsCorporate(data));
      } catch (e: unknown) {
        await dispatch(handleGeneralError(e));
      }
    };

export const fetchAccountDocuments =
  (accountId: string) =>
    async (dispatch: ThunkDispatch<unknown, unknown, Action>) => {
      try {
        const response = USE_SERVER
          ? await AccountService.accountDocuments(accountId)
          : await AccountTestService.accountDocuments(accountId);

        const { result: documents } = response.data;
        dispatch(setAccountDocuments(documents));
      } catch (e: unknown) {
        await dispatch(handleGeneralError(e));
      }
    };

export const fetchAccountPhotos =
  (accountId: string) =>
    async (dispatch: ThunkDispatch<unknown, unknown, Action>) => {
      try {
        const response = USE_SERVER
          ? await AccountService.accountPhotos(accountId)
          : await AccountTestService.accountPhotos(accountId);

        const { result: photos } = response.data;
        dispatch(setAccountPhotos(photos));
      } catch (e: unknown) {
        await dispatch(handleGeneralError(e));
      }
    };

export const fetchAccountIdentityVerificationInfo =
  (accountId: string) =>
    async (dispatch: ThunkDispatch<unknown, unknown, Action>) => {
      try {
        const response = USE_SERVER
          ? await AccountService.accountIdentityVerificationInfo(accountId)
          : await AccountTestService.accountIdentityVerificationInfo(accountId);

        const { result } = response.data;
        dispatch(setAccountIdentityVerification(result));
      } catch (e: unknown) {
        await dispatch(handleGeneralError(e));
      }
    };
  
export const fetchAccountWallets =
  (accountId: string) =>
    async (dispatch: ThunkDispatch<unknown, unknown, Action>) => {
      try {
        const response = USE_SERVER
          ? await AccountService.accountWallets(accountId)
          : await AccountTestService.accountWallets(accountId);

        const { result: wallets } = response.data;
        dispatch(setAccountWallets(wallets));
      } catch (e: unknown) {
        await dispatch(handleGeneralError(e));
      }
    };

export const fetchAccountSatchelWallets =
  (accountId: string) =>
    async (dispatch: ThunkDispatch<unknown, unknown, Action>) => {
      try {
        const response =
        USE_SERVER
          ?
          await AccountService.accountSatchelWallets(accountId)
          : 
          await AccountTestService.accountSatchelWallets(accountId);

        const { result: wallets } = response.data;
        dispatch(setAccountSatchelWallets(wallets));
      } catch (e: unknown) {
        await dispatch(handleGeneralError(e));
      }
    };

export const fetchAccountOndatoDetails =
  (accountId: string) =>
    async (dispatch: ThunkDispatch<unknown, unknown, Action>) => {
      try {
        const response =
        USE_SERVER ?
          await AccountService.accountOndatoDetails(accountId)
          :
          await AccountTestService.accountOndatoDetails(accountId);

        const { result } = response.data;
        dispatch(setAccountOndatoDetails(result));
      } catch (e: unknown) {
        await dispatch(handleGeneralError(e));
      }
    };

export const fetchAccountOndatoImages =
  (ondataSessionId: string) =>
    async (dispatch: ThunkDispatch<unknown, unknown, Action>) => {
      try {
        const response =
        USE_SERVER ?
          await AccountService.accountOndatoSessionData(ondataSessionId)
          :
          await AccountTestService.accountOndatoSessionData(ondataSessionId);

        const { docImages, photoImages } = response.data.result;
        dispatch(setAccountOndatoImages(ondataSessionId, docImages, photoImages));
      } catch (e: unknown) {
        await dispatch(handleGeneralError(e));
      }
    };

export const fetchAccountSignedContracts =
  (accountId: string) =>
    async (dispatch: ThunkDispatch<unknown, unknown, Action>) => {
      try {
        const response =
        USE_SERVER ?
          await AccountService.accountSignedContracts(accountId)
          :
          await AccountTestService.accountSignedContracts(accountId);

        const { result } = response.data;
        dispatch(setAccountSignedContracts(result));
      } catch (e: unknown) {
        // await dispatch(handleGeneralError(e));
      }
    };
