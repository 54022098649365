import yn from "yn";

/** Link to backend server. */
export const API_URL = process.env.REACT_APP_API_URL;

/** This is a constant that is used to determine whether or not to use the server. */
export const USE_SERVER = yn(process.env.REACT_APP_USE_SERVER) === undefined ? true : yn(process.env.REACT_APP_USE_SERVER);

/** This is a constant that is used to determine how long a status will be saved in localStorage. */
export const LIFETIME_STATUSES = process.env.REACT_APP_LIFETIME_STATUSES ? +process.env.REACT_APP_LIFETIME_STATUSES : 0;

export const ONDATO_IDENTIFICATION_LINK = process.env.REACT_APP_ONDATO_IDENTIFICATION_LINK || "";

// console.log(API_URL, USE_SERVER, LIFETIME_STATUSES);