import React, {FC} from "react";
import {Card, CardContent, CardHeader} from "@mui/material";
import {
  IAccountCorporateDocumentCompany,
  IAccountCorporateDocumentPerson
} from "../../../types/store/account/account-details";
import AccountDetailGridRow from "../account-detail-grid-row";
import {useTranslation} from "react-i18next";

interface IAccountDocumentsBlockCorporateProps {
    cardTitle?: string,
    documents: IAccountCorporateDocumentCompany[] | IAccountCorporateDocumentPerson[]
}

const AccountDocumentsBlockCorporate: FC<IAccountDocumentsBlockCorporateProps> = ({documents, cardTitle}) => {
  const {t} = useTranslation();

  return (
    <>
      {documents.map((document, documentIndex) => (
        <Card key={documentIndex} sx={{mb: 2}}>
          <CardHeader sx={{fontSize: "1.2rem"}} title={t("account.details.documentType") + ": " + document.type}/>
          <CardContent>
            {document.images.map((image, imageIndex) => (
              <AccountDetailGridRow
                key={imageIndex}
                title={"File"}
                value={image.s3_link}
                contentType={image.content_type || ""}
              />
            ))}
            {("number" in document) && <AccountDetailGridRow title={t("account.details.document.number")} value={document.number}/>}
            {("citizenCountry" in document) && <AccountDetailGridRow title={t("account.details.document.citizenCountry")} value={document.citizenCountry}/>}
            {("issueCountry" in document) &&<AccountDetailGridRow title={t("account.details.document.issueCountry")} value={document.issueCountry}/>}
            {("issueDate" in document) && <AccountDetailGridRow title={t("account.details.document.issueDate")} value={document.issueDate}/>}
            {("expiryDate" in document) && <AccountDetailGridRow title={t("account.details.document.expiryDate")} value={document.expiryDate}/>}
            {("issuing" in document) && <AccountDetailGridRow title={t("account.details.document.issuing")} value={document.issuing}/>}
          </CardContent>
        </Card>
      ))}
    </>
  );
};

export default AccountDocumentsBlockCorporate;