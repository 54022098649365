import {
  AccountTransactionListActions,
  IAccountTransactionListState,
  TAccountTransactionListAction
} from "../../../types/store/account/account-transaction-list";

const initialState: IAccountTransactionListState = {
  transactionsByPage: [],
  isLoading: false,
  currentPage: 0,
};

export const accountTransactionListReducer = (state = initialState, action: TAccountTransactionListAction): IAccountTransactionListState => {
  switch (action.type) {
  case AccountTransactionListActions.SET_ACCOUNT_TRANSACTION_LIST:
    return {
      ...state,
      transactionsByPage: [
        ...state.transactionsByPage,
        action.list
      ],
      // prevPage: state.transactionsByPage.length > 0 ? state.transactionsByPage[state.transactionsByPage.length - 1].nextOffsetKey : null,
      lastPage: action.list.nextOffsetKey
    };
  case AccountTransactionListActions.SET_ACCOUNT_TRANSACTION_LIST_LOADING:
    return {...state, isLoading: action.loading};
  case AccountTransactionListActions.SET_ACCOUNT_TRANSACTION_LIST_CURRENT_PAGE:
    return {...state, currentPage: action.page};
  case AccountTransactionListActions.CLEAR_ACCOUNT_TRANSACTION_LIST:
    return initialState;
  default:
    return state;
  }
};