import {CardListActions, ICardListState, TCardListAction} from "../../../types/store/card/card-list";
import {ICard} from "../../../types/store/card";

const initialState: ICardListState = {
  cards: [] as ICard[],
  isLoading: false,
  page: 1,
  totalPages: 1,
  perPage: 10,
};

export const cardListReducer = (state = initialState, action: TCardListAction): ICardListState => {
  switch (action.type) {
  case CardListActions.SET_CARD_LIST:
    return {...state, cards: action.cards, totalPages: action.totalPages };
  case CardListActions.SET_CARD_CUR_PAGE:
    return {...state, page: action.page };
  case CardListActions.SET_CARD_PER_PAGE:
    return {...state, perPage: action.perPage };
  case CardListActions.SET_CARD_LIST_LOADING:
    return {...state, isLoading: action.loading};
  case CardListActions.CLEAR_CARD_LIST:
    return initialState;
  default:
    return state;
  }
};