import React, { FC } from "react";
import { Card, CardMedia, Modal } from "@mui/material";
import { useModalImageWindow } from "../../../hooks/useModalImageWindow";

interface ModalImageWindowProps {
  readonly handleClose: () => void;
  readonly openModal: boolean;
  readonly imageData: string;
}

export const ModalImageWindow: FC<ModalImageWindowProps> = ({imageData, openModal, handleClose}) => {
  return (
    <Modal
      open={openModal}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Card
        sx={{
          maxWidth: "90vw",
          maxHeight: "90vh",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          boxShadow: 24,
        }}
      >
        <CardMedia
          component="img"
          alt="document image"
          image={imageData}
          sx={{
            maxWidth: "90vw",
            maxHeight: "90vh",
          }}
        />
      </Card>
    </Modal>
  );
};
