export interface IPermission {
  id: number,
  name?: string,
  description?: string,
  createdAt?: string,
  updatedAt?: string
}

export interface IPermissionsListState {
  permissions: IPermission[],
  isLoading: boolean,
  errorCode?: number,
  errorMessage?: string
}

export enum PermissionsListActions {
  SET_PERMISSIONS_LIST = "SET_PERMISSIONS_LIST",
  SET_PERMISSIONS_LIST_LOADING = "SET_PERMISSIONS_LIST_LOADING",
  SET_PERMISSIONS_LIST_ERROR = "SET_PERMISSIONS_LIST_ERROR"
}

export interface ISetPermissionsList {
  type: PermissionsListActions.SET_PERMISSIONS_LIST,
  permissions: IPermission[]
}

export interface ISetPermissionsListLoading {
  type: PermissionsListActions.SET_PERMISSIONS_LIST_LOADING,
  loading: boolean
}

export interface ISetPermissionsListError {
  type: PermissionsListActions.SET_PERMISSIONS_LIST_ERROR,
  errorCode: number,
  errorMessage: string
}

export type TPermissionsAction = ISetPermissionsList | ISetPermissionsListLoading | ISetPermissionsListError;

export interface ILocalStoragePermissions {
  permissions: IPermission[],
  expired: number
}