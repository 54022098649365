import React, { useEffect } from "react";
import { useActions } from "../../../hooks/useActions";
import { useTypedSelector } from "../../../hooks/useTypedSelector";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Grid,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import { useAccountsList } from "../../../hooks/useAccountsList";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ability from "../../../utils/can";
import { Can } from "@casl/react";
import Error404 from "../../error-pages/error-404";
import { getDateTime } from "../../../utils/time";
import CustomTablePaginationActionsNew from "../../../components/common/table-pagination-new";
import {IAccountDetail} from "../../../types/store/account/account-details";

/** A React component that displays a list of accounts. */
const AccountsList = () => {
  const {
    fetchAccountStatuses,
    fetchAccountsList,
    setAccountsListPage,
    setAccountsListItemsPerPage,
    setAccountsListStatus,
    setAccountsListSort,
  } = useActions();
  const { isLoading, accounts, page, totalPages, perPage, sort, filterStatus } =
    useTypedSelector((state) => state.accountsList);
  const { headCells } = useAccountsList();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleViewAccount =
    (account: IAccountDetail) => () => {
      navigate(`/accounts/${account.id}`);
    };

  const handlePageChange = (event: unknown, page: number) => {
    if (!isLoading) setAccountsListPage(page);
  };

  const handlePerPageChange = (perPage: number) => {
    if (!isLoading) {
      setAccountsListItemsPerPage(perPage);
      setAccountsListPage(1);
    }
  };

  const handleSortTable = () => {
    const reverseSort = sort === "DESC" ? "ASC" : "DESC";
    setAccountsListSort(reverseSort);
  };

  useEffect(() => {
    if (ability.cannot("get", "verification_list")) {
      navigate("/home");
    }
    return () => {
      setAccountsListPage(1);
      setAccountsListStatus(undefined);
    };
  }, []);

  useEffect(() => {
    fetchAccountStatuses();
    if (ability.can("get", "verification_list")) fetchAccountsList();
  }, [page, perPage, filterStatus, sort]);

  if (ability.cannot("get", "verification_list")) return <Error404 />;

  return (
    <Container maxWidth="xl">
      <Can I="get" a="verification_list" ability={ability}>
        <Grid container columns={16} spacing={3}>
          <Grid item xs={16} md={16}>
            <Card>
              <CardHeader title={t("account.table.accounts")} />
              <CardContent>
                <TableContainer component={Paper}>
                  <Table
                    sx={{ minWidth: 650 }}
                    aria-label="Accounts list table"
                    size={"small"}
                  >
                    <TableHead>
                      <TableRow
                        sx={{ "& .MuiTableCell-head": { fontWeight: 600 } }}
                      >
                        {headCells.map((headCell) => {
                          return (
                            <TableCell key={headCell.id}>
                              {headCell.id === "id" ? (
                                <TableSortLabel
                                  active={true}
                                  direction={sort === "ASC" ? "asc" : "desc"}
                                  onClick={handleSortTable}
                                >
                                  {headCell.label}
                                </TableSortLabel>
                              ) : (
                                headCell.label
                              )}
                            </TableCell>
                          );
                        })}
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {isLoading &&
                        Array(perPage)
                          .fill("")
                          .map((row, index) => (
                            <TableRow
                              key={index}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              {headCells.map((headCell, index) => {
                                return (
                                  <TableCell key={index}>
                                    <Skeleton />
                                  </TableCell>
                                );
                              })}
                              <TableCell>
                                <Skeleton height={30} />
                              </TableCell>
                            </TableRow>
                          ))}
                      {!isLoading &&
                        accounts.map((account) => (
                          <TableRow
                            key={account.id}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                            hover
                          >
                            <TableCell>{account.id}</TableCell>
                            <TableCell>{account.keycloackId}</TableCell>
                            <TableCell>{account.emailAddress}</TableCell>
                            <TableCell>{account.phoneNumber}</TableCell>
                            <TableCell>
                              {getDateTime(account.createdAt)}
                            </TableCell>
                            <TableCell>
                              <Button
                                variant={"contained"}
                                size="small"
                                onClick={handleViewAccount(account)}
                                disabled={ability.cannot("get", "account_info")}
                              >
                                {t("account.table.details")}
                              </Button>
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                    <TableFooter>
                      <TableRow>
                        <TableCell colSpan={7}>
                          <CustomTablePaginationActionsNew
                            totalPages={totalPages}
                            page={page}
                            rowsPerPage={perPage}
                            onPageChange={handlePageChange}
                            onPerPageChange={handlePerPageChange}
                          />
                        </TableCell>
                      </TableRow>
                    </TableFooter>
                  </Table>
                </TableContainer>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Can>
    </Container>
  );
};

export default AccountsList;
