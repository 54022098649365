import { HeadCell } from "../types/table";
import { IAccount } from "../types/store/account";
import { useTranslation } from "react-i18next";

/**
 * It returns an object with three properties:
 *
 * - headCells: an array of objects that describe the table's columns
 * - getStatusName: a function that returns the name of a status
 * - getStatusColor: a function that returns the color of a status
 * - statusesList: an array of statuses
 *
 * The headCells array is used by the Table component to render the table's columns
 * @returns An object with the following properties:
 * - headCells: An array of HeadCell<IAccount>
 * - getStatusName: A function that takes an id and returns a string
 * - getStatusColor: A function that takes an id and returns a string
 * - statusesList: An array of AccountStatuses
 */
export const useAccountsList = () => {
  const { t } = useTranslation();

  const headCells: HeadCell<IAccount>[] = [
    {
      id: "id",
      numeric: true,
      label: t("account.table.id"),
    },
    {
      id: "keycloackId",
      label: t("account.table.keycloackId"),
    },
    {
      id: "emailAddress",
      label: t("account.table.email"),
    },
    {
      id: "phoneNumber",
      label: t("account.table.phone"),
    },
    {
      id: "createdAt",
      label: t("account.table.created_at"),
    },
    // {
    //   id: "type",
    //   label: t("account.table.type"),
    // },
    // {
    //   id: "name",
    //   label: t("account.table.name"),
    // },
    // {
    //   id: "personalNumber",
    //   label: t("account.table.number"),
    // },
    // {
    //   id: "status",
    //   numeric: false,
    //   label: t("account.table.status"),
    //   disablePadding: false,
    //   handleColumn: (value) =>
    //     AccountStatus(value, getStatusColor, getStatusName),
    // },
  ];

  return {
    headCells,
  };
};
