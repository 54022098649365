import AccountDetailGridRow from "../../../account/account-detail-grid-row";
import React from "react";

interface IProps {
  s3Link: string,
  contentType: string
}

export const DocumentImageData = ({s3Link, contentType}: IProps) => {
  return (
    <AccountDetailGridRow
      title={""}
      value={s3Link}
      contentType={contentType}
    />
  );
};