import {ITransaction, TTransferType} from "./index";
import {TOrder} from "../../table";

export interface ITransactionsListState {
    transactions: ITransaction[],
    isLoading: boolean,
    page: number,
    totalPages: number,
    perPage: number,
    filterStatus?: number,
    sort: TOrder,
    errorCode?: number,
    errorMessage?: string,
    transferType?: TTransferType
}

export enum TransactionListActions {
    SET_TRANSACTIONS_LIST = "SET_TRANSACTIONS_LIST",
    SET_TRANSACTIONS_LIST_ERROR = "SET_TRANSACTIONS_LIST_ERROR",
    CLEAR_TRANSACTIONS_LIST = "CLEAR_TRANSACTIONS_LIST",
    SET_TRANSACTIONS_LIST_LOADING = "SET_TRANSACTIONS_LIST_LOADING",
    SET_TRANSACTIONS_LIST_PAGE = "SET_TRANSACTIONS_LIST_PAGE",
    SET_TRANSACTIONS_LIST_ITEMS_PER_PAGE = "SET_TRANSACTIONS_LIST_ITEMS_PER_PAGE",
    SET_TRANSACTIONS_LIST_STATUS = "SET_TRANSACTIONS_LIST_STATUS",
    SET_TRANSACTIONS_LIST_SORT = "SET_TRANSACTIONS_LIST_SORT",
    SET_TRANSACTIONS_LIST_TRANSFER_TYPE = "SET_TRANSACTIONS_LIST_TRANSFER_TYPE"
}

export interface ISetTransactionsList {
    type: TransactionListActions.SET_TRANSACTIONS_LIST,
    payload: {
        transactions: ITransaction[],
        totalPages: number
    }
}

export interface IClearTransactionList {
    type: TransactionListActions.CLEAR_TRANSACTIONS_LIST;
}

export interface ISetTransactionsListLoading {
    type: TransactionListActions.SET_TRANSACTIONS_LIST_LOADING,
    loading: boolean
}

export interface ISetTransactionsListPage {
    type: TransactionListActions.SET_TRANSACTIONS_LIST_PAGE,
    page: number
}

export interface ISetTransactionsListItemsPerPage {
    type: TransactionListActions.SET_TRANSACTIONS_LIST_ITEMS_PER_PAGE,
    perPage: number
}

export interface ISetTransactionsListError {
    type: TransactionListActions.SET_TRANSACTIONS_LIST_ERROR,
    errorCode: number,
    errorMessage: string
}

export interface ISetTransactionsListSort {
    type: TransactionListActions.SET_TRANSACTIONS_LIST_SORT,
    sort: TOrder
}

export interface ISetTransactionsListStatus {
    type: TransactionListActions.SET_TRANSACTIONS_LIST_STATUS,
    status?: number
}

export interface ISetTransactionsListTransferType {
    type: TransactionListActions.SET_TRANSACTIONS_LIST_TRANSFER_TYPE,
    transferType: TTransferType
}

export type TTransactionsListAction =
    ISetTransactionsList
    | IClearTransactionList
    | ISetTransactionsListLoading
    | ISetTransactionsListPage
    | ISetTransactionsListItemsPerPage
    | ISetTransactionsListError
    | ISetTransactionsListSort
    | ISetTransactionsListStatus
    | ISetTransactionsListTransferType