import React, { useEffect } from "react";
import {
  Autocomplete,
  Box,
  Button,
  Grid,
  MenuItem,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import { IBeneficiariesForm } from "./types";
import { DatePicker } from "@mui/lab";
import { countries } from "../../../../utils/countries";

interface IProps {
  index: number;
  removeBeneficiary: (index: number) => void;
  disabled?: boolean;
}

export function BeneficiaryForm({
  removeBeneficiary,
  index,
  disabled,
}: IProps) {
  const { control } = useFormContext<IBeneficiariesForm>();

  const documentTypes = ["ID", "PASSPORT"];

  const theme = useTheme();
  const mdAndUp = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Box my={2}>
      <Grid container spacing={2} mb={2}>
        <Grid item xs={6}>
          <Typography variant={"h4"}>Personal info</Typography>
        </Grid>
        <Grid item xs={6} display={"flex"} justifyContent={"flex-end"}>
          <Button
            variant={"contained"}
            color={"error"}
            onClick={() => {
              removeBeneficiary(index);
            }}
          >
            Remove beneficiary
          </Button>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Controller
            control={control}
            name={`beneficiaries.${index}.firstName`}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                label={"First name"}
                fullWidth
                error={fieldState.invalid}
                helperText={fieldState.error?.message}
                disabled={disabled}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <Controller
            control={control}
            name={`beneficiaries.${index}.lastName`}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                label={"Last name"}
                fullWidth
                error={fieldState.invalid}
                helperText={fieldState.error?.message}
                disabled={disabled}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <Controller
            control={control}
            name={`beneficiaries.${index}.dateOfBirthday`}
            render={({ field, fieldState }) => (
              <DatePicker
                {...field}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    label={"Date of birth"}
                    error={fieldState.invalid}
                    helperText={fieldState.error?.message}
                    disabled={disabled}
                  />
                )}
                disableFuture
              />
            )}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <Controller
            control={control}
            name={`beneficiaries.${index}.nationality`}
            render={({ field, fieldState }) => (
              <Autocomplete
                {...field}
                fullWidth
                disablePortal
                options={countries}
                getOptionLabel={(option) => option.name}
                value={countries.find(
                  (item) => item.abbreviation === field.value
                )}
                onChange={(e, option) => {
                  field.onChange(option?.abbreviation);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={"Nationality"}
                    fullWidth
                    error={fieldState.invalid}
                    helperText={fieldState.error?.message}
                    disabled={disabled}
                  />
                )}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            control={control}
            name={`beneficiaries.${index}.personalNumber`}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                label={"Personal number"}
                fullWidth
                error={fieldState.invalid}
                helperText={fieldState.error?.message}
                disabled={disabled}
              />
            )}
          />
        </Grid>
        {mdAndUp && (
          <Grid item xs={0} md={6}>
            <></>
          </Grid>
        )}

        <Grid item xs={12}>
          <Typography variant={"h4"}>Documents</Typography>
        </Grid>

        <Grid item xs={12} md={6}>
          <Controller
            control={control}
            name={`beneficiaries.${index}.documents.${0}.type`}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                select
                label={"Document type"}
                fullWidth
                error={fieldState.invalid}
                helperText={fieldState.error?.message}
                disabled={disabled}
              >
                <MenuItem value={""}></MenuItem>
                {documentTypes.map((doc) => (
                  <MenuItem key={doc} value={doc}>
                    {doc}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <Controller
            control={control}
            name={`beneficiaries.${index}.documents.${0}.number`}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                label={"Document number"}
                fullWidth
                error={fieldState.invalid}
                helperText={fieldState.error?.message}
                disabled={disabled}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <Controller
            control={control}
            name={`beneficiaries.${index}.documents.${0}.issueCountry`}
            render={({ field, fieldState }) => (
              <Autocomplete
                {...field}
                fullWidth
                disablePortal
                options={countries}
                getOptionLabel={(option) => option.name}
                value={countries.find(
                  (item) => item.abbreviation === field.value
                )}
                onChange={(e, option) => {
                  field.onChange(option?.abbreviation);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={"Country of issue"}
                    fullWidth
                    error={fieldState.invalid}
                    helperText={fieldState.error?.message}
                    disabled={disabled}
                  />
                )}
              />
            )}
          />
        </Grid>

        <Grid item xs={12}>
          <Typography variant={"h4"}>Address</Typography>
        </Grid>

        <Grid item xs={12} md={6}>
          <Controller
            control={control}
            name={`beneficiaries.${index}.address.countryCode`}
            render={({ field, fieldState }) => (
              <Autocomplete
                {...field}
                fullWidth
                disablePortal
                options={countries}
                getOptionLabel={(option) => option.name}
                value={countries.find(
                  (item) => item.abbreviation === field.value
                )}
                onChange={(e, option) => {
                  field.onChange(option?.abbreviation);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={"Country"}
                    fullWidth
                    error={fieldState.invalid}
                    helperText={fieldState.error?.message}
                    disabled={disabled}
                  />
                )}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <Controller
            control={control}
            name={`beneficiaries.${index}.address.city`}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                label={"City"}
                fullWidth
                error={fieldState.invalid}
                helperText={fieldState.error?.message}
                disabled={disabled}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <Controller
            control={control}
            name={`beneficiaries.${index}.address.streetAddress`}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                label={"Address line"}
                fullWidth
                error={fieldState.invalid}
                helperText={fieldState.error?.message}
                disabled={disabled}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <Controller
            control={control}
            name={`beneficiaries.${index}.address.zipCode`}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                label={"ZIP code"}
                fullWidth
                error={fieldState.invalid}
                helperText={fieldState.error?.message}
                disabled={disabled}
              />
            )}
          />
        </Grid>

        <Grid item xs={12}>
          <Typography variant={"h4"}>Contacts</Typography>
        </Grid>

        <Grid item xs={12} md={6}>
          <Controller
            control={control}
            name={`beneficiaries.${index}.phoneNumber`}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                label={"Phone number"}
                fullWidth
                error={fieldState.invalid}
                helperText={fieldState.error?.message}
                disabled={disabled}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <Controller
            control={control}
            name={`beneficiaries.${index}.emailAddress`}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                label={"Email"}
                fullWidth
                error={fieldState.invalid}
                helperText={fieldState.error?.message}
                disabled={disabled}
              />
            )}
          />
        </Grid>
      </Grid>
    </Box>
  );
}
