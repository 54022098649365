import {IRoleListState, RoleListActions, TRoleListAction} from "../../../types/store/role/role-list";

const initialState: IRoleListState = {
  roles: [],
  isLoading: false,
  confirmDelete: false
};

export const roleListReducer = (state: IRoleListState = initialState, action: TRoleListAction): IRoleListState => {
  switch (action.type){
  case RoleListActions.SET_ROLE_LIST:
    return {...state, roles: action.roles};
  case RoleListActions.SET_ROLE_LIST_LOADING:
    return {...state, isLoading: action.loading};
  case RoleListActions.SET_ROLE_LIST_CONFIRM_DELETE:
    return {...state, confirmDelete: action.confirmDelete};
  default:
    return state;
  }
};