import {ICardListRequestParams} from "../types/service/request/card-request";
import {AxiosResponse} from "axios";
import $api from "../http";
import {IGeneralResponse} from "../types/service/response/general-response";
import {ICard} from "../types/store/card";

export interface CardsResult {
  readonly page: number;
  readonly per_page: number;
  readonly total_pages: number;
  readonly list: ICard[];
}

export interface CardsResponse {
  readonly result: CardsResult;
}

/** It's a service that provides methods to interact with the API endpoints related to cards */
export default class CardService {
  static async cardInfo(id: string): Promise<AxiosResponse<IGeneralResponse<ICard>>> {
    return await $api.get("/cards" + "/" + id);
  }

  static async cardList(params: ICardListRequestParams): Promise<AxiosResponse<CardsResponse>> {
    return await $api.get("/cards", {params});
  }

  static async confirmCard(id: string): Promise<AxiosResponse<IGeneralResponse<boolean>>> {
    return await $api.get("/cards/confirm-card", {params: {id}});
  }

  static async cancelCard(id: string): Promise<AxiosResponse<IGeneralResponse<boolean>>> {
    return await $api.get("/cards/cancel-card", {params: {id}});
  }

  static async setCardNumber(id: string, cardNumber: string): Promise<AxiosResponse<IGeneralResponse<boolean>>> {
    return await $api.post("/cards/set-number", {id, cardNumber});
  }
}