import React from "react";

export const useModalImageWindow = () => {

  const [openModalImage, setOpenModalImage] = React.useState(false);
  const [modalImageData, setModalImageData] = React.useState<string>("");
  
  const handleOpenImage = (imgSrc: string) => () => {
    setModalImageData(imgSrc);
    setOpenModalImage(true);
  };

  const handleCloseImage = () => {
    setOpenModalImage(false);
    setModalImageData("");
  };

  return {
    openModalImage,
    handleOpenImage,
    modalImageData,
    handleCloseImage,
  };
};
