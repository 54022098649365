import React from "react";
import {Box, FormControl, IconButton, MenuItem, Select, useTheme} from "@mui/material";
import {
  FirstPage,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  LastPage,
} from "@mui/icons-material";
import {useTranslation} from "react-i18next";

interface TablePaginationActionsProps {
  totalPages: number;
  page: number;
  rowsPerPage: number;
  onPageChange: (
    event: React.MouseEvent<HTMLButtonElement>,
    newPage: number
  ) => void;
  onPerPageChange: (perPage: number) => void
}

/**
 *  A custom pagination component.
 */
const CustomTablePaginationActionsNew = ({
  totalPages,
  page,
  rowsPerPage,
  onPageChange,
  onPerPageChange
}: TablePaginationActionsProps) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const handleFirstPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, 1);
  };

  const handleBackButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, totalPages);
  };

  return (
    <Box display={"flex"} sx={{width: "100%", alignItems: "center", justifyContent: "flex-end", my: 1}}>
      <Box display={"flex"} sx={{ flexShrink: 0, ml: 2.5, alignItems: "center" }}>
        <Box
          component={"span"}
          mr={2}
          sx={{
            fontSize: "1rem",
            color: "black"
          }}
        >
          {t("common.Rows per page")}
        </Box>
        <FormControl variant={"standard"}>
          <Select
            value={rowsPerPage}
            onChange={(event) => onPerPageChange(+event.target.value)}
          >
            <MenuItem value={5}>5</MenuItem>
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={20}>20</MenuItem>
          </Select>
        </FormControl>
      </Box>
      <Box component={"span"} sx={{ flexShrink: 0, ml: 2.5, fontSize: "1rem", color: "black" }}>
        page {page} of {totalPages}
      </Box>
      <Box sx={{ flexShrink: 0, ml: 2.5 }}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 1}
          aria-label="first page"
        >
          {theme.direction === "rtl" ? <LastPage /> : <FirstPage />}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 1}
          aria-label="previous page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= totalPages}
          aria-label="next page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= totalPages }
          aria-label="last page"
        >
          {theme.direction === "rtl" ? <FirstPage /> : <LastPage />}
        </IconButton>
      </Box>
    </Box>
  );
};

export default CustomTablePaginationActionsNew;
