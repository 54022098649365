import {TAccountTransactionType} from "../account/account-transaction-list";

export interface IWalletTransactionListState {
    walletId: string | null,
    transactionList: IWalletTransaction[],
    isLoading: boolean,
    perPage: number,
    page: number,
    totalPages: number,
}

export interface IWalletTransaction {
    id: number;
    satchel_id: string;
    category: string;
    email: string;
    amount: string;
    currency_code: string;
    fee: string;
    fee_currency_code: string;
    beneficiary_account_number: string;
    beneficiary_bank_swift: string;
    beneficiary_iban: string;
    beneficiary_card_uuid: string;
    sender_account_number: string;
    narrative: string;
    status: number;
    type: TAccountTransactionType;
    rejection_reason: string;
    processing_time: string;
    finished_at: string;
    created_at: string;
    updated_at: string;
}

export enum WalletTransactionListActions {
    SET_WALLET_ID = "SET_WALLET_ID",
    SET_TRANSACTION_LIST = "SET_TRANSACTION_LIST",
    CLEAR_TRANSACTION_LIST = "CLEAR_TRANSACTION_LIST",
    CLEAR_WALLET_ID = "CLEAR_WALLET_ID",
    SET_LOADING = "SET_LOADING",
    SET_PAGE = "SET_PAGE",
    SET_PER_PAGE = "SET_PER_PAGE",
    SET_TOTAL_PAGES = "SET_TOTAL_PAGES",
}

export interface ISetWalletTransactionListId {
    type: WalletTransactionListActions.SET_WALLET_ID,
    walletId: string
}

export interface ISetWalletTransactionList {
    type: WalletTransactionListActions.SET_TRANSACTION_LIST,
    transactionList: IWalletTransaction[]
}

export interface IClearWalletTransactionList {
    type: WalletTransactionListActions.CLEAR_TRANSACTION_LIST
}

export interface IClearWalletTransactionListId {
    type: WalletTransactionListActions.CLEAR_WALLET_ID
}

export interface ISetWalletTransactionListLoading {
    type: WalletTransactionListActions.SET_LOADING,
    loading: boolean
}

export interface ISetWalletTransactionListPage {
    type: WalletTransactionListActions.SET_PAGE,
    page: number
}

export interface ISetWalletTransactionListPerPage {
    type: WalletTransactionListActions.SET_PER_PAGE,
    perPage: number
}

export interface ISetWalletTransactionListTotalPages {
    type: WalletTransactionListActions.SET_TOTAL_PAGES,
    totalPages: number
}

export type TWalletTransactionListAction = ISetWalletTransactionListId
    | ISetWalletTransactionList
    | ISetWalletTransactionListLoading
    | ISetWalletTransactionListPage
    | ISetWalletTransactionListPerPage
    | ISetWalletTransactionListTotalPages
    | IClearWalletTransactionList
    | IClearWalletTransactionListId;