import {
  Box, Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Grid,
  Paper, Skeleton,
  Table, TableBody,
  TableCell, TableContainer,
  TableHead,
  TableRow
} from "@mui/material";
import React, {useEffect, useState} from "react";
import ability from "../../../utils/can";
import {Can} from "@casl/react";
import {useTranslation} from "react-i18next";
import {HeadCell} from "../../../types/table";
import {IRole} from "../../../types/store/role";
import {useTypedSelector} from "../../../hooks/useTypedSelector";
import {useActions} from "../../../hooks/useActions";
import {useNavigate} from "react-router-dom";
import DialogConfirmation from "../../../components/common/dialog-confirmation";
import Error404 from "../../error-pages/error-404";

/**
 * A React component to show role list table
 * */
const RoleList = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const {isLoading, roles, confirmDelete} = useTypedSelector(state => state.roleList);
  const {fetchRoleList, deleteRole, setRoleListConfirmDelete} = useActions();
  const [isOpenDeleteDialog, setIsOpenDeleteDialog] = useState(false);
  const [idToDelete, setIdToDelete] = useState(0);
  const [titleDialog, setTitleDialog] = useState("");
  const [subtitleDialog, setSubtitleDialog] = useState("");
  const itemsPerPage = 10;

  const headCells: HeadCell<IRole>[] = [
    {
      id: "id",
      numeric: true,
      label: t("role.table.id"),
      disablePadding: false,
    },
    {
      id: "name",
      numeric: false,
      label: t("role.table.name"),
      disablePadding: false,
    },
    {
      id: "actions",
      numeric: false,
      label: "",
      disablePadding: false,
      isHide: true
    },
  ];

  const handleViewRole = (roleId: number) => () => {
    navigate(`/role/edit/${roleId}`);
  };

  const handleCreateRole = () => {
    navigate("/role/add");
  };

  const handleDeleteRole = () => {
    deleteRole(idToDelete);
    handleCloseDeleteDialog();
  };

  const handleOpenDeleteDialog = (value: number) => async () => {
    let confirmDeleteDialogType = confirmDelete;
    if (value !== idToDelete) {
      await setRoleListConfirmDelete(false);
      confirmDeleteDialogType = false;
    }
    setIdToDelete(value);
    setTitleDialog(t("role.common.Role delete confirmation"));
    setSubtitleDialog(confirmDeleteDialogType ? t("role.common.The role is assigned to users. All users with this role will be blocked.", {id: value}) : t("role.common.Are you sure that you want to delete role?", {id: value}));
    setIsOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setRoleListConfirmDelete(false);
    setIsOpenDeleteDialog(false);
  };

  useEffect(() => {
    if (ability.can("get", "roles")) {
      fetchRoleList();
    }
  }, []);

  useEffect(() => {
    if (confirmDelete){
      handleOpenDeleteDialog(idToDelete)();
    }
  }, [confirmDelete]);

  if (ability.cannot("get", "roles")) {
    return <Error404/>;
  }

  return (
    <Container maxWidth="xl">
      <Can I="get" a="roles" ability={ability}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
            <Card>
              <CardHeader title={t("role.table.title")}/>
              <CardContent>
                <Box sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  mb: 2
                }}>
                  <Button
                    variant={"contained"}
                    size="small"
                    color="success"
                    onClick={handleCreateRole}
                    disabled={ability.cannot("create", "role")}
                  >
                    {t("role.table.create")}
                  </Button>
                </Box>
                <TableContainer component={Paper}>
                  <Table sx={{minWidth: 450}} aria-label="Accounts list table" size={"small"}>
                    <TableHead>
                      <TableRow sx={{"& .MuiTableCell-head": {fontWeight: 600}}}>
                        {headCells.map((headCell) => {
                          return (
                            <TableCell
                              key={headCell.id}
                            >
                              {headCell.label}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {isLoading && Array(itemsPerPage).fill("").map((row, index) => (
                        <TableRow
                          key={index}
                          sx={{"&:last-child td, &:last-child th": {border: 0}}}
                        >
                          <TableCell><Skeleton/></TableCell>
                          <TableCell><Skeleton/></TableCell>
                          <TableCell><Skeleton height={30}/></TableCell>
                        </TableRow>
                      ))}
                      {!isLoading && roles.map((role) => (
                        <TableRow
                          key={role.id}
                          sx={{"&:last-child td, &:last-child th": {border: 0}}}
                          hover
                        >
                          <TableCell>{role.id}</TableCell>
                          <TableCell>{role.name}</TableCell>
                          <TableCell align={"right"}>
                            <Button
                              sx={{ mr: 2 }}
                              variant={"contained"}
                              size="small"
                              onClick={handleViewRole(role.id)}
                              disabled={ability.cannot("get", "role")}
                            >
                              {t("role.table.edit")}
                            </Button>
                            <Button
                              variant={"contained"}
                              size="small"
                              color="error"
                              onClick={handleOpenDeleteDialog(role.id)}
                              disabled={ability.cannot("remove", "role")}
                            >
                              {t("role.table.delete")}
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <DialogConfirmation
          isOpen={isOpenDeleteDialog}
          onClose={handleCloseDeleteDialog}
          onConfirm={handleDeleteRole}
          title={titleDialog}
          message={subtitleDialog} />
      </Can>
    </Container>
  );
};

export default RoleList;