import React, {useEffect} from "react";
import {Box, Button, Card, CardContent, Grid, TextField, Typography} from "@mui/material";
import {useFormik} from "formik";
import {useTranslation} from "react-i18next";
import {useActions} from "../../hooks/useActions";
import {IAuthValuesSend, IAuthValuesSignIn} from "../../types/store/auth";
import {LoadingButton} from "@mui/lab";
import {useTypedSelector} from "../../hooks/useTypedSelector";

/**
 *  A React component with form to auth
 *
 *  */
const AuthCard = () => {
  const {t} = useTranslation();
  const {user, authErrors} = useTypedSelector(state => state.auth);
  const {login, checkPasscode, setAuthErrors, setAuthUser} = useActions();

  const formikForSend = useFormik({
    initialValues: {
      email: "",
      password: "",
    } as IAuthValuesSend,

    validate: (values) => {
      const errors: Partial<IAuthValuesSend> = {};

      const email = validateEmail(values.email);
      const password = validatePassword(values.password);
      if (email) {
        errors.email = email;
      }
      if (password) {
        errors.password = password;
      }

      return errors;
    },

    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true);
      const {email, password} = values;
      await login(email, password);
      setSubmitting(false);
    }
  });

  const {
    values: valuesForSend,
    errors: errorsForSend,
    handleChange: handleChangeForSend,
    touched: touchedForSend,
    handleSubmit: handleSubmitForSend,
    isSubmitting: isSubmittingForSend,
    handleBlur: handleBlurForSend,
    validateForm: validateFormForSend
  } = formikForSend;

  const formikForSignIn = useFormik({
    initialValues: {
      userId: "",
      passcode: "",
    } as IAuthValuesSignIn,

    validate: (values) => {
      const errors: Partial<IAuthValuesSignIn> = {};

      const userId = validateUserId(values.userId);
      const passcode = validatePasscode(values.passcode);
      if (userId) {
        errors.userId = userId;
      }
      if (passcode) {
        errors.passcode = passcode;
      }

      return errors;
    },

    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true);
      const {userId, passcode} = values;
      await checkPasscode(userId, passcode);
      setSubmitting(false);
    },
  });

  const {
    values: valuesForSignIn,
    errors: errorsForSignIn,
    handleChange: handleChangeForSignIn,
    touched: touchedForSignIn,
    handleSubmit: handleSubmitForSignIn,
    handleReset: handleResetForSignIn,
    isSubmitting: isSubmittingForSignIn,
    handleBlur: handleBlurForSignIn,
    validateForm: validateFormForSignIn,
    resetForm: resetFormSignIn,
  } = formikForSignIn;

  const validateEmail = (email: string): string | undefined => {
    if (!email) {
      return t("validations.Login is required");
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
      return t("validations.Login must be a an email");
    } else if (authErrors?.email) {
      return t(`validations.${authErrors.email}`);
    }
  };

  const validatePassword = (password: string): string | undefined => {
    if (!password) {
      return t("validations.Password is required");
    } else if (authErrors?.password) {
      return t(`validations.${authErrors.password}`);
    }
  };

  const validateUserId = (userId: string): string | undefined => {
    if (!userId) {
      return t("validations.User ID is required");
    } else if (authErrors?.userId) {
      return t(`validations.${authErrors.userId}`);
    }
  };

  const validatePasscode = (passcode: string): string | undefined => {
    if (!passcode) {
      return t("validations.Passcode is required");
    } else if (!/^\d{6}$/.test(passcode)) {
      return t("validations.Passcode is 6 digit");
    } else if (authErrors?.passcode) {
      return t(`validations.${authErrors.passcode}`);
    }
  };

  const handleChangeValue = () => {
    setAuthErrors({email: "", password: "", userId: "", passcode: ""});
  };

  useEffect((): () => void => {
    //Run validating one more time, because state updating is async
    let mounted = true;
    if (mounted) {
      validateFormForSend();
      validateFormForSignIn();
    }
    return () => mounted = false;
  }, [authErrors]);

  useEffect(() => {
    resetFormSignIn({values: {userId: user?.id || "", passcode: ""}});
  }, [user]);

  return (
    <Box
      sx={{
        height: "80vh",
        width: {xs: "90vw", sm: "auto"},
        mx: "auto",
        maxWidth: "512px"
      }}
      display="flex"
      alignItems="center"
      justifyContent="center">
      <Card>
        <CardContent>
          {
            !user?.id &&
                        <form onSubmit={handleSubmitForSend}>
                          <TextField
                            id="email"
                            name="email"
                            type="email"
                            label={t("form.auth.Login")}
                            error={touchedForSend.email && !!errorsForSend.email}
                            helperText={touchedForSend.email && errorsForSend.email}
                            sx={{my: 1, minHeight: 80}}
                            fullWidth
                            value={valuesForSend.email}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                              handleChangeValue();
                              handleChangeForSend(e);
                            }}
                            onBlur={handleBlurForSend}
                          />
                          <TextField
                            id="password"
                            type="password"
                            label={t("form.auth.Password")}
                            name="password"
                            error={touchedForSend.password && !!errorsForSend.password}
                            helperText={touchedForSend.password && errorsForSend.password}
                            sx={{my: 1, minHeight: 80}}
                            fullWidth
                            value={valuesForSend.password}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                              handleChangeValue();
                              handleChangeForSend(e);
                            }}
                            onBlur={handleBlurForSend}
                          />
                          <Box sx={{
                            display: "flex",
                            justifyContent: "flex-end",
                            width: "100%"
                          }}>
                            <LoadingButton
                              type="submit"
                              variant="contained"
                              color="primary"
                              size="large"
                              loading={isSubmittingForSend}
                            >
                              {t("form.auth.Send passcode")}
                            </LoadingButton>
                          </Box>
                        </form>
          }
          {
            user?.id &&
                        <form onSubmit={handleSubmitForSignIn} onReset={handleResetForSignIn}>
                          <Grid container spacing={2}>
                            <Grid item xs={12}>
                              <Typography textAlign={"center"}>{user?.email}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                              <TextField
                                sx={{display: "none"}}
                                id="userId"
                                type="hidden"
                                name="userId"
                                value={valuesForSignIn.userId}
                              />
                              <TextField
                                id="passcode"
                                type="text"
                                label={t("form.auth.Passcode")}
                                name="passcode"
                                error={touchedForSignIn.passcode && (!!errorsForSignIn.passcode || !!errorsForSignIn.userId)}
                                helperText={touchedForSignIn.passcode && (errorsForSignIn.passcode || errorsForSignIn.userId)}
                                fullWidth
                                value={valuesForSignIn.passcode}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                  handleChangeValue();
                                  handleChangeForSignIn(e);
                                }}
                                onBlur={handleBlurForSignIn}
                              />
                            </Grid>
                            <Grid item xs={12} display={"flex"} justifyContent={"space-between"}>
                              <Button
                                color="secondary"
                                size="large"
                                onClick={() => setAuthUser({...user, id: "", email: ""})}
                              >
                                {t("form.auth.Back")}
                              </Button>
                              <LoadingButton
                                type="submit"
                                variant="contained"
                                color="primary"
                                size="large"
                                loading={isSubmittingForSignIn}
                              >
                                {t("form.auth.Sign In")}
                              </LoadingButton>
                            </Grid>
                          </Grid>
                        </form>
          }
        </CardContent>
      </Card>
    </Box>
  );
};

export default AuthCard;
