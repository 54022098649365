import {
  CardActions,
  CardStatus,
  ICard,
  ICardFormFields,
  IClearCard,
  ISetCard,
  ISetCardError,
  ISetCardFormErrors,
  ISetCardLoading,
  TCardStatus
} from "../../../types/store/card";
import {ThunkDispatch} from "redux-thunk";
import {Action} from "redux";
import {handleGeneralError, handleNetworkError} from "../error-handler";
import {AxiosError} from "axios";
import {AccountDetailsResponse} from "../../../types/service/response/accounts-response";
import CardTestService from "../../../sevices/test/card-test-service";
import {delay} from "../../../utils/common";
import {IGeneralResponse} from "../../../types/service/response/general-response";
import {USE_SERVER} from "../../../constants/constants";
import CardService from "../../../sevices/card-service";

/**
 * It takes an ICard object and returns an ISetCard object
 * @param {ICard} card - ICard - this is the card object that we're going to be setting in the store.
 */
const setCardInfo = (card: ICard): ISetCard => ({
  type: CardActions.SET_CARD,
  card
});

/**
 * It returns an object with a type of SET_CARD_LOADING and a loading property
 * @param {boolean} loading - boolean
 */
const setCardLoading = (loading: boolean): ISetCardLoading => ({
  type: CardActions.SET_CARD_LOADING,
  loading
});

/**
 * It returns an object with the type property set to CardActions.SET_CARD_ERROR, and the errorMessage and errorCode
 * properties set to the values passed in
 * @param {number} errorCode - number - The error code that was returned from the API.
 * @param {string} errorMessage - string - The error message to display to the user.
 */
const setCardError = (errorCode: number, errorMessage: string): ISetCardError => ({
  type: CardActions.SET_CARD_ERROR,
  errorMessage,
  errorCode
});

/**
 * It returns an object with a type property set to the CLEAR_CARD constant
 */
export const clearCardInfo = (): IClearCard => ({
  type: CardActions.CLEAR_CARD
});

/**
 * It returns an object with a type property and a formErrors property
 * @param {ICardFormFields} formErrors - ICardFormFields
 */
export const setFormErrors = (formErrors: ICardFormFields): ISetCardFormErrors => ({
  type: CardActions.SET_CARD_FORM_ERRORS,
  formErrors,
});


/**
 * It fetches card info from the server and dispatches the result to the store
 * @param {string} id - string - the id of the card
 * @param {TCardStatus} [status] - TCardStatus - this is the type of the status of the card. It's an enum.
 */
export const fetchCardInfo = (id: string, status?: TCardStatus) => async (dispatch: ThunkDispatch<unknown, unknown, Action>) => {
  try {
    dispatch(setCardLoading(true));
    const response =
            USE_SERVER
              ?
              await CardService.cardInfo(id)
              :
              await CardTestService.cardInfo(id, status);

    dispatch(setCardInfo(response?.data?.result || {} as ICard));
  } catch (e: unknown) {
    dispatch(setCardInfo({} as ICard));
    await dispatch(handleGeneralError(e));
    const {response} = e as AxiosError<AccountDetailsResponse>;
    if (response?.status) {
      dispatch(setCardError(response.status, response?.data?.errors?.[0] ?? ""));
    }
  } finally {
    dispatch(setCardLoading(false));
  }
};

/**
 * It fetches the card info from the server, and then updates the card status
 * @param {string} id - string - the id of the card
 * @param {"cancel" | "confirm"} status - "cancel" | "confirm"
 */
export const updateCardStatus = (id: string, status: "cancel" | "confirm") => async (dispatch: ThunkDispatch<unknown, unknown, Action>) => {
  try {
    dispatch(setCardLoading(true));

    if (USE_SERVER) {
      if (status === "cancel") await CardService.cancelCard(id);
      else if (status === "confirm") await CardService.confirmCard(id);
    }

    await delay(600);
    await dispatch(fetchCardInfo(id, status === "cancel" ? CardStatus.REQUEST_CANCELED : CardStatus.CARD_SENT));
  } catch (e: unknown) {
    await dispatch(handleGeneralError(e));
  } finally {
    dispatch(setCardLoading(false));
  }
};

/**
 * It dispatches a loading action, then it either calls the server or waits for a bit, then it dispatches a fetch action,
 * and if there's an error it dispatches an error action
 * @param {string} id - string - the id of the card
 * @param {string} cardNumber - string - the card number to set
 */
export const setCardNumber = (id: string, cardNumber: string) => async (dispatch: ThunkDispatch<unknown, unknown, Action>) => {
  try {
    dispatch(setCardLoading(true));

    if (USE_SERVER) {
      await CardService.setCardNumber(id, cardNumber);
    }

    await delay(600);
    await dispatch(fetchCardInfo(id, CardStatus.CARD_ASSIGNED));
  } catch (e: unknown) {
    await dispatch(handleNetworkError(e));
    const {response} = e as AxiosError<IGeneralResponse<boolean>>;
    const errors: string[] | undefined = response?.data?.errors;

    const errorStrings: ICardFormFields = {cardNumber: ""};
    if (errors && errors?.length > 0) {
      errors.map(error => {
        const key = error.split(" ")[0];
        if (key in errorStrings) {
          errorStrings[key as keyof ICardFormFields] = error;
        }
      });
    }
    dispatch(setFormErrors(errorStrings));
  } finally {
    dispatch(setCardLoading(false));
  }
};