import { IRequestResponseType } from "../../types/service/test/test-response";
import {
  AccountDetailsResponse,
  AccountsListResponse,
  AccountStatusesResponse,
  IAccountInfoOndatoSessionRefreshStatusResponse,
} from "../../types/service/response/accounts-response";
import { delay, descendingComparator, getRandomInt } from "../../utils/common";
import { AccountListRequestParams } from "../../types/service/request/accounts-request";
import {
  IAccountDetail,
  IAccountDetailsCorporate,
  IAccountDetailsIndividual,
  IAccountDocument,
  IAccountIdentityVerificationInfo,
  IAccountOndatoDetail,
  IAccountPhoto,
  IAccountSatchelWallet,
  IAccountSatchelWalletsAllTypes,
  IAccountSignedContract,
  IAccountWallet,
} from "../../types/store/account/account-details";
import { IGeneralResponse } from "../../types/service/response/general-response";
import {
  IAccountTransaction,
  IAccountTransactionItemsByPage,
} from "../../types/store/account/account-transaction-list";
import { ACCOUNT_STATUSES } from "../../constants/statuses";

/** It's a mock for the API */
export default class AccountTestService {
  static async accountList(
    params: AccountListRequestParams = {}
  ): Promise<IRequestResponseType<undefined, AccountsListResponse>> {
    const { per_page, status, sort, page } = params;
    const accounts = [...Array(per_page)].map(() => {
      const randId = getRandomInt(1, 99999);
      const statusNum = status ?? getRandomInt(1, 4);
      return {
        id: "14",
        createdAt: "2023-03-08T12:44:53.000Z",
        updatedAt: "2023-03-08T12:44:53.000Z",
        satchelIdIndividual: null,
        satchelIdCorporate: null,
        satchelIsIndividual: 0,
        satchelIsCorporate: 0,
        keycloackId: "ba4fa0de-10b8-4219-b0f6-9011064a4752",
        status: 0,
        statusAml: 0,
        statusSatchelIndividual: 0,
        statusSatchelCorporate: 0,
        statusOndata: null,
        metadata: {
          finish_time: "",
          ip: "",
          start_time: "",
          user_agent: "",
          privacyPolicyArbitas: "",
          privacyPolicySatchel: "",
          termsAndConditionsArbitas: "",
          termsAndConditionsSatchel: "",
        },
        phoneNumber: "+38099999999",
        emailAddress: "qwe@qwe.qsd",
      } as IAccountDetail;
    });

    accounts.sort((a, b) => {
      if (sort === "DESC") {
        return descendingComparator<IAccountDetail>(a, b, "id");
      } else {
        return -descendingComparator<IAccountDetail>(a, b, "id");
      }
    });

    try {
      await delay(600);
      return {
        headers: undefined,
        data: {
          result: {
            list: accounts,
            total_pages: 1000,
            page: page,
            per_page: per_page,
          },
        },
      };
    } catch (e) {
      return {
        headers: undefined,
        data: {} as AccountsListResponse,
      };
    }
  }

  static async accountDetails(
    accountId: string
  ): Promise<IRequestResponseType<undefined, AccountDetailsResponse>> {
    try {
      await delay(600);
      return {
        headers: undefined,
        data: {
          result: {
            id: "22",
            createdAt: "2023-03-10T09:01:49.000Z",
            updatedAt: "2023-03-10T09:01:49.000Z",
            satchelIdIndividual: null,
            satchelIdCorporate: null,
            satchelIsIndividual: 0,
            satchelIsCorporate: 0,
            keycloackId: "2e0af3bd-53da-4161-afb6-b727e6da994f",
            status: 0,
            statusAml: 0,
            statusSatchelIndividual: 0,
            statusSatchelCorporate: 0,
            statusOndata: null,
            metadata: {
              finish_time: "",
              ip: "",
              start_time: "",
              user_agent: "",
              privacyPolicyArbitas: "",
              privacyPolicySatchel: "",
              termsAndConditionsArbitas: "",
              termsAndConditionsSatchel: "",
            },
            emailAddress: "test@gmail.com",
            phoneNumber: "+1231231231",
          },
        },
      };
    } catch (e) {
      return {
        headers: undefined,
        data: {
          result: {} as IAccountDetail,
        } as AccountDetailsResponse,
      };
    }
  }

  static async accountDetailsIndividual(
    id: string
  ): Promise<
    IRequestResponseType<unknown, IGeneralResponse<IAccountDetailsIndividual>>
  > {
    try {
      await delay(600);
      return {
        headers: undefined,
        data: {
          result: {
            id: "10",
            createdAt: "2022-11-25T15:22:05.000Z",
            updatedAt: "2022-11-25T15:22:05.000Z",
            userId: 130,
            firstName: "Ihor",
            lastName: "Spr",
            personalNumber: "ui0000778",
            dateOfBirthday: "1983-09-09T00:00:00.000Z",
            nationality: "ua",
            addressCountryCode: "UA",
            addressCity: "Odesa",
            addressStreet: "st.Washington",
            addressZipCode: "65444",
            phoneNumber: "+380665190843",
            emailAddress: "igosprin@gmail.com",
            birthPlace: "Odesa",
            taxCountry: "ua",
            employment: "Employed under contract",
            sourceInitialFunding: "Up to 1000 EUR",
            pep: "yes",
            usCitizenship: "no",
            termsAndConditions: "09.08.1984",
            privacyPolicy: "09.08.1984",
            usePersonalData: "09.08.1984",
            selfieImage: "",
            privacyPolicyArbitas: "",
            privacyPolicySatchel: "",
            termsAndConditionsArbitas: "",
            termsAndConditionsSatchel: "",
            taxIdentificationNumber: "123123123",
            dualCitizenship: 0,
            primarySourceIncome: "Salary/Pension/Social benefits",
            countriesToWorkIncome: "UA",
            countriesToWorkOutcome: "UA",
            monthlyFrequencyOfPaymentsIncome: "From 20 to 50",
            monthlyFrequencyOfPaymentsOutcome: "From 20 to 50",
            monthlyTurnoverIncome: "From 1000 EUR to 5000 EUR",
            monthlyTurnoverOutcome: "From 1000 EUR to 5000 EUR",
            purposeOfAccount: "UA",
          },
        },
      };
    } catch (e) {
      return {
        headers: undefined,
        data: {
          result: {} as IAccountDetailsIndividual,
        },
      };
    }
  }

  static async accountDetailsCorporate(
    id: string
  ): Promise<
    IRequestResponseType<
      unknown,
      IGeneralResponse<boolean> & { data: IAccountDetailsCorporate }
    >
  > {
    try {
      await delay(600);
      return {
        headers: undefined,
        data: {
          result: true,
          data: {
            id: id,
            createdAt: "2022-11-10T16:39:06.000Z",
            updatedAt: "2022-11-10T16:39:06.000Z",
            userId: 110,
            name: "Company LTD",
            dateOfRegistrations: "2022-09-11T00:00:00.000Z",
            registrationNumber: "LTD3364963-CV",
            taxIdentificationNumber: "8952",
            addressCountryCode: "FR",
            addressCity: "London",
            addressStreet: "15/C Central avenue",
            addressZipCode: "8952",
            emailAddress: "test@test.com",
            phoneNumber: "+45342634346",
            businessCategories: "",
            website: null,
            planedMonthlyTurnoverIncoming: "",
            planedMonthlyTurnoverOutgoing: "",
            termsAndConditionsArbitas: "",
            privacyPolicyArbitas: "",
            termsAndConditionsSatchel: "",
            privacyPolicySatchel: "",
            personalData: "",
            details: {
              companies: [
                {
                  id: "5",
                  createdAt: "2022-11-10T16:39:06.000Z",
                  updatedAt: "2022-11-10T16:39:06.000Z",
                  userId: "110",
                  type: "directors",
                  name: "Company LTD",
                  registrationNumber: "LTD1928155-CV",
                  countryOfIncorporation: null,
                  addressStreet: null,
                  addressCity: null,
                  addressCountryCode: null,
                  addressZipCode: null,
                  countryRegion: null,
                  website: null,
                  phoneNumber: null,
                  email: null,
                  documents: [
                    {
                      id: "1",
                      createdAt: "2022-11-10T16:25:07.000Z",
                      updatedAt: "2022-11-10T16:25:07.000Z",
                      companyId: "5",
                      type: "1",
                      images: [
                        {
                          id: 1,
                          s3_id: "0a9fa3b7-66f2-4396-a590-4c2dfa3d284d",
                          document_id: 1,
                          content_type: null,
                          created_at: "2022-11-10T16:39:06.000Z",
                          updated_at: "2022-11-10T16:39:06.000Z",
                          s3_link:
                            "https://arbipay-userdocuments-dev.s3.eu-central-1.amazonaws.com/0a9fa3b7-66f2-4396-a590-4c2dfa3d284d?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=AKIASFPCDLCCDWOEBVCV%2F20221221%2Feu-central-1%2Fs3%2Faws4_request&X-Amz-Date=20221221T125334Z&X-Amz-Expires=3600&X-Amz-Signature=c82b751650e8a7d78c26d89c0a35bf25e0a4da28d41ddefa7249e06f2f8d78e6&X-Amz-SignedHeaders=host&x-id=GetObject",
                        },
                        {
                          id: 2,
                          s3_id: "0a9fa3b7-66f2-4396-a590-4c2dfa3d284d",
                          document_id: 1,
                          content_type: null,
                          created_at: "2022-11-10T16:39:06.000Z",
                          updated_at: "2022-11-10T16:39:06.000Z",
                          s3_link:
                            "https://arbipay-userdocuments-dev.s3.eu-central-1.amazonaws.com/0a9fa3b7-66f2-4396-a590-4c2dfa3d284d?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=AKIASFPCDLCCDWOEBVCV%2F20221221%2Feu-central-1%2Fs3%2Faws4_request&X-Amz-Date=20221221T125334Z&X-Amz-Expires=3600&X-Amz-Signature=c82b751650e8a7d78c26d89c0a35bf25e0a4da28d41ddefa7249e06f2f8d78e6&X-Amz-SignedHeaders=host&x-id=GetObject",
                        },
                      ],
                    },
                    {
                      id: "2",
                      createdAt: "2022-11-10T16:25:07.000Z",
                      updatedAt: "2022-11-10T16:25:07.000Z",
                      companyId: "5",
                      type: "2",
                      images: [],
                    },
                  ],
                },
                {
                  id: "6",
                  createdAt: "2022-11-10T16:39:06.000Z",
                  updatedAt: "2022-11-10T16:39:06.000Z",
                  userId: "110",
                  type: "shareholders",
                  name: "Company LTD",
                  registrationNumber: "LTD9990351-CV",
                  countryOfIncorporation: null,
                  addressStreet: null,
                  addressCity: null,
                  addressCountryCode: null,
                  addressZipCode: null,
                  countryRegion: null,
                  website: null,
                  phoneNumber: null,
                  email: null,
                  documents: [],
                },
              ],
              persons: [
                {
                  id: "8",
                  createdAt: "2022-11-10T16:39:06.000Z",
                  updatedAt: "2022-11-10T16:39:06.000Z",
                  userId: "110",
                  type: "authorizedSignatories",
                  firstName: "John",
                  lastName: "Doe",
                  dateOfBirthday: "1979-01-12T00:00:00.000Z",
                  nationality: "FR",
                  personal_number: "",
                  address_street: null,
                  address_city: null,
                  address_country_code: null,
                  address_zip_code: null,
                  job_title: null,
                  phone_number: null,
                  email: null,
                  pep: null,
                  documents: [
                    {
                      id: "8",
                      createdAt: "2022-11-10T16:39:06.000Z",
                      updatedAt: "2022-11-10T16:39:06.000Z",
                      personId: "8",
                      type: "PASSPORT",
                      number: "string",
                      citizenCountry: "FR",
                      issueCountry: "FR",
                      issuing: "string",
                      issueDate: "01.12.2019",
                      expiryDate: "01.12.2023",
                      images: [
                        {
                          id: 1,
                          s3_id: "0a9fa3b7-66f2-4396-a590-4c2dfa3d284d",
                          document_id: 8,
                          content_type: null,
                          created_at: "2022-11-10T16:39:06.000Z",
                          updated_at: "2022-11-10T16:39:06.000Z",
                          s3_link:
                            "https://arbipay-userdocuments-dev.s3.eu-central-1.amazonaws.com/0a9fa3b7-66f2-4396-a590-4c2dfa3d284d?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=AKIASFPCDLCCDWOEBVCV%2F20221221%2Feu-central-1%2Fs3%2Faws4_request&X-Amz-Date=20221221T125334Z&X-Amz-Expires=3600&X-Amz-Signature=c82b751650e8a7d78c26d89c0a35bf25e0a4da28d41ddefa7249e06f2f8d78e6&X-Amz-SignedHeaders=host&x-id=GetObject",
                        },
                        {
                          id: 2,
                          s3_id: "80c0957e-0656-4ab2-9a98-8b7a9f12c510",
                          document_id: 8,
                          content_type: null,
                          created_at: "2022-11-10T16:39:06.000Z",
                          updated_at: "2022-11-10T16:39:06.000Z",
                          s3_link:
                            "https://arbipay-userdocuments-dev.s3.eu-central-1.amazonaws.com/80c0957e-0656-4ab2-9a98-8b7a9f12c510?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=AKIASFPCDLCCDWOEBVCV%2F20221221%2Feu-central-1%2Fs3%2Faws4_request&X-Amz-Date=20221221T125334Z&X-Amz-Expires=3600&X-Amz-Signature=f069e4c7cb93cebad920e966d833b132fa02983b34706b8461e229883de9290d&X-Amz-SignedHeaders=host&x-id=GetObject",
                        },
                      ],
                    },
                  ],
                },
                {
                  id: "9",
                  createdAt: "2022-11-10T16:39:06.000Z",
                  updatedAt: "2022-11-10T16:39:06.000Z",
                  userId: "110",
                  type: "finalBeneficiaries",
                  firstName: "John",
                  lastName: "Doe",
                  dateOfBirthday: "1979-01-12T00:00:00.000Z",
                  nationality: "FR",
                  personal_number: "",
                  address_street: null,
                  address_city: null,
                  address_country_code: null,
                  address_zip_code: null,
                  job_title: null,
                  phone_number: null,
                  email: null,
                  pep: null,
                  documents: [
                    {
                      id: "9",
                      createdAt: "2022-11-10T16:39:06.000Z",
                      updatedAt: "2022-11-10T16:39:06.000Z",
                      personId: "9",
                      type: "PASSPORT",
                      number: "string",
                      citizenCountry: "FR",
                      issueCountry: "FR",
                      issuing: "string",
                      issueDate: "01.12.1979",
                      expiryDate: "01.12.2023",
                      images: [],
                    },
                  ],
                },
              ],
            },
          },
        },
      };
    } catch (e) {
      return {
        headers: undefined,
        data: {
          result: false,
          data: {} as IAccountDetailsCorporate,
        },
      };
    }
  }

  static async accountDocuments(
    id: string
  ): Promise<
    IRequestResponseType<unknown, IGeneralResponse<IAccountDocument[]>>
  > {
    try {
      await delay(600);
      return {
        headers: undefined,
        data: {
          result: [
            {
              id: "34",
              createdAt: "2022-11-25T21:22:33.000Z",
              updatedAt: "2022-11-25T21:22:33.000Z",
              userId: "" + id,
              type: "ID",
              number: "9090909",
              citizenCountry: "SK",
              issueCountry: "SK",
              issuing: "Bratislava",
              issueDate: "25.11.2022",
              expiryDate: "25.11.2022",
              images: [
                {
                  id: "39",
                  createdAt: "2022-11-25T21:22:30.000Z",
                  updatedAt: "2022-11-25T21:22:33.000Z",
                  s3Id: "84c56677-fa20-434c-8254-c3d3c3b4a3b9",
                  s3Link:
                    "https://arbipay-userdocuments-dev.s3.eu-central-1.amazonaws.com/84c56677-fa20-434c-8254-c3d3c3b4a3b9?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=AKIASFPCDLCCDWOEBVCV%2F20221204%2Feu-central-1%2Fs3%2Faws4_request&X-Amz-Date=20221204T114455Z&X-Amz-Expires=3600&X-Amz-Signature=470f65b97a03a0e01336e48de381179f645c693a502d0fe03f226aeea776fee6&X-Amz-SignedHeaders=host&x-id=GetObject",
                  documentId: "34",
                  contentType: "image/png",
                },
                {
                  id: "40",
                  createdAt: "2022-11-25T21:22:31.000Z",
                  updatedAt: "2022-11-25T21:22:33.000Z",
                  s3Id: "4d643639-b304-4627-aa14-dac06d1b2cf6",
                  s3Link:
                    "https://arbipay-userdocuments-dev.s3.eu-central-1.amazonaws.com/4d643639-b304-4627-aa14-dac06d1b2cf6?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=AKIASFPCDLCCDWOEBVCV%2F20221204%2Feu-central-1%2Fs3%2Faws4_request&X-Amz-Date=20221204T114455Z&X-Amz-Expires=3600&X-Amz-Signature=a6b7ab68d9bbbe6864c00fff4db2f5fcf8ab008c07e525cd283f19c6f4d408b6&X-Amz-SignedHeaders=host&x-id=GetObject",
                  documentId: "34",
                  contentType: "image/png",
                },
              ],
            },
          ],
        },
      };
    } catch (e) {
      return {
        headers: undefined,
        data: {
          result: [],
        },
      };
    }
  }

  static async accountPhotos(
    id: string
  ): Promise<IRequestResponseType<unknown, IGeneralResponse<IAccountPhoto[]>>> {
    try {
      await delay(600);
      return {
        headers: undefined,
        data: {
          result: [
            {
              id: "3",
              createdAt: "2023-01-13T16:51:13.000Z",
              updatedAt: "2023-01-13T16:51:13.000Z",
              userId: "161",
              s3Id: "2d79b025-66f5-4129-9195-2195e3530a11",
              s3Link:
                "https://arbipay-userdocuments-dev.s3.eu-central-1.amazonaws.com/2d79b025-66f5-4129-9195-2195e3530a11?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=AKIASFPCDLCCDWOEBVCV%2F20230116%2Feu-central-1%2Fs3%2Faws4_request&X-Amz-Date=20230116T103012Z&X-Amz-Expires=3600&X-Amz-Signature=37a0649d877044f2a08e3506b503329511d1a04711a6c5193c1a988736666524&X-Amz-SignedHeaders=host&x-id=GetObject",
              contentType: "image/jpeg",
            },
          ],
        },
      };
    } catch (e) {
      return {
        headers: undefined,
        data: {
          result: [],
        },
      };
    }
  }

  static async accountWallets(
    id: string
  ): Promise<
    IRequestResponseType<unknown, IGeneralResponse<IAccountWallet[]>>
  > {
    try {
      await delay(600);
      return {
        headers: undefined,
        data: {
          result: [
            {
              id: "18",
              userId: "" + id,
              accountName: "Doe John",
              accountNumber: "410015454369",
              currentBalance: "2.5",
              availableBalance: "2.5",
              currencyCode: "EUR",
              poolId: "3",
              iban: "LT541234500005454369",
              createdAt: "2022-10-22T09:07:16.000Z",
              updatedAt: "2022-12-04T17:16:12.000Z",
            },
          ],
        },
      };
    } catch (e) {
      return {
        headers: undefined,
        data: {
          result: [],
        },
      };
    }
  }

  static async accountSatchelWallets(
    id: string
  ): Promise<
    IRequestResponseType<
      unknown,
      IGeneralResponse<IAccountSatchelWalletsAllTypes>
    >
  > {
    await delay(600);
    return {
      headers: undefined,
      data: {
        result: {
          individual: {
            satchelId: "1234",
            list: [
              {
                id: "123",
                iban: "LT123123123123123",
                createdAt: "2022-10-22T09:07:16.000Z",
                currencyCode: "eur",
                userId: "1234",
                accountNumber: "123123123",
                availableBalance: "1",
                currentBalance: "1",
                poolId: "1",
                accountName: "1",
                type: "ind",
                updatedAt: "2022-10-22T09:07:16.000Z",
              },
            ],
          },
          corporate: {
            satchelId: "1235",
            list: [],
          },
        },
      },
    };
  }

  static async accountTransactionList(
    accountId: string,
    offsetKey?: string | null
  ): Promise<
    IRequestResponseType<
      unknown,
      IGeneralResponse<IAccountTransactionItemsByPage>
    >
  > {
    await delay(600);
    const statuses = ["100", "200", "400"];

    const transactions = [...Array(10)].map(() => {
      const randId = getRandomInt(1, 99999);
      const accountTransactionVariants = [
        {
          id: "TRANS_" + randId.toString(),
          type: "withdraw",
          initiatedBy: {
            type: "terminal",
            id: "g7xqSJGY_fb2RmbeoB02Dg",
          },
          status: statuses[getRandomInt(0, 3)],
          transaction: {
            accountId: "ABS249653625",
            amount: getRandomInt(100, 1000000).toString(),
          },
          createdAt: "2022-04-22T07:39:11.962Z",
          amlDetails: [
            '{"createdAt":"2022-04-22T07:41:12.492Z","status":"400"}',
          ],
        } as IAccountTransaction,
        {
          id: "TRANS_" + randId.toString(),
          type: "deposit",
          initiatedBy: {
            type: "terminal",
            id: "g7xqSJGY_fb2RmbeoB02Dg",
          },
          status: statuses[getRandomInt(0, 3)],
          transaction: {
            accountId: "ABS249653625",
            amount: getRandomInt(100, 1000000).toString(),
          },
          createdAt: "2022-04-22T09:09:37.739Z",
          amlDetails: [
            '{"createdAt":"2022-04-22T09:09:38.301Z","status":"200"}',
          ],
          processingTransactionId:
            "2022-04-22-2c5ecd27d8514c12ba2e8e265afb7831",
        } as IAccountTransaction,
        {
          id: "TRANS_" + randId.toString(),
          type: "transfer",
          initiatedBy: {
            type: "terminal",
            id: "mZ_TasyKDep21TwxhfSeYQ",
          },
          status: statuses[getRandomInt(0, 3)],
          transaction: {
            description: "Pay transfer order #8",
            sourceAccountId: "ABS249653625",
            targetAccountId: "ABS367382551",
            amount: getRandomInt(100, 1000000).toString(),
            variableSymbol: "1",
          },
          createdAt: "2022-04-22T13:33:31.870Z",
          amlDetails: [
            '{"createdAt":"2022-04-22T13:33:33.435Z","status":"200"}',
          ],
          processingTransactionId:
            "2022-04-22-1eda9c27f08f423aafcaadca04e06bbd",
        } as IAccountTransaction,
      ];
      return accountTransactionVariants[getRandomInt(0, 3)];
    });

    const nextOffsetPage =
      offsetKey && +offsetKey
        ? +offsetKey <= 5
          ? (+offsetKey + 1).toString()
          : null
        : "1";

    return {
      headers: undefined,
      data: {
        result: {
          items: transactions,
          nextOffsetKey: nextOffsetPage,
        },
      },
    };
  }

  static async accountStatuses(): Promise<
    IRequestResponseType<unknown, AccountStatusesResponse>
  > {
    return {
      headers: undefined,
      data: {
        result: {
          statuses: ACCOUNT_STATUSES,
        },
      },
    };
  }

  static async setSatchelStatus(
    userId: string,
    status: boolean
  ): Promise<IRequestResponseType<unknown, IGeneralResponse<boolean>>> {
    await delay(2000);
    return {
      headers: undefined,
      data: {
        result: true,
      },
    };
  }

  static async setArbipayStatus(
    userId: string,
    status: boolean
  ): Promise<IRequestResponseType<unknown, IGeneralResponse<boolean>>> {
    await delay(2000);
    return {
      headers: undefined,
      data: {
        result: true,
      },
    };
  }

  static async setOndataStatus(
    userId: string
  ): Promise<IRequestResponseType<unknown, IGeneralResponse<boolean>>> {
    await delay(2000);
    return {
      headers: undefined,
      data: {
        result: true,
      },
    };
  }

  static async accountOndatoDetails(
    id: string
  ): Promise<
    IRequestResponseType<unknown, IGeneralResponse<IAccountOndatoDetail[]>>
  > {
    try {
      await delay(1000);
      return {
        headers: undefined,
        data: {
          result: [
            {
              id: "2",
              createdAt: "2023-02-09T16:01:13.000Z",
              updatedAt: "2023-02-10T08:26:24.000Z",
              userId: 238,
              ondatoId: "12b51899b2db42c8ad5e7c7412e924e6",
              recognizeData:
                '{\n  "statusData": {\n    "applicationId": "578b8256-c963-4c76-ae4f-92d924ec42e3",\n    "status": "REJECTED",\n    "rejectionReason": "MORE_THAN_ONE_PERSON",\n    "isSessionStarted": true,\n    "isVerified": true,\n    "isCompleted": true,\n    "isCrossChecked": true,\n    "hasDocumentData": true,\n    "hasMedia": true\n  },\n  "requestData": {},\n  "documentData": {\n    "firstName": "DMYTRO",\n    "lastName": "KAMINSKYI",\n    "documentNumber": "007409662",\n    "dateOfIssue": "2022-01-18T00:00:00",\n    "dateOfExpiration": "2032-01-18T00:00:00",\n    "dateOfBirth": "1988-07-01T00:00:00",\n    "personalIdentityCode": "19880701-03755",\n    "nationality": "UKR",\n    "country": "UKR",\n    "gender": "M",\n    "documentType": "ID_CARD"\n  }\n}',
              status: "NEW",
              kycId: null,
              statusReason: null,
            },
          ],
        },
      };
    } catch (e) {
      return {
        headers: undefined,
        data: {
          result: [] as IAccountOndatoDetail[],
        },
      };
    }
  }

  static async accountOndatoSessionData(
    id: string
  ): Promise<
    IRequestResponseType<unknown, IGeneralResponse<IAccountOndatoDetail>>
  > {
    try {
      await delay(1000);
      return {
        headers: undefined,
        data: {
          result: {
            id: "2",
            createdAt: "2023-02-09T16:01:13.000Z",
            updatedAt: "2023-02-10T08:26:24.000Z",
            userId: 238,
            kycId: null,
            statusReason: null,
            ondatoId: "12b51899b2db42c8ad5e7c7412e924e6",
            recognizeData:
              '{\n  "statusData": {\n    "applicationId": "578b8256-c963-4c76-ae4f-92d924ec42e3",\n    "status": "REJECTED",\n    "rejectionReason": "MORE_THAN_ONE_PERSON",\n    "isSessionStarted": true,\n    "isVerified": true,\n    "isCompleted": true,\n    "isCrossChecked": true,\n    "hasDocumentData": true,\n    "hasMedia": true\n  },\n  "requestData": {},\n  "documentData": {\n    "firstName": "DMYTRO",\n    "lastName": "KAMINSKYI",\n    "documentNumber": "007409662",\n    "dateOfIssue": "2022-01-18T00:00:00",\n    "dateOfExpiration": "2032-01-18T00:00:00",\n    "dateOfBirth": "1988-07-01T00:00:00",\n    "personalIdentityCode": "19880701-03755",\n    "nationality": "UKR",\n    "country": "UKR",\n    "gender": "M",\n    "documentType": "ID_CARD"\n  }\n}',
            status: "NEW",
          },
        },
      };
    } catch (e) {
      return {
        headers: undefined,
        data: {
          result: {} as IAccountOndatoDetail,
        },
      };
    }
  }

  static async accountSignedContracts(
    id: string
  ): Promise<
    IRequestResponseType<unknown, IGeneralResponse<IAccountSignedContract[]>>
  > {
    try {
      await delay(1000);
      return {
        headers: undefined,
        data: {
          result: [
            {
              id: 123,
              userId: 234,
              s3Id: "string",
              // "s3Link": getRandomInt(1, 99999) + "",
              s3Link:
                "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
              contractVersion: "string",
              contentType: "application/pdf",
              created_at: "datetime",
              updated_at: "datetime",
            },
            {
              id: 124,
              userId: 234,
              s3Id: "string",
              s3Link:
                "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf",
              contractVersion: "string",
              contentType: "application/pdf",
              created_at: "datetime",
              updated_at: "datetime",
            },
          ],
        },
      };
    } catch (e) {
      return {
        headers: undefined,
        data: {
          result: [] as IAccountSignedContract[],
        },
      };
    }
  }

  static async accountIdentityVerificationInfo(
    id: string
  ): Promise<
    IRequestResponseType<
      unknown,
      IGeneralResponse<IAccountIdentityVerificationInfo>
    >
  > {
    try {
      await delay(1000);
      return {
        headers: undefined,
        data: {
          result: {
            satchel: {
              id: "1",
              createdAt: "2023-03-13T08:25:40.000Z",
              updatedAt: "2023-03-13T08:25:40.000Z",
              userId: 5,
              firstName: "test",
              lastName: "test",
              personalNumber: "test",
              dateOfBirthday: "1993-03-13T00:00:00.000Z",
              nationality: "test",
              addressCountryCode: "test",
              addressCity: "test",
              addressStreet: "test",
              addressZipCode: "test",
              birthPlace: "test",
              taxCountry: "test",
              employment: "test",
              sourceInitialFunding: "test",
              pep: "test",
              usCitizenship: "test",
              termsAndConditions: "test",
              privacyPolicy: "test",
              usePersonalData: "test",
              selfieImage: "",
              privacyPolicyArbitas: "test",
              privacyPolicySatchel: "test",
              termsAndConditionsArbitas: "test",
              termsAndConditionsSatchel: "test",
            },
            recognized: {
              id: "1",
              createdAt: "2023-03-13T08:25:40.000Z",
              updatedAt: "2023-03-13T09:02:58.000Z",
              userId: 5,
              sessionId: 4,
              firstName: "John",
              lastName: "Jones",
              middleName: "Eve",
              documentNumber: "PU2343443343",
              issueDate: "2000-02-03",
              expiryDate: "2000-02-03",
              dateOfBirthday: "2000-02-03",
              personalNumber: "199202030202",
              address: "string",
              nationality: "LTU",
              countryCode: "LTU",
              gender: "Male",
              docType: "IdCard",
              mrzVerified: 1,
              category: "B",
              stateCode: "GR",
            },
          },
        },
      };
    } catch (e) {
      return {
        headers: undefined,
        data: {
          result: {} as IAccountIdentityVerificationInfo,
        },
      };
    }
  }

  static async accountInfoOndatoSessionRefreshStatus(
    sessionId: number
  ): Promise<
    IRequestResponseType<
      unknown,
      IAccountInfoOndatoSessionRefreshStatusResponse
    >
  > {
    await delay(600);
    return {
      headers: undefined,
      data: {
        result: {
          status: "APPROVED",
        },
      },
    };
  }

  static async deleteAccount(
    accountId: string
  ): Promise<IRequestResponseType<unknown, IGeneralResponse<boolean>>> {
    await delay(600);
    return {
      headers: undefined,
      data: { result: true },
    };
  }
}
