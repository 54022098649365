const sk = {
  translation: {
    common: {
      deposit: "Vklad",
      credit: "Kredit",
      investments: "Investície",
      rows_per_page: "Počet riadkov na stranu:",
      of: "z",
      btn_cancel: "Zrušiť",
      btn_decline: "Zamietnuť",
      btn_confirm: "Potvrdiť",
      btn_more_info: "Viacej detailov",
      btn_open: "Otvoriť",
      btn_open_link: "Otvoriť link",
    },
    transactions: {
      client_profile: {
        header: "Profil Klienta",
        full_name: "Meno a priezvisko",
        birthday: "Dátum narodenia",
        sin: "Rodné číslo",
        contact: "Kontakt",
        address: "Adresa",
        home: "Domov",
        mobile: "Telefónne číslo",
        email: "Email",
        employer: "Zamestnávateľ",
        job_title: "Pracovná pozícia",
        employer_name: "Názov zamestnávateľa",
        start_date: "Dátum vytvorenia",
        salary: "Plat",
        family: "Rodina",
        marital_status: "Rodinný stav",
        spouse_name: "Meno manžela/ky",
        spouse_birthday: "Dátum narodenia manžela/ky",
        children: "Deti"
      },
      account_activity: {
        header: "Aktivity na účte",
        branch: "Pobočka",
        institution: "Inštitúcia",
        account_number: "Číslo účtu"
      },
      table: {
        id: "ID",
        title: "Transakcie",
        timestamp: "Dátum",
        operation: "Operácia",
        transactionId: "ID transakcie",
        operationType: "Operácia",
        fromAccount: "Z účtu",
        toAccount: "Na účet",
        status: "Stav",
        amount: "Suma",
        details: "Detaily",
        all: "Všetko",
        transferType: "Typ prevodu",
        "transferType transfer": "Prevod peňazí",
        "transferType merchantTransfer": "Prevod obchodníkovi"
      },
      info: {
        header: "Informácia o transakcii",
        data: "Údaje o transakcii",
        origin: "Z eWallet účtu",
        destination: "Na eWallet účet",
        "Transaction id": "ID transakcie",
        "Time & date": "Dátum a čas",
        "Amount": "Suma",
        "Status": "Stav",
        "Processing status": "Stav procesovania",
        "Source": "Miesto vytvorenia",
        "Type of operation": "Typ operácie",
        "Confirm time": "Čas potvrdenia",
        "End time": "Čas ukončenia",
        "Account": "Číslo eWallet účtu",
        "Country": "Krajina",
        "Bank code": "Bankový kód",
      },
      modal: {
        confirm_header: "Potvrdiť transakciu?",
        confirm_question: "Naozaj si prajete potvrdiť túto transakciu?",
        decline_header: "Zamietnuť účet?",
        decline_question: "Naozaj si prajete zamietnuť tento účet?",
        more_info_header: "Potrebujem viacej informácií",
        more_info_question: "Prosím napíšte aké informácie sú potrebné na úspešné spracovanie tejto transakcie.",
        more_info_label: "Správa",
      },
      data: {
        merchant: "Informácie o obchodníkovi"
      },
      statuses: {
        "UNDEFINED": "NEDEFINOVANÉ",
        "PENDING": "ČAKAJÚCE",
        "ACCEPTED": "AKCEPTOVANÉ",
        "REJECTED": "ZAMIETNUTÉ",
        "AML_CHECK": "AML KONTROLA",
        "CREATED": "VYTVORENÉ",
        "AML_SUCCESS": "AML SCHVÁLENÉ",
        "BLOCKCHAIN_PENDING": "ČAKAJÚCE NA BLOCKCHAIN"
      }
    },
    account: {
      common: {
        unassigned: "Nepriradené",
        declined: "Zamietnuté",
        confirmed: "Potvrdené",
        all: "Všetko"
      },
      table: {
        accounts: "Účty",
        id: "ID",
        account_id: "Číslo klientského účtu",
        firstName: "Meno",
        lastName: "Priezvisko",
        uniqueIdentifier: "Rodné číslo",
        status: "Stav",
        created_at: "Dátum",
        details: "Detaily",
        actions: "Akcia",
        sanctionStatus: "Sankčný stav",
        email: "Email",
        phone: "Phone"
      },
      details: {
        header: "Detaily klientského účtu",
        basic_info: "Základné informácie",
        identity_verification: "Identity verification",
        verified_information: "Verified information",
        first_name: "Meno",
        last_name: "Priezvisko",
        contact_info: "Kontaktné informácie",
        email: "Emailová adresa",
        phone_number: "Telefónne číslo",
        status: "Stav",
        account_ids: "Prehľad eWallet účtov",
        btn_confirm: "Potvrdiť",
        btn_decline: "Zamietnuť",
        sex: "Pohlavie",
        nationality: "Národnosť",
        birthDate: "Dátum narodenia",
        documents: "Doklady",
        document: {
          title: "Dokument",
          id: "Občiansky preukaz",
          passport: "Passport",
          driverLicense: "Driver livense",
        },
        sexF: "Žena",
        sexM: "Muž",
        photo: "Fotografia",
        signedContract: "Zmluvná dokumentácia",
        sanctions: "Sankčný zoznam",
        check_result: "Skontrolovať výsledok",
        find_by: "Hľadať podľa",
        sanction_link: "Link na sankčný zoznam"
      },
      modal: {
        confirm_header: "Schváliť účet?",
        confirm_question: "Naozaj si prajete schváliť tento účet?",
        decline_header: "Zamietnuť účet?",
        decline_question: "Naozaj si prajete zamietnuť tento účet? Prosím popíšte dôvod zamietnutia do textového políčka nižšie.",
        decline_reason_label: "Dôvod"
      },
      alert: {
        error: "Chyba",
        "Account ID not found in partner API": "Číslo účtu nebolo nájdené počas procesovania API"
      },
      sanctions: {
        fullName: "Meno a priezvisko"
      },
      statuses: {
        "UNDEFINED": "NEDEFINOVANÉ",
        "PENDING": "ČAKAJÚCE",
        "ACCEPTED": "AKCEPTOVANÉ",
        "REJECTED": "ZAMIETNUTÉ"
      },
      metadata: {
        "CompareResult": "Porovnať výsledok",
        "AgeRange": "Vekový pásmo",
        "years old": "Vek",
        "AgeRangeHigh": "Maximálny vek",
        "AgeRangeLow": "Minimálny vek",
        "Beard_true": "Má bradu",
        "Beard_false": "Nemá bradu",
        "Confidence": "Vyzerá ako tvár",
        "Emotions (CALM)": "Emócie POKOJNÝ",
        "Emotions (SURPRISED)": "Emócie PREKVAPENÝ",
        "Emotions (FEAR)": "Emócie VYSTRAŠENÝ",
        "Emotions (SAD)": "Emócie SMUTNÝ",
        "Emotions (HAPPY)": "Emócie ŠŤASTNÝ",
        "Emotions (CONFUSED)": "Emócie ZMÄTENÝ",
        "Emotions (ANGRY)": "Emócie NAHNEVANÝ",
        "Emotions (DISGUSTED)": "Emócie ZNECHUTENÝ",
        "Eyeglasses_true": "Má okuliare",
        "Eyeglasses_false": "Nemá okuliare",
        "EyesOpen_true": "Oči sú otvorené",
        "EyesOpen_false": "Oči sú zatvorené",
        "Male": "Vyzerá ako muž",
        "Female": "Vyzerá ako žena",
        "MouthOpen_true": "Ústa sú otvorené",
        "MouthOpen_false": "Ústa sú zatvorené",
        "Mustache_true": "Má fúzy",
        "Mustache_false": "Nemá fúzy",
        "PosePitch": "Kývanie hlavy",
        "PoseRoll": "Nakláňanie hlavy",
        "PoseYaw": "Krútenie hlavy",
        "QualityBrightness": "Kvalita jasu",
        "QualitySharpness": "Kvalita ostrosti",
        "Smile_true": "Usmieva sa",
        "Smile_false": "Neusmieva sa",
        "Sunglasses_true": "Má slnečné okuliare",
        "Sunglasses_false": "Nemá slnečné okuliare",
      }
    },
    eWallet: {
      balance: "Zostatok",
    },
    menu: {
      home: "Domov",
      applications: "Vyhlásenia",
      transactions: "Transakcie",
      accounts: "Účty",
      profile: "Profil",
      logout: "Odhlásenie",
      management: "Manažment",
      permissions: "Oprávnenia",
      roles: "Role",
      users: "Užívatelia",
      merchants: "Obchodníci",
      "Blockchain monitoring": "Blockchain monitoring",
      techEWallet: "Technický eWallet",
      cards: "Karty",
      "Sanction list": "Sankčný zoznam",
      "Search": "Vyhľadať",
      "Search List": "Vyhľadávací zoznam",
      "Search Countries": "Vyhľadávací krajiny",
    },
    http: {
      errors: {
        "email incorrect": "Email adresa nie je správna",
        "password incorrect": "Heslo nie je správne",
        "server not response": "Server neodpovedá",
        "server error": "Chyba servera",
        "password must be longer than or equal to 4 characters": "Heslo je príliš krátke",
        "name is not unique": "Toto meno už existuje",
        "email is not unique": "Tento email už existuje",
        "QueryFailedError": "Uložiť chybu",
        "account with this accountId not find in internal database": "Toto číslo účtu nebolo nájdené v databáze"
      }
    },
    form: {
      auth: {
        "Login": "Prihlásenie",
        "Password": "Heslo",
        "Email": "Email",
        "Sign In": "Prihlásiť sa",
      }
    },
    auth: {
      common: {
        "Password successfully changed!": "Heslo bolo úspešne zmenené.",
      }
    },
    profile: {
      header: "Profil",
      change_password: "Zmeniť heslo",
      general_info: "Všeobecné informácie",
      form: {
        email: "Email",
        password: "Súčasné heslo",
        passwordNew: "Nové heslo",
        passwordDup: "Potvrdiť nové heslo",
        save: "Uložiť"
      }
    },
    validations: {
      "Login is required": "Je potrebné sa prihlásiť",
      "Login must be a an email": "Na prihlásenie je potrebné zadať email",
      "Password is required": "Heslo je povinné",
      "Validation error": "Chyba overenia",
      "Wrong email": "Nesprávny email",
      "Min length": "Minimálna dĺžka je {{hodnota}} znakov",
      "Max length": "Maximálna dĺžka je {{hodnota}} znakov",
      "email incorrect": "Email nebol nájdený",
      "password incorrect": "Heslo je nesprávne",
      "password must be longer than or equal to 4 characters": "Heslo je príliš krátke",
      "Name is required": "Meno je povinné",
      "Email is required": "Email je povinný",
      "Passwords do not match": "Heslá sa nezhodujú",
      "email is not unique": "Táto emailová adresa už existuje",
      "Role is required": "Rola je povinná",
      "name is not unique": "Toto meno už existuje",
      "You cannot delete your account": "Nemôžete zmazať Váš účet",
      "Old password is required": "Pôvodné heslo je potrebné",
      "Field is required": "Pole je povinné",
      "Allow only digits": "Povolené sú len číslice",
    },
    error_page: {
      "Ops": "Chyba",
      "Page not found": "Táto stránka neexistuje alebo je nedostupná.",
      "Go back to home": "Vráťte sa späť do hlavného menu"
    },
    permissions: {
      table: {
        title: "Povolenia",
        id: "Identifikačné číslo",
        name: "Meno",
        description: "Popis"
      },
      names: {
        get_permissions: "Zobraziť zoznam povolení",
        get_roles: "Zobraziť zoznam rolí",
        get_role: "Zobraziť detaily role",
        create_role: "Vytvoriť rolu",
        update_role: "Upraviť rolu",
        remove_role: "Odstrániť rolu",
        get_users: "Zobraziť zoznam užívateľov",
        get_user: "Zobraziť detaily užívateľa",
        create_user: "Vytvoriť užívateľa",
        update_user: "Upraviť užívateľa",
        remove_user: "Odstrániť užívateľa",
        get_verification_list: "Zobraziť zoznam účtov",
        get_account_info: "Zobraziť detaily na účte",
        set_verification_result: "Nastaviť stav výsledku overenia účtu",
        get_transactions_list: "Zobraziť zoznam transakcií",
        get_transaction_info: "Zobraziť detail transakcie",
        set_transaction_verification_result: "Nastaviť stav výsledku overenia transakcie",
        get_transaction_additional_info: "Zobraziť dodatočné informácie o transakcii",
        update_setting: "Upraviť nastavenia",
        get_merchants: "Zobraziť zoznam obchodníkov",
        get_merchant: "Zobraziť detaily obchodníka",
        create_merchant: "Vytvoriť obchodníka",
        update_merchant: "Upraviť obchodníka",
        remove_merchant: "Odstrániť obchodníka",
        get_wallets: "Zobraziť zoznam eWallet účtov",
        get_wallet: "Zobraziť detaily na eWallet účte",
        create_wallet: "Vytvoriť eWallet",
        update_wallet: "Upraviť eWallet",
        remove_wallet: "Odstrániť eWallet",
        get_cards_list: "Zobraziť zoznam kariet",
        get_card_info: "Zobraziť detail karty",
        update_card_status: "Upraviť stav karty",
        get_sanctions: "Zobraziť sankcie",
      },
      descriptions: {
        get_permissions: "Povoliť zobraziť zoznam povolení",
        get_roles: "Povoliť zobraziť zoznam rolí",
        get_role: "Povoliť zobraziť informácie k roli",
        create_role: "Povoliť vyytvoriť novú rolu",
        update_role: "Povoliť upraviť existujúcu rolu",
        remove_role: "Povoliť odstrániť rolu",
        get_users: "Povoliť zobraziť zoznam užívateľov",
        get_user: "Povoliť zobraziť informácie o užívateľovi",
        create_user: "Povoliť vytvoriť nového užívateľa",
        update_user: "Povoliť upraviť existujúceho užívateľa",
        remove_user: "Povoliť odstrániť užívateľa",
        get_verification_list: "Povoliť zobraziť zoznam účtov",
        get_account_info: "Povoliť zobraziť detaily na účte",
        set_verification_result: "Povoliť nastaviť stav výsledku overenia účtu",
        get_transactions_list: "Povoliť zobraziť zoznam transakcií",
        get_transaction_info: "Povoliť zobraziť detaily transakcie",
        set_transaction_verification_result: "Nastaviť stav výsledku overenia transakcie",
        get_transaction_additional_info: "Povoliť zobraziť dodatočné informácie o transakcii",
        update_setting: "Povoliť upraviť nastavenia",
        get_merchants: "Povoliť zobraziť zoznam obchodníkov",
        get_merchant: "Povoliť zobraziť detaily obchodníka",
        create_merchant: "Povoliť vytvoriť nového obchodníka",
        update_merchant: "Povoliť upraviť existujúceho obchodníka",
        remove_merchant: "Povoliť odstrániť obchodníka",
        get_wallets: "Povoliť zobraziť zoznam eWallet účtov",
        get_wallet: "Povoliť zobraziť detaily na eWallet účte",
        create_wallet: "Povoliť vytvoriť nový eWallet účet",
        update_wallet: "Povoliť upraviť existujúci eWallet",
        remove_wallet: "Povoliť odstrániť eWallet",
        get_cards_list: "Povoliť zobraziť zoznam kariet",
        get_card_info: "Povoliť zobraziť detail karty",
        update_card_status: "Povoliť upraviť stav karty",
        get_sanctions: "Povoliť zobraziť sankcie",
      },
    },
    role: {
      table: {
        title: "Role",
        id: "ID",
        name: "Názov",
        permissions: "Povolenia",
        actions: "Akcie",
        edit: "Upraviť",
        delete: "Vymazať",
        create: "Vytvoriť",
      },
      edit: {
        header: "Upraviť rolu"
      },
      add: {
        header: "Vytvoriť novú rolu"
      },
      form: {
        name: "Názov",
        permissions: "Povolenia",
        "check all permissions": "Skontroluj všetky povolenia",
        save: "Uložiť",
      },
      common: {
        "Role delete confirmation": "Potvrdenie vymazania role",
        "Are you sure that you want to delete role?": "Naozaj si prajete vymazať rolu?  ID role = {{id}}",
        "The role is assigned to users. All users with this role will be blocked.": "Táto rola už bola priradená k užívateľom. Všetci užívatelia s priradenou touto rolou budú zablokovaní. Naozaj si prajete vymazať túto rolu?  Identifikačné číslo role = {{id}}",
        "Role updated!": "Rola bola upravená.",
        "Role created!": "Rola bola vytvorená.",
        "Role id successfully deleted!": "Rola s ID = {{id}} bola úspešne vymazaná.",
      }
    },
    user: {
      table: {
        title: "Back Office užívatelia",
        id: "ID",
        name: "Meno",
        email: "Email",
        role: "Rola",
        createdAt: "Dátum vytvorenia",
        updatedAt: "Dátum zmeny",
        create: "Vytvoriť užívateľa",
        details: "Detaily",
        delete: "Vymazať",
        block: "Zablokovať",
        unblock: "Odblokovať",
        status: "Stav",
        "find by email": "Vyhľadať podľa emailu",
      },
      info: {
        header: "Informácie o užívateľovi"
      },
      add: {
        header: "Vytvoriť nového užívateľa"
      },
      form: {
        name: "Meno",
        email: "Email",
        oldPassword: "Pôvodné heslo",
        password: "Heslo",
        passwordConfirm: "Potvrdiť heslo",
        role: "Rola",
        save: "Uložiť",
      },
      common: {
        "User block confirmation": "Potvrdenie zablokovania užívateľa",
        "Are you sure that you want to block user?": "Naozaj si prajete zablokovať užívateľa? ID užívateľa = {{id}}",
        blocked: "Zablokovaný",
        active: "Aktívny",
        "User updated!": "Užívateľ bol zmenený.",
        "User created!": "Užívateľ bol vytvorený.",
        "User id successfully blocked/unblocked": "Užívateľ s ID ={{id}} bol úspešne {{status}}",
      },
    },
    management: {
      header: "Manažment"
    },
    merchant: {
      table: {
        id: "ID",
        name: "Názov",
        type: {
          title: "Typ",
          PhoneOperator: "Telefónny operátor",
          POS: "POS",
        },
        eWallet: "Číslo účtu",
        details: "Detaily",
        title: "Obchodníci",
      },
      details: {
        header: "Detaily obchodníka",
        balance: "Zostatok",
      }
    },
    techEWallet: {
      header: "Technický eWallet účet"
    },
    card: {
      table: {
        title: "Karty",
        id: "ID",
        balance: "Balance",
        pan: "Číslo karty",
        expiryAt: "Date of expiry",
        createdAt: "Dátum vytvorenia",
        details: "Detaily"
      },
      alert: {
        error: "",
        "Card ID not found in partner API": "Číslo karty nebolo nájdené počas procesovania API"
      },
      details: {
        header: "Informácie o karte",
        basic_info: "Základné informácie",
        additional_info: "Dodatočné informácie",
        fullName: "Meno a priezvisko",
        phoneNumber: "Telefónne číslo",
        city: "Mesto",
        street: "Ulica",
        unit: "Číslo domu",
        zip: "PSČ",
        cardNumber: "Číslo karty",
        userId: "Číslo klientského účtu",
        emoneyAccountId: "Číslo eWallet účtu",
        status: "Stav",
        createdAt: "Dátum vytvorenia",
        set_card_num: "Nastaviť číslo karty",
        "Cancel card request": "Zrušiť žiadosť o vytvorenie karty",
        "Are you sure that you want cancel card request?": "Naozaj si prajete zrušiť žiadosť o vytvorenie karty?",
        "Confirm card request": "Potvrdiť žiadosť o vytvorenie karty",
        "Are you sure that you want to confirm card request?": "Naozaj si prajete schváliť žiadosť o vytvorenie karty?",
        "Set card number": "Nastaviť číslo karty",
        "Input card number": "Zadanie čísla karty",
        cardNumber_not_defined: "Číslo karty nie je definované",
      },
      statuses: {
        "NEW_REQUEST": "NOVÁ POŽIADAVKA",
        "CARD_ASSIGNED": "PRIDELENÁ KARTA",
        "CARD_SENT": "POSLANÁ KARTA",
        "REQUEST_CANCELED": "ZAMIETNUTÁ POŽIADAVKA"
      }
    },
    sanction: {
      list: {
        EU: "EU Sankčný zoznam",
        UN: "Spojené národy Sankčný zoznam",
        US: "USA Sankčný zoznam",
        UK: "Veľká Británia Sankčný zoznam",
      },
      search: {
        title: "Vyhľadať osobu v sankčnom zozname",
      },
      form: {
        firstName: "Meno",
        lastName: "Priezvisko",
        fullName: "Meno a priezvisko",
        find: "Vyhľadať",
        sanctionBase: "Databáza sankcií",
      },
      table: {
        id: "ID",
        publicationUrl: "URL Publikácie",
        title: "Sankčný zoznam",
        fullName: "Meno a priezvisko",
        firstName: "Meno",
        lastName: "Priezvisko",
        "Result is empty": "Na zozname sa nenachádza.",
        link: "Link"
      },
      status: {
        found: "Nájdený",
        not_found: "Nenájdený",
      }
    }
  }
};

export default sk;
