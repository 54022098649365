import {
  AuthActionTypeList,
  IAuthValuesSend,
  IAuthValuesSignIn,
  ISetAuthErrors,
  ISetAuthLoading,
  ISetAuthStatus,
  ISetAuthUser,
  IUser,
  TAuthActions
} from "../../types/store/auth";
import {Action, Dispatch} from "redux";
import {AuthTestService} from "../../sevices/test/auth-test-service";
import AuthService from "../../sevices/auth-service";
import jwtDecode from "jwt-decode";
import {LocalStorageItems} from "../../types/local-storage";
import {AuthResponseHeaders} from "../../types/service/response/auth-response";
import {USE_SERVER} from "../../constants/constants";
import i18n from "i18next";
import {AxiosError} from "axios";
import {IGeneralResponse} from "../../types/service/response/general-response";
import {handleNetworkError} from "./error-handler";
import {ThunkDispatch} from "redux-thunk";
import {notification} from "../../utils/notification";
import Cookies from "js-cookie";

export const setAuthLoading = (type: boolean): ISetAuthLoading => ({
  type: AuthActionTypeList.SET_AUTH_LOADING,
  payload: type
});

export const setAuthStatus = (status: boolean): ISetAuthStatus => ({
  type: AuthActionTypeList.SET_AUTH_STATUS,
  payload: status
});

export const setAuthUser = (user: IUser): ISetAuthUser => ({
  type: AuthActionTypeList.SET_AUTH_USER,
  payload: user
});

export const setAuthErrors = (errors: Record<string, string>): ISetAuthErrors => ({
  type: AuthActionTypeList.SET_AUTH_ERRORS,
  payload: errors
});

const userIsAuth = (user: IUser) => ({...user, permissions: [...user.permissions, "is_auth"]});

/**
 * It takes an email and password, dispatches a loading action, tries send passcode to email, and if it succeeds, 
 * the userId to the user.id that was returned from the server. If it fails, it handles the error and sets
 * the auth errors to the errors that were returned from the server
 * @param {string} email - string
 * @param {string} password - string
 */
export const login = (email: string, password: string) => async (dispatch: ThunkDispatch<unknown, unknown, Action>) => {
  dispatch(setAuthLoading(true));
  try {
    const response = USE_SERVER ?
      await AuthService.login(email, password)
      :
      await AuthTestService.login(email, password);

    // const user = jwtDecode<IUser>(response.headers[AuthResponseHeaders.ACCESS_TOKEN]);
    dispatch(setAuthUser({
      id: response.data.userId || "",
      email,
      permissions: [],
      role: ""
    } as IUser));
  } catch (e: unknown) {
    await dispatch(handleNetworkError(e));

    const {response} = e as AxiosError<IGeneralResponse<boolean>>;
    const errors: string[] | undefined = response?.data?.errors;
    const errorStrings: Partial<IAuthValuesSend> = {email: "", password: ""};
    if (errors && errors?.length > 0) {
      errors.map(error => {
        notification.showErrorMessage(i18n.t("validations." + error));
        const key = error.split(" ")[0];
        if (key in errorStrings) {
          errorStrings[key as keyof IAuthValuesSend] = error;
        }
      });
    }
    dispatch(setAuthErrors(errorStrings));
  } finally {
    dispatch(setAuthLoading(false));
  }
};

/**
 * It takes a passcode, dispatches a loading action, tries login with user.id and passcode, and if it succeeds, it sets auth status to
 * true and the user to the user that was returned from the server. If it fails, it handles the error and sets
 * the auth errors to the errors that were returned from the server
 * @param {string} userId
 * @param {string} passcode - string
 */
export const checkPasscode = (userId: string, passcode: string) => async (dispatch: ThunkDispatch<unknown, unknown, Action>) => {
  dispatch(setAuthLoading(true));
  try {
    const response = USE_SERVER ?
      await AuthService.checkPasscode(userId, passcode)
      :
      await AuthTestService.checkPasscode(userId, passcode);

    const user = jwtDecode<IUser>(response.headers[AuthResponseHeaders.ACCESS_TOKEN]);
    dispatch(setAuthStatus(true));
    dispatch(setAuthUser(userIsAuth(user)));
  } catch (e: unknown) {
    await dispatch(handleNetworkError(e));

    const {response} = e as AxiosError<IGeneralResponse<boolean>>;
    const errors: string[] | undefined = response?.data?.errors;
    const errorStrings: Partial<IAuthValuesSignIn> = {passcode: ""};
    if (errors && errors?.length > 0) {
      errors.map(error => {
        notification.showErrorMessage(i18n.t("validations." + error));
        const key = error.split(" ")[0];
        if (key in errorStrings) {
          errorStrings[key as keyof IAuthValuesSignIn] = error;
        }
      });
    }
    dispatch(setAuthErrors(errorStrings));
  } finally {
    dispatch(setAuthLoading(false));
  }
};

/**
 * Method to logout user.
 */
export const logout = () => async (dispatch: Dispatch<TAuthActions>) => {
  //TODO: need service-query to logout, for delete token
  try {
    Cookies.remove(LocalStorageItems.TOKEN);
    dispatch(setAuthUser({} as IUser));
    dispatch(setAuthStatus(false));
  } catch (e: any) {
    console.log(e.response?.data?.message);
  }
};

/**
 * It checks if the user is authenticated and if so, it sets the user and the auth status in the store
 */
export const checkAuth = () => async (dispatch: Dispatch<TAuthActions>) => {
  try {
    const response = USE_SERVER ?
      await AuthService.checkToken()
      :
      await AuthTestService.checkToken();

    const user = jwtDecode<IUser>(response.headers[AuthResponseHeaders.ACCESS_TOKEN]);
    dispatch(setAuthStatus(true));
    dispatch(setAuthUser(userIsAuth(user)));
  } catch (e: unknown) {
    Cookies.remove(LocalStorageItems.TOKEN);
    dispatch(setAuthUser({} as IUser));
    dispatch(setAuthStatus(false));
  }
};
