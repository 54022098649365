import {
  IPermission,
  IPermissionsListState,
  PermissionsListActions,
  TPermissionsAction
} from "../../types/store/permissions";

const initialState: IPermissionsListState = {
  permissions: [] as IPermission[],
  isLoading: false
};

export const permissionsListReducer = (state: IPermissionsListState = initialState, action: TPermissionsAction): IPermissionsListState => {
  switch (action.type){
  case PermissionsListActions.SET_PERMISSIONS_LIST:
    return {...state, permissions: action.permissions};
  case PermissionsListActions.SET_PERMISSIONS_LIST_LOADING:
    return {...state, isLoading: action.loading};
  case PermissionsListActions.SET_PERMISSIONS_LIST_ERROR:
    return {...state, errorMessage: action.errorMessage, errorCode: action.errorCode};
  default:
    return state;
  }
};