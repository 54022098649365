import {
  IClearWalletAccounts,
  ISetWalletAccounts,
  ISetWalletAccountsLoading,
  IUpdateWalletAccount,
  IWalletAccountData,
  WalletAccountsActions
} from "../../../types/store/wallet/wallet-accounts";
import {ThunkDispatch} from "redux-thunk";
import {Action} from "redux";
import {handleGeneralError} from "../error-handler";
import WalletTestService from "../../../sevices/test/wallet-test-service";
import {USE_SERVER} from "../../../constants/constants";
import WalletService from "../../../sevices/wallet-service";

/**
 * It returns an object with a type property and an accounts property
 * @param {IWalletAccountData[]} wallets - IWalletAccountData[]
 * @param isTech
 */
export const setWalletAccounts = (wallets: IWalletAccountData[], isTech = false): ISetWalletAccounts => ({
  type: WalletAccountsActions.SET_WALLET_ACCOUNTS,
  wallets,
  isTech
});

/**
 * It returns an object with a type property and an account property
 * @param {IWalletAccountData} wallet - IWalletAccountData
 */
export const updateWalletAccount = (wallet: IWalletAccountData): IUpdateWalletAccount => ({
  type: WalletAccountsActions.UPDATE_WALLET_ACCOUNT,
  wallet,
});

/**
 * It returns an object with a type property set to the CLEAR_WALLET_ACCOUNTS constant
 */
export const clearWalletAccounts = (): IClearWalletAccounts => ({
  type: WalletAccountsActions.CLEAR_WALLET_ACCOUNTS
});

export const setWalletAccountsLoading = (loading: boolean): ISetWalletAccountsLoading => ({
  type: WalletAccountsActions.SET_WALLET_ACCOUNTS_LOADING,
  loading
});

/**
 * It fetches the list of accounts from the server, and then fetches the data for each account
 */
export const fetchTechWalletAccounts = () => async (dispatch: ThunkDispatch<unknown, unknown, Action>) => {
  try {
    dispatch(clearWalletAccounts());
    dispatch(setWalletAccountsLoading(true));
    const response =
            USE_SERVER
              ? await WalletService.getWalletAccountList()
              :
              await WalletTestService.getWalletAccountList();
    const accounts = response.data.wallets;
    await dispatch(setWalletAccounts(accounts, true));
    accounts.map(async (account) => {
      await dispatch(fetchWalletAccountData(account.accountNumber));
    });
  } catch (e: unknown) {
    await dispatch(handleGeneralError(e));
  } finally {
    dispatch(setWalletAccountsLoading(false));
  }
};


/**
 * It takes an array of account IDs, clears the wallet accounts, and then fetches the data for each account
 * @param {string[]} accountNumbers - string[] - an array of account IDs to set as the specified accounts
 * @returns A function that takes a ThunkDispatch and returns a Promise.
 */
export const setSpecifiedWalletAccounts = (accountNumbers: string[]) => async (dispatch: ThunkDispatch<unknown, unknown, Action>) => {
  dispatch(clearWalletAccounts());
  if (!accountNumbers.length) {
    return;
  }
  const accounts: IWalletAccountData[] = accountNumbers.map(accountNumber => ({accountNumber} as IWalletAccountData));
  await dispatch(setWalletAccounts(accounts));
  accounts.map(async (account) => {
    await dispatch(fetchWalletAccountData(account.accountNumber));
  });
};

/**
 * It fetches the wallet account data from the server and dispatches an action to update the wallet account data in the
 * Redux store
 * @param {string} accountNumber - string - the account id of the wallet account you want to fetch
 */
export const fetchWalletAccountData = (accountNumber: string) => async (dispatch: ThunkDispatch<unknown, unknown, Action>) => {
  try {
    const response =
            USE_SERVER
              ? await WalletService.getWalletAccountData(accountNumber)
              :
              await WalletTestService.getWalletAccountData(accountNumber);
    dispatch(updateWalletAccount(response.data.wallet));
  } catch (e: unknown) {
    await dispatch(handleGeneralError(e));
  }
};
