import React, {FC} from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import {useTranslation} from "react-i18next";

interface DialogConfirmationProps {
    isOpen: boolean,
    onClose: () => void,
    onConfirm: () => void,
    title: string,
    message: string
}

/**
 * General component to dialog confirmation
 */
const DialogConfirmation: FC<DialogConfirmationProps> = ({isOpen, onClose, onConfirm, title, message}) => {
  const {t} = useTranslation();
  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>
          {t("common.btn_cancel")}
        </Button>
        <Button onClick={onConfirm}>
          {t("common.btn_confirm")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogConfirmation;