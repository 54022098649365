import React, {FC} from "react";
import {Box, Button, Card, CardContent, CardHeader, Divider, Grid, Typography, useTheme} from "@mui/material";
import i18n from "i18next";
import {useTranslation} from "react-i18next";
import {ISanction} from "../../types/store/sanction";

interface ISanctionListProps {
    sanctions: ISanction[] | undefined;
}

const SanctionList: FC<ISanctionListProps> = ({sanctions}) => {
  const theme = useTheme();
  const {t} = useTranslation();
    
  return (
    <Box>
      {sanctions && sanctions.map((sanctionBase, index) => (
        <Card key={index} sx={{mt: 2}}>
          <CardHeader title={t(`sanction.list.${sanctionBase.sanctionList}`)}/>
          <CardContent>
            {sanctionBase.findBy !== null && (
              <>
                <Grid container sx={{display: "flex", py: 2}} spacing={2}>
                  <Grid item xs={6} md={4}>
                    <Typography variant="subtitle1">
                      {t("account.details.check_result")}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} md={8} sx={{textAlign: "right"}}>
                    <Box
                      sx={{
                        color: theme.palette.error.main,
                        backgroundColor: theme.palette.error.main + "44",
                        padding: "3px 10px",
                        borderRadius: "20px",
                        display: "inline-block",
                      }}
                    >
                      {t("sanction.status.found")}
                    </Box>
                  </Grid>
                </Grid>
                <Divider/>
                <Grid container sx={{display: "flex", alignItems: "center"}}>
                  <Grid item xs={6} md={4}>
                    <Typography variant="subtitle1">
                      {t("account.details.find_by")}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} md={8} sx={{textAlign: "right"}}>
                    <Grid container sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      flexDirection: "column"
                    }} spacing={2}>
                      {JSON.parse(sanctionBase.findBy) !== null && Array.isArray(JSON.parse(sanctionBase.findBy)) && JSON.parse(sanctionBase.findBy).map((sanction: object, indexFindBy: number) => (
                        <Grid item key={indexFindBy}>
                          {indexFindBy > 0 && (
                            <Divider/>
                          )}
                          <Grid container sx={{
                            display: "flex",
                            py: 1,
                            flexDirection: "column"
                          }}>
                            {Object.keys(sanction).map((key, sanctionKeyIndex) => {
                              if (key !== "publicationUrl") {
                                return (
                                  <Grid item
                                    key={sanctionKeyIndex}>
                                    <Typography
                                      variant={"body1"}>
                                      {i18n.exists("account.sanctions." + key) ? t("account.sanctions." + key) : key}: {sanction[key as keyof object]}
                                    </Typography>
                                  </Grid>
                                );
                              }
                            })}
                          </Grid>
                          <Grid container sx={{
                            display: "flex",
                            pb: 1,
                            justifyContent: "flex-end"
                          }}>
                            {Object.keys(sanction).map((key, sanctionKeyIndex) => {
                              if (key === "publicationUrl") {
                                return (
                                  <Grid item
                                    key={sanctionKeyIndex}>
                                    <Typography>
                                      {t("account.details.sanction_link")}:&nbsp;
                                      <Button
                                        variant="contained"
                                        size="small"
                                        color="primary"
                                        onClick={() => {
                                          window.open(sanction[key as keyof object], "_blank");
                                        }}
                                      >
                                        {t("common.btn_open")}
                                      </Button>
                                    </Typography>
                                  </Grid>
                                );
                              }
                            })}
                          </Grid>
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                </Grid>
                <Divider/>
              </>
            )}
            {sanctionBase.findBy === null && (
              <Grid container sx={{display: "flex"}}>
                <Grid item xs={6} md={4}>
                  <Typography variant="subtitle1">
                    {t("account.details.check_result")}
                  </Typography>
                </Grid>
                <Grid item xs={6} md={8} sx={{textAlign: "right"}}>
                  <Box
                    sx={{
                      color: theme.palette.success.main,
                      backgroundColor: theme.palette.success.main + "44",
                      padding: "3px 10px",
                      borderRadius: "20px",
                      display: "inline-block",
                    }}
                  >
                    {t("sanction.status.not_found")}
                  </Box>
                </Grid>
              </Grid>
            )}
          </CardContent>
        </Card>
      ))}
    </Box>
  );
};

export default SanctionList;