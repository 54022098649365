export interface IUser {
  id: string,
  email: string,
  permissions: string[],
  role: string
}

export interface IAuthState {
  isAuth: boolean,
  isLoading: boolean,
  user: IUser,
  authErrors: Partial<IAuthValuesSend & IAuthValuesSignIn>
}

export interface IAuthValuesSend {
  email: string,
  password: string,
}

export interface IAuthValuesSignIn {
  userId: string,
  passcode: string,
}

export enum AuthActionTypeList {
  SET_AUTH_LOADING = "SET_AUTH_LOADING",
  SET_AUTH_STATUS = "SET_AUTH_STATUS",
  SET_AUTH_USER = "SET_AUTH_USER",
  SET_AUTH_ERRORS = "SET_AUTH_ERRORS",
}

export interface ISetAuthLoading {
  type: AuthActionTypeList.SET_AUTH_LOADING,
  payload: boolean
}

export interface ISetAuthStatus {
  type: AuthActionTypeList.SET_AUTH_STATUS,
  payload: boolean
}

export interface ISetAuthUser {
  type: AuthActionTypeList.SET_AUTH_USER,
  payload: IUser
}

export interface ISetAuthErrors {
  type: AuthActionTypeList.SET_AUTH_ERRORS,
  payload: Partial<IAuthValuesSend>
}

export type TAuthActions = ISetAuthLoading | ISetAuthStatus | ISetAuthUser | ISetAuthErrors
