import React, { useEffect } from "react";
import {
  Card,
  CardContent,
  CardHeader,
  Container,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { HeadCell } from "../../../types/table";
import { ISanctionCountriesItem } from "../../../types/store/sanction/countries";
import { useTypedSelector } from "../../../hooks/useTypedSelector";
import { useActions } from "../../../hooks/useActions";
import ability from "../../../utils/can";
import Error404 from "../../error-pages/error-404";

const SanctionCountriesTable = () => {
  const { t } = useTranslation();
  const headCells: HeadCell<ISanctionCountriesItem>[] = [
    {
      id: "name",
      label: t("sanction.countries.table.name")
    },
    {
      id: "iso2",
      label: t("sanction.countries.table.iso2")
    },
    {
      id: "iso3",
      label: t("sanction.countries.table.iso3")
    },
  ];

  const { isLoading, sanctions } = useTypedSelector(state => state.sanctionCountries);
  const { fetchSanctionCountries } = useActions();

  useEffect(() => {
    if (ability.can("get", "sanctions_countries_list")) {
      fetchSanctionCountries();
    }
  }, []);

  if (ability.cannot("get", "sanctions_countries_list")) {
    return <Error404 />;
  }

  return (
    <Container maxWidth="xl">
      <Card>
        <CardHeader title={t("sanction.countries.table.title")} />
        <CardContent>
          <Table sx={{ minWidth: 650 }} aria-label="sanction countries table" size={"small"}>
            <TableHead>
              <TableRow sx={{ "& .MuiTableCell-head": { fontWeight: 600 } }}>
                {headCells.map((headCell) => {
                  return (
                    <TableCell
                      key={headCell.id}
                    >
                      {headCell.label}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading && (
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  {headCells.map((headCell, index) => {
                    return (
                      <TableCell key={index}><Skeleton /></TableCell>
                    );
                  })}
                </TableRow>
              )}
              {!isLoading && sanctions.map(sanction => (
                <TableRow
                  key={sanction.countryName}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  hover
                >
                  <TableCell>{sanction.countryName}</TableCell>
                  <TableCell>{sanction.countryIsoCode2}</TableCell>
                  <TableCell>{sanction.countryIsoCode3}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </CardContent>
      </Card>
    </Container>
  );
};

export default SanctionCountriesTable;