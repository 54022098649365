import React  from "react";
import { Container, Grid } from "@mui/material";
import TransactionsListTable from "../../../components/transactions/transactions-list-table";
import ability from "../../../utils/can";
import Error404 from "../../error-pages/error-404";

/**
 * A React component that return table with transaction list.
 * @returns A component that renders a table of transactions.
 */
const TransactionsList = () => {
  if (ability.cannot("get", "transactions_list"))
    return <Error404 />;

  return (
    <Container maxWidth="xl">
      <Grid container columns={16} spacing={3}>
        <Grid item xs={16} md={16}>
          <TransactionsListTable />
        </Grid>
      </Grid>
    </Container>
  );
};

export default TransactionsList;
