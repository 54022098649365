import {delay} from "../../utils/common";
import {
  IAuthLoginHeaders,
  IRequestResponseType,
} from "../../types/service/test/test-response";
import {AuthResponse, AuthResponseHeaders} from "../../types/service/response/auth-response";
import {LocalStorageItems} from "../../types/local-storage";
import Cookies from "js-cookie";

/** It's a mock for the API */
export const AuthTestService = {
  login: async (email: string, password: string): Promise<IRequestResponseType<IAuthLoginHeaders, AuthResponse>> => {
    try {
      await delay(1000);
      //TODO Edit token.
      // const token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwiZW1haWwiOiJpbml0YWRtaW5AYW1sYWRtaW4uY29tIiwicm9sZSI6ImluaXRpYWwtYWRtaW4iLCJwZXJtaXNzaW9ucyI6WyJnZXRfcGVybWlzc2lvbnMiLCJnZXRfcm9sZXMiLCJnZXRfcm9sZSIsImNyZWF0ZV9yb2xlIiwidXBkYXRlX3JvbGUiLCJyZW1vdmVfcm9sZSIsImdldF91c2VycyIsImdldF91c2VyIiwiY3JlYXRlX3VzZXIiLCJ1cGRhdGVfdXNlciIsInJlbW92ZV91c2VyIiwiZ2V0X3ZlcmlmaWNhdGlvbl9saXN0IiwiZ2V0X2FjY291bnRfaW5mbyIsInNldF92ZXJpZmljYXRpb25fcmVzdWx0IiwiZ2V0X3RyYW5zYWN0aW9uc19saXN0IiwiZ2V0X3RyYW5zYWN0aW9uX2luZm8iLCJzZXRfdHJhbnNhY3Rpb25fdmVyaWZpY2F0aW9uX3Jlc3VsdCIsImdldF90cmFuc2FjdGlvbl9hZGRpdGlvbmFsX2luZm8iLCJ1cGRhdGVfc2V0dGluZyIsImdldF9tZXJjaGFudHMiLCJnZXRfbWVyY2hhbnQiLCJjcmVhdGVfbWVyY2hhbnQiLCJ1cGRhdGVfbWVyY2hhbnQiLCJyZW1vdmVfbWVyY2hhbnQiLCJnZXRfd2FsbGV0cyIsImdldF93YWxsZXQiLCJjcmVhdGVfd2FsbGV0IiwidXBkYXRlX3dhbGxldCIsInJlbW92ZV93YWxsZXQiLCJnZXRfY2FyZHNfbGlzdCIsImdldF9jYXJkX2luZm8iXSwiaWF0IjoxNjU0ODQzNjkwLCJleHAiOjE2NTQ4NDQyOTB9.zWiWAsmNvVkl37vw1ZwGlj8tzZ-sYuqoQLIY6E5_FY8";
      // Cookies.set(LocalStorageItems.TOKEN, token, {expires: 1});
            
      return {
        headers: {
          [AuthResponseHeaders.ACCESS_TOKEN]: "",
        },
        data: {
          result: true,
          userId: "42",
        } as AuthResponse
      };
    } catch (e) {
      throw {
        headers: {
          [AuthResponseHeaders.ACCESS_TOKEN]: "",
        },
        data: {
          result: false
        } as AuthResponse,
        status: 401
      };
    }
  },

  checkPasscode: async (userId: string, passcode: string): Promise<IRequestResponseType<IAuthLoginHeaders, AuthResponse>> => {
    try {
      await delay(600);
      if (passcode !== "123456") throw new Error("validations.Passcode not correct");

      //TODO Edit token.
      const token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6NSwiZW1haWwiOiJ2aXRhbHkubHlhbHl1a292QGFyYml0YXMuY29tIiwicm9sZSI6ImRldmVsb3BlciIsInBlcm1pc3Npb25zIjpbImdldF9wZXJtaXNzaW9ucyIsImdldF9yb2xlcyIsImdldF9yb2xlIiwiY3JlYXRlX3JvbGUiLCJ1cGRhdGVfcm9sZSIsInJlbW92ZV9yb2xlIiwiZ2V0X3VzZXJzIiwiZ2V0X3VzZXIiLCJjcmVhdGVfdXNlciIsInVwZGF0ZV91c2VyIiwicmVtb3ZlX3VzZXIiLCJnZXRfdmVyaWZpY2F0aW9uX2xpc3QiLCJnZXRfYWNjb3VudF9pbmZvIiwic2V0X3ZlcmlmaWNhdGlvbl9yZXN1bHQiLCJnZXRfdHJhbnNhY3Rpb25zX2xpc3QiLCJnZXRfdHJhbnNhY3Rpb25faW5mbyIsInNldF90cmFuc2FjdGlvbl92ZXJpZmljYXRpb25fcmVzdWx0IiwiZ2V0X3RyYW5zYWN0aW9uX2FkZGl0aW9uYWxfaW5mbyIsInVwZGF0ZV9zZXR0aW5nIiwiZ2V0X21lcmNoYW50cyIsImdldF9tZXJjaGFudCIsImNyZWF0ZV9tZXJjaGFudCIsInVwZGF0ZV9tZXJjaGFudCIsInJlbW92ZV9tZXJjaGFudCIsImdldF93YWxsZXRzIiwiZ2V0X3dhbGxldCIsImNyZWF0ZV93YWxsZXQiLCJ1cGRhdGVfd2FsbGV0IiwicmVtb3ZlX3dhbGxldCIsImdldF9jYXJkc19saXN0IiwiZ2V0X2NhcmRfaW5mbyIsInVwZGF0ZV9jYXJkX3N0YXR1cyIsImdldF9zYW5jdGlvbnMiLCJnZXRfc2FuY3Rpb25zX2NvdW50cmllc19saXN0Il0sImlhdCI6MTY2MjAyMjMyOSwiZXhwIjoxNjYyMDI5NTI5fQ.8M5uZLywMv0t7jKsp5vqaVTt9UKn2xiqCAwTBWfvjB4";
      Cookies.set(LocalStorageItems.TOKEN, token, {expires: 1});
            
      return {
        headers: {
          [AuthResponseHeaders.ACCESS_TOKEN]: token,
        },
        data: {
          result: true,
        } as AuthResponse
      };
    } catch (e) {
      throw {
        headers: {
          [AuthResponseHeaders.ACCESS_TOKEN]: "",
        },
        data: {
          result: false
        } as AuthResponse,
        status: 401
      };
    }
  },

  logout: async (): Promise<IRequestResponseType<IAuthLoginHeaders, AuthResponse>> => {
    try {
      await delay(600);
      return {
        headers: {} as IAuthLoginHeaders,
        data: {
          result: true
        } as AuthResponse
      };
    } catch (e) {
      throw {
        data: {
          result: false
        } as AuthResponse
      };
    }
  },

  checkToken: async (): Promise<IRequestResponseType<IAuthLoginHeaders, AuthResponse>> => {
    try {
      await delay(600);
      if (!Cookies.get(LocalStorageItems.TOKEN)) throw "error";
      return {
        headers: {
          [AuthResponseHeaders.ACCESS_TOKEN]: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwiZW1haWwiOiJpbml0YWRtaW5AYW1sYWRtaW4uY29tIiwicm9sZSI6ImluaXRpYWwtYWRtaW4iLCJwZXJtaXNzaW9ucyI6WyJnZXRfcGVybWlzc2lvbnMiLCJnZXRfcm9sZXMiLCJnZXRfcm9sZSIsImNyZWF0ZV9yb2xlIiwidXBkYXRlX3JvbGUiLCJyZW1vdmVfcm9sZSIsImdldF91c2VycyIsImdldF91c2VyIiwiY3JlYXRlX3VzZXIiLCJ1cGRhdGVfdXNlciIsInJlbW92ZV91c2VyIiwiZ2V0X3ZlcmlmaWNhdGlvbl9saXN0IiwiZ2V0X2FjY291bnRfaW5mbyIsInNldF92ZXJpZmljYXRpb25fcmVzdWx0IiwiZ2V0X3RyYW5zYWN0aW9uc19saXN0IiwiZ2V0X3RyYW5zYWN0aW9uX2luZm8iLCJzZXRfdHJhbnNhY3Rpb25fdmVyaWZpY2F0aW9uX3Jlc3VsdCIsImdldF90cmFuc2FjdGlvbl9hZGRpdGlvbmFsX2luZm8iLCJ1cGRhdGVfc2V0dGluZyIsImdldF9tZXJjaGFudHMiLCJnZXRfbWVyY2hhbnQiLCJjcmVhdGVfbWVyY2hhbnQiLCJ1cGRhdGVfbWVyY2hhbnQiLCJyZW1vdmVfbWVyY2hhbnQiLCJnZXRfd2FsbGV0cyIsImdldF93YWxsZXQiLCJjcmVhdGVfd2FsbGV0IiwidXBkYXRlX3dhbGxldCIsInJlbW92ZV93YWxsZXQiLCJnZXRfY2FyZHNfbGlzdCIsImdldF9jYXJkX2luZm8iLCJ1cGRhdGVfY2FyZF9zdGF0dXMiLCJnZXRfc2FuY3Rpb25zIl0sImlhdCI6MTY1NzIwNDc4NCwiZXhwIjoxNjU3MjA1Mzg0fQ.e6sBMNFZvT5tOy9KzpvYo36q1ohhez-BrCn25POmJYk",
        },
        data: {
          result: true
        } as AuthResponse
      };
    } catch (e) {
      throw {
        data: {
          result: false
        } as AuthResponse
      };
    }
  }
};
