import React, { Fragment } from "react";
import { Box, Button } from "@mui/material";
import { TApplicationStatus } from "../../../types/store/applications";
import { TApplicationType } from "../types";
import { SxProps } from "@mui/system";

interface IProps {
  status: TApplicationStatus;
  loading: boolean;
  type: TApplicationType;
  onApprove: () => void;
  onReject: () => void;
  onSave?: () => void;
  sx?: SxProps;
  btnSize?: "small" | "medium" | "large";
}

export function ListItemRowActions({
  status,
  loading,
  type,
  onApprove,
  onReject,
  onSave,
  sx,
  btnSize,
}: IProps) {
  return (
    <Box display={"flex"} gap={2} sx={sx}>
      {status === "received" && (
        <Fragment>
          {type === "corporate" && (
            <Button
              disabled={loading}
              size={btnSize || "small"}
              variant={"contained"}
              color={"primary"}
              onClick={onSave}
            >
              SAVE BENEFICIARIES
            </Button>
          )}
          <Button
            disabled={loading}
            size={btnSize || "small"}
            variant={"contained"}
            color={"success"}
            onClick={onApprove}
          >
            APPROVE
          </Button>
          <Button
            disabled={loading}
            size={btnSize || "small"}
            variant={"contained"}
            color={"error"}
            onClick={onReject}
          >
            REJECT
          </Button>
        </Fragment>
      )}
    </Box>
  );
}
