import {
  IClearRoleInfo,
  ISetRoleInfo,
  ISetRoleInfoLoading,
  ISetRoleValidationErrors,
  RoleInfoActions
} from "../../../types/store/role/role-info";
import {IRole} from "../../../types/store/role";
import {Action} from "redux";
import RoleTestService from "../../../sevices/test/role-test-service";
import {AxiosError} from "axios";
import {IGeneralResponse} from "../../../types/service/response/general-response";
import {IPermission} from "../../../types/store/permissions";
import {USE_SERVER} from "../../../constants/constants";
import RoleService from "../../../sevices/role-service";
import {ThunkDispatch} from "redux-thunk";
import {handleGeneralError} from "../error-handler";
import i18n from "i18next";
import {notification} from "../../../utils/notification";

/**
 * It takes an IRole object and returns an ISetRoleInfo object
 * @param {IRole} role - IRole - this is the role object that we're going to pass to the reducer.
 */
const setRoleInfo = (role: IRole): ISetRoleInfo => ({
  type: RoleInfoActions.SET_ROLE_INFO,
  role
});

/**
 * It returns an object with a type property and a loading property
 * @param {boolean} loading - boolean
 */
const setRoleInfoLoading = (loading: boolean): ISetRoleInfoLoading => ({
  type: RoleInfoActions.SET_ROLE_INFO_LOADING,
  loading
});

/**
 * It returns an object with a type property set to the CLEAR_ROLE_INFO constant
 */
export const clearRoleInfo = (): IClearRoleInfo => ({
  type: RoleInfoActions.CLEAR_ROLE_INFO
});

/**
 * It returns an object with a type property and an errors property
 * @param errors - Pick<IRole, "name">
 */
export const setRoleValidationErrors = (errors: Pick<IRole, "name">): ISetRoleValidationErrors => ({
  type: RoleInfoActions.SET_ROLE_VALIDATION_ERRORS,
  errors: errors
});

/**
 * It fetches the role info from the server and dispatches the result to the reducer
 * @param {string | number} id - string | number - The id of the role to fetch
 */
export const fetchRoleInfo = (id: string | number) => async (dispatch: ThunkDispatch<unknown, unknown, Action>) => {
  dispatch(clearRoleInfo());
  try {
    dispatch(setRoleInfoLoading(true));
    const response = USE_SERVER
      ? await RoleService.roleInfo(id)
      : await RoleTestService.roleInfo(id);
    dispatch(setRoleInfo(response.data.result));
  } catch (e: unknown) {
    await dispatch(handleGeneralError(e));
  } finally {
    dispatch(setRoleInfoLoading(false));
  }
};

/**
 * It updates a role
 * @param {number} id - number - the id of the role to update
 * @param {string} name - string - the name of the role
 * @param {IPermission[]} permissions - IPermission[]
 */
export const updateRole = (id: number, name: string, permissions: IPermission[]) => async (dispatch: ThunkDispatch<unknown, unknown, Action>) => {
  try {
    const response = USE_SERVER
      ? await RoleService.updateRole(id, name, permissions)
      : await RoleTestService.updateRole(id, name, permissions);

    dispatch(setRoleInfo(response.data.result));
    notification.showSuccessMessage(i18n.t("role.common.Role updated!"));
  } catch (e: unknown) {
    const {response} = e as AxiosError<IGeneralResponse<boolean>>;
    await dispatch(handleGeneralError(e));

    //TODO add general func
    const responseErrors: string[] | undefined = response?.data?.errors;
    const errorStrings: Pick<IRole, "name"> = {name: ""};
    if (responseErrors && responseErrors?.length > 0){
      responseErrors.map(string => {
        const key = string.split(" ")[0];
        if (key in errorStrings){
          errorStrings[key as keyof Pick<IRole, "name">] = string;
        }
      });
    }
    dispatch(setRoleValidationErrors(errorStrings));
  }
};

/**
 * It creates a role with the given name and permissions
 * @param {string} name - string - the name of the role
 * @param {IPermission[]} permissions - IPermission[] - this is the array of permissions that the role will have.
 */
export const createRole = (name: string, permissions: IPermission[]) => async (dispatch: ThunkDispatch<unknown, unknown, Action>) => {
  try {
    dispatch(clearRoleInfo());
    const response = USE_SERVER
      ? await RoleService.createRole(name, permissions)
      : await RoleTestService.createRole(name, permissions);

    dispatch(setRoleInfo(response.data.result));
    notification.showSuccessMessage(i18n.t("role.common.Role created!"));
  } catch (e: unknown) {
    const {response} = e as AxiosError<IGeneralResponse<boolean>>;
    await dispatch(handleGeneralError(e));

    //TODO add general func
    const responseErrors: string[] | undefined = response?.data?.errors;
    const errorStrings: Pick<IRole, "name"> = {name: ""};
    if (responseErrors && responseErrors?.length > 0){
      responseErrors.map(string => {
        const key = string.split(" ")[0];
        if (key in errorStrings){
          errorStrings[key as keyof Pick<IRole, "name">] = string;
        }
      });
    }
    dispatch(setRoleValidationErrors(errorStrings));
  }
};