import {IRequestResponseType} from "../../types/service/test/test-response";
import {delay, getRandomInt} from "../../utils/common";
import {ISanctionCountriesResponse, ISanctionListByBaseAndNameResponse, ISanctionListByNameResponse} from "../../types/service/response/sanction";
import {ISanctionListItem} from "../../types/store/sanction/list";
import {ISanctionCountriesRequest, ISanctionListByBaseAndNameRequest} from "../../types/service/request/sanction-request";

export default class SanctionTestService {
  static async sanctionListByName(firstName: string, lastName: string): Promise<IRequestResponseType<unknown, ISanctionListByNameResponse>> {
    await delay(1000);
    return {
      headers: undefined,
      data: {
        result: true,
        sanctions: [
          {
            "sanctionList": "EU",
            "findBy": "[{\"fullName\":\"SADDAM HUSSEIN AL-TIKRITI\",\"publicationUrl\":\"http://eur-lex.europa.eu/LexUriServ/LexUriServ.do?uri=OJ:L:2003:169:0006:0023:EN:PDF\"}]"
          },
          {
            "sanctionList": "UN",
            "findBy": null
          },
          {
            "sanctionList": "US",
            "findBy": null
          },
          {
            "sanctionList": "UK",
            "findBy": null
          }
        ]
      }
    };
  }

  static async sanctionListByBaseAndName(
    params: ISanctionListByBaseAndNameRequest
  ): Promise<IRequestResponseType<unknown, ISanctionListByBaseAndNameResponse>> {
    await delay(600);
    const sanctions: ISanctionListItem[] = [];

    if (params.sanctionBase === "UK" || params.sanctionBase === "UN") {
      [...Array(params.itemsPerPage)].map(item => {
        sanctions.push(
                    {
                      id: getRandomInt(0, 10000),
                      fullName: params.firstName + " " + params.lastName,
                      publicationUrl: "https://google.com",
                    } as ISanctionListItem
        );
      });
    } else {
      [...Array(params.itemsPerPage)].map(item => {
        sanctions.push(
                    {
                      id: getRandomInt(0, 10000),
                      firstName: params.firstName,
                      lastName: params.lastName,
                      publicationUrl: "https://google.com",
                    } as ISanctionListItem
        );
      });
    }

    return {
      headers: undefined,
      data: {
        result: true,
        sanctions: sanctions,
        total: 1000
      }
    };
  }

  static async sanctionCountriesAll(): Promise<IRequestResponseType<unknown, ISanctionCountriesResponse>> {
    await delay(1000);
    return {
      headers: undefined,
      data: {
        result: [
          {
            // id: 0,
            countryName: "Afghanistan",
            countryIsoCode2: "AF",
            countryIsoCode3: "AFG"
          },
          {
            // id: 1,
            countryName: "Albania",
            countryIsoCode2: "AL",
            countryIsoCode3: "ALB"
          },
          {
            // id: 2,
            countryName: "Algeria",
            countryIsoCode2: "DZ",
            countryIsoCode3: "DZA"
          },
          {
            // id: 3,
            countryName: "American Samoa",
            countryIsoCode2: "AS",
            countryIsoCode3: "ASM"
          }
        ],
      },
    };
  }
}