import {
  WalletTransactionListActions,
  IClearWalletTransactionList,
  ISetWalletTransactionList,
  ISetWalletTransactionListLoading,
  ISetWalletTransactionListPage,
  ISetWalletTransactionListPerPage,
  ISetWalletTransactionListTotalPages,
  IWalletTransaction, ISetWalletTransactionListId, IClearWalletTransactionListId,
} from "../../../types/store/wallet/wallet-transaction-list";
import {store} from "../../index";
import {IWalletTransactionListReqParams} from "../../../types/service/request/wallet";
import {ThunkDispatch} from "redux-thunk";
import {Action} from "redux";
import {handleGeneralError} from "../error-handler";
import {notification} from "../../../utils/notification";
import {USE_SERVER} from "../../../constants/constants";
import WalletService from "../../../sevices/wallet-service";
import WalletTestService from "../../../sevices/test/wallet-test-service";

const setWalletTransactionList = (
  transactionList: IWalletTransaction[]
): ISetWalletTransactionList => ({
  type: WalletTransactionListActions.SET_TRANSACTION_LIST,
  transactionList,
});

export const setWalletTransactionListId = (
  walletId: string
): ISetWalletTransactionListId => ({
  type: WalletTransactionListActions.SET_WALLET_ID,
  walletId,
});

const clearWalletTransactionList = (): IClearWalletTransactionList => ({
  type: WalletTransactionListActions.CLEAR_TRANSACTION_LIST,
});

const clearWalletTransactionListId = (): IClearWalletTransactionListId => ({
  type: WalletTransactionListActions.CLEAR_WALLET_ID,
});

const setWalletTransactionListLoading = (
  loading: boolean
): ISetWalletTransactionListLoading => ({
  type: WalletTransactionListActions.SET_LOADING,
  loading,
});

export const setWalletTransactionListPage = (
  page: number
): ISetWalletTransactionListPage => ({
  type: WalletTransactionListActions.SET_PAGE,
  page,
});

export const setWalletTransactionListPerPage = (
  perPage: number
): ISetWalletTransactionListPerPage => ({
  type: WalletTransactionListActions.SET_PER_PAGE,
  perPage,
});

const setWalletTransactionListTotalPages = (totalPages: number): ISetWalletTransactionListTotalPages => ({
  type: WalletTransactionListActions.SET_TOTAL_PAGES,
  totalPages
});

const getFetchWalletTransactionsListParams = (): IWalletTransactionListReqParams => {
  const {page, perPage, walletId} = store.getState().walletTransactionList;
  return {
    per_page: perPage,
    page: page,
    accountNumber: walletId || "",
  };
};

export const fetchWalletTransactionList = (params = getFetchWalletTransactionsListParams()) => async (dispatch: ThunkDispatch<unknown, unknown, Action>) => {
  if (!params.accountNumber) return notification.showErrorMessage("Wallet(accountID) not found!");
  try{
    dispatch(setWalletTransactionListLoading(true));
    dispatch(clearWalletTransactionList());
    const response = USE_SERVER
      ? await WalletService.getWalletTransactionList(params)
      : await WalletTestService.getWalletTransactionList(params);

    const {
      list: transactionList,
      total_page: totalPages,
    } = response.data.items;

    dispatch(setWalletTransactionList(transactionList));
    dispatch(setWalletTransactionListTotalPages(totalPages));
  } catch (e: unknown) {
    await dispatch(handleGeneralError(e));
  } finally {
    dispatch(setWalletTransactionListLoading(false));
  }
};
