import i18n from "i18next";

export function serverNotResponse() {
  return {
    errorCode: 502,
    errorMessage: i18n.t("http.errors.server not response")
  };
}

export function checkErrorResponseCodeMessage(code: undefined | number, message: undefined | string) {
  const errorCode = code ?? 500;
  const errorMessage = message ? (i18n.exists("http.errors." + message) ? i18n.t("http.errors." + message) : message) : i18n.t("http.errors.server not response");
  return {
    errorCode,
    errorMessage
  };
}