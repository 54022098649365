
export interface IAccountStatusesState {
  statuses: Record<string, string>,
  expired: number,
}

export enum AccountStatusesActions {
  SET_ACCOUNT_STATUSES_LOADING = "SET_ACCOUNT_STATUSES_LOADING",
  SET_ACCOUNT_STATUSES_SUCCESS = "SET_ACCOUNT_STATUSES_SUCCESS",
  SET_ACCOUNT_STATUSES_FAILURE = "SET_ACCOUNT_STATUSES_FAILURE",
}

export interface ISetAccountStatusesSuccess { 
  type: AccountStatusesActions.SET_ACCOUNT_STATUSES_SUCCESS,
  payload: IAccountStatusesState,
}

export type TAccountStatusesAction = ISetAccountStatusesSuccess;
