import {IProfileState, ProfileActions, TProfileAction} from "../../types/store/profile";

const initialState: IProfileState = {
  isLoading: false,
  isSuccessSaved: false,
  errors: {}
};

export const profileReducer = (state = initialState, action: TProfileAction): IProfileState => {
  switch (action.type) {
  case ProfileActions.SET_PROFILE_ERRORS:
    return {...state, errors: action.errors};
  case ProfileActions.SET_PROFILE_LOADING:
    return {...state, isLoading: action.loading};
  case ProfileActions.SET_PROFILE_SAVED_STATUS:
    return {...state, isSuccessSaved: action.status};
  default:
    return state;
  }
};