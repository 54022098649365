import * as yup from "yup";
import { IBeneficiariesForm, IBeneficiary } from "./types";
import { countriesAbbreviation } from "../../../../utils/countries";
import moment from "moment";

export function useBeneficiaryValidationScheme() {
  const beneficiaryScheme: yup.ObjectSchema<IBeneficiary> = yup.object().shape({
    firstName: yup
      .string()
      .min(2, "Invalid value")
      .max(255, "Invalid value")
      .required("Required"),
    lastName: yup
      .string()
      .min(2, "Invalid value")
      .max(255, "Invalid value")
      .required("Required"),
    dateOfBirthday: yup
      .date()
      .test("dateOfBirthday", "Should be greater than 18", (value, context) => {
        const currentDate = moment(new Date());
        const dateOfBirthday = moment(value);
        const years = currentDate.diff(dateOfBirthday, "years");
        return years >= 18;
      })
      .required("Required"),
    nationality: yup
      .string()
      .oneOf(countriesAbbreviation, "Select country from list")
      .required("Required"),
    documents: yup
      .array(
        yup.object({
          type: yup
            .mixed<"PASSPORT" | "ID">()
            .oneOf(["PASSPORT", "ID"])
            .required("Required"),
          number: yup.string().required("Required"),
          issueCountry: yup
            .string()
            .oneOf(countriesAbbreviation, "Select country from list")
            .required("Required"),
        })
      )
      .required("Required"),
    address: yup
      .object({
        countryCode: yup
          .string()
          .oneOf(countriesAbbreviation, "Select country from list")
          .required("Required"),
        city: yup.string().required("Required"),
        zipCode: yup.string().required("Required"),
        streetAddress: yup.string().required("Required"),
      })
      .required("Required"),
    emailAddress: yup.string().email("Invalid email").required("Required"),
    phoneNumber: yup.string().required("Required"),
    personalNumber: yup.string().required("Required"),
  });

  const validationScheme: yup.ObjectSchema<IBeneficiariesForm> = yup
    .object()
    .shape({
      beneficiaries: yup.array(beneficiaryScheme).required("Required"),
    });

  return validationScheme;
}
