import React, {useEffect, useState} from "react";
import {
  Card,
  CardContent,
  Container,
  Grid,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography
} from "@mui/material";
import {useTranslation} from "react-i18next";
import {useFormik} from "formik";
import {LoadingButton} from "@mui/lab";
import {useTypedSelector} from "../../../hooks/useTypedSelector";
import {useActions} from "../../../hooks/useActions";
import {IProfileFormFields} from "../../../types/store/profile";

/** A React component that show profile information and allow to edit it. */
const Profile = () => {
  const {t} = useTranslation();
  const profileCategoriesList = [
    t("profile.general_info"),
    t("profile.change_password"),
  ];

  const {user} = useTypedSelector(state => state.auth);
  const {errors: profileErrors, isSuccessSaved} = useTypedSelector(state => state.profile);
  const {changePassword, setProfileErrors} = useActions();
  const [category, setCategory] = useState(profileCategoriesList[0]);

  const formik = useFormik({
    initialValues: {
      password: "",
      passwordNew: "",
      passwordDup: "",
    } as IProfileFormFields,

    validate: (values) => {
      const errors: Partial<IProfileFormFields> = {};
      const password = validatePassword(values.password);
      const passwordNew = validatePasswordNew();
      const passwordDup = validatePasswordDup(values.passwordNew, values.passwordDup);

      if (password) {
        errors.password = password;
      }

      if (passwordNew) {
        errors.passwordNew = passwordNew;
      }

      if (passwordDup) {
        errors.passwordDup = passwordDup;
      }

      return errors;
    },

    onSubmit: async (values) => {
      changePassword(values.password, values.passwordNew, values.passwordDup);
    }
  });

  const validatePasswordNew = (): string | undefined => {
    if (profileErrors?.passwordNew && profileErrors.passwordNew !== "password incorrect") {
      return t(`validations.${profileErrors.passwordNew}`);
    }
  };
  const validatePasswordDup = (password: string, passwordDup: string): string | undefined => {
    if (password !== passwordDup) {
      return t("validations.Passwords do not match");
    } else if (profileErrors?.passwordDup) {
      return t(`validations.${profileErrors.passwordDup}`);
    }
  };
  const validatePassword = (password: string): string | undefined => {
    if (!password) {
      return t("validations.Password is required");
    } else if (profileErrors?.password && profileErrors.password === "password incorrect") {
      return t(`validations.${profileErrors.password}`);
    }
  };

  const {
    values,
    errors,
    handleChange,
    touched,
    handleSubmit,
    isSubmitting,
    handleBlur,
    validateForm,
    resetForm
  } = formik;

  const handleChangeCategory = (event: React.MouseEvent<HTMLElement>, nextView: string | null) => {
    if (nextView !== null) {
      setCategory(nextView);
    }
  };

  const handleChangeValue = () => {
    setProfileErrors({password: "", passwordNew: "", passwordDup: ""});
  };

  useEffect((): () => void => {
    //Run validating one more time, because state updating is async
    let mounted = true;
    if (mounted) {
      validateForm();
    }
    return () => mounted = false;
  }, [profileErrors]);

  useEffect(() => {
    if (isSuccessSaved){
      resetForm({
        values: {
          password: "",
          passwordNew: "",
          passwordDup: "",
        }
      });
    }
  }, [isSuccessSaved]);

  return (
    <Container>
      <Typography
        variant="h5"
        sx={{display: "flex", justifyContent: "center", mb: 2}}
      >
        {t("profile.header")}
      </Typography>
      <Card>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={3}
              sx={{md: {minHeight: "300px"}, width: "100%", my: 1}}>
              <ToggleButtonGroup
                orientation="vertical"
                value={category}
                exclusive
                onChange={handleChangeCategory}
                color="primary"
              >
                {profileCategoriesList && profileCategoriesList.map((cat, index) => (
                  <ToggleButton key={index} value={cat} aria-label={cat}>
                    {cat}
                  </ToggleButton>
                ))}
              </ToggleButtonGroup>
            </Grid>
            <Grid item xs={12} md={9}>
              {category === t("profile.general_info") &&
                                <Typography variant="subtitle1" sx={{my: 2}}>
                                  {t("profile.form.email")} : {user.email}
                                </Typography>
              }
              {category === t("profile.change_password") &&
                                <form onSubmit={handleSubmit}>
                                  <Grid container>
                                    <Grid item xs={12}>
                                      <TextField
                                        id="password"
                                        name="password"
                                        type="password"
                                        label={t("profile.form.password")}
                                        error={touched.password && !!errors.password}
                                        helperText={touched.password && errors.password}
                                        sx={{my: 1, minHeight: 80}}
                                        fullWidth
                                        value={values.password}
                                        onChange={(e) => {
                                          handleChangeValue();
                                          handleChange(e);
                                        }}
                                        onBlur={handleBlur}
                                      />
                                    </Grid>
                                    <Grid item xs={12}>
                                      <TextField
                                        id="passwordNew"
                                        name="passwordNew"
                                        type="password"
                                        label={t("profile.form.passwordNew")}
                                        error={touched.passwordNew && !!errors.passwordNew}
                                        helperText={touched.passwordNew && errors.passwordNew}
                                        sx={{my: 1, minHeight: 80}}
                                        fullWidth
                                        value={values.passwordNew}
                                        onChange={(e) => {
                                          handleChangeValue();
                                          handleChange(e);
                                        }}
                                        onBlur={handleBlur}
                                      />
                                    </Grid>
                                    <Grid item xs={12}>
                                      <TextField
                                        id="passwordDup"
                                        name="passwordDup"
                                        type="password"
                                        label={t("profile.form.passwordDup")}
                                        error={touched.passwordDup && !!errors.passwordDup}
                                        helperText={touched.passwordDup && errors.passwordDup}
                                        sx={{my: 1, minHeight: 80}}
                                        fullWidth
                                        value={values.passwordDup}
                                        onChange={(e) => {
                                          handleChangeValue();
                                          handleChange(e);
                                        }}
                                        onBlur={handleBlur}
                                      />
                                    </Grid>
                                  </Grid>
                                  <LoadingButton
                                    type="submit"
                                    variant="contained"
                                    color="success"
                                    loading={isSubmitting}
                                  >
                                    {t("profile.form.save")}
                                  </LoadingButton>
                                </form>
              }
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Container>
  );
};

export default Profile;