import $api, {$apiWithoutToken} from "../http";
import {AxiosResponse} from "axios";
import {AuthResponse, IEditProfileResponse} from "../types/service/response/auth-response";
import {IEditProfileRequest} from "../types/service/request/auth-request";

/** It's a class that contains static methods that make requests to the backend */
export default class AuthService {
  static async login(email: string, password: string) : Promise<AxiosResponse<AuthResponse>>{
    return $apiWithoutToken.post<AuthResponse>("/auth/login", {email, password});
  }

  static async checkPasscode(userId: string, code: string) : Promise<AxiosResponse<AuthResponse>>{
    return $apiWithoutToken.post<AuthResponse>("/auth/check-code", {userId, code});
  }

  static async registration(email: string, password: string) : Promise<AxiosResponse<AuthResponse>>{
    return $api.post<AuthResponse>("/api/registration", {email, password});
  }

  static async logout() : Promise<void>{
    return $api.post("/api/logout");
  }

  static async checkToken(): Promise<AxiosResponse<AuthResponse>>{
    return $api.get("/auth/me");
  }

  static async editProfile(params: IEditProfileRequest): Promise<AxiosResponse<IEditProfileResponse>>{
    return $api.patch("/auth/profile", params);
  }
}
