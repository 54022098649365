import {getDateTime} from "./time";

export const toClearDateFormat = (value: string, key?: string) => {
  switch (key){
  case "updateAt":
  case "createdAt":
  case "updated_at":
  case "created_at":
    return getDateTime(value);
  default:
    return value;
  }
};