import {Action} from "redux";
import AccountService from "../../../sevices/account-service";
import {
  AccountsListActions,
  IClearAccountsList,
  ISetAccountsListSuccess,
  ISetAccountsListItemsPerPage,
  ISetAccountsListLoading,
  ISetAccountsListPage,
  ISetAccountsListSort,
  ISetAccountsListStatus,
  ISetAccountsListFailure
} from "../../../types/store/account/accounts-list";
import {USE_SERVER} from "../../../constants/constants";
import AccountTestService from "../../../sevices/test/account-test-service";
import {AccountListRequestParams} from "../../../types/service/request/accounts-request";
import {TOrder} from "../../../types/table";
import {store} from "../../index";
import {handleGeneralError} from "../error-handler";
import {ThunkDispatch} from "redux-thunk";
import {IAccountDetail} from "../../../types/store/account/account-details";

/**
 * It returns an object with a type property and a payload property. The type property is a string that is the same as the
 * string in the SET_ACCOUNTS_LIST_FAILURE constant. The payload property is an object with two properties: errorCode and
 * errorMessage
 * @param {number} errorCode - number - The error code that was returned from the API.
 * @param {string} errorMessage - string - The error message to display to the user.
 */
export const setAccountsListFailure = (errorCode: number, errorMessage: string): ISetAccountsListFailure => ({
  type: AccountsListActions.SET_ACCOUNTS_LIST_FAILURE,
  payload: {
    errorCode,
    errorMessage
  },
});

/**
 * It returns an object with a type property and a payload property
 * @param {IAccountDetail[]} accounts - IAccountDetail[] - the accounts list
 * @param [totalPages=0] - The total number of items in the list.
 */
export const setAccountsListSuccess = (
  accounts: IAccountDetail[],
  totalPages = 0,
): ISetAccountsListSuccess => ({
  type: AccountsListActions.SET_ACCOUNTS_LIST_SUCCESS,
  payload: {
    accounts,
    totalPages,
  }
});

/**
 * It returns an object with a type property set to CLEAR_ACCOUNTS_LIST
 */
export const clearAccountsList = (): IClearAccountsList => ({
  type: AccountsListActions.CLEAR_ACCOUNTS_LIST
});

/**
 * It returns an object with a type property and a loading property
 * @param {boolean} loading - boolean
 */
export const setAccountsListLoading = (loading: boolean): ISetAccountsListLoading => ({
  type: AccountsListActions.SET_ACCOUNTS_LIST_LOADING,
  loading
});

/**
 * It returns an object with a type property and a page property
 * @param {number} page - number
 */
export const setAccountsListPage = (page: number): ISetAccountsListPage => ({
  type: AccountsListActions.SET_ACCOUNTS_LIST_PAGE,
  page,
});

/**
 * It returns an object with a type property and a status property
 * @param {string} [status] - TAccountStatus
 */
export const setAccountsListStatus = (status?: string): ISetAccountsListStatus => ({
  type: AccountsListActions.SET_ACCOUNTS_LIST_STATUS,
  status
});

/**
 * It returns an object with a type property and an itemsPerPage property
 * @param {number} items - number - the number of items per page
 */
export const setAccountsListItemsPerPage = (items: number): ISetAccountsListItemsPerPage => ({
  type: AccountsListActions.SET_ACCOUNTS_LIST_ITEMS_PER_PAGE,
  perPage: items
});

/**
 * It returns an object with a type property and a sort property
 * @param {TOrder} sort - TOrder
 */
export const setAccountsListSort = (sort: TOrder): ISetAccountsListSort => ({
  type: AccountsListActions.SET_ACCOUNTS_LIST_SORT,
  sort
});

/**
 * It returns an object with the current page, items per page, filter status, and sort from the Redux store
 * @returns An object with the following properties:
 *     items_per_page: number
 *     page: number
 *     status: string
 *     sort: string
 */
const getFetchAccountsListParams = (): AccountListRequestParams => {
  const {page, perPage} = store.getState().accountsList;
  return {
    per_page: perPage,
    page: page,
  } as AccountListRequestParams;
};

/**
 * It fetches the list of accounts from the server and dispatches the appropriate actions
 * @param {AccountListRequestParams} params - AccountListRequestParams = getFetchAccountsListParams()
 * @returns a function that is being called by the thunk middleware.
 */
export const fetchAccountsList = (params: AccountListRequestParams = getFetchAccountsListParams()) => async (dispatch: ThunkDispatch<unknown, unknown, Action>) => {
  try {
    dispatch(setAccountsListLoading(true));

    const response = USE_SERVER
      ? await AccountService.accountsList(params)
      : await AccountTestService.accountList(params);

    const {
      list: accounts,
      total_pages: totalPages,
    } = response.data.result;

    dispatch(setAccountsListSuccess(accounts, totalPages));
  } catch (e: unknown) {
    await dispatch(handleGeneralError(e));
  } finally {
    dispatch(setAccountsListLoading(false));
  }
};
