import { IApplication } from "../../../types/store/applications";
import {
  ApplicationsListActions,
  IApplicationsListState,
  TApplicationListAction
} from "../../../types/store/applications/applications-list";

const initialState: IApplicationsListState = {
  applications: [] as IApplication[],
  isLoading: false,
  totalPages: 0,
  perPage: 10,
  page: 1,
  status: undefined,
  errorCode: undefined,
  errorMessage: "",
};

export const applicationsListReducer = (state: IApplicationsListState = initialState, action: TApplicationListAction): IApplicationsListState => {
  switch (action.type) {
  case ApplicationsListActions.SET_APPLICATIONS_LIST:
    return {
      ...state,
      applications: action.payload.applications,
      totalPages: action.payload.totalPages
    };
  case ApplicationsListActions.CLEAR_APPLICATIONS_LIST:
    return initialState;
  case ApplicationsListActions.SET_APPLICATIONS_LIST_PAGE:
    return {
      ...state,
      page: action.page
    };
  case ApplicationsListActions.SET_APPLICATIONS_LIST_LOADING:
    return {
      ...state,
      isLoading: action.loading
    };
  case ApplicationsListActions.SET_APPLICATIONS_LIST_ITEMS_PER_PAGE:
    return {
      ...state,
      perPage: action.perPage
    };
  case ApplicationsListActions.SET_APPLICATIONS_LIST_STATUS:
    return {
      ...state,
      status: action.status
    };
  case ApplicationsListActions.SET_APPLICATIONS_LIST_ERROR:
    return {
      ...state,
      errorMessage: action.errorMessage,
      errorCode: action.errorCode
    };
  case ApplicationsListActions.SET_APPLICATION_DETAIL_INFO:
    return {
      ...state,
      applications: [...state.applications.map(application => {
        if (String(application.id) === String(action.detailInfo.requestId)) {
          application.detailInfo = action.detailInfo;
        }
        return application;
      })]
    };
  default:
    return state;
  }
};