import {IMerchant, IMerchantState, MerchantActions, TMerchantAction} from "../../../types/store/merchant";

const initialState: IMerchantState = {
  isLoading: false,
  merchant: {} as IMerchant,
  merchantTypes: []
};

export const merchantReducer = (state = initialState, action: TMerchantAction): IMerchantState => {
  switch (action.type) {
  case MerchantActions.SET_MERCHANT:
    return {...state, merchant: action.merchant};
  case MerchantActions.SET_MERCHANT_LOADING:
    return {...state, isLoading: action.loading};
  case MerchantActions.SET_MERCHANT_ERROR:
    return {...state, errorCode: action.errorCode, errorMessage: action.errorMessage};
  case MerchantActions.SET_MERCHANT_VALIDATION_ERRORS:
    return {...state, validationErrors: action.validationErrors};
  case MerchantActions.SET_MERCHANT_TYPES:
    return {...state, merchantTypes: action.list};
  case MerchantActions.CLEAR_MERCHANT:
    return {...initialState, merchantTypes: state.merchantTypes};
  default:
    return state;
  }
};