import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {useTypedSelector} from "../../../hooks/useTypedSelector";
import {Container, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import MerchantForm from "../../../components/merchant/merchant-form";
import {useActions} from "../../../hooks/useActions";

/** A React component that is used to add merchant. */
const MerchantAdd = () => {
  const navigate = useNavigate();
  const {t} = useTranslation();
  const {merchant} = useTypedSelector(state => state.merchant);
  const {clearMerchant} = useActions();
  const [firstRender, setFirstRender] = useState(false);

  useEffect(() => {
    return () => {
      clearMerchant();
    };
  }, []);

  useEffect(() => {
    if (merchant?.id === undefined){
      setFirstRender(true);
    }
    if (merchant?.id > 0 && firstRender) {
      navigate("/merchants/" + merchant.id);
    }
  }, [merchant.id]);

  return (
    <Container>
      <Typography
        variant="h5"
        sx={{display: "flex", justifyContent: "center", mb: 4}}
      >
        {t("merchant.add.header")}
      </Typography>
      <MerchantForm type={"add"}/>
    </Container>
  );
};

export default MerchantAdd;