import {
  HeaderMenuActionList,
  ISetHeaderMenuSwitchDisabling,
  ISetHeaderMenuSwitchStatus
} from "../../types/store/header-menu";
import {Action} from "redux";
import {SettingsService} from "../../sevices/settings-service";
import {handleGeneralError} from "./error-handler";
import {ThunkDispatch} from "redux-thunk";
import {USE_SERVER} from "../../constants/constants";
import {SettingsTestService} from "../../sevices/test/settings-test-service";

const setStatus = (switchStatus: boolean): ISetHeaderMenuSwitchStatus => ({
  type: HeaderMenuActionList.SET_HEADER_MENU_SWITCH_STATUS,
  switchStatus
});

const setDisableStatus = (disable: boolean): ISetHeaderMenuSwitchDisabling => ({
  type: HeaderMenuActionList.SET_HEADER_MENU_SWITCH_DISABLING,
  isSwitchDisabled: disable
});

/** Setting the status of the switch in the header menu. */
export const setHeaderMenuSwitchStatus = (status: boolean) => async (dispatch: ThunkDispatch<unknown, unknown, Action>) => {
  try {
    dispatch(setDisableStatus(true));
    const response =
            USE_SERVER
              ?
              await SettingsService.setParserSwitchStatus(status)
              :
              await SettingsTestService.setParserSwitchStatus(status);
    const newStatus = response.data.result.settingValue === "on";
    dispatch(setStatus(newStatus));
  } catch (e: unknown) {
    await dispatch(handleGeneralError(e));
  } finally {
    dispatch(setDisableStatus(false));
  }
};

/** Getting the status of the switch in the header menu. */
export const getHeaderMenuSwitchStatus = () => async (dispatch: ThunkDispatch<unknown, unknown, Action>) => {
  try {
    dispatch(setDisableStatus(true));
    const response =
            USE_SERVER
              ?
              await SettingsService.getParserSwitchStatus()
              :
              await SettingsTestService.getParserSwitchStatus();
    const newStatus = response.data.result.settingValue === "on";
    dispatch(setStatus(newStatus));
  } catch (e: unknown) {
    await dispatch(handleGeneralError(e));
  } finally {
    dispatch(setDisableStatus(false));
  }
};