import {IApplication, IApplicationInfo, TApplicationStatus} from "./index";
import {TOrder} from "../../table";

export interface IApplicationsListState {
    applications: IApplication[],
    isLoading: boolean,
    page: number,
    totalPages: number,
    perPage: number,
    status?: TApplicationStatus,
    clientId?: string,
    errorCode?: number,
    errorMessage?: string,
}

export enum ApplicationsListActions {
    SET_APPLICATIONS_LIST = "SET_APPLICATIONS_LIST",
    SET_APPLICATIONS_LIST_ERROR = "SET_APPLICATIONS_LIST_ERROR",
    CLEAR_APPLICATIONS_LIST = "CLEAR_APPLICATIONS_LIST",
    SET_APPLICATIONS_LIST_LOADING = "SET_APPLICATIONS_LIST_LOADING",
    SET_APPLICATIONS_LIST_PAGE = "SET_APPLICATIONS_LIST_PAGE",
    SET_APPLICATIONS_LIST_ITEMS_PER_PAGE = "SET_APPLICATIONS_LIST_ITEMS_PER_PAGE",
    SET_APPLICATIONS_LIST_STATUS = "SET_APPLICATIONS_LIST_STATUS",
    SET_APPLICATIONS_LIST_SORT = "SET_APPLICATIONS_LIST_SORT",
    SET_APPLICATIONS_LIST_TRANSFER_TYPE = "SET_APPLICATIONS_LIST_TRANSFER_TYPE",
    SET_APPLICATION_DETAIL_INFO = "SET_APPLICATION_DETAIL_INFO"
}

export interface ISetApplicationsList {
    type: ApplicationsListActions.SET_APPLICATIONS_LIST,
    payload: {
        applications: IApplication[],
        totalPages: number
    }
}

export interface IClearApplicationsList {
    type: ApplicationsListActions.CLEAR_APPLICATIONS_LIST;
}

export interface ISetApplicationsListLoading {
    type: ApplicationsListActions.SET_APPLICATIONS_LIST_LOADING,
    loading: boolean
}

export interface ISetApplicationsListPage {
    type: ApplicationsListActions.SET_APPLICATIONS_LIST_PAGE,
    page: number
}

export interface ISetApplicationsListItemsPerPage {
    type: ApplicationsListActions.SET_APPLICATIONS_LIST_ITEMS_PER_PAGE,
    perPage: number
}

export interface ISetApplicationsListError {
    type: ApplicationsListActions.SET_APPLICATIONS_LIST_ERROR,
    errorCode: number,
    errorMessage: string
}

export interface ISetApplicationsListSort {
    type: ApplicationsListActions.SET_APPLICATIONS_LIST_SORT,
    sort: TOrder
}

export interface ISetApplicationsListStatus {
    type: ApplicationsListActions.SET_APPLICATIONS_LIST_STATUS,
    status: TApplicationStatus
}

export interface ISetApplicationDetailInfo {
    type: ApplicationsListActions.SET_APPLICATION_DETAIL_INFO,
    detailInfo: IApplicationInfo
}

export type TApplicationListAction =
    ISetApplicationsList
    | IClearApplicationsList
    | ISetApplicationsListLoading
    | ISetApplicationsListPage
    | ISetApplicationsListItemsPerPage
    | ISetApplicationsListError
    | ISetApplicationsListSort
    | ISetApplicationsListStatus
    |ISetApplicationDetailInfo
    ;
