import React, {useEffect, useState} from "react";
import {Alert, AlertTitle, Box, Card, CardContent, Container, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import ability from "../../../utils/can";
import Error404 from "../../error-pages/error-404";
import {useTypedSelector} from "../../../hooks/useTypedSelector";
import {useActions} from "../../../hooks/useActions";
import {useParams} from "react-router-dom";
import Loading from "../../../components/loading";
import MerchantCard from "../../../components/merchant/merchant-card";

/** A React component that is used to display merchant information. */
const MerchantInfo = () => {
  const {t} = useTranslation();
  const {merchant, isLoading, errorCode,} = useTypedSelector(state => state.merchant);
  const {fetchMerchant} = useActions();
  const URLParams = useParams();
  const merchantId = URLParams?.id || "";
  const [accountId, setAccountId] = useState("");

  useEffect(() => {
    if (ability.can("get", "merchant") && +merchantId > 0){
      fetchMerchant(+merchantId);
    }
  }, []);

  useEffect(() => {
    setAccountId(merchant?.ewallet ?? "");
  }, [merchant]);

  if (ability.cannot("get", "merchant"))
    return <Error404 />;

  //Return information by presence of errors or loading status
  if (isLoading) {
    return <Loading/>;
  } else if (errorCode === 424) {
    return (
      <Container sx={{mt: 2}}>
        <Alert severity="error">
          <AlertTitle>{t("merchant.alert.error")}</AlertTitle>
          {t("merchant.alert.Merchant ID not found in partner API")}
        </Alert>
      </Container>
    );
  } else if (errorCode === 404 || Number.isNaN(merchantId)) {
    return (<Error404/>);
  }

  return (
    <Container>
      <Typography
        variant="h5"
        sx={{display: "flex", justifyContent: "center", mb: 2}}
      >
        {t("merchant.details.header")}
      </Typography>
      <Box>
        <Card>
          <CardContent>
            <MerchantCard merchant={merchant} showBalance={true}/>
            {/*<WalletTransactionList accountId={accountId}/>*/}
          </CardContent>
        </Card>
      </Box>

    </Container>
  );
};

export default MerchantInfo;