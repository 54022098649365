import React, {useEffect, useState} from "react";
import {Container} from "@mui/material";
import ability from "../../../utils/can";
import { Can } from "@casl/react";
import TableWithList, {THeadCells, TTableItem} from "../../../components/table-list";
import {HeadCell} from "../../../types/table";
import {IPermission} from "../../../types/store/permissions";
import {useTranslation} from "react-i18next";
import {useActions} from "../../../hooks/useActions";
import {useTypedSelector} from "../../../hooks/useTypedSelector";
import Error404 from "../../error-pages/error-404";

/**
 * A React component that displays permissions list in a table
 * @returns A React component
 */
const Permissions = () => {
  const {fetchPermissionsList} = useActions();
  const {permissions, isLoading} = useTypedSelector(state => state.permissionsList);
  const {t, i18n} = useTranslation();
  const [processedPermissions, setProcessedPermissions] = useState<IPermission[]>([]);

  const headCells: HeadCell<IPermission>[] = [
    {
      id: "id",
      numeric: true,
      label: t("permissions.table.id"),
      disablePadding: false,
    },
    {
      id: "name",
      numeric: false,
      label: t("permissions.table.name"),
      disablePadding: false,
    },
    {
      id: "description",
      numeric: false,
      label: t("permissions.table.description"),
      disablePadding: false,
    }
  ];

  useEffect(() => {
    if (ability.can("get", "permissions")) {
      fetchPermissionsList();
    }
  }, []);

  useEffect(() => {
    setProcessedPermissions(
      permissions.map(permission => {
        const name = i18n.exists("permissions.names." + permission.name) ? t("permissions.names." + permission.name) : permission.name;
        const description = i18n.exists("permissions.descriptions." + permission.name) ? t("permissions.descriptions." + permission.name) : permission.name + "_desc";
        return {
          ...permission,
          name,
          description
        };
      })
    );
  }, [permissions, i18n.language]);

  if (ability.cannot("get", "permissions")) {
    return <Error404/>;
  }

  return (
    <Container maxWidth="xl">
      <Can I={"get"} a={"permissions"} ability={ability}>
        <TableWithList
          title={t("permissions.table.title")}
          headCells={headCells as THeadCells}
          list={processedPermissions as TTableItem[]}
          isLoading={isLoading}
        />
      </Can>
    </Container>
  );
};

export default Permissions;