import {IRoleInfoState, RoleInfoActions, TRoleInfoAction} from "../../../types/store/role/role-info";
import {IPermission} from "../../../types/store/permissions";

const initialState: IRoleInfoState = {
  role: {
    id: 0,
    name: "",
    permissions: [] as IPermission[]
  },
  isLoading: false
};

export const roleInfoReducer = (state = initialState, action: TRoleInfoAction): IRoleInfoState => {
  switch (action.type) {
  case RoleInfoActions.SET_ROLE_INFO:
    return {...state, role: action.role};
  case RoleInfoActions.SET_ROLE_INFO_LOADING:
    return {...state, isLoading: action.loading};
  case RoleInfoActions.SET_ROLE_VALIDATION_ERRORS:
    return {...state, roleValidationsErrors: action.errors};
  case RoleInfoActions.CLEAR_ROLE_INFO:
    return initialState;
  default:
    return state;
  }
};