import {Typography, Box} from "@mui/material";
import React, {Fragment, useEffect, useState} from "react";
import TreeView from "@mui/lab/TreeView";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import TreeItem from "@mui/lab/TreeItem";
import { DirectorShareholderData } from "./director-shareholder-data";
import {IDetailsData, IDirectorData} from "./types";
import {toCapitalizedWords} from "../../../../utils/common";

interface IProps {
  details?: string
}

export function CorporateDetails({details}: IProps) {
  const [data, setData] = useState<IDetailsData | null>(null);

  useEffect(() => {
    if (!(details)) return;
    try {
      const obj = JSON.parse(details);
      setData(obj);
    } catch (e) {
      return;
    }
  }, []);


  const checkIsDirectorShareholder = (dir: keyof IDetailsData): "directors" | "shareholders" | undefined => {
    if (dir === "directors" || dir === "shareholders") {
      return dir;
    }
    return;
  };

  return (
    <Box mt={2}>
      {!!data && (Object.keys(data)).map((detailsKey) => {
        const validDetailsKey = checkIsDirectorShareholder(detailsKey as keyof IDetailsData);
        if (!validDetailsKey) return <></>;
        if (data[validDetailsKey]?.length > 0) return (
          <Fragment key={detailsKey}>
            <Typography mt={2} fontSize={"1.5rem"}>{toCapitalizedWords(detailsKey)}</Typography>

            <TreeView
              defaultCollapseIcon={<ExpandMoreIcon />}
              defaultExpandIcon={<ChevronRightIcon />}
            >
              {data[validDetailsKey].map((director: IDirectorData, index: number,) => (
                <TreeItem
                  key={index} 
                  nodeId={`${index}`}
                  label={`${validDetailsKey === "directors" ? "Director" : "Shareholder"} ${index + 1} - ${"personal" in director ? "personal" : "company"}`}
                  sx={{my:1}}
                >
                  <DirectorShareholderData director={director} key={index}/>
                </TreeItem>
              ))}
            </TreeView>
          </Fragment>
        );
      })}
    </Box>
  );
}