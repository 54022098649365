import $api from "../http";
import { AxiosResponse } from "axios";
import {
  AccountDetailsResponse,
  AccountsListResponse,
  AccountStatusesResponse, IAccountInfoOndatoSessionRefreshStatusResponse,
  SetAccountVerificationResult,
} from "../types/service/response/accounts-response";
import { AccountListRequestParams } from "../types/service/request/accounts-request";
import { IGeneralResponse } from "../types/service/response/general-response";
import { IAccountTransactionItemsByPage } from "../types/store/account/account-transaction-list";
import {
  IAccountDetailsCorporate,
  IAccountDetailsIndividual,
  IAccountDocument, IAccountIdentityVerificationInfo, IAccountOndatoDetail,
  IAccountPhoto, IAccountSatchelWalletsAllTypes, IAccountSignedContract,
  IAccountWallet
} from "../types/store/account/account-details";

/** It's a wrapper for the API endpoints related to the account verification process */
export default class AccountService {
  static async accountsList(
    params: AccountListRequestParams = {}
  ): Promise<AxiosResponse<AccountsListResponse>> {
    let i: keyof AccountListRequestParams;
    for (i in params) {
      if (params[i] === undefined) delete params[i];
    }
    return $api.get<AccountsListResponse>("/account/verification-list", {
      params,
    });
  }

  static async accountDetails(
    id: string
  ): Promise<AxiosResponse<AccountDetailsResponse>> {
    return $api.get<AccountDetailsResponse>("/account/account-info-main", {
      params: { id },
    });
  }

  static async accountDetailsIndividual(
    id: string
  ): Promise<AxiosResponse<IGeneralResponse<IAccountDetailsIndividual>>> {
    return $api.get<IGeneralResponse<IAccountDetailsIndividual>>(
      "/account/account-info-individual",
      {
        params: { id },
      }
    );
  }

  static async accountDetailsCorporate(
    id: string
  ): Promise<AxiosResponse<IGeneralResponse<boolean> & {data: IAccountDetailsCorporate}>> {
    return $api.get<IGeneralResponse<boolean> & {data: IAccountDetailsCorporate}>(
      "/account/account-info-corporate",
      {
        params: { id },
      }
    );
  }

  static async accountDocuments(
    id: string
  ): Promise<AxiosResponse<IGeneralResponse<IAccountDocument[]>>> {
    return $api.get<IGeneralResponse<IAccountDocument[]>>(
      "/account/account-info-docs",
      {
        params: {id}
      }
    );
  }

  static async accountPhotos(
    id: string
  ): Promise<AxiosResponse<IGeneralResponse<IAccountPhoto[]>>> {
    return $api.get<IGeneralResponse<IAccountPhoto[]>>(
      "/account/account-info-photos",
      {
        params: {id}
      }
    );
  }

  static async accountWallets(
    id: string
  ): Promise<AxiosResponse<IGeneralResponse<IAccountWallet[]>>> {
    return $api.get<IGeneralResponse<IAccountWallet[]>>(
      "/account/account-info-wallets",
      {
        params: {id}
      }
    );
  }

  static async setVerificationResult(
    accountId: string,
    hmac: string,
    verificationResult: boolean,
    reason: string
  ): Promise<AxiosResponse<SetAccountVerificationResult>> {
    return $api.post<SetAccountVerificationResult>(
      "account/set-verification-result",
      {
        accountId: accountId,
        hmac: hmac,
        verificationResult: verificationResult,
        reason: reason,
      }
    );
  }

  static async accountStatuses(): Promise<
    AxiosResponse<AccountStatusesResponse>
    > {
    return $api.get<AccountStatusesResponse>("account/statuses");
  }

  static async accountTransactionList(
    accountId: string,
    offsetKey?: string | null
  ): Promise<AxiosResponse<IGeneralResponse<IAccountTransactionItemsByPage>>> {
    const data: Record<string, string> = { accountId };
    if (offsetKey) data.offsetKey = offsetKey;
    return $api.post("account/get-transaction-list-by-account-id", data);
  }

  static async sendPassword(accountId: string): Promise<AxiosResponse<IGeneralResponse<any>>> {
    const data = { userId: accountId };
    return $api.post("account/send-user-passcode", data);
  }

  static async setSatchelStatus(userId: string, status: boolean): Promise<AxiosResponse<IGeneralResponse<boolean>>> {
    const data = {userId, status};
    return $api.post("users/set-satchel-status", data);
  }

  static async setArbipayStatus(userId: string, status: boolean): Promise<AxiosResponse<IGeneralResponse<boolean>>> {
    const data = {userId, status};
    return $api.post("users/set-backoffice-status", data);
  }

  static async setOndataStatus(userId: string): Promise<AxiosResponse<IGeneralResponse<boolean>>> {
    const data = {userId};
    return $api.post("users/set-ondata-status", data);
  }

  static async accountOndatoDetails(userId: string): Promise<AxiosResponse<IGeneralResponse<IAccountOndatoDetail[]>>> {
    const params = {id: userId};
    return $api.get("account/account-info-ondato-list", {params});
  }

  static async accountOndatoSessionData(sessionId: string): Promise<AxiosResponse<IGeneralResponse<IAccountOndatoDetail>>> {
    return $api.get("account/account-info-ondato-session", {params: {sessionId}});
  }

  static async accountSignedContracts(id: string): Promise<AxiosResponse<IGeneralResponse<IAccountSignedContract[]>>> {
    const data = {id};
    return $api.post("account/account-signed-contract", data);
  }

  static async accountIdentityVerificationInfo(id: string): Promise<AxiosResponse<IGeneralResponse<IAccountIdentityVerificationInfo>>> {
    const params = {id};
    return $api.get("account/account-info-idv-result", {params});
  }

  static async accountInfoOndatoSessionRefreshStatus(sessionId: number): Promise<AxiosResponse<IAccountInfoOndatoSessionRefreshStatusResponse>> {
    const data = {sessionId};
    return $api.patch("account/account-info-ondato-session-refresh-status", data);
  };

  static async accountSatchelWallets(
    id: string
  ): Promise<AxiosResponse<IGeneralResponse<IAccountSatchelWalletsAllTypes>>> {
    return $api.get<IGeneralResponse<IAccountSatchelWalletsAllTypes>>(
      "/account/account-info-wallets-details",
      {
        params: {id}
      }
    );
  }

  static async deleteAccount(accountId: string): Promise<AxiosResponse<IGeneralResponse<boolean>>> {
    const data = {userId: accountId};
    return $api.post("/users/delete-user", data);
  }
}
