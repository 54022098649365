import { ITransactionStatusesState, TransactionStatusesActions, TTransactionStatusesAction } from "../../../types/store/transaction/transaction-statuses";

const initialState: ITransactionStatusesState = {
  statuses: {},
  expired: 0,
};

export const transactionStatusesReducer = (state: ITransactionStatusesState = initialState, action: TTransactionStatusesAction) => {
  const { type, payload } = action;

  switch (type) {
  case TransactionStatusesActions.SET_TRANSACTION_STATUSES_SUCCESS:
    return { ...state, ...payload };
  default:
    return state;
  }
};
