import React, {useState} from "react";
import {useTypedSelector} from "../../../hooks/useTypedSelector";
import {
  Button,
  Card,
  CardContent,
  CardHeader, Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import AccountDetailGridRow from "../account-detail-grid-row";
import {useTranslation} from "react-i18next";
import {USE_SERVER} from "../../../constants/constants";
import AccountService from "../../../sevices/account-service";
import AccountTestService from "../../../sevices/test/account-test-service";
import {notification} from "../../../utils/notification";
import { handleGeneralError } from "../../../store/actions/error-handler";
import {LoadingButton} from "@mui/lab";
import {useNavigate} from "react-router-dom";

const AccountBasicInfo = () => {
  const { account } = useTypedSelector((state) => state.accountDetails);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [openConfirmDialog, setOpenConfirmDialog] = React.useState(false);
  const [loadingButton, setLoadingButton] = useState(false);

  const deleteAccount = async () => {
    if (!account.id) return;
    setLoadingButton(true);
    try {
      const response =
	      USE_SERVER
		      ?
		      await AccountService.deleteAccount(account.id)
		      :
		      await AccountTestService.deleteAccount(account.id);

	    if (!response.data.result) notification.showErrorMessage("User not deleted!");
      else {
		    notification.showSuccessMessage("User successfully deleted!");
        navigate("/accounts");
	    }
    } catch (e) {
	    await handleGeneralError(e);
    } finally {
	    setLoadingButton(false);
      setOpenConfirmDialog(false);
    }
  };

  return (
    <>
	    <Card sx={{ mb: 2 }}>
		    <CardHeader
			    title={t("account.details.basic_info")}
			    action={
            <Button variant={"contained"} color={"error"} onClick={() => setOpenConfirmDialog(true)}>
	            Delete account
            </Button>
          }
		    />
		    <CardContent>
			    <AccountDetailGridRow
				    title={t("account.details.keycloackId")}
				    value={account?.keycloackId}
			    />
			    <AccountDetailGridRow
				    title={t("account.details.phone_number")}
				    value={account?.phoneNumber}
			    />
			    <AccountDetailGridRow
				    title={t("account.details.email")}
				    value={account?.emailAddress}
				    hideDivider
			    />
		    </CardContent>
	    </Card>
	    <Dialog open={openConfirmDialog} onClose={() => setOpenConfirmDialog(false)}>
		    <DialogTitle>{t("account.details.Account deleting")}</DialogTitle>
		    <DialogContent>
			    <DialogContentText sx={{mb: 2, textAlign: "center"}}>
				    {t("account.details.Account deleting confirmation", {accountId: account.id})}
			    </DialogContentText>
		    </DialogContent>
		    <DialogActions>
			    <Button
				    disabled={loadingButton}
				    onClick={() => setOpenConfirmDialog(false)}>
				    {t("common.btn_cancel")}
			    </Button>
			    <LoadingButton
				    loading={loadingButton}
				    onClick={() => deleteAccount()}
			    >
				    {t("common.btn_confirm")}
			    </LoadingButton>
		    </DialogActions>
	    </Dialog>
    </>
  );
};

export default AccountBasicInfo;