import {
  IWalletListResponse,
  IWalletResponse,
  IWalletTransactionListRespResult
} from "../../types/service/response/wallet-response";
import {IRequestResponseType} from "../../types/service/test/test-response";
import {delay} from "../../utils/common";
import {IWalletTransactionListReqParams} from "../../types/service/request/wallet";

/** It's a mock service that returns a list of wallets and a single wallet */
export default class WalletTestService {
  static async getWalletAccountList(): Promise<IRequestResponseType<unknown, IWalletListResponse>> {
    await delay(1200);
    return {
      headers: undefined,
      data: {
        result: true,
        wallets: [
          {
            id: "1",
            accountNumber: "ABS234659961",
            accountName: "Test Wallet",
          },
          {
            id: "2",
            accountNumber: "ABS123452",
            accountName: "Test Wallet",
          },
          {
            id: "3",
            accountNumber: "ABS123453",
            accountName: "Test Wallet",
          },
          {
            id: "3",
            accountNumber: "ABS123454",
            accountName: "Test Wallet",
          },
        ]
      }
    };
  }

  static async getWalletAccountData(accountId: string): Promise<IRequestResponseType<unknown, IWalletResponse>> {
    await delay(600);
    return {
      headers: undefined,
      data: {
        result: true,
        wallet: {
          id: "8",
          accountNumber: accountId,
          accountName: "Test Wallet",
          availableBalance: "123.64",
          currentBalance: "123.64",
          currencyCode: "eEur",
        }
      }
    };
  }

  static async getWalletTransactionList(params: IWalletTransactionListReqParams): Promise<IRequestResponseType<unknown, IWalletTransactionListRespResult>> {
    try {
      await delay(600);
      return {
        headers: undefined,
        data: {
          result: true,
          items:{
            per_page: 10,
            page: 1,
            total_page: 3,
            list : [
              {
                "id": 43,
                "satchel_id": "6872",
                "category": "transferSEPA",
                "email": "string",
                "amount": "1",
                "currency_code": "EUR",
                "fee": "0",
                "fee_currency_code": "EUR",
                "beneficiary_account_number": "string",
                "beneficiary_bank_swift": "string",
                "beneficiary_iban": "LT761234500001818122",
                "beneficiary_card_uuid": "string",
                "sender_account_number": "410015454369",
                "narrative": "qwe",
                "status": 101,
                "type": "transfer",
                "rejection_reason": "string",
                "processing_time": "string",
                "finished_at": "string",
                "created_at": "2022-11-21T10:05:19.000Z",
                "updated_at": "2022-11-21T10:05:19.000Z"
              }
            ]
          },
        }
      };
    } catch (e) {
      return {
        headers: undefined,
        data: {
          result: false,
          items: {
            per_page: 10,
            page: 1,
            total_page: 3,
            list : [
              {
                "id": 43,
                "satchel_id": "6872",
                "category": "transferSEPA",
                "email": "string",
                "amount": "1",
                "currency_code": "EUR",
                "fee": "0",
                "fee_currency_code": "EUR",
                "beneficiary_account_number": "string",
                "beneficiary_bank_swift": "string",
                "beneficiary_iban": "LT761234500001818122",
                "beneficiary_card_uuid": "string",
                "sender_account_number": "410015454369",
                "narrative": "qwe",
                "status": 101,
                "type": "transfer",
                "rejection_reason": "string",
                "processing_time": "string",
                "finished_at": "string",
                "created_at": "2022-11-21T10:05:19.000Z",
                "updated_at": "2022-11-21T10:05:19.000Z"
              }
            ]
          }
        }
      };
    }
  }
}