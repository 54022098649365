import React, {useEffect, useState} from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Grid, IconButton,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer, TableFooter,
  TableHead, TablePagination,
  TableRow,
  TableSortLabel, TextField, useTheme
} from "@mui/material";
import {useTranslation} from "react-i18next";
import {useTypedSelector} from "../../../hooks/useTypedSelector";
import {useActions} from "../../../hooks/useActions";
import {HeadCell, TOrder} from "../../../types/table";
import {IUserInfo} from "../../../types/store/user";
import {useNavigate} from "react-router-dom";
import DialogConfirmation from "../../../components/common/dialog-confirmation";
import ability from "../../../utils/can";
import Error404 from "../../error-pages/error-404";
import {Can} from "@casl/react";
import CustomTablePaginationActions from "../../../components/common/table-pagination";
import {Clear} from "@mui/icons-material";
import {notification} from "../../../utils/notification";
import StatusBox from "../../../components/status/status-box";

/**
 * A React component that is responsible for displaying a list of users.
 */
const UserList = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const {
    users,
    isLoading,
    totalItems
  } = useTypedSelector(state => state.userList);
  const {user: authUser} = useTypedSelector(state => state.auth);
  const {
    fetchUserList,
    updateUserBlockStatus,
  } = useActions();
  const theme = useTheme();

    type SortField = "name" | "email";

    const [order, setOrder] = useState<TOrder>("DESC");
    const [orderBy, setOrderBy] = useState<SortField>("name");
    const [page, setPage] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(5);
    const [filterEmail, setFilterEmail] = useState("");
    const [isOpenBlockDialog, setIsOpenBlockDialog] = useState(false);
    const [idToBlock, setIdToBlock] = useState(0);

    const handlePageChange = (event: unknown, page: number) => {
      if (!isLoading) setPage(page);
    };

    const handlePerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      if (!isLoading) {
        setItemsPerPage(+event.target.value);
        setPage(0);
      }
    };

    const handleSortTable = (orderBy: SortField) => () => {
      const reverseSort = order === "DESC" ? "ASC" : "DESC";
      setOrder(reverseSort);
      setOrderBy(orderBy);
    };

    const handleEditUser = (userId: string | number) => () => {
      navigate(`/user/edit/${userId}`);
    };



    const headCells: Array<HeadCell<IUserInfo>> = [
      {
        id: "id",
        numeric: true,
        disablePadding: false,
        label: t("user.table.id")
      },
      {
        id: "name",
        numeric: false,
        disablePadding: false,
        label: t("user.table.name")
      },
      {
        id: "email",
        numeric: false,
        disablePadding: false,
        label: t("user.table.email")
      },
      {
        id: "status",
        numeric: false,
        disablePadding: false,
        label: t("user.table.status")
      },
      {
        id: "action",
        numeric: false,
        label: "",
        disablePadding: false,
        isHide: true,
      },
    ];

    const handleCreateUser = () => {
      navigate("/user/add");
    };

    const handleBlockUser = () => {
      const userToBlock = users.find((user) => (user.id === idToBlock && user.email === authUser.email));
      if (userToBlock) {
        notification.showErrorMessage(t("validations.You cannot block your account"));
        return;
      }
      updateUserBlockStatus(idToBlock, true);
      handleCloseBlockDialog();
    };

    const handleUnblockUser = (id: number) => () => {
      updateUserBlockStatus(id, false);
    };

    const handleOpenBlockDialog = (value: number) => () => {
      setIsOpenBlockDialog(true);
      setIdToBlock(value);
    };

    const handleCloseBlockDialog = () => {
      setIsOpenBlockDialog(false);
    };

    const getBlockStatusColor = (isBlocked: boolean) => {
      return isBlocked ? theme.palette.error.main : theme.palette.success.main;
    };

    const getBlockStatusName = (isBlocked: boolean) => {
      return isBlocked ? t("user.common.blocked") : t("user.common.active");
    };

    const handleFilterEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
      setPage(0);
      setFilterEmail(e.target.value);
    };

    useEffect(() => {
      if (ability.can("get", "users"))
        fetchUserList({
          items_per_page: itemsPerPage,
          email: filterEmail ? filterEmail : undefined,
          asc: order === "ASC" ? orderBy : undefined,
          desc: order == "DESC" ? orderBy : undefined,
          current_page: page
        });
    }, [filterEmail, order, orderBy, page, itemsPerPage]);

    if (ability.cannot("get", "users"))
      return <Error404/>;

    return (
      <Container maxWidth="xl">
        <Can I="get" a="users" ability={ability}>
          <Grid container columns={16} spacing={3}>
            <Grid item xs={16} md={16}>
              <Card>
                <CardHeader title={t("user.table.title")}/>
                <CardContent>
                  <Box sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    mb: 2
                  }}>
                    <TextField
                      id="filterEmail"
                      name="filterEmail"
                      label={t("user.table.find by email")}
                      value={filterEmail}
                      InputProps={{endAdornment: (
                        <IconButton onClick={() => setFilterEmail("")}>
                          <Clear/>
                        </IconButton>
                      )}}
                      onChange={handleFilterEmail}
                    />
                    <Button
                      variant={"contained"}
                      size="small"
                      color="success"
                      onClick={handleCreateUser}
                      disabled={ability.cannot("create", "user")}
                    >
                      {t("user.table.create")}
                    </Button>
                  </Box>
                  <TableContainer component={Paper}>
                    <Table sx={{minWidth: 650}} aria-label="Transactions list table" size={"small"}>
                      <TableHead>
                        <TableRow sx={{"& .MuiTableCell-head": {fontWeight: 600}}}>
                          {headCells.map((headCell) => (
                            <TableCell
                              sx={{color: headCell.isHide ? "transparent" : ""}}
                              key={headCell.id}
                            >
                              {headCell.id === "name" || headCell.id === "email" ?
                                <TableSortLabel
                                  active={orderBy === headCell.id}
                                  direction={order === "ASC" ? "asc" : "desc"}
                                  onClick={handleSortTable(headCell.id)}
                                >
                                  {headCell.label}
                                </TableSortLabel>
                                :
                                headCell.label
                              }
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {isLoading && Array(itemsPerPage).fill("").map((row, index) => (
                          <TableRow
                            key={index}
                            sx={{"&:last-child td, &:last-child th": {border: 0}}}
                          >
                            {Array(headCells.length).fill("").map((row, index) => (
                              <TableCell key={index}>
                                <Skeleton height={30}/>
                              </TableCell>
                            ))}
                          </TableRow>
                        ))}
                        {!isLoading && users.map((user) => (
                          <TableRow
                            key={user.id}
                            sx={{"&:last-child td, &:last-child th": {border: 0}}}
                            hover
                          >
                            <TableCell>{user?.id}</TableCell>
                            <TableCell>{user?.name}</TableCell>
                            <TableCell>{user?.email}</TableCell>
                            <TableCell>
                              <StatusBox label={getBlockStatusName(user.isBlocked)} color={getBlockStatusColor(user.isBlocked)}/>
                            </TableCell>
                            <TableCell align="right">
                              <Can ability={ability} I="update" a={"user"}>
                                <Grid container spacing={2} justifyContent="flex-end">
                                  <Grid item>
                                    <Button
                                      sx={{minWidth: "90px"}}
                                      variant={"contained"}
                                      size="small"
                                      onClick={handleEditUser(user.id)}
                                    >
                                      {t("user.table.details")}
                                    </Button>
                                  </Grid>
                                  <Grid item>
                                    {!user?.isBlocked &&
                                                                        <Button
                                                                          sx={{minWidth: "90px"}}
                                                                          variant={"contained"}
                                                                          size="small"
                                                                          color="error"
                                                                          onClick={handleOpenBlockDialog(user.id)}
                                                                          /* Checking if the user is the same as the authUser. If it is,
                                                                            then the button is disabled. */
                                                                          disabled={ability.cannot("remove", "user") || user?.email === authUser.email}
                                                                        >
                                                                          {t("user.table.block")}
                                                                        </Button>
                                    }
                                    {user?.isBlocked &&
                                                                        <Button
                                                                          sx={{minWidth: "90px"}}
                                                                          variant={"contained"}
                                                                          size="small"
                                                                          color="success"
                                                                          onClick={handleUnblockUser(user.id)}
                                                                          /* Checking if the user is the same as the authUser. If it is,
                                                                            then the button is disabled. */
                                                                          disabled={ability.cannot("remove", "user") || user?.email === authUser.email}
                                                                        >
                                                                          {t("user.table.unblock")}
                                                                        </Button>
                                    }
                                  </Grid>
                                </Grid>
                              </Can>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                      <TableFooter>
                        <TableRow>
                          <TablePagination
                            count={totalItems}
                            page={page}
                            onPageChange={handlePageChange}
                            onRowsPerPageChange={handlePerPageChange}
                            labelRowsPerPage={t("common.rows_per_page")}
                            rowsPerPage={totalItems ? itemsPerPage: -1}
                            rowsPerPageOptions={totalItems ? [5, 10, 20] : [-1]}
                            ActionsComponent={CustomTablePaginationActions}
                          />
                        </TableRow>
                      </TableFooter>
                    </Table>
                  </TableContainer>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
          <DialogConfirmation
            isOpen={isOpenBlockDialog}
            onClose={handleCloseBlockDialog}
            onConfirm={handleBlockUser}
            title={t("user.common.User block confirmation")}
            message={t("user.common.Are you sure that you want to block user?", {id: idToBlock})}/>
        </Can>
      </Container>
    );
};

export default UserList;