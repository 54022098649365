
export interface ITransactionStatusesState {
  statuses: Record<string, string>,
  expired: number,
}

export enum TransactionStatusesActions {
  SET_TRANSACTION_STATUSES_LOADING = "SET_TRANSACTION_STATUSES_LOADING",
  SET_TRANSACTION_STATUSES_SUCCESS = "SET_TRANSACTION_STATUSES_SUCCESS",
  SET_TRANSACTION_STATUSES_FAILURE = "SET_TRANSACTION_STATUSES_FAILURE",
}

export interface ISetTransactionStatusesLoading { 
  type: TransactionStatusesActions.SET_TRANSACTION_STATUSES_LOADING,
  payload: unknown,
}

export interface ISetTransactionStatusesSuccess { 
  type: TransactionStatusesActions.SET_TRANSACTION_STATUSES_SUCCESS,
  payload: ITransactionStatusesState,
}

export interface ISetTransactionStatusesFailure { 
  type: TransactionStatusesActions.SET_TRANSACTION_STATUSES_FAILURE,
  payload: unknown,
}

export type TTransactionStatusesAction = ISetTransactionStatusesLoading | ISetTransactionStatusesSuccess | ISetTransactionStatusesFailure;
