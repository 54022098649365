import {
  IClearSanctionCountries,
  ISanctionCountriesItem,
  ISetSanctionCountries,
  ISetSanctionCountriesLoading,
  SanctionCountriesActions
} from "../../../types/store/sanction/countries";
import {ThunkDispatch} from "redux-thunk";
import {Action} from "redux";
import SanctionTestService from "../../../sevices/test/sanction-test-service";
import {handleGeneralError} from "../error-handler";
import {USE_SERVER} from "../../../constants/constants";
import SanctionService from "../../../sevices/sanction-service";

const setSanctionCountries = (sanctions: ISanctionCountriesItem[]): ISetSanctionCountries => ({
  type: SanctionCountriesActions.SET_SANCTION_COUNTRIES,
  sanctions,
});

const clearSanctionCountries = (): IClearSanctionCountries => ({
  type: SanctionCountriesActions.CLEAR_SANCTION_COUNTRIES
});

const setSanctionCountriesLoading = (loading: boolean): ISetSanctionCountriesLoading => ({
  type: SanctionCountriesActions.SET_SANCTION_COUNTRIES_LOADING,
  loading
});

export const fetchSanctionCountries = () => async (dispatch: ThunkDispatch<unknown, unknown, Action>) => {
  try {
    dispatch(clearSanctionCountries());
    dispatch(setSanctionCountriesLoading(true));
    const response =
            USE_SERVER ?
              await SanctionService.sanctionCountriesAll()
              :
              await SanctionTestService.sanctionCountriesAll();

    dispatch(setSanctionCountries(response.data.result));

  } catch (e){
    await dispatch(handleGeneralError(e));
  } finally {
    dispatch(setSanctionCountriesLoading(false));
  }
};