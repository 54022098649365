import React, {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {Can} from "@casl/react";
import {Button, Grid} from "@mui/material";

import ability from "../../../utils/can";
import {useActions} from "../../../hooks/useActions";
import {useTypedSelector} from "../../../hooks/useTypedSelector";
import TableWithList, {THeadCells, TTableItem} from "../../../components/table-list";
import useHome from "../../../hooks/useHome";
import {useTranslation} from "react-i18next";
import {useAccountsList} from "../../../hooks/useAccountsList";

/** A React component of main page. */
const Home = () => {
  const navigate = useNavigate();
  const {
    fetchAccountsList,
    fetchTransactionsList
  } = useActions();
  const { user } = useTypedSelector((state) => state.auth);
  const {accounts, isLoading: isLoadingAccounts} = useTypedSelector((state) => state.accountsList);
  const {transactions, isLoading: isLoadingTransactions} = useTypedSelector((state) => state.transactionsList);
  const { headTransactions, headAccounts} = useHome();
  const {t} = useTranslation();
  const content = [
    {
      action: "get",
      subject: "transactions_list",
      title: "transactions.table.title",
      route: "/transactions",
      reference: "id",
      headCells: headTransactions,
      list: transactions,
      isLoading: isLoadingTransactions,
    },
    {
      action: "get",
      subject: "verification_list",
      title: "account.table.accounts",
      reference: "id",
      route: "/accounts",
      headCells: headAccounts,
      list: accounts,
      isLoading: isLoadingAccounts,
    },
  ];
  useEffect(() => {
    if (ability.can("get", "verification_list"))
      fetchAccountsList({ per_page: 5, page: 1, sort: "DESC" });

    if (ability.can("get", "transactions_list"))
      fetchTransactionsList({ per_page: 5, cur_page: 1, sort: "DESC" });
  }, [user]);

  return (
    <Grid container justifyContent="center" alignItems="flex-start" spacing={2} sx={{px: 2}}>
      {content.map(
        ({action, subject, title, route, reference, headCells, list, isLoading}, index) => {
          const handleClickRow = (item: TTableItem) => {
            let navigateTo = route + "/" + item[reference as keyof TTableItem];
            if (action === "get" && subject === "verification_list") {
              navigateTo =
                route + "/" + item[reference as keyof TTableItem];
            }
            navigate(navigateTo);
          };

          return (
            <Can I={action} a={subject} ability={ability} key={index}>
              <Grid
                sx={{position: "relative"}}
                item
                xs={12}
                lg={6}
              >
                <TableWithList
                  title={title}
                  headCells={headCells as THeadCells}
                  list={list as TTableItem[]}
                  isLoading={isLoading}
                  handleClickRow={handleClickRow}
                />
                <Button
                  sx={{
                    position: "absolute",
                    top: "2rem",
                    right: "2rem",
                  }}
                  variant="contained"
                  size="medium"
                  onClick={() => navigate(route)}
                >
                  {t("common.btn_more_info")}
                </Button>
              </Grid>
            </Can>
          );
        }
      )}
    </Grid>
  );
};

export default Home;
