import {IWalletAccountsState, TWalletAccountsAction, WalletAccountsActions} from "../../../types/store/wallet/wallet-accounts";

const initialState: IWalletAccountsState = {
  wallets: [],
  isLoading: false,
  isTech: false
};

export const walletAccountsReducer = (state = initialState, action: TWalletAccountsAction): IWalletAccountsState => {
  switch (action.type){
  case WalletAccountsActions.CLEAR_WALLET_ACCOUNTS:
    return initialState;
  case WalletAccountsActions.SET_WALLET_ACCOUNTS:
    return {...state, wallets: action.wallets, isTech: action.isTech};
  case WalletAccountsActions.UPDATE_WALLET_ACCOUNT:
    return {
      ...state,
      wallets: state.wallets.map(wallet => {
        if (wallet.accountNumber === action.wallet.accountNumber){
          return action.wallet;
        }
        return wallet;
      })
    };
  case WalletAccountsActions.SET_WALLET_ACCOUNTS_LOADING:
    return {
      ...state,
      isLoading: action.loading
    };
  default:
    return state;
  }
};