import { AccountStatusesActions, IAccountStatusesState, TAccountStatusesAction } from "../../../types/store/account/account-statuses";

const initialState: IAccountStatusesState = {
  statuses: {},
  expired: 0,
};

export const accountStatusesReducer = (state: IAccountStatusesState = initialState, action: TAccountStatusesAction) => {
  const { type, payload } = action;

  switch (type) {
  case AccountStatusesActions.SET_ACCOUNT_STATUSES_SUCCESS:
    return { ...state, ...payload };
  default:
    return state;
  }
};
