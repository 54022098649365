import {IUserInfo} from "./index";

export interface IUserListState {
    users: IUserInfo[],
    isLoading: boolean,
    totalItems: number
}

export enum UserListActions {
    SET_USER_LIST = "SET_USER_LIST",
    SET_USER_LIST_LOADING = "SET_USER_LIST_LOADING",
}

export interface ISetUserList {
    type: UserListActions.SET_USER_LIST,
    users: IUserInfo[],
    totalItems?: number
}

export interface ISetUserListLoading {
    type: UserListActions.SET_USER_LIST_LOADING,
    loading: boolean
}

export type TUserListAction = ISetUserList | ISetUserListLoading;