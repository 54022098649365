import {IRequestResponseType} from "../../types/service/test/test-response";
import {SettingsResponse} from "../../types/service/response/settings-response";

/** This class is a service that provides a method to get the settings for the application. It is a mock for the API */
export class SettingsTestService {
  static async getParserSwitchStatus(): Promise<IRequestResponseType<undefined, SettingsResponse>> {
    return {
      headers: undefined,
      data: {
        result: {
          id: 1,
          settingName: "parser_switch",
          settingValue: "on",
          createdAt: "2022-03-06",
          updatedAt: "2022-03-06"
        }
      }
    };
  }

  static async setParserSwitchStatus(status: boolean): Promise<IRequestResponseType<undefined, SettingsResponse>> {
    const statusName = status ? "on" : "off";
    return {
      headers: undefined,
      data: {
        result: {
          id: 1,
          settingName: "parser_switch",
          settingValue: statusName,
          createdAt: "2022-03-06",
          updatedAt: "2022-03-06"
        }
      }
    };
  }
}