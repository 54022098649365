import { TransactionsListRequestParams } from "../../types/service/request/transactions-request";
import { IRequestResponseType } from "../../types/service/test/test-response";
import {
  TransactionInfoResponse,
  TransactionsListResponse,
  TransactionStatusesResponse,
} from "../../types/service/response/transactions-response";
import { delay, descendingComparator, getRandomInt } from "../../utils/common";
import {ITransaction, TransactionStatuses} from "../../types/store/transaction";
import { TRANSACTION_STATUSES } from "../../constants/statuses";

/** A mock service for testing purposes. */
export default class TransactionsTestService {
  static async transactionsList(
    params: TransactionsListRequestParams
  ): Promise<IRequestResponseType<undefined, TransactionsListResponse>> {
    const { per_page, cur_page: page } = params;
    const transactions = [...Array(per_page)].map(() => {
      const randId = getRandomInt(1, 99999);
      return {
        id: randId,
        satchelId: "6872",
        category: "transferSEPA",
        email: null,
        amount: "1",
        currencyCode: "EUR",
        fee: "0",
        feeCurrencyCode: "EUR",
        beneficiaryAccountNumber: null,
        beneficiaryBankSwift: null,
        beneficiaryIban: "LT761234500001818122",
        beneficiaryCardUuid: null,
        senderAccountNumber: "410015454369",
        narrative: "qwe",
        status: TransactionStatuses.AML_CHECK,
        type: "transfer",
        rejectionReason: null,
        processingTime: null,
        finishedAt: null,
        createdAt: "2022-11-21T10:05:19.000Z",
        updatedAt: "2022-11-21T10:05:19.000Z",
      } as ITransaction;
    });

    // transactions.sort((a, b) => {
    //   if (sort === "DESC") {
    //     return descendingComparator<ITransaction>(a, b, "id");
    //   } else {
    //     return -descendingComparator<ITransaction>(a, b, "id");
    //   }
    // });

    await delay(600);
    return {
      headers: undefined,
      data: {
        data: {
          list: transactions,
          totalPages: 10,
          // per_page: per_page,
          // page: page,
        },
        result: true
      },
    };
  }

  static async transactionInfo(
    id: number
  ): Promise<IRequestResponseType<undefined, TransactionInfoResponse>> {
    const transaction: ITransaction = {
      id: id,
      satchelId: "6872",
      category: "transferSEPA",
      email: null,
      amount: "1",
      currencyCode: "EUR",
      fee: "0",
      feeCurrencyCode: "EUR",
      beneficiaryAccountNumber: null,
      beneficiaryBankSwift: null,
      beneficiaryIban: "LT761234500001818122",
      beneficiaryCardUuid: null,
      senderAccountNumber: "410015454369",
      narrative: "qwe",
      status: TransactionStatuses.AML_CHECK,
      type: "transfer",
      rejectionReason: null,
      processingTime: null,
      finishedAt: null,
      createdAt: "2022-11-21T10:05:19.000Z",
      updatedAt: "2022-11-21T10:05:19.000Z",
    };

    return {
      headers: undefined,
      data: {
        result: true,
        data: transaction,
      },
    };
  }

  static async transactionStatuses(): Promise<
    IRequestResponseType<unknown, TransactionStatusesResponse>
    > {
    return {
      headers: undefined,
      data: {
        result: {
          statuses: TRANSACTION_STATUSES,
        },
      },
    };
  }
}
