import {
  IMerchantListState,
  MerchantListActions,
  TMerchantListAction
} from "../../../types/store/merchant/merchant-list";

const initialState: IMerchantListState = {
  isLoading: false,
  merchants: []
};

export const merchantListReducer = (state = initialState, action: TMerchantListAction): IMerchantListState => {
  switch (action.type) {
  case MerchantListActions.SET_MERCHANT_LIST:
    return {...state, merchants: action.merchants};
  case MerchantListActions.SET_MERCHANT_LIST_LOADING:
    return {...state, isLoading: action.loading};
  default:
    return state;
  }
};