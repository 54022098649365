import React, {FC, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useApplicationsList} from "../../hooks/useApplicationsList";
import {useTypedSelector} from "../../hooks/useTypedSelector";
import {useActions} from "../../hooks/useActions";
import ability from "../../utils/can";
import {
  Card,
  CardContent,
  CardHeader,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@mui/material";
import {
  IApplication,
  TApplicationStatus
} from "../../types/store/applications";
import {USE_SERVER} from "../../constants/constants";
import ApplicationsService from "../../sevices/applications-service";
import ApplicationsTestService from "../../sevices/test/applications-test-service";
import {toast} from "react-toastify";
import {delay} from "../../utils/common";
import {ApplicationsListItemRow} from "./application-list-item/applications-list-item-row";
import {TApplicationType} from "./types";

interface IProps {
  type: TApplicationType
}

const ApplicationsListTableByAccount:FC<IProps> = ({type}) => {
  const {t} = useTranslation();
  const { headCellsByAccount } = useApplicationsList();
  const {
    isLoading,
    applications,
    perPage,
  } = useTypedSelector(state => state.applicationsList);
  const {account} = useTypedSelector(state => state.accountDetails);
  const [applicationsByAccountType, setApplicationsByAccountType] = useState<IApplication[]>([]);
  const {
    fetchApplicationsListByAccountType,
    handleGeneralError
  } = useActions();


  const updateApplicationStatus = async (applicationRequestId: string, type: TApplicationType, status: Exclude<TApplicationStatus, "received">, rejectedReason?: string) => {
    try {
      const response = USE_SERVER
        ? await ApplicationsService.updateApplicationRequest(applicationRequestId, type, status, account.id, rejectedReason)
        : await ApplicationsTestService.updateApplicationRequest(applicationRequestId, type, status, account.id, rejectedReason);

      const {result} = response.data;
      if (result) {
        toast.success(t("applications.Application status successfully updated"));
        await delay(1000);
        fetchApplicationsListByAccountType({
          client_id: account.id,
        });
      }
    } catch (e) {
      await handleGeneralError(e);
    }
  };

  useEffect(() => {
    if (ability.can("get", "satchel_accounts") && type === "individual" && account.id)
      fetchApplicationsListByAccountType({
        client_id: account.id,
      });
  }, [account.id]);

  useEffect(() => {
    setApplicationsByAccountType(
      applications.filter(item => item.accountType === type)
    );
  }, [applications]);

  return (
    <Card sx={{mb:2}}>
      <CardHeader title={type === "individual" ?
        t("account.details.Personal account applications")
        :
        t("account.details.Business account applications")}/>
      <CardContent>
        {!isLoading && applicationsByAccountType.length === 0 && (
          <Typography>
            {t("account.details.nodata")}
          </Typography>
        )}
        {applicationsByAccountType.length > 0 && (
          <TableContainer component={Paper}>
            <Table sx={{minWidth: 650}} size={"small"}>
              <TableHead>
                <TableRow sx={{"& .MuiTableCell-head": {fontWeight: 600}}}>
                  {headCellsByAccount.map((headCell) => (
                    <TableCell
                      sx={{color: headCell.isHide ? "transparent" : ""}}
                      key={headCell.id}
                    >
                      {headCell.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {isLoading && Array(perPage).fill("").map((row, index) => (
                  <TableRow
                    key={index}
                    sx={{"&:last-child td, &:last-child th": {border: 0}}}
                  >
                    {Array(headCellsByAccount.length).fill("").map((row, index) => (
                      <TableCell key={index}>
                        <Skeleton height={30}/>
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
                {!isLoading && applicationsByAccountType.map((application, index) => (
                  <ApplicationsListItemRow
                    application={application}
                    type={type}
                    approve={async () => await updateApplicationStatus(String(application.id), type, "approved")}
                    reject={async (rejectReason) => await updateApplicationStatus(String(application.id), type, "rejected", rejectReason)}
                    key={index}/>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </CardContent>
    </Card>
  );
};





export default ApplicationsListTableByAccount;