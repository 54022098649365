import {HeaderMenuActionList, IHeaderMenuState, THeaderMenuAction} from "../../types/store/header-menu";

const initialState: IHeaderMenuState = {
  switchStatus: false,
  isSwitchDisabled: false,
};

export const headerMenuReducer = (state: IHeaderMenuState = initialState, action: THeaderMenuAction): IHeaderMenuState => {
  switch (action.type) {
  case HeaderMenuActionList.SET_HEADER_MENU_SWITCH_STATUS:
    return {...state, switchStatus: action.switchStatus};
  case HeaderMenuActionList.SET_HEADER_MENU_SWITCH_DISABLING:
    return {...state, isSwitchDisabled: action.isSwitchDisabled};
  default:
    return state;
  }
};