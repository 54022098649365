import {TTransactionStatus} from "../transaction";

export type TAccountTransactionType = "withdraw" | "deposit" | "transfer";

export interface IAccountTransaction {
    id: string,
    type: TAccountTransactionType,
    initiatedBy: {
        type: string,
        id: string
    },
    status: TTransactionStatus,
    transaction: {
        accountId?: string,
        amount: string,
        description?: string,
        sourceAccountId?: string,
        targetAccountId?: string
        variableSymbol: string
    },
    createdAt: string,
    amlDetails: string[]
    processingTransactionId?: string
}

export interface IAccountTransactionItemsByPage {
    items: IAccountTransaction[],
    nextOffsetKey?: string | null
}

export interface IAccountTransactionListState {
    accountId?: string,
    transactionsByPage: IAccountTransactionItemsByPage[]
    isLoading: boolean,
    currentPage: number,
    lastPage?: string | null,
}

export enum AccountTransactionListActions {
    SET_ACCOUNT_TRANSACTION_LIST = "SET_ACCOUNT_TRANSACTIONS_LIST",
    SET_ACCOUNT_TRANSACTION_LIST_LOADING = "SET_ACCOUNT_TRANSACTION_LIST_LOADING",
    CLEAR_ACCOUNT_TRANSACTION_LIST = "CLEAR_ACCOUNT_TRANSACTION_LIST",
    SET_ACCOUNT_TRANSACTION_LIST_CURRENT_PAGE = "SET_ACCOUNT_TRANSACTION_LIST_CURRENT_PAGE"
}

export interface ISetAccountTransactionList {
    type: AccountTransactionListActions.SET_ACCOUNT_TRANSACTION_LIST,
    list: IAccountTransactionItemsByPage,
}

export interface ISetAccountTransactionListLoading {
    type: AccountTransactionListActions.SET_ACCOUNT_TRANSACTION_LIST_LOADING,
    loading: boolean
}

export interface IClearAccountTransactionList {
    type: AccountTransactionListActions.CLEAR_ACCOUNT_TRANSACTION_LIST
}

export interface ISetAccountTransactionListCurrentPage {
    type: AccountTransactionListActions.SET_ACCOUNT_TRANSACTION_LIST_CURRENT_PAGE,
    page: number
}

export type TAccountTransactionListAction =
    ISetAccountTransactionList |
    ISetAccountTransactionListLoading |
    IClearAccountTransactionList |
    ISetAccountTransactionListCurrentPage