import {IUserInfo, IUserInfoState, TUserInfoAction, UserInfoActions} from "../../../types/store/user";

const initialState: IUserInfoState = {
  user: {} as IUserInfo,
  isLoading: false
};

export const userInfoReducer = (state = initialState, action: TUserInfoAction): IUserInfoState => {
  switch (action.type) {
  case UserInfoActions.SET_USER_INFO:
    return {...state, user: action.user};
  case UserInfoActions.SET_USER_INFO_LOADING:
    return {...state, isLoading: action.loading};
  case UserInfoActions.SET_USER_VALIDATION_ERRORS:
    return {...state, userValidationsErrors: action.errors};
  case UserInfoActions.CLEAR_USER_INFO:
    return initialState;
  default:
    return state;
  }
};