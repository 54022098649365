import {
  IClearUserInfo,
  ISetUserInfo,
  ISetUserInfoLoading,
  ISetUserValidationErrors,
  IUserDataToUpdate,
  IUserInfo,
  UserInfoActions,
  UserValidationErrors
} from "../../../types/store/user";
import {Action} from "redux";
import {AxiosError} from "axios";
import {USE_SERVER} from "../../../constants/constants";
import UserService from "../../../sevices/user-service";
import UserTestService from "../../../sevices/test/user-test-service";
import {IGeneralResponse} from "../../../types/service/response/general-response";
import {ThunkDispatch} from "redux-thunk";
import {handleGeneralError} from "../error-handler";
import i18n from "i18next";
import {notification} from "../../../utils/notification";

/**
 * It takes an object of type IUserInfo and returns an object of type ISetUserInfo
 * @param {IUserInfo} user - IUserInfo - this is the type of the parameter that is passed into the function.
 */
const setUserInfo = (user: IUserInfo): ISetUserInfo => ({
  type: UserInfoActions.SET_USER_INFO,
  user
});

/**
 * It returns an object with a type property and a loading property
 * @param {boolean} loading - boolean
 */
const setUserInfoLoading = (loading: boolean): ISetUserInfoLoading => ({
  type: UserInfoActions.SET_USER_INFO_LOADING,
  loading
});

/**
 * It returns an object with a type property and an errors property
 * @param {UserValidationErrors} errors - UserValidationErrors
 */
export const setUserValidationErrors = (errors: UserValidationErrors): ISetUserValidationErrors => ({
  type: UserInfoActions.SET_USER_VALIDATION_ERRORS,
  errors
});

/**
 * It returns an object with a type property set to CLEAR_USER_INFO
 */
export const clearUserInfo = (): IClearUserInfo => ({
  type: UserInfoActions.CLEAR_USER_INFO
});

/**
 * It fetches the user info from the server and dispatches the result to the reducer
 * @param {number | string} userId - number | string
 */
export const fetchUserInfo = (userId: number | string) => async (dispatch: ThunkDispatch<unknown, unknown, Action>) => {
  try {
    dispatch(setUserInfoLoading(true));
    const response = USE_SERVER
      ? await UserService.userInfo(userId)
      : await UserTestService.userInfo(userId);
    dispatch(setUserInfo(response.data.user));
  } catch (e: unknown) {
    await dispatch(handleGeneralError(e));
  } finally {
    dispatch(setUserInfoLoading(false));
  }
};

/**
 * It updates the user's information
 * @param {number} id - number - the id of the user to update
 * @param {IUserDataToUpdate} data - IUserDataToUpdate - this is the data that we want to send to the server.
 */
export const updateUser = (id: number, data: IUserDataToUpdate) => async (dispatch: ThunkDispatch<unknown, unknown, Action>) => {
  try {
    const response = USE_SERVER
      ? await UserService.updateUser(id, data)
      : await UserTestService.updateUser(id, data);
    dispatch(setUserInfo(response.data.user));
    notification.showSuccessMessage(i18n.t("user.common.User updated!"));
  } catch (e: unknown) {
    const {response} = e as AxiosError<IGeneralResponse<boolean>>;
    await dispatch(handleGeneralError(e));

    //TODO add general func
    const responseErrors: string[] | undefined = response?.data?.errors;
    const errorStrings: UserValidationErrors = {password: "", email: ""};
    if (responseErrors && responseErrors?.length > 0){
      responseErrors.map(string => {
        const key = string.split(" ")[0];
        if (key in errorStrings){
          errorStrings[key as keyof UserValidationErrors] = string;
        }
      });
    }
    dispatch(setUserValidationErrors(errorStrings));
  }
};

/**
 * It creates a user
 * @param {IUserDataToUpdate} data - IUserDataToUpdate - this is the data that we're sending to the server.
 */
export const createUser = (data: IUserDataToUpdate) => async (dispatch: ThunkDispatch<unknown, unknown, Action>) => {
  try {
    dispatch(clearUserInfo());
    const response = USE_SERVER
      ? await UserService.createUser(data)
      : await UserTestService.createUser(data);
    dispatch(setUserInfo(response.data.user));
    notification.showSuccessMessage(i18n.t("user.common.User created!"));
  } catch (e: unknown) {
    const {response} = e as AxiosError<IGeneralResponse<boolean>>;
    await dispatch(handleGeneralError(e));

    //TODO add general func utils/common.ts
    const responseErrors: string[] | undefined = response?.data?.errors;
    const errorStrings: UserValidationErrors = {password: "", email: ""};
    if (responseErrors && responseErrors?.length > 0){
      responseErrors.map(string => {
        const key = string.split(" ")[0];
        if (key in errorStrings){
          errorStrings[key as keyof UserValidationErrors] = string;
        }
      });
    }
    dispatch(setUserValidationErrors(errorStrings));
  }
};