import AccountDetailGridRow from "../components/account/account-detail-grid-row";
import {Typography} from "@mui/material";
import React from "react";
import {toCapitalizedWords} from "./common";

const formDataByType = (val: string | object | any, key: number | string): JSX.Element | undefined=> {
  if (typeof val === "string" || typeof val === "boolean") {
    return (
      <AccountDetailGridRow
        key={key}
        title={toCapitalizedWords(key.toString())}
        value={String(val)}
      />
    );
  } else if (typeof val === "object" && val !== null && !Array.isArray(val)) {
    return (
      <>
        <Typography fontSize={20} my={1}>{toCapitalizedWords(key.toString())}</Typography>
        {Object.keys(val).map((key2, indexKey2) => (
          <React.Fragment key={indexKey2}>
            {formDataByType(val[key2], key2)}
          </React.Fragment>
        ))}
      </>
    );
  }
  else if (typeof val === "object" && val !== null && Array.isArray(val)) {
    return (
      <>
        {val.map((valItem, indexKey2) => (
          <React.Fragment key={indexKey2}>
            {formDataByType(valItem, indexKey2)}
          </React.Fragment>
        ))}
      </>
    );
  }
  else return <></>;
};

export const parseObjectData = (json: string | undefined, formatStingValue?: (value: string, key?: string) => string) => {
  if (!(json)) return;
  try {
    const obj = JSON.parse(json);

    return (
      <>
        {(Object.keys(obj as Record<string, any>)
          .map((key, indexKey) => (
            <React.Fragment key={indexKey}>
              {formatStingValue && typeof obj[key] === "string"
                ? formDataByType(formatStingValue(obj[key], key), key)
                : formDataByType(obj[key], key)}
            </React.Fragment>
          )))}
      </>
    );
  } catch (e) {
    return;
  }
};