import React from "react";
import {theme} from "./theme";
import {Box, ThemeProvider} from "@mui/material";
import {BrowserRouter} from "react-router-dom";
import {Provider} from "react-redux";
import {store} from "./store";
import LayoutView from "./components/layout/layout-view";
import {ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import ModalImageWindowProvider from "./providers/modalImageWindowProvider";
import { LocalizationProvider } from "@mui/lab";
import AdapterMoment from "@mui/lab/AdapterMoment";

/**
 * Entrypoint component
 */
export function App() {
  return (
    <React.StrictMode>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="en">
            <BrowserRouter>
              <Box sx={{background: theme.palette.background.default}}>
                <ModalImageWindowProvider>
                  <LayoutView/>
                </ModalImageWindowProvider>

                <ToastContainer/>
              </Box>
            </BrowserRouter>
          </LocalizationProvider>
        </ThemeProvider>
      </Provider>
    </React.StrictMode>
  );
}

// TODO Remove formik - rewrite on react-hook-form
// TODO Update datepickers to mui-x
// TODO Update react
// TODO Update mui
