import {IRole} from "./index";

export interface IRoleListState {
    roles: IRole[],
    isLoading: boolean,
    confirmDelete: boolean,
}

export enum RoleListActions {
    SET_ROLE_LIST = "SET_ROLE_LIST",
    SET_ROLE_LIST_LOADING = "SET_ROLE_LIST_LOADING",
    SET_ROLE_LIST_CONFIRM_DELETE = "SET_ROLE_LIST_CONFIRM_DELETE",
}

export interface ISetRoleList {
    type: RoleListActions.SET_ROLE_LIST,
    roles: IRole[]
}

export interface ISetRoleListLoading {
    type: RoleListActions.SET_ROLE_LIST_LOADING,
    loading: boolean
}

export interface ISetRoleListConfirmDelete{
    type: RoleListActions.SET_ROLE_LIST_CONFIRM_DELETE,
    confirmDelete: boolean
}

export type TRoleListAction = ISetRoleList | ISetRoleListLoading | ISetRoleListConfirmDelete;