import React, {useEffect, useState} from "react";
import MainLayout from "../main-layout";
import {useActions} from "../../../hooks/useActions";
import {matchPath, useLocation} from "react-router-dom";
import {authorizedRoutes, unauthorizedRoutes} from "../../../routes";
import Loading from "../../loading";

/**
 * It renders the appropriate layout based on the current layout state
 * @returns A React component
 */
const LayoutView = () => {
  const {checkAuth} = useActions();
  const location = useLocation();

  const [loading, setLoading] = useState<boolean>(true);
  const [firstLoading, setFirstLoading] = useState<boolean>(true);

  const isAuthPath = authorizedRoutes.some(route => matchPath(route?.path ?? "", location.pathname));
  const isNotAuthPath = unauthorizedRoutes.some(route => matchPath(route?.path ?? "", location.pathname));

  const getUser = async () => {
    setLoading(true);
    await checkAuth();
    setLoading(false);
    setFirstLoading(false);
  };

  useEffect(() => {
    getUser();
  }, []);

  if (firstLoading) {
    return (<Loading/>);
  }

  return <MainLayout loading={loading} isAuthPath={isAuthPath} isNotAuthPath={isNotAuthPath} />;
};

export default LayoutView;
