import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import {useTypedSelector} from "../../../hooks/useTypedSelector";
import {useActions} from "../../../hooks/useActions";
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
  Divider,
  Grid,
  List,
  ListItem,
  Skeleton, TextField,
  Typography
} from "@mui/material";
import Loading from "../../../components/loading";
import Error404 from "../../error-pages/error-404";
import ability from "../../../utils/can";
import {CardStatus, getCardStatusColor, getCardStatusName, ICardFormFields} from "../../../types/store/card";
import DialogConfirmation from "../../../components/common/dialog-confirmation";
import {useFormik} from "formik";
import {setFormErrors} from "../../../store/actions/card";
import StatusBox from "../../../components/status/status-box";
import {getDateTime} from "../../../utils/time";
import CardList from "./card-list";
import moment from "moment";

/** A React component that is used to display information about a card. And allow to update card status.*/
const CardInfo = () => {
  const {t} = useTranslation();
  const URLParams = useParams();
  const cardId = URLParams.id;
  if (!cardId) return CardList();
  const {isLoading, card, errorCode, formErrors} = useTypedSelector(state => state.card);
  const {fetchCardInfo, setCardNumber, updateCardStatus, clearCardInfo} = useActions();

  const [isOpenCancelRequestDialog, setIsOpenCancelRequestDialog] = useState(false);
  const [isOpenConfirmRequestDialog, setIsOpenConfirmRequestDialog] = useState(false);
  const [isOpenCardNumberDialog, setIsOpenCardNumberDialog] = useState(false);

  const formik = useFormik({
    initialValues: {
      cardNumber: "",
    } as ICardFormFields,

    validate: (values) => {
      const errors: Partial<ICardFormFields> = {};
      const cardNumber = validateCardNum(values.cardNumber);

      if (cardNumber) {
        errors.cardNumber = cardNumber;
      }

      return errors;
    },

    onSubmit: async (values) => {
      setCardNumber(cardId, values.cardNumber);
      handleCloseDialog();
    }
  });

  const validateCardNum = (cardNumber: string): string | undefined => {
    if (!cardNumber) {
      return t("validations.Field is required");
    } else if (formErrors?.cardNumber) {
      return t(`validations.${formErrors.cardNumber}`);
    }
  };

  const {
    values,
    errors,
    handleChange,
    touched,
    submitForm,
    handleBlur,
    validateForm,
  } = formik;

  const handleChangeValue = () => {
    setFormErrors({cardNumber: ""});
  };

  const handleUpdateCardStatus = (status: "cancel" | "confirm") => () => {
    updateCardStatus(cardId, status);
    handleCloseDialog();
  };

  const handleCloseDialog = () => {
    setIsOpenCardNumberDialog(false);
    setIsOpenConfirmRequestDialog(false);
    setIsOpenCancelRequestDialog(false);
  };

  useEffect(() => {
    clearCardInfo();
  }, []);

  useEffect(() => {
    if (ability.can("get", "card_info"))
      fetchCardInfo(cardId);
  }, [cardId]);

  useEffect((): () => void => {
    //Run validating one more time, because state updating is async
    let mounted = true;
    if (mounted) {
      validateForm();
    }
    return () => mounted = false;
  }, [formErrors]);

  if (ability.cannot("get", "card_info"))
    return <Error404/>;

  //Return information by presence of errors or loading status
  if (isLoading) {
    return <Loading/>;
  } else if (errorCode === 424) {
    return (
      <Container sx={{mt: 2}}>
        <Alert severity="error">
          <AlertTitle>{t("card.alert.error")}</AlertTitle>
          {t("card.alert.Card ID not found in partner API")}
        </Alert>
      </Container>
    );
  } else if (errorCode === 404) {
    return (<Error404/>);
  }

  const keysCardInfo = [
    {
      id: "id",
      label: t("card.details.id"),
      join: [],
      format: null,
    },
    {
      id: "user_id",
      label: t("card.details.userId"),
      join: [],
      format: null,
    },
    {
      id: "uuid",
      label: "UUID",
      join: [],
      format: null,
    },
    {
      id: "balance",
      label: t("card.details.balance"),
      join: ["currency"],
      format: null,
    },
    {
      id: "pan",
      label: t("card.details.cardNumber"),
      join: [],
      format: null,
    },
    {
      id: "cvc2",
      label: "CVC2",
      join: [],
      format: null,
    },
    {
      id: "processing_type",
      label: t("card.details.processingType"),
      join: [],
      format: null,
    },
    {
      id: "embossing",
      label: t("card.details.embossing"),
      join: [],
      format: null,
    },
    {
      id: "account_to_charge",
      label: t("card.details.emoneyAccountId"),
      join: [],
      format: null,
    },
    {
      id: "cardholder_phone",
      label: t("card.details.phoneNumber"),
      join: [],
      format: null,
    },
    {
      id: "status",
      label: t("card.details.status"),
      join: [],
      format: null,
    },
    {
      id: "expiry_date",
      label: t("card.details.expiryAt"),
      join: [],
      format: null,
    },
    {
      id: "created_at",
      label: t("card.details.createdAt"),
      join: [],
      format: (value: string)=>moment(value).format("DD.MM.YYYY HH:mm:ss"),
    },
    {
      id: "updated_at",
      label: t("card.details.updatedAt"),
      join: [],
      format: (value: string)=>moment(value).format("DD.MM.YYYY HH:mm:ss"),
    },
  ] as const;
  
  return (
    <Container>
      <Typography
        variant="h5"
        sx={{display: "flex", justifyContent: "center", mb: 2}}
      >
        {t("card.details.header")}
      </Typography>
      <Box>
        <Card sx={{mb: 2}}>
          <CardContent>
            <Card sx={{mt: 2}}>
              <CardHeader title={t("card.details.basic_info")}/>
              <List>
                {keysCardInfo.map((key, index)=>
                  <ListItem key={index}>
                    <Grid container spacing={2}>  
                      <Grid item xs={6} md={4}>
                        {key.label}
                      </Grid>
                      <Grid item xs={6} md={8}>
                        {[
                          key.format ? key.format(card[key.id]) : card[key.id], 
                          ...key.join.map(next=>card[next])
                        ].join(" ")}
                      </Grid>
                      <Grid item xs={12}>
                        <Divider/>
                      </Grid>
                    </Grid>
                  </ListItem>
                )}
              </List>
            </Card>
          </CardContent>
        </Card>
      </Box>
      <DialogConfirmation
        isOpen={isOpenCancelRequestDialog}
        onClose={handleCloseDialog}
        onConfirm={handleUpdateCardStatus("cancel")}
        title={t("card.details.Cancel card request")}
        message={t("card.details.Are you sure that you want cancel card request?")}
      />
      <DialogConfirmation
        isOpen={isOpenConfirmRequestDialog}
        onClose={handleCloseDialog}
        onConfirm={handleUpdateCardStatus("confirm")}
        title={t("card.details.Confirm card request")}
        message={t("card.details.Are you sure that you want to confirm card request?")}
      />
      <Dialog open={isOpenCardNumberDialog} onClose={handleCloseDialog}>
        <DialogTitle>{t("card.details.Set card number")}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t("card.details.Input card number")}
          </DialogContentText>
          <form>
            <TextField
              id="cardNumber"
              name="cardNumber"
              type="text"
              label={t("card.details.cardNumber")}
              error={touched.cardNumber && !!errors.cardNumber}
              helperText={touched.cardNumber && errors.cardNumber}
              sx={{my: 1, minHeight: 80, minWidth: {xs: "250px", sm: "350px", md: "500px"}}}
              fullWidth
              autoComplete='off'
              value={values.cardNumber}
              onChange={(e) => {
                handleChangeValue();
                handleChange(e);
              }}
              onBlur={handleBlur}
            />
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>
            {t("common.btn_cancel")}
          </Button>
          <Button onClick={submitForm}>
            {t("common.btn_confirm")}
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default CardInfo;