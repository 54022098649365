import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Card,
  CardContent,
  CardHeader,
  LinearProgress,
  Typography,
  useTheme
} from "@mui/material";
import React, {useEffect, useState} from "react";
import {useTypedSelector} from "../../../hooks/useTypedSelector";
import {useActions} from "../../../hooks/useActions";
import Loading from "../../loading";
import AccountDetailGridRow from "../account-detail-grid-row";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccountOndatoDocuments from "./account-ondato-documents";
import AccountOndatoPhotos from "./account-ondato-photos";
import {useTranslation} from "react-i18next";
import StatusBox from "../../status/status-box";
import {getDateTime} from "../../../utils/time";
import OndatoIcon from "../../../assets/img/ondato-icon.svg";
import {ONDATO_IDENTIFICATION_LINK, USE_SERVER} from "../../../constants/constants";
import Spacer from "../../common/spacer/spacer";
import {LoadingButton} from "@mui/lab";
import AccountService from "../../../sevices/account-service";
import AccountTestService from "../../../sevices/test/account-test-service";
import {toCapitalizedWords} from "../../../utils/common";

type TAccountOndatoSessionStatus = "NEW" | "AWAINTING" | "SENT_CALLBACK" | string

interface IAccountOndatoDetailsProps {
  updateAccountDetails?: () => void
}

export default function AccountOndatoDetails({updateAccountDetails}: IAccountOndatoDetailsProps) {
  const {ondatoDetails, account} = useTypedSelector(state => state.accountDetails);
  const {fetchAccountOndatoDetails, fetchAccountOndatoImages, handleGeneralError} = useActions();
  const [loading, setLoading] = useState(false);
  const [loadingUpdateButton, setLoadingUpdateButton] = useState<string[]>([]);
  const {t} = useTranslation();
  const theme = useTheme();

  const getImagesByOndataSession = async (ondatoSessionIdd: string) => {
    await fetchAccountOndatoImages(ondatoSessionIdd);
  };

  const getOndataDetails = async (accountId: string) => {
    setLoading(true);
    await fetchAccountOndatoDetails(accountId);
    setLoading(false);
  };

  const updateOndatoStatus = async (sessionId: string) => {
    setLoadingUpdateButton(prevState => [...prevState, sessionId]);
    try {
      const response = USE_SERVER
        ? await AccountService.accountInfoOndatoSessionRefreshStatus(+sessionId)
        : await AccountTestService.accountInfoOndatoSessionRefreshStatus(+sessionId);
      const { status } = response.data.result;
      if (status === "APPROVED" || status === "REJECTED") {
        if (updateAccountDetails) updateAccountDetails();
      }
    } catch (e) {
      await handleGeneralError(e);
    } finally {
      setLoadingUpdateButton(prevState => prevState.filter(item => item !== sessionId));
    }
  };

  // TODO Duplicate code, but not working if we use parse-json.tsx
  const formDataByType = (val: string | object | any, key: number | string): JSX.Element | undefined=> {
    if (typeof val === "string" || typeof val === "boolean") {
      return (
        <AccountDetailGridRow
          key={key as string}
          title={toCapitalizedWords(key as string)}
          value={String(val)}
        />
      );
    } else if (typeof val === "object" && val !== null && !Array.isArray(val)) {
      return (
        <>
          <Typography fontSize={20} my={1}>{key}</Typography>
          {Object.keys(val).map((key2, indexKey2) => (
            <React.Fragment key={indexKey2}>
              {formDataByType(val[key2], key2)}
            </React.Fragment>
          ))}
        </>
      );
    } else if (typeof val === "object" && val !== null && Array.isArray(val)) {
      return (
        <>
          {val.map((valItem, indexKey2) => (
            <React.Fragment key={indexKey2}>
              {formDataByType(valItem, indexKey2)}
            </React.Fragment>
          ))}
        </>
      );
    }
  };
  const parseObjectData = (json: string | undefined) => {
    if (!(json)) return;
    try {
      const obj = JSON.parse(json);
      return (
        <>
          {(Object.keys(obj as Record<string, any>)
            .map((key, indexKey) => (
              <React.Fragment key={indexKey}>
                {formDataByType(obj[key], key)}
              </React.Fragment>
            )))}
        </>
      );
    } catch (e) {
      return;
    }
  };

  const getOndatoSessionStatusColor = (status: TAccountOndatoSessionStatus) => {
    switch (status) {
    case "NEW":
      return theme.palette.primary.main;
    case "AWAITING":
      return theme.palette.warning.main;
    case "APPROVED":
      return theme.palette.success.main;
    case "REJECTED":
      return theme.palette.error.main;
    default:
      return theme.palette.info.main;
    }
  };

  useEffect(() => {
    if (!account?.id) return;
	  getOndataDetails(account.id);
  }, [account.id]);

  if (loading) {
    return <Loading/>;
  }

  return (
	  <Card sx={{ mb: 2 }}>
		  <CardHeader title={"Verification history"}/>
		  <CardContent sx={{maxHeight: "360px", overflowY: "auto", mb: 4}}>
        {ondatoDetails.length === 0 && (
          <Typography>
            {t("account.details.The user did not try to pass verification")}
          </Typography>
        )}
			  {ondatoDetails.map((ondatoItem, ondatoIndex) => (
          <Accordion
            key={ondatoIndex}
            onChange={(e,expanded) => {
              if(expanded && (!ondatoItem.docImages || !ondatoItem.photoImages)){
                getImagesByOndataSession(ondatoItem.id);
              }
            }}
            sx={{
              borderTop: theme => "5px solid " + theme.palette.primary.main,
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Box sx={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                gap: 2
              }}>
                <Typography>Ondato session ID - {ondatoItem.id}</Typography>
                <Spacer/>
                {!!ondatoItem.kycId && (
                  <LoadingButton
                    color={"info"}
                    variant={"outlined"}
                    sx={{
                      p: 1,
                    }}
                    loading={loadingUpdateButton.includes(ondatoItem.id)}
                    onClick={(e) => {
                      updateOndatoStatus(ondatoItem.id);
                    }}
                  >
                    Update status
                  </LoadingButton>
                )}
                <StatusBox label={ondatoItem.status} color={getOndatoSessionStatusColor(ondatoItem.status)}/>
                {!!ondatoItem.kycId && (
                  <Box
                    component={"a"}
                    href={ONDATO_IDENTIFICATION_LINK + ondatoItem.ondatoId}
                    target={"_blank"}
                    sx={{
                      textDecoration: "none"
                    }}>
                    <Box
                      component={"img"}
                      src={OndatoIcon}
                    />
                  </Box>
                )}
              </Box>

            </AccordionSummary>
            <AccordionDetails>
              <Box>
                <AccountDetailGridRow title={t("account.details.createdAt")} value={getDateTime(ondatoItem.createdAt)}/>
                <AccountDetailGridRow title={t("account.details.updatedAt")} value={getDateTime(ondatoItem.updatedAt)}/>
              </Box>
              <Box>
                {ondatoItem.recognizeData && parseObjectData(ondatoItem.recognizeData)}
              </Box>
              {ondatoItem?.isLoadingImages && (
                <Box sx={{ width: "100%" }}>
                  <LinearProgress />
                </Box>
              )}
              {!ondatoItem?.isLoadingImages && (
                <>
                  {ondatoItem.docImages && ondatoItem?.docImages?.length > 0 && (
                    <AccountOndatoDocuments documents={ondatoItem.docImages}/>
                  )}
                  {ondatoItem.photoImages && ondatoItem?.photoImages?.length > 0 && (
                    <AccountOndatoPhotos cardHeader={t("account.details.selfieImage")} photos={ondatoItem.photoImages}/>
                  )}
                </>
              )}
            </AccordionDetails>
          </Accordion>
			  ))}
		  </CardContent>
	  </Card>
  );
}