import {IRole} from "../../../types/store/role";
import {
  ISetRoleList, ISetRoleListConfirmDelete,
  ISetRoleListLoading,
  RoleListActions
} from "../../../types/store/role/role-list";
import {Action} from "redux";
import RoleService from "../../../sevices/role-service";
import {AxiosError} from "axios";
import {ThunkDispatch} from "redux-thunk";
import {USE_SERVER} from "../../../constants/constants";
import RoleTestService from "../../../sevices/test/role-test-service";
import {IGeneralResponse} from "../../../types/service/response/general-response";
import {handleGeneralError} from "../error-handler";
import {store} from "../../index";
import i18n from "i18next";
import {notification} from "../../../utils/notification";

/**
 * It returns an object with a type property and a roles property
 * @param {IRole[]} roles - IRole[] - this is the array of roles that will be set in the store.
 */
export const setRoleList = (roles: IRole[]): ISetRoleList => ({
  type: RoleListActions.SET_ROLE_LIST,
  roles
});

/**
 * It returns an object with a type property and a loading property
 * @param {boolean} loading - boolean
 */
export const setRoleListLoading = (loading: boolean): ISetRoleListLoading => ({
  type: RoleListActions.SET_ROLE_LIST_LOADING,
  loading
});

/**
 * It returns an object with a type property and a confirmDelete property
 * @param {boolean} confirmDelete - boolean - This is the value that will be set to the confirmDelete property in the
 * state.
 */
export const setRoleListConfirmDelete = (confirmDelete: boolean): ISetRoleListConfirmDelete => ({
  type: RoleListActions.SET_ROLE_LIST_CONFIRM_DELETE,
  confirmDelete
});

/**
 * It fetches a list of roles from the server and dispatches the result to the reducer
 */
export const fetchRoleList = () => async (dispatch: ThunkDispatch<unknown, unknown, Action>) => {
  try {
    dispatch(setRoleListLoading(true));
    const response = USE_SERVER
      ? await RoleService.roleList()
      : await RoleTestService.roleList();

    const {result} = response.data;
    dispatch(setRoleList(result));
  } catch (e: unknown){
    await dispatch(handleGeneralError(e));
  } finally {
    dispatch(setRoleListLoading(false));
  }
};

/**
 * It fetches the role list, and if the user confirms the delete, it deletes the role
 * @param {number} id - number - the id of the role to delete
 */
export const deleteRole = (id: number) => async (dispatch: ThunkDispatch<unknown, unknown, Action>) => {
  const confirmDelete = store.getState().roleList.confirmDelete;
  try {
    USE_SERVER
      ? await RoleService.deleteRole(id, confirmDelete)
      : await RoleTestService.deleteRole(id, confirmDelete);

    dispatch(setRoleListConfirmDelete(false));
    notification.showWarningMessage(i18n.t("Role id successfully deleted!", {id}));
  } catch (e: unknown){
    await dispatch(handleGeneralError(e));
    const {response} = e as AxiosError<IGeneralResponse<boolean>>;
    if (response?.status === 422 && response?.data.errors?.[0] === "role is used"){
      dispatch(setRoleListConfirmDelete(true));
    } else {
      dispatch(setRoleListConfirmDelete(false));
    }
  } finally {
    await dispatch(fetchRoleList());
  }
};