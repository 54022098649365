import React from "react";
import {
  Card,
  CardHeader,
  CardContent,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableBody,
  TableFooter,
  TablePagination,
  TableRow,
  TableCell,
  Skeleton,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { HeadCell } from "../../types/table";
import { IAccount } from "../../types/store/account";
import { ITransaction } from "../../types/store/transaction";
import {IPermission} from "../../types/store/permissions";

export type TTableItem = IAccount | ITransaction | IPermission;
export type THeadCells = HeadCell<TTableItem>[];

const pageChange = function (): void {
  //TODO: implement pageChange for table with list
  throw new Error("Function pageChange not implemented.");
};

const perPageChange = function (): void {
  //TODO: implement perPageChange for table with list
  throw new Error("Function perPageChange not implemented.");
};

const clickRow = function (): void {

  console.debug("Function clickRow not implemented.");
};

interface Props<T> {
  title?: string;
  headCells: HeadCell<T>[];
  list: T[];
  itemsPerPage?: number;
  totalItems?: number;
  currentPage?: number;
  handlePageChange?: () => void;
  handlePerPageChange?: () => void;
  handleClickRow?: (item: T) => void;
  isLoading?: boolean;
  isPaginated?: boolean;
}

/**
 * The general React component to generate simple tables.
 *
 * A generic function that takes a generic type T that extends TTableItem. */
function TableWithList<T extends TTableItem> ({
  title = "",
  headCells,
  list,
  itemsPerPage = 5,
  totalItems = 5,
  currentPage = 0,
  handlePageChange = pageChange,
  handlePerPageChange = perPageChange,
  handleClickRow = clickRow,
  isLoading = false,
  isPaginated = false,
}: Props<T>): JSX.Element {
  const { t } = useTranslation();

  const getValue = (obj: TTableItem, keys: string[]): string => {
    const [nextKey, ...rest] = keys;
    const nextObject = obj[nextKey as keyof TTableItem];
    return nextObject !== null && typeof nextObject === "object"
      ? getValue(nextObject as TTableItem, rest)
      : nextObject as unknown as string;
  };

  return (
    <Card>
      <CardHeader title={t(title)} />
      <CardContent>
        <TableContainer component={Paper}>
          <Table
            sx={{ minWidth: 650 }}
            aria-label="AML list table"
            size="medium"
          >
            <TableHead>
              <TableRow sx={{ "& .MuiTableCell-head": { fontWeight: 600 } }}>
                {headCells.map(({ label }, index) => (
                  <TableCell key={index}>{label}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading &&
                Array(itemsPerPage)
                  .fill("")
                  .map((row, index) => (
                    <TableRow
                      key={index}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      {headCells.map((row, index) => (
                        <TableCell key={index}>
                          <Skeleton height={30} />
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
              {!isLoading &&
                list.map((item) => {
                  return (
                    <TableRow
                      key={item.id}
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 }, cursor: "pointer" }}
                      onClick={ () => handleClickRow(item) }
                    >
                      {headCells.map(
                        ({ id, handleColumn }) => {
                          const keys = (id as string).split(".");
                          const value = getValue(item, keys);

                          return (
                            <TableCell key={String(id) + item.id}>
                              {handleColumn && typeof handleColumn === "function"
                                ? handleColumn(value)
                                : value}
                            </TableCell>
                          );
                        }
                      )}
                    </TableRow>
                  );
                })}
            </TableBody>
            <TableFooter>
              <TableRow>
                {isPaginated && (
                  <TablePagination
                    count={totalItems}
                    page={currentPage}
                    onPageChange={handlePageChange}
                    onRowsPerPageChange={handlePerPageChange}
                    labelRowsPerPage={t("common.rows_per_page")}
                    rowsPerPage={itemsPerPage}
                    rowsPerPageOptions={[5, 10, 20]}
                  />
                )}
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  );
}

export default TableWithList;
