import {IRequestResponseType} from "../../types/service/test/test-response";
// import {ICardListResponse, ICardResponse} from "../../types/service/response/card-response";
import {CardStatus, ICard, TCardStatus} from "../../types/store/card";
import {ICardListRequestParams} from "../../types/service/request/card-request";
import {delay, getRandomInt} from "../../utils/common";
import {IGeneralResponse} from "../../types/service/response/general-response";
import { CardsResponse, CardsResult } from "../card-service";
import { AxiosResponse } from "axios";

/** It's a mock for the CardService class */
export default class CardTestService {
  static async cardInfo(id: string, status?: TCardStatus): Promise<IRequestResponseType<unknown, IGeneralResponse<ICard>>> {
    await delay(600);
    return {
      headers: undefined,
      data: {
        result: { 
          "id": id,
          "user_id": "45454560967",
          "uuid":"46f592c9-235a-4aee-8a39-eeaf9a942712",
          "order_uuid":"string",
          "balance":"0",
          "currency":"EUR",
          "pan":"5871451313362524",
          "cvc2":"264",
          "expiry_date":null,
          "account_to_charge":"410045860612",
          "processing_type":"mastercard",
          "embossing":"",
          "cardholder_phone":"+421940824657",
          "status":0,
          "created_at":"2022-11-14T17:06:30.000Z",
          "updated_at":"2022-11-14T17:06:30.000Z"
        },
      }
    };
  }

  static async cardList({
    page, per_page
  }: ICardListRequestParams): Promise<AxiosResponse<CardsResponse>> {
    await delay(600);
    const statuses: TCardStatus[] = [
      CardStatus.CARD_ASSIGNED,
      CardStatus.CARD_SENT,
      CardStatus.NEW_REQUEST,
      CardStatus.REQUEST_CANCELED
    ];
    const cards: ICard[] = [...Array(per_page)].map(() => {
      return {
        id: getRandomInt(1, 99999).toString(),
        user_id: getRandomInt(1, 999999).toString(),
        uuid: "46f592c9-235a-4aee-8a39-eeaf9a942712",
        order_uuid: "46f592c9-235a-4aee-8a39-eeaf9a942712",
        balance: getRandomInt(1, 99999).toString(),
        currency: "EUR",
        pan: "1234 5678 8901 2345",
        cvc2: "098",
        account_to_charge: "3456782367",
        processing_type: "MASTER CARD",
        embossing: "Jhon Doe",
        cardholder_phone: "+380987654321",
        status: 0,
        expiry_date: "2022-03-16 16:51:33.249278",
        created_at: "2022-03-16 16:51:33.249278",
        updated_at: "2022-03-16 16:51:33.249278",
      };
    });

    return {
      status: 200,
      statusText: "Ok",
      headers: {"Content-Type": "application/json"},
      config: {},
      data: {
        result: {
          page,
          per_page,
          total_pages: 3,
          list: cards
        }
      }
    };
  }
}