import React from "react";
import {toast} from "react-toastify";
import {theme} from "../theme";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import WarningIcon from "@mui/icons-material/Warning";

export const notification = {
  showSuccessMessage: (message: string) => {
    toast.success(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      progressStyle: {
        backgroundColor: theme.palette.success.main
      },
      icon: () => (<CheckCircleIcon sx={{color: theme.palette.success.main}}/>)
    });
  },
  showErrorMessage: (message: string) => {
    toast.error(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      progressStyle: {
        backgroundColor: theme.palette.error.main
      },
      icon: () => (<ErrorIcon sx={{color: theme.palette.error.main}}/>)
    });
  },
  showWarningMessage: (message: string) => {
    toast.warning(message, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      progressStyle: {
        backgroundColor: theme.palette.warning.main
      },
      icon: () => (<WarningIcon sx={{color: theme.palette.warning.main}}/>)
    });
  }
};