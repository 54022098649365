import React, {useEffect} from "react";
import {Container, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import {useTypedSelector} from "../../../hooks/useTypedSelector";
import {useActions} from "../../../hooks/useActions";
import {useParams} from "react-router-dom";
import Loading from "../../../components/loading";
import UserForm from "../../../components/user/user-form";
import AlertMessage from "../../../components/common/alert-message";
import Error404 from "../../error-pages/error-404";
import ability from "../../../utils/can";
import { Can } from "@casl/react";

/**
 * A React component that is used for show user info and allow to edit it.
 */
const UserEdit = () => {
  const {t} = useTranslation();
  const userId = useParams()?.id;
  const {isLoading} = useTypedSelector(state => state.userInfo);
  const {fetchUserInfo, clearUserInfo} = useActions();

  useEffect(() => {
    if (userId !== undefined && ability.can("get", "user")) {
      fetchUserInfo(userId);
    }
  },[]);

  useEffect(() => {
    if (userId && +userId > 0 && ability.can("get", "user")) {
      fetchUserInfo(userId);
    }
    return () => {
      clearUserInfo();
    };
  }, []);

  if (ability.cannot("get", "user"))
    return <Error404 />;

  if (!userId || +userId <= 0){
    return (
      <AlertMessage severity={"error"} title={"Error"} message={"User ID is not correct."}/>
    );
  }

  if (isLoading){
    return <Loading/>;
  }

  return (
    <Can I="get" a="user" ability={ability}>
      <Container>
        <Typography
          variant="h5"
          sx={{ display: "flex", justifyContent: "center", mb: 4 }}
        >
          {t("user.info.header")}
        </Typography>
        <UserForm type={"edit"} userId={+userId} />
      </Container>
    </Can>
  );
};

export default UserEdit;