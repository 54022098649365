import React, { Fragment } from "react";
import { useActions } from "../../../hooks/useActions";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import TreeView from "@mui/lab/TreeView";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import TreeItem from "@mui/lab/TreeItem";
import { parseObjectData } from "../../../utils/parse-json";
import { useTypedSelector } from "../../../hooks/useTypedSelector";
import AccountDetailGridRow from "../account-detail-grid-row";
import { toCapitalizedWords } from "../../../utils/common";
import { IAccountDetailsIndividual } from "../../../types/store/account/account-details";
import { getDateTime } from "../../../utils/time";
import yn from "yn";

export function AccountIndividualDetails() {
  const { fetchAccountDetailsIndividual } = useActions();
  const URLParams = useParams();
  const userAccountId = URLParams.id || "";
  const { individualDetails } = useTypedSelector(
    (state) => state.accountDetails
  );

  useEffect(() => {
    if (!userAccountId) return;

    fetchAccountDetailsIndividual(userAccountId);
  }, []);

  const keysToSkip: Array<keyof IAccountDetailsIndividual> = [
    "termsAndConditionsArbitas",
    "termsAndConditionsSatchel",
    "privacyPolicyArbitas",
    "privacyPolicySatchel",
  ];

  const booleanFields: Array<keyof IAccountDetailsIndividual> = [
    "dualCitizenship",
    "privacyPolicy",
    "usePersonalData",
  ];

  const dateFields: Array<keyof IAccountDetailsIndividual> = [
    "createdAt",
    "updatedAt",
    "dateOfBirthday",
  ];

  return (
    <TreeView
      defaultCollapseIcon={<ExpandMoreIcon />}
      defaultExpandIcon={<ChevronRightIcon />}
    >
      <TreeItem nodeId="1" label={"Account info individual"}>
        {individualDetails &&
          (
            Object.keys(individualDetails) as Array<
              keyof IAccountDetailsIndividual
            >
          ).map((item) => {
            if (keysToSkip.includes(item)) return;

            if (item === "termsAndConditions") {
              return (
                <Fragment key={item}>
                  {parseObjectData(individualDetails[item], getDateTime)}
                </Fragment>
              );
            }

            if (dateFields.includes(item)) {
              return (
                <AccountDetailGridRow
                  key={item}
                  title={toCapitalizedWords(item)}
                  value={getDateTime(individualDetails[item])}
                />
              );
            }

            if (item === "pep") {
              return (
                <AccountDetailGridRow
                  key={item}
                  title={toCapitalizedWords(item)}
                  value={
                    yn(individualDetails[item]) ? "is a PEP" : "is not a PEP"
                  }
                />
              );
            }

            if (item === "usCitizenship") {
              return (
                <AccountDetailGridRow
                  key={item}
                  title={toCapitalizedWords(item)}
                  value={
                    yn(individualDetails[item])
                      ? "is a not US citizen"
                      : "is a US citizen"
                  }
                />
              );
            }

            if (booleanFields.includes(item)) {
              return (
                <AccountDetailGridRow
                  key={item}
                  title={toCapitalizedWords(item)}
                  value={yn(individualDetails[item]) ? "yes" : "no"}
                />
              );
            }

            if (
              typeof individualDetails[item] === "string" ||
              typeof individualDetails[item] === "boolean" ||
              typeof individualDetails[item] === "number"
            ) {
              return (
                <AccountDetailGridRow
                  key={item}
                  title={toCapitalizedWords(item.toString())}
                  value={String(individualDetails[item])}
                />
              );
            }

            if (individualDetails[item] === null) {
              return (
                <AccountDetailGridRow
                  key={item}
                  title={toCapitalizedWords(item.toString())}
                  value={"-"}
                />
              );
            }
          })}
      </TreeItem>
    </TreeView>
  );
}
