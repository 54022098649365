import React, {FC, useRef, useState} from "react";
import {useTypedSelector} from "../../../hooks/useTypedSelector";
import {NavMenuProps} from "../../../types/components/menu";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import Avatar from "@mui/material/Avatar";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import {ClickAwayListener, Divider, Grow, MenuList, Paper, Popper} from "@mui/material";
import Box from "@mui/material/Box";

/**
 * A React Component that allow to switch some user settings.
 */
const UserSettings: FC<NavMenuProps> = ({pages: settings}) => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);
  const {user} = useTypedSelector(state => state.auth);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (
      anchorRef.current &&
            anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current && !open) {
      anchorRef.current?.focus();
    }

    prevOpen.current = open;
  }, [open]);

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === "Escape") {
      setOpen(false);
    }
  }

  return (
    <Box sx={{flexGrow: 0}}>
      <Tooltip title="Open settings">
        <IconButton ref={anchorRef} onClick={handleToggle} sx={{p: 0}}>
          <Avatar alt={user.email ? user.email?.toUpperCase() : ""} src="/broken-image.jpg"/>
        </IconButton>
      </Tooltip>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        placement="bottom-end"
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                                    placement === "bottom-end" ? "right top" : "right bottom",
            }}
          >
            <Paper sx={{mt: ".2rem"}}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id="composition-menu"
                  aria-labelledby="composition-button"
                  onKeyDown={handleListKeyDown}
                >
                  {settings.map(setting => {
                    const item = [
                      <MenuItem
                        key={setting.name}
                        onClick={(e) => {
                          setting.action();
                          handleClose(e);
                        }}
                      >
                        <Typography textAlign="center">{setting.name}</Typography>
                      </MenuItem>
                    ];
                    if (setting.divider) item.push(<Divider/>);
                    return item;
                  })}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )
        }
      </Popper>
    </Box>
  );
};

export default UserSettings;