import {IRequestResponseType} from "../../types/service/test/test-response";
import {UserInfoResponse, UserListResponse} from "../../types/service/response/user-response";
import {delay} from "../../utils/common";
import {IUserDataToUpdate} from "../../types/store/user";
import {IUserListRequestParams} from "../../types/service/request/users-request";

/** It's a mock for the class UserTestService. */
export default class UserTestService {
  static async userList(params: IUserListRequestParams): Promise<IRequestResponseType<undefined, UserListResponse>> {
    await delay(600);
    return {
      headers: undefined,
      data: {
        result: true,
        users: [
          {
            "id": 2,
            "name": "admin",
            "email": "admin@absaml.com",
            "email_verified_at": null,
            "password": "yacfbntldfzyrs//5169d3bd95c09d6c4a7093cc4fdd70df8 ...",
            "createdAt": "2022-04-22T13:25:38.962Z",
            "updatedAt": "2022-04-22T13:27:25.092Z",
            "role": {
              "id": 2,
              "name": "admin",
              "createdAt": "2022-04-22T11:57:06.065Z",
              "updatedAt": "2022-04-22T13:18:48.290Z"
            },
            isBlocked: false
          }
        ]
      }
    };
  }

  static async userInfo(id: number | string): Promise<IRequestResponseType<undefined, UserInfoResponse>> {
    await delay(600);
    return {
      headers: undefined,
      data: {
        result: true,
        user: {
          "id": +id,
          "name": "admin",
          "email": "admin@absaml.com",
          "email_verified_at": null,
          "password": "yacfbntldfzyrs//5169d3bd95c09d6c4a7093cc4fdd70df8 ...",
          "createdAt": "2022-04-22T13:25:38.962Z",
          "updatedAt": "2022-04-22T13:27:25.092Z",
          "role": {
            "id": 2,
            "name": "admin",
            "createdAt": "2022-04-22T11:57:06.065Z",
            "updatedAt": "2022-04-22T13:18:48.290Z"
          },
          isBlocked: false
        }
      }
    };
  }
    
  static async updateUser(id: number, data: IUserDataToUpdate): Promise<IRequestResponseType<undefined, UserInfoResponse>> {
    await delay(600);
    return {
      headers: undefined,
      data: {
        result: true,
        user: {
          id: id,
          name: "initial-admin",
          email: "initial-admin@email.com",
          password: "",
          role: {
            name: "admin",
            id: 1
          },
          isBlocked: false
        }
      }
    };
  }

  static async updateUserBlockStatus(id: number, isBlocked: boolean): Promise<IRequestResponseType<undefined, UserInfoResponse>> {
    await delay(600);
    return {
      headers: undefined,
      data: {
        result: true,
        user: {
          id: id,
          name: "initial-admin",
          email: "initial-admin@email.com",
          password: "",
          role: {
            name: "admin",
            id: 1
          },
          isBlocked: isBlocked
        }
      }
    };
  }

  static async createUser(data: IUserDataToUpdate): Promise<IRequestResponseType<undefined, UserInfoResponse>> {
    await delay(600);
    return {
      headers: undefined,
      data: {
        result: true,
        user: {
          id: 1,
          name: "initial-admin",
          email: "initial-admin@email.com",
          password: "",
          role: {
            name: "admin",
            id: 1
          },
          isBlocked: false
        }
      }
    };
  }
}