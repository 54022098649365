import { AccountStatusEnum } from "../../../components/account/account-statuses";

export interface IAccountDetailsState {
  account: IAccountDetail;
  individualDetails: IAccountDetailsIndividual;
  corporateDetails: IAccountDetailsCorporate;
  ondatoDetails: IAccountOndatoDetail[];
  signedContracts: IAccountSignedContract[];
  identityVerification: IAccountIdentityVerificationInfo;
  documents: IAccountDocument[];
  photos: IAccountPhoto[];
  wallets: IAccountWallet[];
  satchelWallets: IAccountSatchelWalletsAllTypes;
  isLoading: boolean;
  errorCode?: number;
  errorMessage?: string;
}

export type TStatusOndata =
  | "PROCESSED"
  | "CROSS_CHECKED"
  | "UPDATED"
  | "REJECTED"
  | null;

export interface IAccountDetail {
  id: string;
  createdAt: string;
  updatedAt: string;
  satchelIdIndividual: null | string | number;
  satchelIdCorporate: null | string | number;
  satchelIsIndividual: null | string | number;
  satchelIsCorporate: null | string | number;
  keycloackId: string;
  status: number;
  statusAml: number;
  statusSatchelIndividual: number;
  statusSatchelCorporate: number;
  statusOndata: TStatusOndata;
  metadata?: {
    finish_time: string;
    ip: string;
    start_time: string;
    user_agent: string;
    privacyPolicyArbitas: string;
    privacyPolicySatchel: string;
    termsAndConditionsArbitas: string;
    termsAndConditionsSatchel: string;
  };
  emailAddress: string;
  phoneNumber: string;
}

export interface IAccountDetailsIndividual {
  id: string;
  createdAt: string;
  updatedAt: string;
  userId: number;
  firstName: string;
  lastName: string;
  personalNumber: string;
  dateOfBirthday: string;
  nationality: string;
  addressCountryCode: string;
  addressCity: string;
  addressStreet: string;
  addressZipCode: string;
  phoneNumber: string;
  emailAddress: string;
  birthPlace: string;
  taxCountry: string;
  employment: string;
  sourceInitialFunding: string;
  pep: string;
  usCitizenship: string;
  termsAndConditions: string; // JSON
  privacyPolicy: string;
  usePersonalData: string;
  selfieImage: string;
  privacyPolicyArbitas: string;
  privacyPolicySatchel: string;
  termsAndConditionsArbitas: string;
  termsAndConditionsSatchel: string;
  taxIdentificationNumber: string;
  dualCitizenship: number;
  primarySourceIncome: string;
  countriesToWorkIncome: string;
  countriesToWorkOutcome: string;
  monthlyFrequencyOfPaymentsIncome: string;
  monthlyFrequencyOfPaymentsOutcome: string;
  monthlyTurnoverIncome: string;
  monthlyTurnoverOutcome: string;
  purposeOfAccount: string;
}

export interface IAccountDetailsCorporate {
  id: string;
  userId: number;
  name: string;
  dateOfRegistrations: string;
  registrationNumber: string;
  addressCountryCode: string;
  addressCity: string;
  addressStreet: string;
  addressZipCode: string;
  taxIdentificationNumber: string;
  businessCategories: string; // JSON array
  website: string | null;
  planedMonthlyTurnoverIncoming: string;
  planedMonthlyTurnoverOutgoing: string;
  termsAndConditionsArbitas: string;
  privacyPolicyArbitas: string;
  termsAndConditionsSatchel: string;
  privacyPolicySatchel: string;
  personalData: string;
  emailAddress: string;
  phoneNumber: string;
  createdAt: string;
  updatedAt: string;
  details: {
    companies: IAccountCorporateDetailsCompany[];
    persons: IAccountCorporateDetailsPerson[];
  };
}

export interface IAccountCorporateDetailsCompany {
  id: string;
  createdAt: string;
  updatedAt: string;
  userId: string;
  type: string;
  name: string;
  registrationNumber: string;
  countryOfIncorporation: string | null;
  addressStreet: string | null;
  addressCity: string | null;
  addressCountryCode: string | null;
  addressZipCode: string | null;
  countryRegion: string | null;
  website: string | null;
  phoneNumber: string | null;
  email: string | null;
  documents: IAccountCorporateDocumentCompany[];
}

export interface IAccountCorporateDetailsPerson {
  id: string;
  createdAt: string;
  updatedAt: string;
  userId: string;
  type: string;
  firstName: string;
  lastName: string;
  dateOfBirthday: string;
  nationality: string;
  personal_number: string;
  address_street: string | null;
  address_city: string | null;
  address_country_code: string | null;
  address_zip_code: string | null;
  job_title: string | null;
  phone_number: string | null;
  email: string | null;
  pep: string | null;
  documents: IAccountCorporateDocumentPerson[];
}

export interface IAccountOndatoDetail {
  id: string;
  createdAt: string;
  updatedAt: string;
  userId: number;
  ondatoId: string | null;
  kycId: string | null;
  recognizeData: string;
  status: string;
  statusReason: string | null;
  docImages?: IAccountOndatoImageData[];
  photoImages?: IAccountOndatoImageData[];
  isLoadingImages?: boolean;
}

export interface IAccountOndatoImageData {
  id: string;
  createdAt: string;
  updatedAt: string;
  s3Id: string;
  s3Link: string;
  contentType: string;
  sessionId: number;
  side?: string;
  type?: string;
}

export interface IAccountDocument {
  id: string;
  createdAt: string;
  updatedAt: string;
  userId: string;
  type: string; // TODO types
  number: string;
  citizenCountry: string; //TODO TCountry
  issueCountry: string; //TODO TCountry
  issuing: string;
  issueDate: string;
  expiryDate: string;
  images: IAccountDocumentImage[];
}

export interface IAccountPhoto {
  id: string;
  createdAt: string;
  updatedAt: string;
  userId: string;
  s3Id: string;
  s3Link: string;
  contentType: string;
}

export interface IAccountCorporateDocumentCompany {
  id: string;
  createdAt: string;
  updatedAt: string;
  companyId: string;
  type: string;
  images: IAccountCorporateDocumentImage[];
}

export interface IAccountCorporateDocumentPerson {
  id: string;
  createdAt: string;
  updatedAt: string;
  personId: string;
  type: string;
  number: string;
  citizenCountry: string;
  issueCountry: string;
  issuing: string;
  issueDate: string;
  expiryDate: string;
  images: IAccountCorporateDocumentImage[];
}

export interface IAccountDocumentImage {
  id: string;
  createdAt: string;
  updatedAt: string;
  s3Id: string;
  s3Link: string;
  documentId: string;
  contentType: string | null;
}

export interface IAccountCorporateDocumentImage {
  id: number;
  s3_id: string;
  document_id: number | string;
  content_type: string | null;
  created_at: string;
  updated_at: string;
  s3_link: string;
}

export interface IAccountWallet {
  id: string;
  userId: string;
  accountName: string;
  accountNumber: string;
  currentBalance: string;
  availableBalance: string;
  currencyCode: string;
  poolId: string;
  iban: string;
  createdAt: string;
  updatedAt: string;
}

export interface IAccountSatchelWallet {
  id: string;
  iban: string;
  createdAt: string;
  updatedAt: string;
  currencyCode: string;
  userId: string;
  accountNumber: string;
  accountName: string;
  availableBalance: string;
  currentBalance: string;
  poolId: string;
  type: string;
}

export interface IAccountSatchelWalletsAllTypes {
  individual?: {
    satchelId: string;
    list: IAccountSatchelWallet[];
  };
  corporate?: {
    satchelId: string;
    list: IAccountSatchelWallet[];
  };
}

export interface IAccountDocumentData {
  id: string;
  metadata: string;
  url: string;
}

export interface IAccountSignedContract {
  id: number;
  userId: number;
  s3Id: string;
  s3Link: string;
  contractVersion: string;
  contentType: string; //"application/pdf"
  created_at: string; //datetime
  updated_at: string; //datetime
}

export interface IAccountIdentityVerificationInfo {
  satchel: {
    id: string;
    createdAt: string;
    updatedAt: string;
    userId: number;
    firstName: string;
    lastName: string;
    personalNumber: string;
    dateOfBirthday: string;
    nationality: string;
    addressCountryCode: string;
    addressCity: string;
    addressStreet: string;
    addressZipCode: string;
    birthPlace: string;
    taxCountry: string;
    employment: string;
    sourceInitialFunding: string;
    pep: string;
    usCitizenship: string;
    termsAndConditions: string;
    privacyPolicy: string;
    usePersonalData: string;
    selfieImage: string;
    privacyPolicyArbitas: string;
    privacyPolicySatchel: string;
    termsAndConditionsArbitas: string;
    termsAndConditionsSatchel: string;
  };
  recognized: {
    id: string;
    createdAt: string;
    updatedAt: string;
    userId: number;
    sessionId: number;
    firstName: string;
    lastName: string;
    middleName: string;
    documentNumber: string;
    issueDate: string;
    expiryDate: string;
    dateOfBirthday: string;
    personalNumber: string;
    address: string;
    nationality: string;
    countryCode: string;
    gender: string;
    docType: string;
    mrzVerified: number;
    category: string;
    stateCode: string;
  };
}

export enum AccountDetailsActions {
  SET_ACCOUNT_DETAILS = "SET_ACCOUNT_DETAILS",
  CLEAR_ACCOUNT_DETAILS = "CLEAR_ACCOUNT_DETAILS",
  SET_ACCOUNT_DETAILS_LOADING = "SET_ACCOUNT_DETAILS_LOADING",
  SET_ACCOUNT_DETAILS_ERROR = "SET_ACCOUNT_DETAILS_ERROR",
  SET_ACCOUNT_DETAILS_INDIVIDUAL = "SET_ACCOUNT_DETAILS_INDIVIDUAL",
  SET_ACCOUNT_DETAILS_CORPORATE = "SET_ACCOUNT_DETAILS_CORPORATE",
  SET_ACCOUNT_DOCUMENTS = "SET_ACCOUNT_DOCUMENTS",
  SET_ACCOUNT_PHOTOS = "SET_ACCOUNT_PHOTOS",
  SET_ACCOUNT_IDENTITY_VERIFICATION_INFO = "SET_ACCOUNT_IDENTITY_VERIFICATION_INFO",
  SET_ACCOUNT_WALLETS = "SET_ACCOUNT_WALLETS",
  SET_ACCOUNT_SATCHEL_WALLETS = "SET_ACCOUNT_SATCHEL_WALLETS",
  SET_ACCOUNT_SATCHEL_STATUS = "SET_ACCOUNT_SATCHEL_STATUS",
  SET_ACCOUNT_ARBIPAY_STATUS = "SET_ACCOUNT_ARBIPAY_STATUS",
  SET_ACCOUNT_ONDATA_STATUS = "SET_ACCOUNT_ONDATA_STATUS",
  SET_ACCOUNT_ONDATO_DETAILS = "SET_ACCOUNT_ONDATO_DETAILS",
  SET_ACCOUNT_ONDATO_IMAGES = "SET_ACCOUNT_ONDATO_IMAGES",
  SET_ACCOUNT_SIGNED_CONTRACTS = "SET_ACCOUNT_SIGNED_CONTRACTS",
}

export interface ISetAccountDetails {
  type: AccountDetailsActions.SET_ACCOUNT_DETAILS;
  account: IAccountDetail;
}

export interface ISetAccountDetailsLoading {
  type: AccountDetailsActions.SET_ACCOUNT_DETAILS_LOADING;
  loading: boolean;
}

export interface ISetAccountDetailsError {
  type: AccountDetailsActions.SET_ACCOUNT_DETAILS_ERROR;
  payload: {
    errorCode: number;
    errorMessage: string;
  };
}

export interface IClearAccountDetails {
  type: AccountDetailsActions.CLEAR_ACCOUNT_DETAILS;
}

export interface ISetAccountDetailsIndividual {
  type: AccountDetailsActions.SET_ACCOUNT_DETAILS_INDIVIDUAL;
  accountIndividual: IAccountDetailsIndividual;
}

export interface ISetAccountDetailsCorporate {
  type: AccountDetailsActions.SET_ACCOUNT_DETAILS_CORPORATE;
  accountCorporate: IAccountDetailsCorporate;
}

export interface ISetAccountDocuments {
  type: AccountDetailsActions.SET_ACCOUNT_DOCUMENTS;
  documents: IAccountDocument[];
}

export interface ISetAccountPhotos {
  type: AccountDetailsActions.SET_ACCOUNT_PHOTOS;
  photos: IAccountPhoto[];
}

export interface ISetAccountIdentityVerificationInfo {
  type: AccountDetailsActions.SET_ACCOUNT_IDENTITY_VERIFICATION_INFO;
  identityVerification: IAccountIdentityVerificationInfo;
}

export interface ISetAccountWallets {
  type: AccountDetailsActions.SET_ACCOUNT_WALLETS;
  wallets: IAccountWallet[];
}

export interface ISetAccountSatchelWallets {
  type: AccountDetailsActions.SET_ACCOUNT_SATCHEL_WALLETS;
  satchelWallets: IAccountSatchelWalletsAllTypes;
}

export interface ISetAccountSatchelStatus {
  type: AccountDetailsActions.SET_ACCOUNT_SATCHEL_STATUS;
  status: AccountStatusEnum;
}

export interface ISetAccountArbipayStatus {
  type: AccountDetailsActions.SET_ACCOUNT_ARBIPAY_STATUS;
  status: AccountStatusEnum;
}

export interface ISetAccountOndataStatus {
  type: AccountDetailsActions.SET_ACCOUNT_ONDATA_STATUS;
  status: TStatusOndata;
}

export interface ISetAccountOndatoDetails {
  type: AccountDetailsActions.SET_ACCOUNT_ONDATO_DETAILS;
  ondatoDetails: IAccountOndatoDetail[];
}

export interface ISetAccountOndatoImages {
  type: AccountDetailsActions.SET_ACCOUNT_ONDATO_IMAGES;
  ondatoSessionId: string;
  docImages: IAccountOndatoImageData[] | undefined;
  photoImages: IAccountOndatoImageData[] | undefined;
}

export interface ISetAccountSignedContracts {
  type: AccountDetailsActions.SET_ACCOUNT_SIGNED_CONTRACTS;
  signedContracts: IAccountSignedContract[];
}

export type TAccountsDetailsAction =
  | ISetAccountDetails
  | ISetAccountDetailsLoading
  | ISetAccountDetailsError
  | IClearAccountDetails
  | ISetAccountDetailsIndividual
  | ISetAccountDetailsCorporate
  | ISetAccountIdentityVerificationInfo
  | ISetAccountDocuments
  | ISetAccountPhotos
  | ISetAccountWallets
  | ISetAccountSatchelWallets
  | ISetAccountSatchelStatus
  | ISetAccountArbipayStatus
  | ISetAccountOndataStatus
  | ISetAccountOndatoDetails
  | ISetAccountOndatoImages
  | ISetAccountSignedContracts;
