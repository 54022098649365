import {
  IWalletTransactionListState,
  TWalletTransactionListAction,
  WalletTransactionListActions,
} from "../../../types/store/wallet/wallet-transaction-list";

const initialState: IWalletTransactionListState = {
  walletId: null,
  transactionList: [],
  isLoading: false,
  page: 1,
  perPage: 10,
  totalPages: 0,
};

export const walletTransactionListReducer = (
  state = initialState,
  action: TWalletTransactionListAction
): IWalletTransactionListState => {
  switch (action.type) {
  case WalletTransactionListActions.CLEAR_TRANSACTION_LIST:
    return { ...state, transactionList: initialState.transactionList};
  case WalletTransactionListActions.CLEAR_WALLET_ID:
    return { ...state, walletId: null };
  case WalletTransactionListActions.SET_LOADING:
    return { ...state, isLoading: action.loading };
  case WalletTransactionListActions.SET_TRANSACTION_LIST:
    return { ...state, transactionList: action.transactionList };
  case WalletTransactionListActions.SET_PAGE:
    return { ...state, page: action.page };
  case WalletTransactionListActions.SET_PER_PAGE:
    return { ...state, perPage: action.perPage };
  case WalletTransactionListActions.SET_TOTAL_PAGES:
    return { ...state, totalPages: action.totalPages };
  case WalletTransactionListActions.SET_WALLET_ID:
    return { ...state, walletId: action.walletId };
  default:
    return state;
  }
};
