import React from "react";
import {RouteObject, useRoutes} from "react-router-dom";
import SignIn from "../pages/auth/sign-in";
import Home from "../pages/admin/home";
import ApplicationsList from "../pages/admin/applications/list";
import TransactionsList from "../pages/admin/transactions/list";
import Accounts from "../pages/admin/account/accounts-list";
import Error404 from "../pages/error-pages/error-404";
import TransactionInfo from "../pages/admin/transactions/info";
import Permissions from "../pages/admin/permissions";
import RoleList from "../pages/admin/role/role-list";
import RoleEdit from "../pages/admin/role/role-edit";
import UserList from "../pages/admin/user/user-list";
import UserEdit from "../pages/admin/user/user-edit";
import Management from "../pages/admin/management";
import RoleAdd from "../pages/admin/role/role-add";
import UserAdd from "../pages/admin/user/user-add";
import Profile from "../pages/admin/profile";
import MerchantList from "../pages/admin/merchant/merchant-list";
import MerchantInfo from "../pages/admin/merchant/merchant-info";
import TechEWallet from "../pages/admin/tech-e-wallet";
import CardList from "../pages/admin/card/card-list";
import CardInfo from "../pages/admin/card/card-info";
import SanctionSearch from "../pages/admin/sanction/sanction-search";
import SanctionListTable from "../pages/admin/sanction/sanction-list-table";
import MerchantEdit from "../pages/admin/merchant/merchant-edit";
import MerchantAdd from "../pages/admin/merchant/merchant-add";
import SanctionCountriesTable from "../pages/admin/sanction/sanction-countries-table";
import AccountDetails from "../pages/admin/account/account-details";

/** Defining the routes that are available to the user when they are not logged in. */
export const unauthorizedRoutes: RouteObject[] = [
  {
    path: "/",
    element: <SignIn/>
  },
];

/** Defining the routes that are available to the user when they are logged in. */
export const authorizedRoutes: RouteObject[] = [
  {
    path: "/home",
    element: <Home/>
  },
  {
    path: "/applications",
    element: <ApplicationsList/>
  },
  {
    path: "/transactions",
    element: <TransactionsList/>
  },
  {
    path: "/transactions/:id",
    element: <TransactionInfo/>
  },
  {
    path: "/accounts",
    element: <Accounts/>
  },
  {
    path: "/accounts/:id",
    element: <AccountDetails/>
  },
  {
    path: "/management",
    element: <Management/>
  },
  {
    path: "/permissions",
    element: <Permissions/>
  },
  {
    path: "/roles",
    element: <RoleList/>
  },
  {
    path: "/role/edit/:id",
    element: <RoleEdit/>
  },
  {
    path: "/role/add",
    element: <RoleAdd/>
  },
  {
    path: "/users",
    element: <UserList/>
  },
  {
    path: "/user/edit/:id",
    element: <UserEdit/>
  },
  {
    path: "/user/add",
    element: <UserAdd/>
  },
  {
    path: "/profile",
    element: <Profile/>
  },
  {
    path: "/merchants",
    element: <MerchantList/>
  },
  {
    path: "/merchants/:id",
    element: <MerchantInfo/>
  },
  {
    path: "/merchant/edit/:id",
    element: <MerchantEdit/>
  },
  {
    path: "/merchant/add",
    element: <MerchantAdd/>
  },
  {
    path: "/tech-e-wallet",
    element: <TechEWallet/>
  },
  {
    path: "/cards",
    element: <CardList/>
  },
  {
    path: "/cards/:id",
    element: <CardInfo/>
  },
  {
    path: "/sanction-search",
    element: <SanctionSearch/>
  },
  {
    path: "/sanction-list",
    element: <SanctionListTable/>
  },
  {
    path: "/sanction-countries",
    element: <SanctionCountriesTable />
  }
];

/** This is the route that will be used when no other route matches the current URL. */
export const anonymousRoutes: RouteObject[] = [
  {
    path: "/404",
    element: <Error404/>
  },
  {
    path: "*",
    element: <Error404/>
  },
];

/**
 * It returns the result of the useRoutes() hook, which is a function that takes an array of routes and returns the first
 * route that matches the current URL
 * @returns The RoutesView component is being returned.
 */
const RoutesView = () => {
  return useRoutes([
    ...unauthorizedRoutes,
    ...authorizedRoutes,
    ...anonymousRoutes
  ]);
};

export default RoutesView;
