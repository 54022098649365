import React from "react";
import {Box, Button, Grid, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";

/** A React component that is used to display a 404 error page. */
const Error404 = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();

  const handleOnClick = () => {
    navigate("/home");
  };

  return (
    <Box
      sx={{
        height: "100vh",
        width: "100vw",
        position: "absolute",
        top: "0",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        zIndex: "0"
      }}
    >
      <Grid
        container
        spacing={2}
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={12}>
          <Typography variant="body1" sx={{fontSize: "6rem", fontWeight: 100}}>
                        404
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1" sx={{fontWeight: 600}}>
            {t("error_page.Ops")}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1" sx={{textTransform: "uppercase"}}>
            {t("error_page.Page not found")}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Button onClick={handleOnClick} variant="contained">
            {t("error_page.Go back to home")}
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Error404;