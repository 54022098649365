import React, {FC} from "react";
import {Box} from "@mui/material";
import {SxProps} from "@mui/system";

interface IStatusBoxProps {
    label: string,
    color: string,
    sx?: SxProps
}

const StatusBox: FC<IStatusBoxProps> = ({label, color, sx}) => {
  return (
    <Box
      sx={{
        color: color,
        backgroundColor: color + "44",
        padding: "3px 10px",
        borderRadius: "20px",
        display: "inline-block",
        ...sx
      }}
    >
      {label}
    </Box>
  );
};

export default StatusBox;