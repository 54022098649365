import * as LayoutActionCreator from "./layout";
import * as AuthActionCreator from "./auth";
import * as AccountStatusesActions from "./account/account-statuses";
import * as AccountsListActions from "./account/accounts-list";
import * as AccountDetailsActions from "./account/account-details";
import * as TransactionStatusesActions from "./transaction/transaction-statuses";
import * as TransactionsListActions from "./transaction/transactions-list";
import * as TransactionInfoActions from "./transaction/transaction-info";
import * as PermissionsListActions from "./permissions";
import * as RoleListActions from "./role/role-list";
import * as HeaderMenuActions from "./header-menu";
import * as UserListActions from "./user/user-list";
import * as UserInfoActions from "./user/user-info";
import * as RoleInfoActions from "./role/role-info";
import * as AccountTransactionListActions from "./account/account-transaction-list";
import * as ProfileActions from "./profile";
import * as MerchantActions from "./merchant/merchant";
import * as MerchantListActions from "./merchant/merchant-list";
import * as WalletAccountsActions from "./wallet/wallet-accounts";
import * as CardActions from "./card";
import * as CardListActions from "./card/card-list";
import * as ErrorHandler from "./error-handler";
import * as SanctionListActions from "./sanction/list";
import * as SanctionCountriesActions from "./sanction/countries";
import * as WalletTransactionList from "./wallet/wallet-transaction-list";
import * as ApplicationList from "./application/applications-list";

export default {
  ...LayoutActionCreator,
  ...AuthActionCreator,
  ...AccountStatusesActions,
  ...AccountsListActions,
  ...AccountDetailsActions,
  ...TransactionStatusesActions,
  ...TransactionsListActions,
  ...TransactionInfoActions,
  ...PermissionsListActions,
  ...RoleListActions,
  ...HeaderMenuActions,
  ...UserListActions,
  ...UserInfoActions,
  ...RoleInfoActions,
  ...AccountTransactionListActions,
  ...ProfileActions,
  ...MerchantActions,
  ...MerchantListActions,
  ...WalletAccountsActions,
  ...CardActions,
  ...CardListActions,
  ...ErrorHandler,
  ...SanctionListActions,
  ...SanctionCountriesActions,
  ...WalletTransactionList,
  ...ApplicationList
};
