import {ISanctionListState, SanctionListActions, TSanctionListAction} from "../../../types/store/sanction/list";

const initialState: ISanctionListState = {
  sanctions: [],
  isLoading: false,
  total: 0
};

export const sanctionListReducer = (state = initialState, action: TSanctionListAction) => {
  switch (action.type){
  case SanctionListActions.SET_SANCTION_LIST:
    return {...state, sanctions: action.sanctions, total: action.total};
  case SanctionListActions.CLEAR_SANCTION_LIST:
    return initialState;
  case SanctionListActions.SET_SANCTION_LIST_LOADING:
    return {...state, isLoading: action.loading};
  default:
    return state;
  }
};