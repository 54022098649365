import {TLayout} from "../reducers/layout";
import {IChangeLayoutAction, LayoutActionList} from "../../types/store/layout";

/**
 * It takes a string and returns an object with a type and a payload
 * @param {TLayout} type - TLayout - this is the type of the payload.
 * @returns An object with a type and a payload.
 */
export const setLayout = (type: TLayout) : IChangeLayoutAction => {
  return {
    type: LayoutActionList.CHANGE_LAYOUT,
    payload: type,
  };
};
