import {AxiosResponse} from "axios";
import {UserInfoResponse, UserListResponse} from "../types/service/response/user-response";
import $api from "../http";
import {IUserDataToUpdate, IUserDataToUpdateBlockStatus} from "../types/store/user";
import {IUserListRequestParams} from "../types/service/request/users-request";

/** It's a class that contains static methods that make requests to the API */
export default class UserService {
  static async userList(params: IUserListRequestParams): Promise<AxiosResponse<UserListResponse>> {
    return await $api.get<UserListResponse>("/users", {params});
  }

  static async userInfo(id: string | number): Promise<AxiosResponse<UserInfoResponse>> {
    return await $api.get<UserInfoResponse>("/users/" + id);
  }

  static async createUser(data: IUserDataToUpdate): Promise<AxiosResponse<UserInfoResponse>> {
    return await $api.post("/users", data);
  }

  static async updateUser(id: string | number, data: IUserDataToUpdate): Promise<AxiosResponse<UserInfoResponse>> {
    return await $api.patch("/users/" + id, data);
  }

  static async updateUserBlockStatus(id: string | number, isBlocked: boolean): Promise<AxiosResponse<UserInfoResponse>> {
    return await $api.patch("/users/" + id, {isBlocked} as IUserDataToUpdateBlockStatus);
  }
}