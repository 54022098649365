import React, {useEffect} from "react";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Grid,
  Paper, Skeleton, Table, TableBody, TableCell,
  TableContainer, TableFooter, TableHead, TableRow,
} from "@mui/material";
import {useTranslation} from "react-i18next";
import {Can} from "@casl/react";
import ability from "../../../utils/can";
import {HeadCell} from "../../../types/table";
import {ICard} from "../../../types/store/card";
import {useTypedSelector} from "../../../hooks/useTypedSelector";
import {useActions} from "../../../hooks/useActions";
import {useNavigate} from "react-router-dom";
import Error404 from "../../error-pages/error-404";
import {getDateTime} from "../../../utils/time";
import CustomTablePaginationActionsNew from "../../../components/common/table-pagination-new";

const FIRST_PAGE = 1;

/** A React component that displays a list of cards. */
const CardList = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const {cards, isLoading, page, perPage, totalPages} = useTypedSelector(state => state.cardList);
  const { setCardCurPage, setCardPerPage, fetchCardList, clearCardList} = useActions();

  const headCells: Array<HeadCell<ICard>> = [
    {
      id: "id",
      numeric: true,
      disablePadding: false,
      label: t("card.table.id"),
    },
    {
      id: "balance",
      numeric: false,
      disablePadding: false,
      label: t("card.table.balance"),
    },
    {
      id: "cardNumber",
      numeric: false,
      disablePadding: false,
      label: t("card.table.pan"),
    },
    {
      id: "expiryAt",
      numeric: false,
      disablePadding: false,
      label: t("card.table.expiryAt"),
    },
    {
      id: "createdAt",
      numeric: false,
      disablePadding: false,
      label: t("card.table.createdAt"),
    },
    {
      id: "action",
      numeric: false,
      disablePadding: false,
      label: "",
    },
  ];

  const handleViewCardInfo = (id: string) => () => {
    navigate("/cards/" + id);
  };

  const handlePageChange = (event: React.MouseEvent<HTMLButtonElement>, page: number) => {
    if (!isLoading) setCardCurPage(page);
  };

  const handlePerPageChange = (perPage: number) => {
    if (!isLoading) {
      setCardPerPage(perPage);
      setCardCurPage(FIRST_PAGE);
    }
  };

  useEffect(() => {
    return () => {
      clearCardList();
    };
  }, []);

  useEffect(() => {
    if (ability.can("get", "cards_list"))
      fetchCardList({
        per_page: perPage,
        page,
        sort: "DESC",
      });
  }, [page, perPage]);

  if (ability.cannot("get", "cards_list"))
    return <Error404/>;

  return (
    <Container maxWidth="xl">
      <Can I="get" a="cards_list" ability={ability}>
        <Grid container columns={16} spacing={3}>
          <Grid item xs={16} md={16}>
            <Card>
              <CardHeader title={t("card.table.title")}/>
              <CardContent>
                <TableContainer component={Paper}>
                  <Table sx={{minWidth: 650}} aria-label="Transactions list table" size={"small"}>
                    <TableHead>
                      <TableRow sx={{"& .MuiTableCell-head": {fontWeight: 600}}}>
                        {headCells.map((headCell) => (
                          <TableCell
                            sx={{color: headCell.isHide ? "transparent" : ""}}
                            key={headCell.id}
                          >
                            {headCell.label}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {isLoading && Array(perPage).fill("").map((row, index) => (
                        <TableRow
                          key={index}
                          sx={{"&:last-child td, &:last-child th": {border: 0}}}
                        >
                          {Array(headCells.length).fill("").map((row, index) => (
                            <TableCell key={index}>
                              <Skeleton height={30}/>
                            </TableCell>
                          ))}
                        </TableRow>
                      ))}
                      {!isLoading && cards.map((card) => (
                        <TableRow
                          key={card.id}
                          sx={{"&:last-child td, &:last-child th": {border: 0}}}
                          hover
                        >
                          <TableCell>{card?.id}</TableCell>
                          <TableCell>{card?.balance}</TableCell>
                          <TableCell>{card?.pan}</TableCell>
                          <TableCell>{card?.expiry_date}</TableCell>
                          <TableCell>{getDateTime(card?.created_at)}</TableCell>
                          <TableCell align="right">
                            <Button variant={"contained"} size="small"
                              onClick={handleViewCardInfo(card.id)}
                              disabled={ability.cannot("get", "card_info")}
                            >
                              {t("card.table.details")}
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                    <TableFooter>
                      <TableRow>
                        <TableCell colSpan={7}>
                          <CustomTablePaginationActionsNew
                            totalPages={totalPages}
                            page={page}
                            rowsPerPage={perPage}
                            onPageChange={handlePageChange}
                            onPerPageChange={handlePerPageChange}
                          />
                        </TableCell>
                      </TableRow>
                    </TableFooter>
                  </Table>
                </TableContainer>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Can>
    </Container>
  );
};

export default CardList;