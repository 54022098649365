import React, {FC} from "react";
import {Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import ApplicationsListTableByAccount from "../../applications/applications-list-table-by-account";
import AccountSatchelWallets from "../account-satchel-wallets/account-satchel-wallets";

const AccountApplications:FC = () => {
  const { t } = useTranslation();
  return (
    <>
	    <Typography sx={{fontSize: "1.5rem", my: 2}}>
		    { t("account.details.Satchel bank accounts") }
	    </Typography>

	    <AccountSatchelWallets type={"individual"} sx={{mb: 4}}/>

	    <ApplicationsListTableByAccount type={"individual"}/>

	    <AccountSatchelWallets type={"corporate"} sx={{mb: 4}}/>

	    <ApplicationsListTableByAccount type={"corporate"}/>

    </>
  );
};

export default AccountApplications;