import React, {FC, useEffect} from "react";
import {Button, Card, CardActions, CardContent, CardMedia, Typography} from "@mui/material";
import {IMerchant} from "../../types/store/merchant";
import {SxProps} from "@mui/system";
import {Theme} from "@mui/material/styles";
import {useTypedSelector} from "../../hooks/useTypedSelector";
import {useActions} from "../../hooks/useActions";
import {useTranslation} from "react-i18next";
import ability from "../../utils/can";
import {LoadingButton} from "@mui/lab";
import {useNavigate} from "react-router-dom";

interface IMerchantCard {
    merchant: IMerchant,
    sx?: SxProps<Theme>;
    showBalance: boolean;
}

/** A React component that allow to show merchant information.
 * @return React Component
 * */
const MerchantCard: FC<IMerchantCard> = ({merchant, sx, showBalance}) => {
  //TODO Next code added by business condition.
  const {wallets: walletAccounts} = useTypedSelector(state => state.walletAccounts);
  const {setSpecifiedWalletAccounts, clearWalletAccounts, updateMerchantBlockStatusSingle} = useActions();
  const {t} = useTranslation();
  const navigate = useNavigate();

  const handleEditMerchant = (id: number) => () => {
    navigate(`/merchant/edit/${id}`);
  };

  const handleSetBlockStatusMerchant = (id: number, block: 1 | 0) => () => {
    updateMerchantBlockStatusSingle(id, block);
  };

  useEffect(() => {
    clearWalletAccounts();
    setSpecifiedWalletAccounts([merchant.ewallet]);
  }, []);

  return (
    <Card sx={sx ?? {mb: 2, maxWidth: "300px", ml: "auto", mr: "auto"}}>
      <CardMedia
        component="img"
        alt={merchant.name}
        image={"data:image/png;base64," + merchant.image}
        sx={{maxWidth: "300px", height: "200px", objectFit: "contain"}}
      />
      <CardContent sx={{textAlign: "center"}}>
        <Typography variant="body1">{merchant.name}</Typography>
        <Typography variant="body1">{merchant.type}</Typography>
        <Typography variant="body1">{merchant.ewallet}</Typography>
        {showBalance && (
          <Typography variant="body1">
            {t("merchant.details.balance")}: {walletAccounts?.[0]?.availableBalance ? walletAccounts?.[0]?.availableBalance + " " + walletAccounts?.[0]?.currencyCode : "-"}
          </Typography>
        )}
      </CardContent>
      <CardActions>
        {showBalance && (
          <>
            <Button
              variant={"contained"}
              size="small"
              onClick={handleEditMerchant(merchant.id)}
              disabled={ability.cannot("update", "merchant")}
              fullWidth
            >
              {t("merchant.table.edit")}
            </Button>
            {!!(merchant.status) && (
              <LoadingButton
                variant={"contained"}
                size="small"
                color="error"
                loading={merchant.isUpdating}
                onClick={handleSetBlockStatusMerchant(merchant.id, 0)}
                disabled={ability.cannot("update", "merchant")}
                fullWidth
              >
                {t("merchant.table.block")}
              </LoadingButton>
            )}
            {!(merchant.status) && (
              <LoadingButton
                variant={"contained"}
                size="small"
                color="success"
                loading={merchant.isUpdating}
                onClick={handleSetBlockStatusMerchant(merchant.id, 1)}
                disabled={ability.cannot("update", "merchant")}
                fullWidth
              >
                {t("merchant.table.unblock")}
              </LoadingButton>
            )}
          </>
        )}
      </CardActions>
    </Card>
  );
};

export default MerchantCard;