import {IUserInfo} from "../../store/user";
import {IGeneralResponse} from "./general-response";

export interface AuthResponse {
  result?: boolean,
  userId?: string,
  statusCode?: number,
  message?: string,
  error?: string[]
}

export enum AuthResponseHeaders {
  ACCESS_TOKEN = "x-access-token"
}

export interface IEditProfileResponse extends IGeneralResponse<boolean> {
  user: IUserInfo,
}
