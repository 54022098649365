import {AxiosResponse} from "axios";
import {
  IWalletListResponse,
  IWalletResponse,
  IWalletTransactionListRespResult
} from "../types/service/response/wallet-response";
import $api from "../http";
import {IWalletTransactionListReqParams} from "../types/service/request/wallet";

/** It's a class that contains static methods that make API calls to the backend */
export default class WalletService {
  static async getWalletAccountList(): Promise<AxiosResponse<IWalletListResponse>> {
    return $api.get("/wallets");
  }
  static async getWalletAccountData(accountNumber: string): Promise<AxiosResponse<IWalletResponse>> {
    return $api.get("/wallets/" + accountNumber);
  }
  static async getWalletTransactionList(params: IWalletTransactionListReqParams): Promise<AxiosResponse<IWalletTransactionListRespResult>>{
    return $api.get("/wallets/get-transaction-list-by-account-id", {params});
  }
}