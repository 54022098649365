import {
  IClearSanctionList,
  ISanctionListItem,
  ISetSanctionList,
  ISetSanctionListLoading,
  SanctionListActions
} from "../../../types/store/sanction/list";
import {ThunkDispatch} from "redux-thunk";
import {Action} from "redux";
import SanctionTestService from "../../../sevices/test/sanction-test-service";
import {ISanctionListByBaseAndNameRequest} from "../../../types/service/request/sanction-request";
import {handleGeneralError} from "../error-handler";
import {USE_SERVER} from "../../../constants/constants";
import SanctionService from "../../../sevices/sanction-service";

const setSanctionList = (sanctions: ISanctionListItem[], total: number): ISetSanctionList => ({
  type: SanctionListActions.SET_SANCTION_LIST,
  sanctions,
  total
});

const clearSanctionList = (): IClearSanctionList => ({
  type: SanctionListActions.CLEAR_SANCTION_LIST
});

const setSanctionListLoading = (loading: boolean): ISetSanctionListLoading => ({
  type: SanctionListActions.SET_SANCTION_LIST_LOADING,
  loading
});

export const fetchSanctionList = (
  props: ISanctionListByBaseAndNameRequest
) => async (dispatch: ThunkDispatch<unknown, unknown, Action>) => {
  try {
    dispatch(clearSanctionList());
    dispatch(setSanctionListLoading(true));
    const response =
            USE_SERVER ?
              await SanctionService.sanctionListByBaseAndName(props)
              :
              await SanctionTestService.sanctionListByBaseAndName(props);

    dispatch(setSanctionList(response.data.sanctions, response.data.total));

  } catch (e){
    await dispatch(handleGeneralError(e));
  } finally {
    dispatch(setSanctionListLoading(false));
  }
};