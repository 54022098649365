import { MomentInput } from "moment";
import { useTranslation } from "react-i18next";
import AccountStatus from "../components/account/account-status";
import { useAccountsList } from "./useAccountsList";
import { IAccount } from "../types/store/account";
import { ITransaction, TTransactionStatus } from "../types/store/transaction";
import { HeadCell } from "../types/table";
import { useTransactionsList } from "./useTransactionsList";
import TransactionStatus from "../components/transaction-status";
import {getDateTime} from "../utils/time";

/**
 * It returns an object with two properties, headAccounts and headTransactions, which are arrays of HeadCell<IAccount> and
 * HeadCell<ITransaction> respectively
 * @returns An object with two properties: headAccounts and headTransactions.
 */
export default function useHome() {
  const { t } = useTranslation();
  const { getStatusColor: getTransactionStatusColor, getStatusName: getTransactionStatusName } = useTransactionsList();

  const headAccounts: HeadCell<IAccount>[] = [
    {
      id: "id",
      numeric: true,
      label: t("account.table.id"),
      disablePadding: false,
    },
    {
      id: "keycloackId",
      numeric: false,
      label: t("account.table.keycloackId"),
      disablePadding: false,
    },
    {
      id: "emailAddress",
      numeric: false,
      label: t("account.table.email"),
      disablePadding: true,
    },
    {
      id: "phoneNumber",
      numeric: false,
      label: t("account.table.phone"),
      disablePadding: false,
    }
  ];

  const headTransactions: HeadCell<ITransaction>[] = [
    {
      id: "id",
      numeric: false,
      label: t("transactions.table.transactionId"),
      disablePadding: false
    },
    {
      id: "type",
      numeric: false,
      label: t("transactions.table.type"),
      disablePadding: false
    },
    {
      id: "amount",
      numeric: false,
      label: t("transactions.table.amount"),
      disablePadding: false,
      handleColumn: (value: unknown)=> (value as string + " EUR"),
    },
    {
      id: "timestamp",
      numeric: false,
      label: t("transactions.table.timestamp"),
      disablePadding: false,
      handleColumn: (value: unknown) => getDateTime(value as MomentInput),
    },
  ];

  return {
    headAccounts,
    headTransactions,
  };
}
