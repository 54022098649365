import $api from "../http";
import { AxiosResponse } from "axios";
import {TransactionInfoResponse, TransactionResultResponse, TransactionsListResponse, TransactionStatusesResponse} from "../types/service/response/transactions-response";
import {TransactionResultRequestBody, TransactionsListRequestParams} from "../types/service/request/transactions-request";

/** It's a wrapper around the API endpoints related to transactions */
export default class TransactionsService {
  static async transactionInfo(
    transactionId: number
  ): Promise<AxiosResponse<TransactionInfoResponse>> {
    return $api.get<TransactionInfoResponse>("/transactions/" + transactionId);
  }

  static async transactionsList(
    params: TransactionsListRequestParams
  ): Promise<AxiosResponse<TransactionsListResponse>> {
    let i: keyof TransactionsListRequestParams;
    for (i in params) {
      if (params[i] === undefined || params[i] === null) delete params[i];
    }
    return $api.get<TransactionsListResponse>("/transactions", {
      params,
    });
  }

  static async transactionStatuses(): Promise<AxiosResponse<TransactionStatusesResponse>> {
    return $api.get<TransactionStatusesResponse>(
      "/transaction/statuses"
    );
  }

  static async transactionResult({ transactionId, hmac, status }: TransactionResultRequestBody): Promise<AxiosResponse<TransactionResultResponse>> { 
    return $api.post("/transaction/set-verification-result", {transactionId, hmac, status});
  }
}
