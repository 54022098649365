import {useEffect, useState} from "react";
import {ITransaction, TTransactionStatus, TTransferType} from "../types/store/transaction";
import ability from "../utils/can";
import {useActions} from "./useActions";
import {useTypedSelector} from "./useTypedSelector";
import {getDateTime} from "../utils/time";
import {useTranslation} from "react-i18next";
import {useTransactionsList} from "./useTransactionsList";
import TransactionStatus from "../components/transaction-status";
import moment from "moment/moment";

/** A hook that fetches transaction info and returns it to the component. */
const useTransactionInfo = (id: number) => {
  const {isLoading, transaction} = useTypedSelector((state) => state.transactionInfo);
  const {fetchTransactionInfo, fetchTransactionStatuses} = useActions();
  const {merchant} = useTypedSelector(state => state.merchant);
  const {fetchMerchantByAccountId} = useActions();
  const {t} = useTranslation();
  const {getStatusColor, getStatusName} = useTransactionsList();

  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [openDeclineDialog, setOpenDeclineDialog] = useState(false);

  const handleVerifyTransaction = (transactionWithResultStatus: ITransaction) => {
    setOpenDeclineDialog(false);
    setOpenConfirmDialog(false);
  };

  const dialog = {
    openConfirmDialog,
    setOpenConfirmDialog,
    openDeclineDialog,
    setOpenDeclineDialog,
    handleVerifyTransaction
  };

  useEffect(() => {
    // fetchTransactionStatuses();
    if (ability.can("get", "transaction_info"))
      fetchTransactionInfo(id);
  }, [id]);

  // const transactionStatus = TransactionStatus(transaction.status, getStatusColor, getStatusName);
  // const transactionProcessingStatus = TransactionStatus(processingStatus === null ? "100" as TTransactionStatus : processingStatus, getStatusColor, getStatusName);

  const infoData = [
    [t("transactions.info.Id") + ": ", transaction.id],
    [t("transactions.info.Satchel id") + ": ", transaction.satchelId],
    [t("transactions.info.Category") + ": ", transaction.category],
    [t("transactions.info.Email") + ": ", transaction.email],
    [t("transactions.info.Amount") + ": ", transaction.amount + " " + transaction.currencyCode],
    [t("transactions.info.Fee") + ": ", transaction.fee + " " + transaction.feeCurrencyCode],
    [t("transactions.info.Beneficiary account number") + ": ", transaction.beneficiaryAccountNumber],
    [t("transactions.info.Beneficiary bank swift") + ": ", transaction.beneficiaryBankSwift],
    [t("transactions.info.Beneficiary iban") + ": ", transaction.beneficiaryIban],
    [t("transactions.info.Beneficiary card uuid") + ": ", transaction.beneficiaryCardUuid],
    [t("transactions.info.Sender account number") + ": ", transaction.senderAccountNumber],
    [t("transactions.info.Narrative") + ": ", transaction.narrative],
    [t("transactions.info.Status") + ": ", transaction.status],
    [t("transactions.info.Type") + ": ", transaction.type],
    [t("transactions.info.Rejection reason") + ": ", transaction.rejectionReason],
    [t("transactions.info.Processing time") + ": ", transaction.processingTime],
    [t("transactions.info.Finished at") + ": ", transaction.finishedAt && moment(transaction.finishedAt).format("DD.MM.YYYY HH:mm:ss")],
    [t("transactions.info.Created at") + ": ", moment(transaction.createdAt).format("DD.MM.YYYY HH:mm:ss")],
    [t("transactions.info.Updated at") + ": ", moment(transaction.updatedAt).format("DD.MM.YYYY HH:mm:ss")],
  ];

  return {isLoading, transaction, infoData, dialog, merchant};
};

export default useTransactionInfo;