export interface ISanctionListItem {
    id: number,
    firstName?: string,
    lastName?: string,
    fullName?: string,
    publicationUrl: string,
}

export interface ISanctionListState {
    sanctions: ISanctionListItem[],
    total: number,
    isLoading: boolean
}

export enum SanctionListActions {
    SET_SANCTION_LIST = "SET_SANCTION_LIST",
    CLEAR_SANCTION_LIST = "CLEAR_SANCTION_LIST",
    SET_SANCTION_LIST_LOADING = "SET_SANCTION_LIST_LOADING",
}

export interface ISetSanctionList {
    type: SanctionListActions.SET_SANCTION_LIST,
    sanctions: ISanctionListItem[],
    total: number
}

export interface IClearSanctionList {
    type: SanctionListActions.CLEAR_SANCTION_LIST
}

export interface ISetSanctionListLoading {
    type: SanctionListActions.SET_SANCTION_LIST_LOADING,
    loading: boolean
}

export type TSanctionListAction = ISetSanctionList | IClearSanctionList | ISetSanctionListLoading;