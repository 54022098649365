import {AxiosResponse} from "axios";
import $api from "../http";
import {ISanctionCountriesRequest, ISanctionListByBaseAndNameRequest} from "../types/service/request/sanction-request";
import {ISanctionCountriesResponse, ISanctionListByBaseAndNameResponse, ISanctionListByNameResponse} from "../types/service/response/sanction";

export default class SanctionService {
  static async sanctionListByName(firstName: string, lastName: string): Promise<AxiosResponse<ISanctionListByNameResponse>> {
    return $api.get("/sanctions/by-name", {params: {first_name: firstName, last_name: lastName}});
  }

  static async sanctionListByBaseAndName(params: ISanctionListByBaseAndNameRequest): Promise<AxiosResponse<ISanctionListByBaseAndNameResponse>> {
    const editedParams: Record<string, string | number> = {
      "current_page": params.page,
      "items_per_page": params.itemsPerPage
    };
    if (params.firstName){
      editedParams.first_name = params.firstName;
    }
    if (params.lastName){
      editedParams.last_name = params.lastName;
    }
    if (params.fullName){
      editedParams.full_name = params.fullName;
    }
    return $api.get("/sanctions/by-list/" + params.sanctionBase, {
      params: editedParams
    });
  }

  static async sanctionCountriesAll(params?: ISanctionCountriesRequest): Promise<AxiosResponse<ISanctionCountriesResponse>> {
    return $api.get("/sanctions-countries/all", { params });
  }
}