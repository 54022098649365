import React, {useState} from "react";
import {useFormik} from "formik";
import {useTranslation} from "react-i18next";
import {Box, Button, Card, CardContent, Container, Grid, TextField, Typography} from "@mui/material";
import {LoadingButton} from "@mui/lab";
import SanctionList from "../../../components/sanction/sanction-list";
import {USE_SERVER} from "../../../constants/constants";
import SanctionTestService from "../../../sevices/test/sanction-test-service";
import SanctionService from "../../../sevices/sanction-service";
import {useActions} from "../../../hooks/useActions";
import {ISanction} from "../../../types/store/sanction";
import ability from "../../../utils/can";
import Error404 from "../../error-pages/error-404";

interface ISanctionSearchFields {
    firstName: string,
    lastName: string
}

const SanctionSearch = () => {
  const {t} = useTranslation();
  const [sanctions, setSanctions] = useState<ISanction[] | undefined>(undefined);
  const {handleGeneralError} = useActions();

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: ""
    } as ISanctionSearchFields,

    validate: (values) => {
      const errors: Partial<ISanctionSearchFields> = {};

      const validateName = (name: string) => {
        if (!name) return t("validations.Field is required");
      };

      const firstNameError = validateName(values.firstName);
      const lastNameError = validateName(values.lastName);
      if (firstNameError) {
        errors.firstName = firstNameError;
      }
      if (lastNameError) {
        errors.lastName = lastNameError;
      }

      return errors;
    },

    onSubmit: async (values) => {
      await getSanctions(values.firstName, values.lastName);
    }
  });
  const {
    values,
    errors,
    handleChange,
    touched,
    handleSubmit,
    isSubmitting,
    handleBlur,
  } = formik;

  const getSanctions = async (firstName: string, lastName: string) => {
    try {
      const response =
                USE_SERVER
                  ?
                  await SanctionService.sanctionListByName(firstName, lastName)
                  :
                  await SanctionTestService.sanctionListByName(firstName, lastName);
      if (Array.isArray(response.data.sanctions)){
        setSanctions(response.data.sanctions);
        formik.resetForm();
      }
    } catch (e) {
      handleGeneralError(e);
    }
  };

  if (ability.cannot("get", "sanctions")) {
    return <Error404/>;
  }

  return (
    <Container>
      <Typography
        variant="h5"
        sx={{display: "flex", justifyContent: "center", mb: 4}}
      >
        {t("sanction.search.title")}
      </Typography>
      {!sanctions && (
        <Card sx={{mb: 2}}>
          <CardContent>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2} sx={{height: {md: "150px"}, py: 5}}>
                <Grid item xs={12} md={4} display={"flex"} justifyContent={"center"}>
                  <TextField
                    id="firstName"
                    name="firstName"
                    type="text"
                    label={t("sanction.form.firstName")}
                    error={touched.firstName && !!errors.firstName}
                    helperText={touched.firstName && errors.firstName}
                    value={values.firstName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    sx={{minWidth: "200px", mx: "auto"}}
                  />
                </Grid>
                <Grid item xs={12} md={4} display={"flex"} justifyContent={"center"}>
                  <TextField
                    id="lastName"
                    name="lastName"
                    type="text"
                    label={t("sanction.form.lastName")}
                    error={touched.lastName && !!errors.lastName}
                    helperText={touched.lastName && errors.lastName}
                    value={values.lastName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    sx={{minWidth: "200px", mx: "auto"}}
                  />
                </Grid>
                <Grid item xs={12} md={4} display={"flex"} justifyContent={"center"} alignItems={"flex-start"}>
                  <LoadingButton
                    type="submit"
                    variant="contained"
                    color="success"
                    loading={isSubmitting}
                    disabled={false}
                    size={"large"}
                  >
                    {t("sanction.form.find")}
                  </LoadingButton>
                </Grid>
              </Grid>
            </form>
          </CardContent>
        </Card>
      )}
      {sanctions && (
        <Card sx={{mb: 2, minHeight: "200px"}}>
          <CardContent>
            <Box sx={{display: "flex", justifyContent: "flex-end"}}>
              <Button variant="contained" color="primary" onClick={() => setSanctions(undefined)}>
                                Search another person
              </Button>
            </Box>

            <SanctionList sanctions={sanctions}/>
          </CardContent>
        </Card>
      )}
    </Container>
  );
};

export default SanctionSearch;