import {AxiosError} from "axios";
import {IGeneralResponse} from "../../types/service/response/general-response";
import {Dispatch} from "redux";
import {checkErrorResponseCodeMessage, serverNotResponse} from "../../utils/error-helper";
import {notification} from "../../utils/notification";

/** A function that takes an error and a dispatch function as parameters. */
export const handleGeneralError = (e: unknown) => async (dispatch: Dispatch) => {
  const {response} = e as AxiosError<IGeneralResponse<boolean>>;
  if (response === undefined) {
    const {errorCode, errorMessage} = serverNotResponse();
    notification.showErrorMessage(errorCode + " " + errorMessage);
    return;
  }
  const {errorCode, errorMessage} = checkErrorResponseCodeMessage(response?.status, response?.data?.errors?.[0]);
  notification.showErrorMessage(errorCode + " " + errorMessage);
};

/** Handling network errors. */
export const handleNetworkError = (e: unknown) => async (dispatch: Dispatch) => {
  const {response} = e as AxiosError<IGeneralResponse<boolean>>;
  if (response === undefined) {
    const {errorCode, errorMessage} = serverNotResponse();
    notification.showErrorMessage(errorCode + " " + errorMessage);
    return;
  }
};