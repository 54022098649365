import React, {useEffect} from "react";
import {
  Button,
  Card,
  CardContent, CardHeader, MenuItem,
  Paper, Skeleton,
  Table, TableBody, TableCell,
  TableContainer, TableFooter,
  TableHead, TablePagination,
  TableRow, TableSortLabel, TextField,
} from "@mui/material";
import {useTranslation} from "react-i18next";
import {useTypedSelector} from "../../hooks/useTypedSelector";
import {useNavigate} from "react-router-dom";
import {useActions} from "../../hooks/useActions";
import CustomTablePaginationActions from "../common/table-pagination";
import {capitalize} from "lodash";
import {useTransactionsList} from "../../hooks/useTransactionsList";
import ability from "../../utils/can";
import {transferTypes, TTransferType} from "../../types/store/transaction";
import TransactionStatus from "../transaction-status";
import {getDateTime} from "../../utils/time";
import i18n from "i18next";
import CustomTablePaginationActionsNew from "../common/table-pagination-new";
import moment from "moment/moment";

/** A React component that displays a table of transactions. */
const TransactionsListTable = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const {headCellsFullData: headCells} = useTransactionsList();
  const {
    isLoading,
    transactions,
    page,
    perPage,
    totalPages,
    // sort,
    // filterStatus,
    // transferType
  } = useTypedSelector(state => state.transactionsList);

  const { statuses } = useTypedSelector(state => state.transactionStatuses);

  const {
    setTransactionsListPage,
    setTransactionsListItemsPerPage,
    fetchTransactionsList,
    // setTransactionsListSort,
    setTransactionsListStatus,
    // fetchTransactionStatuses,
    setTransactionsTransferType
  } = useActions();

  useEffect(() => {
    return () => {
      setTransactionsListPage(1);
      setTransactionsTransferType(undefined);
      setTransactionsListStatus(undefined);
    };
  }, []);

  useEffect(() => {
    // fetchTransactionStatuses();
    if (ability.can("get", "transactions_list"))
      fetchTransactionsList();
  }, [page, perPage]);

  const handleViewTransaction = (transactionId: number) => () => {
    navigate(`/transactions/${transactionId}`);
  };

  const handlePageChange = (event: unknown, page: number) => {
    if (!isLoading) setTransactionsListPage(page);
  };

  const handlePerPageChange = (perPage: number) => {
    if (!isLoading) {
      setTransactionsListItemsPerPage(perPage);
      setTransactionsListPage(1);
    }
  };

  // const handleSortTable = () => {
  //   const reverseSort = sort === "DESC" ? "ASC" : "DESC";
  //   setTransactionsListSort(reverseSort);
  // };
  //
  // const handleSetFilterStatus = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   let status: number | undefined = +event.target.value;
  //   if (isNaN(status)) status = undefined;
  //   if (!isLoading) setTransactionsListStatus(status);
  // };
  //
  // const handleSetFilterTransferType = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   let type: TTransferType = undefined;
  //   if (transferTypes.includes(event.target.value)) {
  //     type = event.target.value as keyof TTransferType;
  //   }
  //   setTransactionsTransferType(type);
  // };

  return (
    <Card>
      <CardHeader title={t("transactions.table.title")}/>
      <CardContent>
        {/*<TextField*/}
        {/*  id="filterSelect"*/}
        {/*  select*/}
        {/*  label={t("transactions.table.status")}*/}
        {/*  value={filterStatus !== undefined ? filterStatus : "all"}*/}
        {/*  onChange={handleSetFilterStatus}*/}
        {/*  SelectProps={{*/}
        {/*    MenuProps: {*/}
        {/*      PaperProps: {*/}
        {/*        style: {*/}
        {/*          borderRadius: "20px"*/}
        {/*        }*/}
        {/*      }*/}
        {/*    }*/}
        {/*  }}*/}
        {/*  sx={{mb: "1rem", mr: 1, minWidth: "135px"}}*/}
        {/*>*/}
        {/*  <MenuItem value={"all"}>{t("transactions.table.all")}</MenuItem>*/}
        {/*  {Object.keys(statuses).map(statusCode =>*/}
        {/*    <MenuItem key={statusCode} value={statusCode}>*/}
        {/*      {i18n.exists("transactions.statuses." + statuses[statusCode]) ?*/}
        {/*        t("transactions.statuses." + statuses[statusCode]) : statuses[statusCode]*/}
        {/*      }*/}
        {/*    </MenuItem>*/}
        {/*  )}*/}
        {/*</TextField>*/}
        {/*<TextField*/}
        {/*  id="transferType"*/}
        {/*  select*/}
        {/*  label={t("transactions.table.transferType")}*/}
        {/*  value={transferType !== undefined ? transferType : "all"}*/}
        {/*  onChange={handleSetFilterTransferType}*/}
        {/*  SelectProps={{*/}
        {/*    MenuProps: {*/}
        {/*      PaperProps: {*/}
        {/*        style: {*/}
        {/*          borderRadius: "20px"*/}
        {/*        }*/}
        {/*      }*/}
        {/*    }*/}
        {/*  }}*/}
        {/*  sx={{mb: "1rem", minWidth: "135px"}}*/}
        {/*>*/}
        {/*  <MenuItem value={"all"}>{t("transactions.table.all")}</MenuItem>*/}
        {/*  {transferTypes.map(type =>*/}
        {/*    <MenuItem key={type} value={type}>*/}
        {/*      {t("transactions.table.transferType " + type)}*/}
        {/*    </MenuItem>*/}
        {/*  )}*/}
        {/*</TextField>*/}
        <TableContainer component={Paper}>
          <Table sx={{minWidth: 650}} aria-label="Transactions list table" size={"small"}>
            <TableHead>
              <TableRow sx={{"& .MuiTableCell-head": {fontWeight: 600}}}>
                {headCells.map((headCell) => (
                  <TableCell
                    sx={{color: headCell.isHide ? "transparent" : ""}}
                    key={headCell.id}
                  >
                    {headCell.id === "id" ?
                      <TableSortLabel
                        active={true}
                        // direction={sort === "ASC" ? "asc" : "desc"}
                        // onClick={handleSortTable}
                      >
                        {headCell.label}
                      </TableSortLabel>
                      :
                      headCell.label
                    }
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading && Array(perPage).fill("").map((row, index) => (
                <TableRow
                  key={index}
                  sx={{"&:last-child td, &:last-child th": {border: 0}}}
                >
                  {Array(headCells.length).fill("").map((row, index) => (
                    <TableCell key={index}>
                      <Skeleton height={30}/>
                    </TableCell>
                  ))}
                </TableRow>
              ))}
              {!isLoading && transactions.map((transaction) => (
                <TableRow
                  key={transaction.id}
                  sx={{"&:last-child td, &:last-child th": {border: 0}}}
                  hover
                >
                  <TableCell>{transaction.id}</TableCell>
                  <TableCell>{transaction.amount + " " + transaction.currencyCode}</TableCell>
                  <TableCell>{transaction.senderAccountNumber}</TableCell>
                  <TableCell>{transaction.beneficiaryAccountNumber || transaction.beneficiaryIban}</TableCell>
                  <TableCell>{moment(transaction.createdAt).format("DD.MM.YYYY HH:mm:ss")}</TableCell>
                  <TableCell>
                    <Button
                      variant={"contained"}
                      size="small"
                      onClick={handleViewTransaction(transaction.id)}
                      disabled={ability.cannot("get", "transaction_info")}
                    >
                      {t("transactions.table.details")}
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TableCell colSpan={7}>
                  <CustomTablePaginationActionsNew
                    totalPages={totalPages}
                    page={page}
                    rowsPerPage={perPage}
                    onPageChange={handlePageChange}
                    onPerPageChange={handlePerPageChange}
                  />
                </TableCell>
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  );
};

export default TransactionsListTable;