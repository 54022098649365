import {ISanctionCountriesState, SanctionCountriesActions, TSanctionCountriesAction} from "../../../types/store/sanction/countries";

const initialState: ISanctionCountriesState = {
  sanctions: [],
  isLoading: false,
};

export const sanctionCountriesReducer = (state = initialState, action: TSanctionCountriesAction) => {
  switch (action.type){
  case SanctionCountriesActions.SET_SANCTION_COUNTRIES:
    return {...state, sanctions: action.sanctions};
  case SanctionCountriesActions.CLEAR_SANCTION_COUNTRIES:
    return initialState;
  case SanctionCountriesActions.SET_SANCTION_COUNTRIES_LOADING:
    return {...state, isLoading: action.loading};
  default:
    return state;
  }
};