import React from "react";
import {Container, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import AccountWallets from "../../../components/account/account-wallets";
import ability from "../../../utils/can";
import Error404 from "../../error-pages/error-404";

/** A React component that is used to display the tech e-wallet page. */
const TechEWallet = () => {
  const {t} = useTranslation();

  if (ability.cannot("get", "wallets")) {
    return <Error404/>;
  }

  return (
    <Container>
      <Typography
        variant="h5"
        sx={{display: "flex", justifyContent: "center", mb: 2}}
      >
        {t("techEWallet.header")}
      </Typography>
      <AccountWallets isTechAccounts={true}/>
    </Container>
  );
};

export default TechEWallet;