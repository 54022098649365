import {
  IClearTransactionInfo,
  IPostTransactionResult,
  ISetTransactionError,
  ISetTransactionInfo,
  ISetTransactionLoading,
  TransactionInfoActions
} from "../../../types/store/transaction/transaction-info";
import {ITransaction} from "../../../types/store/transaction";
import {Dispatch} from "redux";
import TransactionsService from "../../../sevices/transactions-service";
import {USE_SERVER} from "../../../constants/constants";
import TransactionsTestService from "../../../sevices/test/transactions-test-service";

/**
 * It returns an object with a type property and a transaction property
 * @param {ITransaction} transaction - ITransaction - this is the transaction object that we want to set in the store.
 */
export const setTransactionInfo = (transaction: ITransaction): ISetTransactionInfo => ({
  type: TransactionInfoActions.SET_TRANSACTION_INFO,
  transaction
});

/**
 * It returns an object with a type of SET_TRANSACTION_LOADING and a loading property
 * @param {boolean} loading - boolean
 */
export const setTransactionLoading = (loading: boolean): ISetTransactionLoading => ({
  type: TransactionInfoActions.SET_TRANSACTION_LOADING,
  loading
});

/**
 * It returns an object with a type property and an error property
 * @param {number} error - number
 */
export const setTransactionError = (error: number): ISetTransactionError => ({
  type: TransactionInfoActions.SET_TRANSACTION_ERROR,
  error
});

/**
 * It returns an object with a type property set to CLEAR_TRANSACTION_INFO
 */
export const clearTransactionInfo = (): IClearTransactionInfo => ({
  type: TransactionInfoActions.CLEAR_TRANSACTION_INFO
});

/**
 * It returns an object with a type property set to the value of the POST_TRANSACTION_RESULT constant
 */
export const postTransactionResult = (): IPostTransactionResult => ({
  type: TransactionInfoActions.POST_TRANSACTION_RESULT
});

/**
 * It fetches transaction info from the backend and dispatches the result to the redux store
 * @param {string} transactionId - string - the transaction ID that we want to get information about
 */
export const fetchTransactionInfo = (transactionId: number) => async (dispatch: Dispatch) => {
  try {
    dispatch(clearTransactionInfo());
    dispatch(setTransactionLoading(true));

    const { data } =
            USE_SERVER
              ?
              await TransactionsService.transactionInfo(transactionId)
              :
              await TransactionsTestService.transactionInfo(transactionId);

    if (data.result) {
      dispatch(setTransactionInfo(data.data as unknown as ITransaction));
    } else {
      throw new Error(data.error);
    }
  } catch (e: any) {
    //TODO Edit to general Error handler
    const error: number | undefined = e?.response?.status;
    if (typeof error === "number"){
      dispatch(setTransactionError(error));
    }
  } finally {
    dispatch(setTransactionLoading(false));
  }
};

/**
 * It takes a transaction object as an argument, and returns a function that takes a dispatch function as an argument, and
 * returns a promise that dispatches a loading action, a post transaction result action, and either a transaction info
 * action or an error action
 * @param {ITransaction} transactionWithResultStatus - ITransaction - the transaction object with the result status
 */
// export const fetchTransactionResult = (transactionWithResultStatus: ITransaction) => async (dispatch: Dispatch) => {
//   const { transactionId, hmac, status } = transactionWithResultStatus;
//   try {
//     dispatch(setTransactionLoading(true));
//     dispatch(postTransactionResult());
//
//     const { data } = await TransactionsService.transactionResult({ transactionId, hmac, status });
//     if (data.result) {
//       dispatch(setTransactionInfo(transactionWithResultStatus));
//     } else {
//       throw new Error(data.errors && data.errors.join(" "));
//     }
//   } catch (e: any) {
//     const error: number | undefined = e?.response?.status;
//     if (typeof error === "number"){
//       dispatch(setTransactionError(error));
//     }
//   } finally {
//     dispatch(setTransactionLoading(false));
//   }
// };
