import { Dispatch } from "redux";
import AccountService from "../../../sevices/account-service";
import { AccountStatusesActions, IAccountStatusesState } from "../../../types/store/account/account-statuses";
import {LIFETIME_STATUSES, USE_SERVER} from "../../../constants/constants";
import AccountTestService from "../../../sevices/test/account-test-service";

const accountStorage = "account_storage";


export const setAccountStatusesLoading = () => ({ type: AccountStatusesActions.SET_ACCOUNT_STATUSES_LOADING });

export const setAccountStatusesSuccess = (payload: IAccountStatusesState) => ({ type: AccountStatusesActions.SET_ACCOUNT_STATUSES_SUCCESS, payload });

export const setAccountStatusesFailure = () => ({ type: AccountStatusesActions.SET_ACCOUNT_STATUSES_FAILURE });

/**
 * It fetches the account statuses from the server, and if it fails, it tries to fetch them from local storage
 */
export const fetchAccountStatuses = () => async (dispatch: Dispatch) => {
  
  dispatch(setAccountStatusesLoading());

  try {
    const { statuses, expired } = JSON.parse(localStorage.getItem(accountStorage) as string) || { statuses: {}, expired: 0 };
    if (expired > Date.now())
      return dispatch(setAccountStatusesSuccess({
        statuses,
        expired,
      }));

    const { data } =
            USE_SERVER
              ?
              await AccountTestService.accountStatuses() //AccountService.accountStatuses()
              :
              await AccountTestService.accountStatuses();
    const { result, errors } = data;

    if (result && typeof result === "object") {
      const payload = {
        statuses: result.statuses,
        expired: Date.now() + LIFETIME_STATUSES,
      };

      localStorage.setItem(accountStorage, JSON.stringify(payload));
      dispatch(setAccountStatusesSuccess(payload));
    } else { 
      throw new Error(errors?.join(" "));
    }
  } catch (error) {

    dispatch(setAccountStatusesFailure());
  }
};
