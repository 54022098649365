import React from "react";
import {parseObjectData} from "../../../utils/parse-json";
import {getDateTime} from "../../../utils/time";
import AccountDetailGridRow from "../../account/account-detail-grid-row";
import {toCapitalizedWords} from "../../../utils/common";
import yn from "yn";
import {Typography} from "@mui/material";
import {IApplicationInfo} from "../../../types/store/applications";

interface IProps {
    loading?: boolean,
    keys: Array<keyof IApplicationInfo>,
    applicationData?: IApplicationInfo
}

export const GeneralDetails = ({loading, keys, applicationData}: IProps) => {
  return (
    <>
      {!loading && keys.length > 0 && keys.map((key , index) => (
        <React.Fragment key={index}>
          {/*TODO move next code to helper*/}
          {typeof applicationData?.[key] === "string" &&
                        <>
                          {key === "termsAndConditions" && parseObjectData(applicationData[key], getDateTime)}
                          {key === "details" && <></>}
                          {key === "createdAt" || key === "updatedAt" && (
                            <AccountDetailGridRow
                              title={toCapitalizedWords(key)}
                              value={getDateTime(applicationData[key])}
                            />
                          )}
                          {key !== "termsAndConditions" &&
                                key !== "createdAt" &&
                                key !== "details" &&
                                key !== "updatedAt" && (
                            <AccountDetailGridRow
                              title={toCapitalizedWords(key)}
                              value={applicationData[key] as string}
                            />
                          )}
                        </>
          }
          {typeof applicationData?.[key] === "number" &&
                        <>
                          {key === "id" && (
                            <AccountDetailGridRow
                              title={key}
                              value={String(applicationData[key])}
                            />
                          )}
                          {key === "pep" && (
                            <AccountDetailGridRow
                              title={toCapitalizedWords(key)}
                              value={yn(applicationData[key]) ? "is a PEP" : "is not a PEP"}
                            />
                          )}
                          {key === "usCitizenship" && (
                            <AccountDetailGridRow
                              title={toCapitalizedWords(key)}
                              value={yn(applicationData[key]) ? "is a not US citizen" : "is a US citizen"}
                            />
                          )}
                        </>
          }
          {typeof applicationData?.[key] === "object" &&
                        Array.isArray(applicationData[key]) &&
                        key === "addressProof" &&
                        (
                          <>
                            <Typography>Proof of address</Typography>
                            {(applicationData as IApplicationInfo)[key]?.map((img, imgIndex) => (
                              <AccountDetailGridRow
                                key={imgIndex}
                                title={""}
                                value={img.s3Link}
                                contentType={img.contentType}
                              />
                            ))}
                          </>
                        )
          }
        </React.Fragment>
      ))}
    </>
  );
};