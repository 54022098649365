import {ITransaction} from "./index";

export interface ITransactionInfoState {
    isLoading: boolean,
    transaction: ITransaction,
    error?: number
}

export enum TransactionInfoActions {
    SET_TRANSACTION_INFO = "SET_TRANSACTION_INFO",
    SET_TRANSACTION_LOADING = "SET_TRANSACTION_LOADING",
    SET_TRANSACTION_ERROR = "SET_TRANSACTION_ERROR",
    CLEAR_TRANSACTION_INFO = "CLEAR_TRANSACTION_INFO",
    POST_TRANSACTION_RESULT = "POST_TRANSACTION_RESULT",
}

export interface ISetTransactionInfo {
    type: TransactionInfoActions.SET_TRANSACTION_INFO,
    transaction: ITransaction
}

export interface ISetTransactionLoading {
    type: TransactionInfoActions.SET_TRANSACTION_LOADING,
    loading: boolean
}

export interface ISetTransactionError {
    type: TransactionInfoActions.SET_TRANSACTION_ERROR,
    error: number,
}

export interface IClearTransactionInfo {
    type: TransactionInfoActions.CLEAR_TRANSACTION_INFO
}

export interface IPostTransactionResult { 
    type: TransactionInfoActions.POST_TRANSACTION_RESULT
}

export type TTransactionInfoAction =
    ISetTransactionInfo
    | ISetTransactionLoading
    | ISetTransactionError
    | IClearTransactionInfo
    | IPostTransactionResult
    ;
