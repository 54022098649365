import React, { Fragment, useEffect } from "react";
import { useActions } from "../../../hooks/useActions";
import { useParams } from "react-router-dom";
import TreeView from "@mui/lab/TreeView";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import TreeItem from "@mui/lab/TreeItem";
import { parseObjectData } from "../../../utils/parse-json";
import { useTypedSelector } from "../../../hooks/useTypedSelector";
import AccountDetailGridRow from "../account-detail-grid-row";
import { toCapitalizedWords } from "../../../utils/common";
import {
  IAccountDetailsCorporate,
  IAccountDetailsIndividual,
} from "../../../types/store/account/account-details";
import { getDateTime } from "../../../utils/time";
import yn from "yn";
import { Typography } from "@mui/material";
import { AccountCorporateDetailsPersons } from "./account-corporate-details-persons";
import AccountCorporateDetailsCompanies from "./account-corporate-details-companies";

export function AccountCorporateDetails() {
  const { fetchAccountDetailsCorporate } = useActions();
  const URLParams = useParams();
  const userAccountId = URLParams.id || "";
  const { corporateDetails } = useTypedSelector(
    (state) => state.accountDetails
  );
  const corporateDetailsPersons = corporateDetails.details?.persons;
  const corporateDetailsCompanies = corporateDetails.details?.companies;

  useEffect(() => {
    if (!userAccountId) return;

    fetchAccountDetailsCorporate(userAccountId);
  }, []);

  return (
    <TreeView
      defaultCollapseIcon={<ExpandMoreIcon />}
      defaultExpandIcon={<ChevronRightIcon />}
    >
      <TreeItem nodeId="1" label={"Account info corporate"}>
        {corporateDetails &&
          (
            Object.keys(corporateDetails) as Array<
              keyof IAccountDetailsCorporate
            >
          ).map((item) => {
            if (
              typeof corporateDetails[item] === "string" ||
              typeof corporateDetails[item] === "boolean" ||
              typeof corporateDetails[item] === "number"
            ) {
              return (
                <AccountDetailGridRow
                  key={item}
                  title={toCapitalizedWords(item.toString())}
                  value={String(corporateDetails[item])}
                />
              );
            }

            if (item === "details") {
              return (
                <Fragment key={item}>
                  <Typography variant={"h5"}>Details</Typography>
                  <TreeView
                    defaultCollapseIcon={<ExpandMoreIcon />}
                    defaultExpandIcon={<ChevronRightIcon />}
                  >
                    <TreeItem nodeId="1" label={"Persons"}>
                      <TreeView
                        defaultCollapseIcon={<ExpandMoreIcon />}
                        defaultExpandIcon={<ChevronRightIcon />}
                      >
                        <AccountCorporateDetailsPersons
                          corporateDetailsPersons={corporateDetailsPersons}
                        />
                      </TreeView>
                    </TreeItem>
                    <TreeItem nodeId="2" label={"Companies"}>
                      <AccountCorporateDetailsCompanies
                        corporateDetailsCompanies={corporateDetailsCompanies}
                      />
                    </TreeItem>
                  </TreeView>
                </Fragment>
              );
            }
          })}
      </TreeItem>
    </TreeView>
  );
}
