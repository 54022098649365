import React, {FC} from "react";
import {Alert, AlertTitle, Container} from "@mui/material";
import {AlertColor} from "@mui/material/Alert/Alert";

interface AlertMessageProps {
    severity: AlertColor,
    title: string,
    message: string
}

/**
 * General component to show alert message
 * `const AlertMessage: FC<AlertMessageProps> = ({severity, title, message}) => {`
 *
 * The `AlertMessage` function is a React component that takes in a `severity`, `title`, and `message` as props
 * @param severity - The severity of the alert. This can be one of the following:
 * @param title - Title of alert
 * @param message - Message of alert
 * @returns A React component that takes in props and returns an Alert component from Material UI.
 */
const AlertMessage: FC<AlertMessageProps> = ({severity, title, message}) => {
  return (
    <Container sx={{mt: 2}}>
      <Alert severity={severity}>
        <AlertTitle>{title}</AlertTitle>
        {message}
      </Alert>
    </Container>
  );
};

export default AlertMessage;