import React, {useEffect} from "react";
import RoleForm from "../../../components/role/role-form";
import {Container, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import {useTypedSelector} from "../../../hooks/useTypedSelector";
import Loading from "../../../components/loading";
import {useParams} from "react-router-dom";
import {useActions} from "../../../hooks/useActions";
import AlertMessage from "../../../components/common/alert-message";
import ability from "../../../utils/can";
import Error404 from "../../error-pages/error-404";
import {Can} from "@casl/react";

/**
 * A React component is used for show role info and edit role.
 */
const RoleEdit = () => {
  const {t} = useTranslation();
  const {isLoading} = useTypedSelector(state => state.roleInfo);
  const {fetchRoleInfo, clearRoleInfo} = useActions();
  const roleId = useParams()?.id;

  useEffect(() => {
    if (roleId && +roleId > 0 && ability.can("get", "role")) {
      fetchRoleInfo(roleId);
    }
    return () => {
      clearRoleInfo();
    };
  }, []);

  if (ability.cannot("get", "role"))
    return <Error404 />;

  if (isLoading){
    return <Loading/>;
  }
  else if (!roleId || +roleId <= 0){
    return (
      <AlertMessage severity={"error"} title={"Error"} message={"Role ID is not correct."}/>
    );
  }

  return (
    <Can I="get" a="role" ability={ability}>
      <Container>
        <Typography
          variant="h5"
          sx={{display: "flex", justifyContent: "center", mb: 2}}
        >
          {t("role.edit.header")}
        </Typography>
        <RoleForm roleId={+roleId} type={"edit"}/>
      </Container>
    </Can>
  );
};

export default RoleEdit;