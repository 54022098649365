import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {useTypedSelector} from "../../../hooks/useTypedSelector";
import RoleForm from "../../../components/role/role-form";
import {useNavigate} from "react-router-dom";
import {Container, Typography} from "@mui/material";
import {useActions} from "../../../hooks/useActions";
import ability from "../../../utils/can";
import Error404 from "../../error-pages/error-404";
import {Can} from "@casl/react";

/** A React Component is using to add role. */
const RoleAdd = () => {
  const {t} = useTranslation();
  const {role} = useTypedSelector(state => state.roleInfo);
  const {clearRoleInfo} = useActions();
  const navigate = useNavigate();

  useEffect(() => {
    return () => {
      clearRoleInfo();
    };
  }, []);

  useEffect(() => {
    if (role.id > 0){
      navigate("/role/edit/" + role.id);
    }
  }, [role.id]);

  if (ability.cannot("create", "role")) {
    return <Error404/>;
  }

  return (
    <Can I="create" a="role" ability={ability}>
      <Container>
        <Typography
          variant="h5"
          sx={{display: "flex", justifyContent: "center", mb: 2}}
        >
          {t("role.add.header")}
        </Typography>
        <RoleForm type="add"/>
      </Container>
    </Can>
  );
};

export default RoleAdd;