import { Dispatch } from "redux";
import {LIFETIME_STATUSES, USE_SERVER} from "../../../constants/constants";
import { ITransactionStatusesState, TransactionStatusesActions } from "../../../types/store/transaction/transaction-statuses";
import TransactionsService from "../../../sevices/transactions-service";
import TransactionsTestService from "../../../sevices/test/transactions-test-service";

const transactionStorage = "transaction_storage";

export const setTransactionStatusesLoading = () => ({ type: TransactionStatusesActions.SET_TRANSACTION_STATUSES_LOADING });

export const setTransactionStatusesSuccess = (payload: ITransactionStatusesState) => ({ type: TransactionStatusesActions.SET_TRANSACTION_STATUSES_SUCCESS, payload });

export const setTransactionStatusesFailure = () => ({ type: TransactionStatusesActions.SET_TRANSACTION_STATUSES_FAILURE });

/**
 * It fetches the transaction statuses from the local storage if they are not expired, otherwise it fetches them from the
 * server and saves them to the local storage
 */
export const fetchTransactionStatuses = () => async (dispatch: Dispatch) => {
  
  dispatch(setTransactionStatusesLoading());

  try {
    const { statuses, expired } = JSON.parse(localStorage.getItem(transactionStorage) as string) || { statuses: {}, expired: 0 };
    if (expired > Date.now())
      return dispatch(setTransactionStatusesSuccess({
        statuses,
        expired,
      }));

    const { data } =
            USE_SERVER
              ?
              await TransactionsService.transactionStatuses()
              :
              await TransactionsTestService.transactionStatuses();
    const { result, errors } = data;

    if (result && typeof result === "object") {
      const payload = {
        statuses: result.statuses,
        expired: Date.now() + LIFETIME_STATUSES,
      };

      localStorage.setItem(transactionStorage, JSON.stringify(payload));
      dispatch(setTransactionStatusesSuccess(payload));
    } else { 
      throw new Error(errors?.join(" "));
    }
  } catch (error) {

    dispatch(setTransactionStatusesFailure());
  }
};
