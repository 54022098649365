
import axios, {AxiosResponse} from "axios";
import {store} from "../store";
import {setAuthStatus, setAuthUser} from "../store/actions/auth";
import {IUser} from "../types/store/auth";
import {LocalStorageItems} from "../types/local-storage";
import {AuthResponseHeaders} from "../types/service/response/auth-response";
import {API_URL} from "../constants/constants";
import Cookies from "js-cookie";
import jwtDecode from "jwt-decode";

const $api = axios.create({
  withCredentials: true,
  baseURL: API_URL,
});

const $apiWithoutToken = axios.create({
  withCredentials: true,
  baseURL: API_URL,
});

function clearUserInfo() {
  Cookies.remove(LocalStorageItems.TOKEN);
  store.dispatch(setAuthUser({} as IUser));
  store.dispatch(setAuthStatus(false));
}

$api.interceptors.request.use((config) => {
  if (!Cookies.get(LocalStorageItems.TOKEN)) {
    clearUserInfo();
    throw new Error("Token not found!");
  }
  config.headers && (config.headers.Authorization = `Bearer ${Cookies.get(LocalStorageItems.TOKEN)}`);
  return config;
}, () => {console.log("Token not found!");}
);

const responseCallback = (config: AxiosResponse) => {
  if (config.headers?.[AuthResponseHeaders.ACCESS_TOKEN]) {
    const expires: number | undefined = (jwtDecode(config.headers[AuthResponseHeaders.ACCESS_TOKEN]) as any)?.exp;
    let expiresInDays = 1;
    if (expires) expiresInDays = (expires * 1000 - Date.now()) / (24 * 60 * 60 * 1000 );
    Cookies.set(LocalStorageItems.TOKEN, config.headers[AuthResponseHeaders.ACCESS_TOKEN], {expires: expiresInDays});
  }
  return config;
};

const responseErrorCallback = async (error: any) => {
  if (error?.response?.status && error.response.status === 401) {
    clearUserInfo();
  }
  throw error;
};

$api.interceptors.response.use(responseCallback, responseErrorCallback);
$apiWithoutToken.interceptors.response.use(responseCallback, responseErrorCallback);

/** Settings to processing request/response of axios. */
export default $api;

export {
  $apiWithoutToken
};