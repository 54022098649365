import {RoleInfoResponse, RoleListResponse} from "../../types/service/response/roles-response";
import {IRequestResponseType, IResponseError} from "../../types/service/test/test-response";
import {IPermission} from "../../types/store/permissions";
import {IGeneralResponse} from "../../types/service/response/general-response";
import {IRole} from "../../types/store/role";

/** A mock service for testing `Role` component. */
export default class RoleTestService {
  static async roleList(): Promise<IRequestResponseType<unknown, RoleListResponse>> {
    return {
      headers: undefined,
      data: {
        result: [
          {
            "id": 1,
            "name": "initial-admin",
            "createdAt": "2022-04-14T11:13:19.091Z",
            "updatedAt": "2022-04-21T12:07:50.613Z"
          }
        ]
      }
    };
  }

  static async roleInfo(id: number | string): Promise<IRequestResponseType<unknown, RoleInfoResponse>> {
    return {
      headers: undefined,
      data: {
        "result": {
          "id": +id,
          "name": "initial-admin",
          "createdAt": "2022-04-14T11:13:19.091Z",
          "updatedAt": "2022-04-21T12:07:50.613Z",
          "permissions": [
            {
              "id": 1,
            },
            {
              "id": 2,
            },
            {
              "id": 3,
            },
            {
              "id": 4,
            },
            {
              "id": 5,
            },
            {
              "id": 6,
            },
            {
              "id": 7,
            },
            {
              "id": 8,
            },
            {
              "id": 9,
            },
            {
              "id": 10,
            },
            {
              "id": 11,
            },
            {
              "id": 12,
            },
            {
              "id": 13,
            },
            {
              "id": 14,
            },
            {
              "id": 15,
            },
            {
              "id": 16,
            },
            {
              "id": 17,
            },
            {
              "id": 18,
            },
            {
              "id": 19,
            }
          ]
        }
      }
    };
  }

  static async updateRole(id: number, name: string, permissions: IPermission[]): Promise<IRequestResponseType<unknown, IGeneralResponse<IRole>>> {
    return {
      headers: undefined,
      data: {
        "result": {
          "id": id,
          "name": name,
          "permissions": permissions
        }
      }
    };
  }

  static async createRole(name: string, permissions: IPermission[]): Promise<IRequestResponseType<unknown, IGeneralResponse<IRole>>> {
    return {
      headers: undefined,
      data: {
        "result": {
          "id": 1,
          "name": name,
          "permissions": permissions
        }
      }
    };
  }

  static async deleteRole(id: number, confirmDelete: boolean): Promise<IRequestResponseType<unknown, IGeneralResponse<Pick<IRole, "id">>>> {
    if (confirmDelete) {
      return {
        headers: undefined,
        data: {
          "result": {
            "id": id
          }
        },
      };
    } else {
      throw {
        response: {
          headers: undefined,
          data: {
            "result": false,
            errors: [
              "This role have users"
            ]
          },
          status: 422
        }
      } as IResponseError<unknown, unknown>;
    }
  }
}