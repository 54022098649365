import React  from "react";
import { Container, Grid } from "@mui/material";
import ability from "../../../utils/can";
import Error404 from "../../error-pages/error-404";
import ApplicationsListTable from "../../../components/applications/applications-list-table";
import {TApplicationStatus} from "../../../types/store/applications";
import { theme } from "../../../theme";

export const getColorByApplicationStatus = (status: TApplicationStatus) => {
  switch (status) {
  case "received":
    return theme.palette.warning.main;
  case "approved":
    return theme.palette.success.main;
  case "rejected":
    return theme.palette.error.main;
  }
};

/**
 * A React component that return table with applications list.
 * @returns A component that renders a table of applications (requests on opening account of the Satchel bank) .
 */
const ApplicationsList = () => {
  if (ability.cannot("get", "satchel_accounts"))
    return <Error404 />;

  return (
    <Container maxWidth="xl">
      <Grid container columns={16} spacing={3}>
        <Grid item xs={16} md={16}>
          <ApplicationsListTable />
        </Grid>
      </Grid>
    </Container>
  );
};

export default ApplicationsList;
