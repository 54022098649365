import React, {useEffect} from "react";
import {Container, Typography} from "@mui/material";
import UserForm from "../../../components/user/user-form";
import {useTranslation} from "react-i18next";
import {useActions} from "../../../hooks/useActions";
import {useTypedSelector} from "../../../hooks/useTypedSelector";
import {useNavigate} from "react-router-dom";
import ability from "../../../utils/can";
import Error404 from "../../error-pages/error-404";
import { Can } from "@casl/react";

/** A React component that is used to add user. */
const UserAdd = () => {
  const {t} = useTranslation();
  const {clearUserInfo} = useActions();
  const {user} = useTypedSelector(state => state.userInfo);
  const navigate = useNavigate();

  useEffect(() => {
    return () => {
      clearUserInfo();
    };
  }, []);

  useEffect(() => {
    if (user?.id > 0){
      navigate("/user/edit/" + user.id);
    }
  }, [user.id]);

  if (ability.cannot("create", "user")) {
    return <Error404 />;
  }

  return (
    <Can I="create" a="user" ability={ability}>
      <Container>
        <Typography
          variant="h5"
          sx={{ display: "flex", justifyContent: "center", mb: 4 }}
        >
          {t("user.add.header")}
        </Typography>
        <UserForm type={"add"} />
      </Container>
    </Can>
  );
};

export default UserAdd;