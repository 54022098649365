import React, {FC, useEffect, useState} from "react";
import RoutesView from "../../routes";
import {useNavigate} from "react-router-dom";
import {useTypedSelector} from "../../hooks/useTypedSelector";
import MenuBar from "../../components/layout/menu/menu-bar";
import {LayoutProps} from "../../types/components/layout";
import Loading from "../loading";
import {setLayout} from "../../store/actions/layout";
import {layoutList} from "../../store/reducers/layout";
import {Box, styled} from "@mui/material";
import {SxProps} from "@mui/system";

/**
 * A React Component that mounted when user is authenticated.
 *
 * It checks if the user is authenticated, if not, it redirects to the login page, if yes, it renders the menu bar and the
 * routes
 * @param loading - is a boolean value that indicates whether the application is loading or not.
 * @param isAuthPath
 * @param isNotAuthPath
 * @returns A React component that renders a menu bar, a loading component, and a routes view.
 */
const MainLayout: FC<LayoutProps> = ({loading, isAuthPath, isNotAuthPath}) => {
  const navigate = useNavigate();
  const {isAuth} = useTypedSelector(state => state.auth);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(false);
    if (!isAuth) {
      if (isAuthPath){
        navigate("/");
        setLayout(layoutList.AUTH);
      } else {
        setLayout(layoutList.AUTH);
      }
    } else {
      isNotAuthPath && navigate("/home");
    }
    setIsVisible(true);

  }, [isAuth]);

  const Offset: FC<{ sx?: SxProps }> = ({sx}) => {
    const OffsetBlock = styled("div")(({theme}) => theme.mixins.toolbar);
    return (
      <Box sx={sx ?? {}}>
        <OffsetBlock/>
      </Box>
    );
  };

  return (
    <Box
      sx={{display: {sm: "block", md: "flex"}}}
    >
      <MenuBar/>
      {(loading || !isVisible) && <Loading/>}
      <Box sx={{
        flexGrow: {xs: 0, md: 1, minHeight: "100vh"}
      }}>
        <Offset/>
        <Box sx={{p:2}}>
          <RoutesView/>
        </Box>
      </Box>
    </Box>
  );
};

export default MainLayout;
