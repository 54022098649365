import React from "react";
import {Card, CardContent, Container, Grid, Typography} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {SxProps} from "@mui/system";
import {Theme} from "@mui/material/styles";
import {useTranslation} from "react-i18next";
import { Can } from "@casl/react";
import ability from "../../../utils/can";

const cardStyles: SxProps<Theme> = {
  width: "270px",
  height: "150px",
  textDecoration: "none",
  textAlign: "center",
  cursor: "pointer",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  mr: "auto",
  ml: "auto"
};

/** A React component that renders links block to management . */
const Management = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  return (
    <Container>
      <Typography
        variant="h5"
        sx={{display: "flex", justifyContent: "center", mb: 2}}
      >
        {t("management.header")}
      </Typography>
      <Grid container columnSpacing={2} rowSpacing={6} sx={{ml: "auto", mr: "auto"}}>
        <Can I={"get"} a={"permissions"} ability={ability}>
          <Grid item xs={12} sm={6} md={4}>
            <Card sx={cardStyles} onClick={() => { navigate("/permissions"); }}>
              <CardContent>
                <Typography variant={"h5"}>
                  {t("menu.permissions")}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Can>
        <Can I={"get"} a={"roles"} ability={ability}>
          <Grid item xs={12} sm={6} md={4}>
            <Card sx={cardStyles} onClick={() => { navigate("/roles"); }}>
              <CardContent>
                <Typography variant={"h5"}>
                  {t("menu.roles")}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Can>
        <Can I={"get"} a={"users"} ability={ability}>
          <Grid item xs={12} sm={6} md={4}>
            <Card sx={cardStyles} onClick={() => { navigate("/users"); }}>
              <CardContent>
                <Typography variant={"h5"}>
                  {t("menu.users")}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Can>
        {/* <Grid item xs={12} sm={6} md={4}>
          <Card sx={cardStyles} onClick={() => { window.open("http://3.70.247.201:3000/", "_blank"); }}>
            <CardContent>
              <Typography variant={"h5"}>
                {t("menu.Blockchain monitoring")}
              </Typography>
            </CardContent>
          </Card>
        </Grid> */}
      </Grid>
    </Container>
  );
};

export default Management;