import React, {FC, useEffect} from "react";
import {
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow
} from "@mui/material";
import {useTranslation} from "react-i18next";
import {useTypedSelector} from "../../../hooks/useTypedSelector";
import {HeadCell} from "../../../types/table";
import {getDateTime} from "../../../utils/time";
import { useActions } from "../../../hooks/useActions";
import {IWalletTransaction} from "../../../types/store/wallet/wallet-transaction-list";
import CustomTablePaginationActionsNew from "../../common/table-pagination-new";

/** A React component that displays a list of transactions for a given account. */
const  WalletTransactionList: FC = () => {
  const {t} = useTranslation();
  const {isLoading, transactionList, page, perPage, totalPages, walletId } = useTypedSelector(state => state.walletTransactionList);
  const {fetchWalletTransactionList, setWalletTransactionListPage, setWalletTransactionListPerPage} = useActions();

  const headCells: HeadCell<IWalletTransaction & { [key: string]: string }>[] = [
    {
      id: "id",
      numeric: true,
      label: t("wallet.table.Id"),
      disablePadding: false,
    },
    {
      id: "amount",
      numeric: true,
      label: t("wallet.table.Amount"),
      disablePadding: false,
    },
    {
      id: "fromAccount",
      numeric: false,
      label: t("wallet.table.fromAccount"),
      disablePadding: false,
    },
    {
      id: "toAccount",
      numeric: true,
      label: t("wallet.table.toAccount"),
      disablePadding: false,
    },
    {
      id: "date",
      numeric: false,
      label: t("wallet.table.date"),
      disablePadding: false,
    },
  ];

  const handlePageChange = (event: unknown, page: number) => {
    if (!isLoading) setWalletTransactionListPage(page);
  };

  const handlePerPageChange = (perPage: number) => {
    if (!isLoading) {
      setWalletTransactionListPerPage(perPage);
      setWalletTransactionListPage(1);
    }
  };

  useEffect(() => {
    if (walletId) fetchWalletTransactionList();
  }, [walletId, perPage, page]);

  return (
    <TableContainer component={Paper}>
      {walletId &&
                <Table sx={{minWidth: 650}} aria-label="Transactions list table" size={"small"}>
                  <TableHead>
                    <TableRow sx={{"& .MuiTableCell-head": {fontWeight: 600}}}>
                      {headCells.map((headCell) => (
                        <TableCell
                          sx={{color: headCell.isHide ? "transparent" : ""}}
                          key={headCell.id}
                        >
                          {headCell.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {isLoading && Array(10).fill("").map((row, index) => (
                      <TableRow
                        key={index}
                        sx={{"&:last-child td, &:last-child th": {border: 0}}}
                      >
                        {Array(headCells.length).fill("").map((row, index) => (
                          <TableCell key={index}>
                            <Skeleton height={26}/>
                          </TableCell>
                        ))}
                      </TableRow>
                    ))}
                    {!isLoading && transactionList?.map((transaction) => (
                      <TableRow
                        key={transaction.id}
                        sx={{"&:last-child td, &:last-child th": {border: 0}}}
                        hover
                      >
                        <TableCell>{transaction.id}</TableCell>
                        <TableCell>{transaction.amount + " " + transaction.currency_code}</TableCell>
                        <TableCell>{transaction.sender_account_number}</TableCell>
                        <TableCell>{transaction.beneficiary_account_number || transaction.beneficiary_iban}</TableCell>
                        <TableCell>{getDateTime(transaction.created_at)}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                  <TableFooter>
                    <TableRow>
                      <TableCell colSpan={5}>
                        <CustomTablePaginationActionsNew
                          totalPages={totalPages}
                          page={page}
                          rowsPerPage={perPage}
                          onPageChange={handlePageChange}
                          onPerPageChange={handlePerPageChange}
                        />
                      </TableCell>
                    </TableRow>
                  </TableFooter>
                </Table>
      }
    </TableContainer>
  );
};

export default WalletTransactionList;