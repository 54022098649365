import {combineReducers} from "redux";
import {LayoutReducer} from "./layout";
import {authReducer} from "./auth";
import {accountsListReducer} from "./account/accounts-list";
import {accountDetailsReducer} from "./account/account-details";
import {accountStatusesReducer} from "./account/account-statuses";
import {transactionStatusesReducer} from "./transaction/transaction-statuses";
import {transactionsListReducer} from "./transaction/transactions-list";
import {transactionInfoReducer} from "./transaction/transaction-info";
import {permissionsListReducer} from "./permissions";
import {roleListReducer} from "./role/role-list";
import {headerMenuReducer} from "./header-menu";
import {userListReducer} from "./user/user-list";
import {userInfoReducer} from "./user/user-info";
import {roleInfoReducer} from "./role/role-info";
import {accountTransactionListReducer} from "./account/account-transaction-list";
import {profileReducer} from "./profile";
import {merchantReducer} from "./merchant/merchant";
import {merchantListReducer} from "./merchant/merchantList";
import {walletAccountsReducer} from "./wallet/wallet-accounts";
import {cardReducer} from "./card";
import {cardListReducer} from "./card/card-list";
import {sanctionListReducer} from "./sanction/list";
import { sanctionCountriesReducer } from "./sanction/countries";
import {walletTransactionListReducer} from "./wallet/wallet-transaction-list";
import { applicationsListReducer } from "./applications/applications-list";

export const rootReducer = combineReducers({
  layout: LayoutReducer,
  auth: authReducer,
  accountsStatuses: accountStatusesReducer,
  accountsList: accountsListReducer,
  accountDetails: accountDetailsReducer,
  transactionStatuses: transactionStatusesReducer,
  applicationsList: applicationsListReducer,
  transactionsList: transactionsListReducer,
  transactionInfo: transactionInfoReducer,
  permissionsList: permissionsListReducer,
  roleList: roleListReducer,
  headerMenu: headerMenuReducer,
  userList: userListReducer,
  userInfo: userInfoReducer,
  roleInfo: roleInfoReducer,
  accountTransactionList: accountTransactionListReducer,
  profile: profileReducer,
  merchant: merchantReducer,
  merchantList: merchantListReducer,
  walletAccounts: walletAccountsReducer,
  card: cardReducer,
  cardList: cardListReducer,
  sanctionList: sanctionListReducer,
  sanctionCountries: sanctionCountriesReducer,
  walletTransactionList: walletTransactionListReducer
});

export type RootState = ReturnType<typeof rootReducer>;
