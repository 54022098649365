import {AxiosResponse} from "axios";
import {
  IMerchantListResponse,
  IMerchantResponse,
  IMerchantResponseId, IMerchantResponseIdStatus
} from "../types/service/response/merchant-response";
import $api from "../http";
import {IMerchant, IMerchantType} from "../types/store/merchant";
import {IGeneralResponse} from "../types/service/response/general-response";

/** It's a class that contains static methods that make API calls to the backend */
export default class MerchantService {
  static async merchantInfo(id: number): Promise<AxiosResponse<IMerchantResponse>>{
    return $api.get("/merchants/get-by-id", {params: {id}});
  }
  static async merchantList(): Promise<AxiosResponse<IMerchantListResponse>>{
    return $api.get("/merchants/get-all");
  }
  static async merchantInfoByAccountId(id: string | number): Promise<AxiosResponse<IMerchantResponse>>{
    return $api.get("/merchants/get-by-wallet/",{params: {wallet: id}});
  }
  static async updateMerchant(id: number, data: Partial<IMerchant>): Promise<AxiosResponse<IMerchantResponseId>>{
    return $api.patch("/merchants/update-merchant", {id: id, ...data} );
  }
  static async createMerchant(data: Partial<IMerchant>): Promise<AxiosResponse<IMerchantResponseId>>{
    return $api.post("/merchants/add-merchant", data);
  }
  static async updateMerchantBlockStatus(id: number, status: 1 | 0): Promise<AxiosResponse<IMerchantResponseIdStatus>>{
    return $api.patch("/merchants/update-merchant-status", {id: id, status: status});
  }
  static async getMerchantTypes(): Promise<AxiosResponse<IGeneralResponse<IMerchantType[] | false>>>{
    return $api.get("/merchants/get-all-types");
  }
}