import React, {FC, useEffect} from "react";
import {Card, CardContent, Grid, MenuItem, TextField} from "@mui/material";
import {LoadingButton} from "@mui/lab";
import {useTranslation} from "react-i18next";
import {useTypedSelector} from "../../hooks/useTypedSelector";
import {useActions} from "../../hooks/useActions";
import {useFormik} from "formik";
import Loading from "../loading";
import {IUserDataToUpdate} from "../../types/store/user";
import ability from "../../utils/can";
import useNotifierMessage from "../../hooks/useNotifierMessage";
import { Can } from "@casl/react";

interface UserFormValues {
    name: string,
    email: string,
    password: string,
    passwordConfirm: string,
    roleId: number
}

interface UserFormParams {
    type: "edit" | "add",
    userId?: number
}

/** A React component that is used to create and edit users. */
const UserForm: FC<UserFormParams> = ({type, userId}) => {
  const { t } = useTranslation();
  const { showNoPermissionsMessage } = useNotifierMessage();
  const {user, isLoading, userValidationsErrors} = useTypedSelector(state => state.userInfo);
  const {roles} = useTypedSelector(state => state.roleList);
  const {fetchRoleList, updateUser, createUser, setUserValidationErrors} = useActions();

  const formik = useFormik({
    initialValues: {
      name: type === "edit" && user?.name || "",
      email: type === "edit" && user?.email || "",
      password: "",
      passwordConfirm: "",
      roleId: type === "edit" && user?.role?.id || 0,
    } as UserFormValues,

    validate: (values) => {
      const errors: Partial<Omit<UserFormValues, "roleId"> & {roleId: string}> = {};
      const name = validateName(values.name);
      const email = validateEmail(values.email);
      const password = validatePassword();
      const passwordConfirm = validatePasswordConfirm(values.password, values.passwordConfirm);
      const roleId = validateRoleId(values.roleId);
      if (name) {
        errors.name = name;
      }
      if (password) {
        errors.password = password;
      }
      if (email) {
        errors.email = email;
      }
      if (passwordConfirm) {
        errors.passwordConfirm = passwordConfirm;
      }
      if (roleId) {
        errors.roleId = roleId;
      }

      return errors;
    },

    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true);
      const dataToUpdate: IUserDataToUpdate = {
        name: values.name,
        email: values.email,
        password: values.password,
        role: {
          id: values.roleId
        }
      };
      if (ability.can("update", "user") && type === "edit" && userId && userId > 0) {
        if (dataToUpdate.password === "") delete dataToUpdate.password;
        updateUser(userId, dataToUpdate);
      }
      else if (ability.can("create", "user") && type === "add") {
        createUser(dataToUpdate);
      } else {
        showNoPermissionsMessage();
      }
      setSubmitting(false);
    }
  });

  const validateName = (name: string): string | undefined => {
    if (!name) {
      return t("validations.Name is required");
    }
  };
  const validatePassword = (): string | undefined => {
    if (userValidationsErrors?.password) {
      return t(`validations.${userValidationsErrors.password}`);
    }
  };
  const validateEmail = (email: string): string | undefined => {
    if (!email) {
      return t("validations.Email is required");
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
      return t("validations.Login must be a an email");
    } else if (userValidationsErrors?.email) {
      return t(`validations.${userValidationsErrors.email}`);
    }
  };
  const validatePasswordConfirm = (password: string, passwordConfirm: string): string | undefined => {
    if (password !== passwordConfirm) {
      return t("validations.Passwords do not match");
    }
  };
  const validateRoleId = (roleId: number): string | undefined => {
    if (roleId === 0) {
      return t("validations.Role is required");
    }
  };

  const {
    values,
    errors,
    handleChange,
    touched,
    handleSubmit,
    isSubmitting,
    handleBlur,
    validateForm
  } = formik;

  const handleChangeValue = () => {
    setUserValidationErrors({email: "", password: ""});
  };

  useEffect(() => {
    if (ability.can("get", "roles"))
      fetchRoleList();
  },[]);

  useEffect((): () => void => {
    //Run validating one more time, because state updating is async
    let mounted = true;
    if (mounted) {
      validateForm();
    }
    return () => mounted = false;
  }, [userValidationsErrors]);

  if (isLoading){
    return <Loading/>;
  }

  return (
    <Card>
      <CardContent>
        <form onSubmit={handleSubmit}>
          <input type="password" style={{display: "none"}}/>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                id="name"
                name="name"
                type="text"
                label={t("user.form.name")}
                error={touched.name && !!errors.name}
                helperText={touched.name && errors.name}
                sx={{my: 1, minHeight: 80}}
                fullWidth
                value={values.name}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                id="email"
                name="email"
                type="text"
                label={t("user.form.email")}
                error={touched.email && !!errors.email}
                helperText={touched.email && errors.email}
                sx={{my: 1, minHeight: 80}}
                fullWidth
                value={values.email}
                onChange={(e) => {
                  handleChangeValue();
                  handleChange(e);
                }}
                onBlur={handleBlur}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                aria-readonly
                id="password"
                name="password"
                type="password"
                label={t("user.form.password")}
                error={touched.password && !!errors.password}
                helperText={touched.password && errors.password}
                sx={{my: 1, minHeight: 80}}
                fullWidth
                value={values.password}
                onChange={(e) => {
                  handleChangeValue();
                  handleChange(e);
                }}
                onBlur={handleBlur}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                id="passwordConfirm"
                name="passwordConfirm"
                type="password"
                label={t("user.form.passwordConfirm")}
                error={touched.passwordConfirm && !!errors.passwordConfirm}
                helperText={touched.passwordConfirm && errors.passwordConfirm}
                sx={{my: 1, minHeight: 80}}
                fullWidth
                value={values.passwordConfirm}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Grid>
            <Can I={"get"} a={"role"} ability={ability}>
              <Grid item xs={12} md={6}>
                <TextField
                  id="roleId"
                  name="roleId"
                  select
                  label={t("user.form.role")}
                  sx={{ my: 1, minHeight: 80 }}
                  fullWidth
                  value={values.roleId}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.roleId && !!errors?.roleId}
                  helperText={touched.roleId && errors.roleId}
                  disabled={ability.cannot("get", "roles")}
                >
                  <MenuItem value={0}>
                                        -
                  </MenuItem>
                  {roles.map(role => (
                    <MenuItem key={role.id} value={role.id}>
                      {role.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Can>
          </Grid>
          <LoadingButton
            type="submit"
            variant="contained"
            color="success"
            loading={isSubmitting}
            disabled={(type === "add" && ability.cannot("create", "user")) ||
                            (type === "edit" && ability.cannot("update", "user"))}
          >
            {t("user.form.save")}
          </LoadingButton>
        </form>
      </CardContent>
    </Card>
  );
};

export default UserForm;