import {
  ILocalStoragePermissions,
  IPermission,
  ISetPermissionsList,
  ISetPermissionsListLoading,
  PermissionsListActions
} from "../../types/store/permissions";
import {Action} from "redux";
import PermissionsService from "../../sevices/permissions-service";
import {LIFETIME_STATUSES, USE_SERVER} from "../../constants/constants";
import {handleGeneralError} from "./error-handler";
import {ThunkDispatch} from "redux-thunk";
import PermissionsTestService from "../../sevices/test/permissions-test-service";

export const setPermissionsList = (permissions: IPermission[]): ISetPermissionsList => ({
  type: PermissionsListActions.SET_PERMISSIONS_LIST,
  permissions,
});

export const setPermissionsListLoading = (loading: boolean): ISetPermissionsListLoading => ({
  type: PermissionsListActions.SET_PERMISSIONS_LIST_LOADING,
  loading,
});

const LSPermissions = "permissions";

/** Method to fetch permission list from server. */
export const fetchPermissionsList = () => async (dispatch: ThunkDispatch<unknown, unknown, Action>) => {
  try {
    dispatch(setPermissionsListLoading(true));

    const {permissions, expired} = JSON.parse(localStorage.getItem(LSPermissions) as string) as ILocalStoragePermissions || {permissions: [], expired: 0} as ILocalStoragePermissions;
    if (expired > Date.now()) {
      return dispatch(setPermissionsList(permissions));
    }

    const response =
            USE_SERVER
              ?
              await PermissionsService.permissionsList()
              :
              await PermissionsTestService.permissionsList();
    let {result} = response.data;
    if (!Array.isArray(result)) result = [];

    localStorage.setItem(LSPermissions, JSON.stringify({permissions: result, expired: Date.now() + LIFETIME_STATUSES} as ILocalStoragePermissions));
    dispatch(setPermissionsList(result));
  } catch (e: unknown) {
    await dispatch(handleGeneralError(e));
  } finally {
    dispatch(setPermissionsListLoading(false));
  }
};