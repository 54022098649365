import React, {FC, useEffect} from "react";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid, Skeleton, Typography,
} from "@mui/material";
import WalletTransactionList from "./wallet-transaction-list";
import {useTypedSelector} from "../../../hooks/useTypedSelector";
import {useActions} from "../../../hooks/useActions";
import CardBackground from "../../../assets/img/card-background.jpg";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useTranslation } from "react-i18next";
import {setWalletTransactionListId} from "../../../store/actions/wallet/wallet-transaction-list";

/**
 interface of WalletAccount Props
 */
interface WalletAccountsProps {
    cardHeader?: string,
    isTechAccounts?: boolean,
}

/**
 * A React Component for showing transactions by account id
 */
const AccountWallets: FC<WalletAccountsProps> = ({ cardHeader, isTechAccounts = false }) => {
  const { t } = useTranslation();
  const {walletId} = useTypedSelector(state => state.walletTransactionList);
  const {fetchTechWalletAccounts, setWalletTransactionListId} = useActions();
  const {isLoading, wallets} = isTechAccounts 
    ? useTypedSelector(state => state.walletAccounts)
    : useTypedSelector(state => state.accountDetails)
    ;
  const handleChange = (nextView: string | null) => () => {
    if (nextView !== null) {
      setWalletTransactionListId(nextView);
    }
  };

  //TODO: relocate to TechEWallet component and add can ability
  useEffect(() => {
    isTechAccounts && fetchTechWalletAccounts();
  }, [isTechAccounts]);

  useEffect(() => {
    const [firstWallet] = wallets;
    setWalletTransactionListId(firstWallet?.accountNumber ?? null);
  }, [wallets]);

  return (
    <Card sx={{mt: 2}}>
      {cardHeader && (<CardHeader title={cardHeader}/>)}
      <CardContent>
        <Grid container columnSpacing={2} rowSpacing={6}>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              {isLoading && Array(3).fill("").map((row, index) => (
                <Grid key={index} item xs={12} sm={6} md={4}>
                  <Skeleton
                    sx={{
                      borderRadius: "10px",
                      height: "inherit",
                    }} variant="rectangular"/>
                </Grid>
              ))}
              {!isLoading && wallets && wallets.map((wallet, index) => (
                <Grid key={index} item xs={12} sm={6} md={4}>
                  <Card
                    sx={{
                      backgroundImage: `url(${CardBackground})`,
                      backgroundSize: "cover",
                      borderRadius: "10px",
                      minHeight: "180px",
                      color: "#ffffff",
                      cursor: "pointer",
                      // boxShadow: account.accountId === viewWalletId ? "inset -1px 3px 8px 5px #1F87FF, 2px 5px 16px 0px #0B325E, 5px 5px 15px 5px rgba(0,0,0,0)" : "unset",
                    }}
                    onClick={handleChange(wallet.accountNumber)}
                  >
                    <CardContent sx={{
                      boxSizing: "border-box",
                      height: "100%",
                      px: {
                        sm: 2,
                        md: 3
                      },
                      pt: 3,
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      position: "relative"
                    }}>
                      {wallet.accountNumber === walletId && (
                        <Box sx={{
                          position: "absolute",
                          top: "10px",
                          right: "10px"
                        }}>
                          <CheckCircleIcon sx={(theme) => ({
                            color: theme.palette.success.main,
                            fontSize: "1.5rem"
                          })}/>
                        </Box>
                      )}
                      <Box sx={{mb:2}}>
                        <Typography variant={"body1"}>
                          {t("eWallet.balance")}
                        </Typography>
                        <Typography variant={"body1"} sx={{fontSize: {xs: "1.5rem", lg: "2rem", minHeight: "48px"}}}>
                          {wallet.availableBalance} {wallet.currencyCode}
                        </Typography>
                      </Box>
                      <Box>
                        <Typography variant={"body1"} fontWeight={"bold"}>
                          {wallet.accountNumber}
                        </Typography>
                      </Box>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <WalletTransactionList/>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default AccountWallets;