export interface IMerchant {
    id: number,
    name: string,
    type: string,
    image: string,
    ewallet: string,
    min_amount?: string,
    max_amount?: string,
    status: 0 | 1,
    isUpdating?: boolean
    lang_key?: string
}

export interface IMerchantValidationErrors {
    name?: string,
    ewallet?: string,
    min_amount?: string,
    max_amount?: string
}

export interface IMerchantType {
    id: string,
    name: string
}

export interface IMerchantState {
    isLoading: boolean,
    merchant: IMerchant,
    errorCode?: number,
    errorMessage?: string,
    validationErrors?: IMerchantValidationErrors,
    merchantTypes: IMerchantType[]
}

export enum MerchantActions {
    SET_MERCHANT = "SET_MERCHANT",
    SET_MERCHANT_LOADING = "SET_MERCHANT_LOADING",
    SET_MERCHANT_ERROR = "SET_MERCHANT_ERROR",
    SET_MERCHANT_VALIDATION_ERRORS = "SET_MERCHANT_VALIDATION_ERRORS",
    CLEAR_MERCHANT = "CLEAR_MERCHANT",
    SET_MERCHANT_TYPES = "SET_MERCHANT_TYPES"
}

export interface ISetMerchant {
    type: MerchantActions.SET_MERCHANT,
    merchant: IMerchant
}

export interface ISetMerchantLoading {
    type: MerchantActions.SET_MERCHANT_LOADING,
    loading: boolean
}

export interface ISetMerchantError {
    type: MerchantActions.SET_MERCHANT_ERROR,
    errorCode: number,
    errorMessage: string
}

export interface ISetMerchantValidationError {
    type: MerchantActions.SET_MERCHANT_VALIDATION_ERRORS,
    validationErrors: IMerchantValidationErrors
}

export interface IClearMerchant {
    type: MerchantActions.CLEAR_MERCHANT;
}

export interface ISetMerchantTypeList {
    type: MerchantActions.SET_MERCHANT_TYPES,
    list: IMerchantType[]
}

export type TMerchantAction =
    ISetMerchant
    | ISetMerchantLoading
    | ISetMerchantError
    | IClearMerchant
    | ISetMerchantValidationError
    | ISetMerchantTypeList
