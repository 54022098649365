import React, { FC, useContext } from "react";
import { Box, Divider, Grid, Typography } from "@mui/material";
import { ModalImageWindowContext } from "../../providers/modalImageWindowProvider";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { red } from "@mui/material/colors";

const AccountDetailGridRow: FC<{
  title: string;
  value: string | number | null;
  hideDivider?: boolean;
  contentType?: string;
}> = ({ title, value, hideDivider, contentType }) => {
  const { handleOpenImage } = useContext(ModalImageWindowContext);
  return (
    <React.Fragment>
      <Grid container sx={{ display: "flex", alignItems: "center" }}>
        <Grid item xs={6} md={4}>
          <Typography variant="subtitle1">{title}</Typography>
        </Grid>
        <Grid item xs={6} md={8}>
          {!contentType && (
            <Typography variant="subtitle1">{value || "-"}</Typography>
          )}
          {contentType?.split("/")[0] === "image" && (
            <Box
              component={"img"}
              src={String(value)}
              alt={"Photo"}
              loading="lazy"
              sx={{
                maxWidth: "200px",
                height: "100px",
                objectFit: "contain",
                lineHeight: "100%",
                cursor: "pointer",
                marginRight: "2rem",
              }}
              onClick={handleOpenImage(String(value))}
            />
          )}
          {contentType?.split("/")[1] === "pdf" && (
            <Box
              p={1}
              m={1}
              component={"a"}
              target={"_blank"}
              href={value as string}
            >
              <PictureAsPdfIcon sx={{ fontSize: 50, color: red[500] }} />
            </Box>
          )}
        </Grid>
      </Grid>
      {!hideDivider && <Divider />}
    </React.Fragment>
  );
};

export default AccountDetailGridRow;
