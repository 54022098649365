import {ICard} from "../../../types/store/card";
import {CardListActions, IClearCardList, ISetCardCurPage, ISetCardList, ISetCardListLoading, ISetCardPerPage} from "../../../types/store/card/card-list";
import {ThunkDispatch} from "redux-thunk";
import {Action} from "redux";
import CardTestService from "../../../sevices/test/card-test-service";
import {ICardListRequestParams} from "../../../types/service/request/card-request";
import {handleGeneralError} from "../error-handler";
import {USE_SERVER} from "../../../constants/constants";
import CardService from "../../../sevices/card-service";

/**
 * It takes an array of cards and a total number of items, and returns an object with a type and the cards and totalItems
 * @param {ICard[]} cards - ICard[] - the list of cards to be set
 * @param {number} totalItems - The total number of cards in the database.
 */
const setCardList = (cards: ICard[], totalPages: number): ISetCardList => ({
  type: CardListActions.SET_CARD_LIST,
  cards,
  totalPages,
});

export const setCardCurPage = (page: number): ISetCardCurPage => ({
  type: CardListActions.SET_CARD_CUR_PAGE,
  page,
});

export const setCardPerPage = (perPage: number): ISetCardPerPage => ({
  type: CardListActions.SET_CARD_PER_PAGE,
  perPage,
});

/**
 * It returns an object with a type property and a loading property
 * @param {boolean} loading - boolean
 */
const setCardListLoading = (loading: boolean): ISetCardListLoading => ({
  type: CardListActions.SET_CARD_LIST_LOADING,
  loading
});

/**
 * It returns an object with a type property set to CLEAR_CARD_LIST
 */
export const clearCardList = (): IClearCardList => ({
  type: CardListActions.CLEAR_CARD_LIST
});

/**
 * It's a thunk that dispatches a loading action, then makes a request to the server, then dispatches a success action, and
 * finally dispatches a loading action
 * @param {ICardListRequestParams} params - ICardListRequestParams - this is the type of the parameters that will be passed
 * to the function.
 */
export const fetchCardList = (params: ICardListRequestParams) => async (dispatch: ThunkDispatch<unknown, unknown, Action>) => {
  try {
    dispatch(setCardListLoading(true));
    const response =
      USE_SERVER
        ?
        await CardService.cardList(params)
        :
        await CardTestService.cardList(params);
    dispatch(setCardList(response?.data?.result?.list, response?.data?.result?.total_pages));
  } catch (e) {
    await dispatch(handleGeneralError(e));
  } finally {
    dispatch(setCardListLoading(false));
  }
};