import {
  AccountsListActions,
  IAccountsListState,
  TAccountsListAction
} from "../../../types/store/account/accounts-list";
import {IAccountDetail} from "../../../types/store/account/account-details";

const initialState: IAccountsListState = {
  accounts: [] as IAccountDetail[],
  isLoading: false,
  page: 1,
  totalPages: 0,
  perPage: 10,
  errorCode: undefined,
  errorMessage: "",
};

export const accountsListReducer = (
  state: IAccountsListState = initialState,
  action: TAccountsListAction
): IAccountsListState => {
  let accounts: IAccountDetail[], totalPages: number, errorMessage: string, errorCode: number;

  switch (action.type) {
  case AccountsListActions.SET_ACCOUNTS_LIST_LOADING:
    return { ...state, isLoading: action.loading };
  case AccountsListActions.SET_ACCOUNTS_LIST_SUCCESS:
    ({ accounts, totalPages } = action.payload);
    return {
      ...state,
      accounts,
      totalPages,
    };
  case AccountsListActions.SET_ACCOUNTS_LIST_FAILURE:
    ({ errorCode, errorMessage } = action.payload);
    return { ...state, errorMessage, errorCode };
  case AccountsListActions.SET_ACCOUNTS_LIST_ITEMS_PER_PAGE:
    return {...state, perPage: action.perPage};
  case AccountsListActions.SET_ACCOUNTS_LIST_PAGE:
    return {...state, page: action.page};
  // case AccountsListActions.SET_ACCOUNTS_LIST_STATUS:
  //   return {...state, filterStatus: action.status};
  // case AccountsListActions.SET_ACCOUNTS_LIST_SORT:
  //   return {...state, sort: action.sort};
  case AccountsListActions.CLEAR_ACCOUNTS_LIST:
    return {...state, accounts: [], page: 1, totalPages: 0};
  default:
    return state;
  }
};
