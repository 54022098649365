import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import TreeView from "@mui/lab/TreeView";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import TreeItem from "@mui/lab/TreeItem";
import { useActions } from "../../../hooks/useActions";
import {
  IApplication,
  IApplicationInfo,
} from "../../../types/store/applications";
import { USE_SERVER } from "../../../constants/constants";
import ApplicationsService from "../../../sevices/applications-service";
import ApplicationsTestService from "../../../sevices/test/applications-test-service";
import { parseObjectData } from "../../../utils/parse-json";
import { getDateTime } from "../../../utils/time";
import {
  Box,
  Button,
  CircularProgress,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  TableCell,
  TableRow,
  TextField,
} from "@mui/material";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import StatusBox from "../../status/status-box";
import { getColorByApplicationStatus } from "../../../pages/admin/applications/list";
import { toClearDateFormat } from "../../../utils/to-clear-date-format";
import { CorporateDetails } from "./corporate-details";
import { GeneralDetails } from "./general-details";
import { Beneficiaries } from "./beneficiaries";
import { useBeneficiaryValidationScheme } from "./beneficiaries/validation-scheme";
import {
  FieldPath,
  FormProvider,
  useFieldArray,
  useForm,
} from "react-hook-form";
import { IBeneficiariesForm, IBeneficiary } from "./beneficiaries/types";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { TApplicationType } from "../types";
import {
  IBeneficiaryApi,
  IUpdateBusinessApplicationBeneficiariesRequest,
} from "../../../types/service/request/applications-request";
import moment from "moment";
import { notification } from "../../../utils/notification";
import { ListItemRowActions } from "./list-item-row-actions";
import { AxiosError, AxiosResponse } from "axios";

interface IRowProps {
  application: IApplication;
  type: TApplicationType;
  approve: () => Promise<void>;
  reject: (rejectReason: string) => Promise<void>;
}

export function ApplicationsListItemRow({
  application,
  type,
  approve,
  reject,
}: IRowProps) {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);

  const [openConfirmDialog, setOpenConfirmDialog] = React.useState(false);
  const [rejectedReason, setRejectedReason] = useState("");
  const [loadingButton, setLoadingButton] = useState(false);
  const [loadingDetails, setLoadingDetails] = useState(false);

  // Beneficiaries form

  const validationScheme = useBeneficiaryValidationScheme();

  const form = useForm<IBeneficiariesForm>({
    // TODO some problem with types
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    resolver: yupResolver(
      validationScheme as yup.ObjectSchema<IBeneficiariesForm>
    ),
    defaultValues: {
      beneficiaries: [],
    },
    mode: "onChange",
    reValidateMode: "onChange",
  });

  const { getValues, trigger, setError, reset, control } = form;

  const {
    fields: beneficiaries,
    append: addBeneficiary,
    remove: removeBeneficiary,
  } = useFieldArray({
    control,
    name: "beneficiaries",
  });

  const [beneficiariesFromApi, setBeneficiariesFromApi] = useState<
    IBeneficiaryApi[]
  >([]);

  useEffect(() => {
    if (beneficiariesFromApi) {
      reset();
      beneficiariesFromApi.forEach((item) => {
        const formattedBeneficiary: IBeneficiary = {
          ...item,
          dateOfBirthday: moment(item.dateOfBirthday, "DD.MM.YYYY").toDate(),
        };
        addBeneficiary(formattedBeneficiary);
      });
    }
  }, [beneficiariesFromApi]);

  const { handleGeneralError, setApplicationDetails } = useActions();

  const handleClickOpenConfirmDialog = () => {
    setOpenConfirmDialog(true);
  };

  const handleCloseConfirmDialog = () => {
    setOpenConfirmDialog(false);
  };

  const handleSaveBeneficiaries = async () => {
    if (type !== "corporate") {
      return;
    }

    const isBeneficiariesUpdated = await updateBeneficiaries();
    if (!isBeneficiariesUpdated) return setLoadingButton(false);
  };

  const handleSend = async (statusType: "reject" | "approve") => {
    setLoadingButton(true);
    if (statusType === "reject") {
      await reject(rejectedReason);
    }
    if (statusType === "approve") {
      await handleSaveBeneficiaries();
      await approve();
    }
    setLoadingButton(false);
  };

  const [applicationTypeData, setApplicationTypeData] = useState(
    application?.detailInfo
  );
  const [details, setDetails] = useState<Array<keyof IApplicationInfo>>([]);

  const fetchApplicationDetailInfo = async (
    applicationId: string,
    type: TApplicationType
  ) => {
    try {
      setLoadingDetails(true);

      const response = USE_SERVER
        ? await ApplicationsService.applicationDetailInfo(applicationId, type)
        : await ApplicationsTestService.applicationDetailInfo(applicationId);

      const { data } = response.data;

      await setApplicationDetails(data);
    } catch (e) {
      await handleGeneralError(e);
    } finally {
      setLoadingDetails(false);
    }
  };

  const updateBeneficiaries = async () => {
    const beneficiaries = getValues("beneficiaries");
    if (beneficiaries.length === 0) {
      notification.showErrorMessage(
        "Beneficiaries must contain at least 1 element"
      );
      return;
    }

    const isValid = await trigger(undefined, { shouldFocus: true });
    if (!isValid) {
      notification.showErrorMessage("Some field of beneficiaries are invalid");
      return;
    }

    const formattedBeneficiaries: IBeneficiaryApi[] = beneficiaries.map(
      (item) => {
        const dateOfBirthday = moment(item.dateOfBirthday, "DD.MM.YYYY").format(
          "DD.MM.YYYY"
        );
        return { ...item, dateOfBirthday };
      }
    );

    const data: IUpdateBusinessApplicationBeneficiariesRequest = {
      requestId: String(application.id),
      data: formattedBeneficiaries,
    };

    try {
      const response = USE_SERVER
        ? await ApplicationsService.updateBusinessApplicationBeneficiaries(data)
        : await ApplicationsTestService.updateBusinessApplicationBeneficiaries(
            data
          );

      notification.showSuccessMessage("Beneficiaries are updated!");
      return response.data.result;
    } catch (error) {
      const response = (error as AxiosError).response as AxiosResponse<{
        errors?: Record<string, string>;
        error?: string;
      }>;

      const errorList = response?.data?.errors;

      if (!errorList) {
        notification.showErrorMessage(
          response?.data?.error || "Ops! Something went wrong"
        );
        return;
      }

      Object.keys(errorList).forEach((key) => {
        const newKey = key
          .replaceAll("data", "beneficiaries")
          .replaceAll("[", ".")
          .replaceAll("]", "") as FieldPath<IBeneficiariesForm>;
        setError(newKey, {
          type: "apiError",
          message: errorList[key],
        });
      });

      notification.showErrorMessage("Some fields are invalid!");
      return false;
    }
  };

  const fillBeneficiariesForm = () => {
    const beneficiaries = getValues("beneficiaries");

    if (beneficiaries.length > 0) return;
    try {
      if (!application.detailInfo?.finalBeneficiaries) return;

      const beneficiariesFromApi = JSON.parse(
        application.detailInfo.finalBeneficiaries
      ) as IBeneficiaryApi[] | undefined;

      if (!beneficiariesFromApi) return;
      setBeneficiariesFromApi(beneficiariesFromApi);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    setApplicationTypeData(application?.detailInfo);
    setDetails(
      (application?.detailInfo
        ? Object.keys(application.detailInfo)
        : []) as Array<keyof IApplicationInfo>
    );
    fillBeneficiariesForm();
  }, [application?.detailInfo]);

  return (
    <React.Fragment>
      <TableRow
        key={application.id}
        sx={{
          "&:last-child td, &:last-child th": { border: 0 },
          cursor: "pointer",
        }}
        hover
      >
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => {
              if (!applicationTypeData && !open) {
                fetchApplicationDetailInfo(String(application.id), type);
              }
              setOpen(!open);
            }}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>{application.id}</TableCell>
        <TableCell>{application.uuid}</TableCell>
        <TableCell>
          <StatusBox
            label={application.status.toUpperCase()}
            color={getColorByApplicationStatus(application.status)}
          />
        </TableCell>
        <TableCell>{getDateTime(application.createdAt)}</TableCell>
        <TableCell>
          <ListItemRowActions
            status={application.status}
            loading={loadingButton}
            type={type}
            onApprove={() => handleSend("approve")}
            onReject={handleClickOpenConfirmDialog}
            onSave={handleSaveBeneficiaries}
          />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1, color: "black" }}>
              <TreeView
                defaultCollapseIcon={<ExpandMoreIcon />}
                defaultExpandIcon={<ChevronRightIcon />}
              >
                <TreeItem nodeId="1" label={"General Info"}>
                  <React.Fragment>
                    {parseObjectData(application.data, toClearDateFormat)}
                  </React.Fragment>
                </TreeItem>
                <TreeItem
                  nodeId="2"
                  label={"General applicaiton data"}
                  sx={{ my: 1 }}
                >
                  <GeneralDetails
                    loading={loadingDetails}
                    applicationData={applicationTypeData}
                    keys={details}
                  />
                </TreeItem>
                {!!applicationTypeData && "details" in applicationTypeData && (
                  <TreeItem
                    nodeId="3"
                    label={"Directors/Shareholders"}
                    sx={{ my: 1 }}
                  >
                    <CorporateDetails details={applicationTypeData.details} />
                  </TreeItem>
                )}
                {type === "corporate" && (
                  <TreeItem nodeId="4" label={"Beneficiaries"} sx={{ my: 1 }}>
                    <FormProvider {...form}>
                      <Beneficiaries
                        beneficiaries={beneficiaries}
                        applicationStatus={application.status}
                        addBeneficiary={addBeneficiary}
                        removeBeneficiary={removeBeneficiary}
                      />
                    </FormProvider>
                  </TreeItem>
                )}
              </TreeView>

              {loadingDetails && (
                <Box display={"flex"} justifyContent={"center"}>
                  <CircularProgress />
                </Box>
              )}
              <ListItemRowActions
                status={application.status}
                loading={loadingButton}
                type={type}
                onApprove={() => handleSend("approve")}
                onReject={handleClickOpenConfirmDialog}
                onSave={handleSaveBeneficiaries}
                sx={{ justifyContent: "flex-end" }}
                btnSize={"large"}
              />
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>

      {/*Reject application modal*/}
      <Dialog open={openConfirmDialog} onClose={handleCloseConfirmDialog}>
        <DialogTitle>{t("applications.Reject application")}</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ mb: 2, textAlign: "center" }}>
            {t("applications.Reject application question")}
          </DialogContentText>
          <TextField
            fullWidth
            value={rejectedReason}
            onChange={(e) => setRejectedReason(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseConfirmDialog}>
            {t("common.btn_cancel")}
          </Button>
          <Button
            disabled={rejectedReason.length === 0}
            onClick={() => handleSend("reject")}
          >
            {t("common.btn_confirm")}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
