import {
  AccountTransactionListActions,
  IAccountTransactionItemsByPage,
  IClearAccountTransactionList,
  ISetAccountTransactionList, ISetAccountTransactionListCurrentPage,
  ISetAccountTransactionListLoading
} from "../../../types/store/account/account-transaction-list";
import {Action} from "redux";
import AccountTestService from "../../../sevices/test/account-test-service";
import {store} from "../../index";
import {ThunkDispatch} from "redux-thunk";
import AccountService from "../../../sevices/account-service";
import {USE_SERVER} from "../../../constants/constants";
import {handleGeneralError} from "../error-handler";

/**
 * It takes a list of account transactions and returns an action that sets the account transaction list
 * @param {IAccountTransactionItemsByPage} list - IAccountTransactionItemsByPage
 */
const setAccountTransactionList = (list: IAccountTransactionItemsByPage): ISetAccountTransactionList => ({
  type: AccountTransactionListActions.SET_ACCOUNT_TRANSACTION_LIST,
  list
});

/**
 * It returns an object with a type and a loading property
 * @param {boolean} loading - boolean
 */
const setAccountTransactionListLoading = (loading: boolean): ISetAccountTransactionListLoading => ({
  type: AccountTransactionListActions.SET_ACCOUNT_TRANSACTION_LIST_LOADING,
  loading
});

/**
 * It returns an action to call reducer to clear account transaction list
 */
export const clearAccountTransactionList = (): IClearAccountTransactionList => ({
  type: AccountTransactionListActions.CLEAR_ACCOUNT_TRANSACTION_LIST
});

/**
 * It returns an object with a type property and a page property
 * @param {number} page - number
 */
export const setAccountTransactionListCurrentPage = (page: number): ISetAccountTransactionListCurrentPage => ({
  type: AccountTransactionListActions.SET_ACCOUNT_TRANSACTION_LIST_CURRENT_PAGE,
  page
});

/**
 * It fetches the next page of transactions for a given account if the user has scrolled to the bottom of the current page
 * @param {number} posInArray - the position in the array of transactionsByPage that the user is currently viewing
 * @param {string} accountId - the account id of the account you want to fetch the transactions for
 */
export const changePageOfAccountTransactionList = (posInArray: number, accountId: string) => async (dispatch: ThunkDispatch<unknown, unknown, Action>) => {
  const transactionsByPage = store.getState().accountTransactionList.transactionsByPage;
  if (posInArray > transactionsByPage.length - 1){
    await dispatch(fetchAccountTransactionList(accountId));
  }
};

/**
 * It fetches the account transaction list from the server and dispatches the result to the redux store
 * @param {string} accountId - string - the account id
 * @returns a function that is being called by the thunk middleware.
 */
export const fetchAccountTransactionList = (accountId: string) => async (dispatch: ThunkDispatch<unknown, unknown, Action>) => {
  const offsetPage = store.getState().accountTransactionList.lastPage;
  const currentPage = store.getState().accountTransactionList.currentPage;
  try {
    dispatch(setAccountTransactionListLoading(true));
    const response = USE_SERVER
      ? await AccountService.accountTransactionList(accountId, offsetPage)
      : await AccountTestService.accountTransactionList(accountId, offsetPage);

    dispatch(setAccountTransactionList(response.data.result));
  } catch (e: unknown) {
    if (currentPage > 0){
      dispatch(setAccountTransactionListCurrentPage(currentPage - 1));
    }
    await dispatch(handleGeneralError(e));
  } finally {
    dispatch(setAccountTransactionListLoading(false));
  }
};