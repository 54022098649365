import React, { useEffect } from "react";
import {
  Card,
  CardContent,
  CardHeader,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { useNavigate } from "react-router-dom";
import { useActions } from "../../hooks/useActions";
import ability from "../../utils/can";
import CustomTablePaginationActionsNew from "../common/table-pagination-new";
import { useApplicationsList } from "../../hooks/useApplicationsList";
import { getDateTime } from "../../utils/time";
import StatusBox from "../status/status-box";
import { getColorByApplicationStatus } from "../../pages/admin/applications/list";

/** A React component that displays a table of applications. */
const ApplicationsListTable = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { headCells } = useApplicationsList();
  const { isLoading, applications, page, perPage, totalPages } =
    useTypedSelector((state) => state.applicationsList);

  const {
    setApplicationsListItemsPerPage,
    fetchApplicationsList,
    setApplicationsListPage,
    setApplicationsListStatus,
    clearApplicationsList
  } = useActions();

  useEffect(() => {
    setApplicationsListStatus("received");
    clearApplicationsList();
    return () => {
      setApplicationsListPage(1);
    };
  }, []);

  useEffect(() => {
    if (ability.can("get", "satchel_accounts")) fetchApplicationsList();
  }, [page, perPage]);

  const handleViewAccount =
    (accountId: number | string | null | undefined) => () => {
      if (!accountId) return;
      navigate(`/accounts/${accountId}`);
    };

  const handlePageChange = (event: unknown, page: number) => {
    if (!isLoading) setApplicationsListPage(page);
  };

  const handlePerPageChange = (perPage: number) => {
    if (!isLoading) {
      setApplicationsListItemsPerPage(perPage);
      setApplicationsListPage(1);
    }
  };

  return (
    <Card>
      <CardHeader title={t("applications.table.title")} />
      <CardContent>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} size={"small"}>
            <TableHead>
              <TableRow sx={{ "& .MuiTableCell-head": { fontWeight: 600 } }}>
                {headCells.map((headCell) => (
                  <TableCell
                    sx={{ color: headCell.isHide ? "transparent" : "" }}
                    key={headCell.id}
                  >
                    {headCell.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading &&
                Array(perPage)
                  .fill("")
                  .map((row, index) => (
                    <TableRow
                      key={index}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      {Array(headCells.length)
                        .fill("")
                        .map((row, index) => (
                          <TableCell key={index}>
                            <Skeleton height={30} />
                          </TableCell>
                        ))}
                    </TableRow>
                  ))}
              {!isLoading &&
                applications.map((application) => (
                  <TableRow
                    key={application.id}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                      cursor: "pointer",
                    }}
                    onClick={handleViewAccount(application.userId)}
                    hover
                  >
                    <TableCell>{application.id}</TableCell>
                    <TableCell>{application.uuid}</TableCell>
                    <TableCell>{application.userId}</TableCell>
                    <TableCell>{application.accountType}</TableCell>
                    <TableCell>
                      <StatusBox
                        label={application.status.toUpperCase()}
                        color={getColorByApplicationStatus(application.status)}
                      />
                    </TableCell>
                    <TableCell>{getDateTime(application.createdAt)}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TableCell colSpan={7}>
                  <CustomTablePaginationActionsNew
                    totalPages={totalPages}
                    page={page}
                    rowsPerPage={perPage}
                    onPageChange={handlePageChange}
                    onPerPageChange={handlePerPageChange}
                  />
                </TableCell>
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  );
};

export default ApplicationsListTable;
