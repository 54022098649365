import React, {Fragment, useEffect, useState} from "react";
import { Typography} from "@mui/material";
import {toCapitalizedWords} from "../../../../utils/common";
import AccountDetailGridRow from "../../../account/account-detail-grid-row";
import {DocumentImageData} from "./document-image-data";
import {IDirectorData, IDocumentData, IPersonalDirectorData} from "./types";

export const DirectorShareholderData = ({director}: {director: IDirectorData}) => {
  const [directorType, setDirectorType] = useState<keyof IDirectorData | null>(null);

  useEffect(() => {
    if ("personal" in director) {setDirectorType("personal");}
    else if ("company" in director) {setDirectorType("company");}
  }, [director]);

  if (!directorType || !director || !director[directorType]) return <></>;

  return (
    <Fragment>
      {!!director[directorType] && Object.keys(director[directorType]).map((item) => {
        if (item === "address") return (
          <Fragment key={item}>
            <hr/>
            <Typography fontSize={20} my={1}>{toCapitalizedWords(item)}</Typography>
            {Object.keys(director[directorType][item]).map((addressKey) => {
              if (addressKey === "proofOfAddress") return (
                <Fragment key={addressKey}>
                  <AccountDetailGridRow
                    title={""}
                    value={"Proof address images"}
                  />
                  {director[directorType][item][addressKey].map((addressImage) => (
                    <DocumentImageData
                      s3Link={addressImage.s3Link}
                      contentType={addressImage.contentType}
                      key={addressImage.s3Link}
                    />
                  ))}
                </Fragment>

              );

              return (
                <AccountDetailGridRow
                  key={addressKey}
                  title={toCapitalizedWords(addressKey.toString())}
                  value={String(director[directorType][item][addressKey as keyof IPersonalDirectorData["address"]])}
                />
              );
            })}
            <hr/>
          </Fragment>
        );

        if (item === "documents") return (
          <Fragment key={item}>
            <hr/>
            <Typography fontSize={20} my={1}>{toCapitalizedWords(item)}</Typography>
            {director[directorType][item].map((document: IDocumentData) => (
              <Fragment key={item}>
                <AccountDetailGridRow
                  title={document.type}
                  value={String(document.number)}
                />
                {document.images.map((image) => (
                  <DocumentImageData
                    s3Link={image.s3Link}
                    contentType={image.contentType}
                    key={image.s3Link}
                  />
                ))}
              </Fragment>
            ))}
            <hr/>
          </Fragment>
        );

        return (
          <AccountDetailGridRow
            key={item}
            title={toCapitalizedWords(item.toString())}
            value={String(director[directorType][item as keyof IPersonalDirectorData] )}
          />
        );
      })}
    </Fragment>
  );
};
