import {IMerchant} from "../../../types/store/merchant";
import {
  ISetMerchantList,
  ISetMerchantListLoading,
  MerchantListActions
} from "../../../types/store/merchant/merchant-list";
import {ThunkDispatch} from "redux-thunk";
import {Action} from "redux";
import {handleGeneralError} from "../error-handler";
import {USE_SERVER} from "../../../constants/constants";
import MerchantService from "../../../sevices/merchant-service";
import MerchantTestService from "../../../sevices/test/merchant-test-service";
import {notification} from "../../../utils/notification";
import i18n from "i18next";
import {store} from "../../index";

/**
 * It takes an array of objects that have all the properties of an IMerchant object except for the image property, and
 * returns an object that has a type property with the value of MerchantListActions.SET_MERCHANT_LIST, and a merchants
 * property with the value of the array of objects that were passed in
 * @param {Omit<IMerchant, "image">[]} merchants - Omit<IMerchant, "image">[]
 */
const setMerchantList = (merchants: Omit<IMerchant, "image">[]): ISetMerchantList => ({
  type: MerchantListActions.SET_MERCHANT_LIST,
  merchants
});

/**
 * It returns an object with a type property and a loading property
 * @param {boolean} loading - boolean
 */
const setMerchantListLoading = (loading: boolean): ISetMerchantListLoading => ({
  type: MerchantListActions.SET_MERCHANT_LIST_LOADING,
  loading
});

/**
 * "When the user clicks the button, we want to fetch the merchant list from the server and display it in the UI."
 *
 * The first thing we do is dispatch the setMerchantListLoading action. This will set the loading state to true. This is
 * important because we want to show a loading indicator to the user while we're waiting for the server to respond
 */
export const fetchMerchantList = () => async (dispatch: ThunkDispatch<unknown, unknown, Action>) => {
  try {
    await dispatch(setMerchantListLoading(true));
    const response =
            USE_SERVER
              ? await MerchantService.merchantList()
              :
              await MerchantTestService.merchantList();
    if (!response.data.result) throw {response};
    await dispatch(setMerchantList(response.data.result));
  } catch (e: unknown) {
    await dispatch(handleGeneralError(e));
  } finally {
    await dispatch(setMerchantListLoading(false));
  }
};

/**
 * It updates the merchant block status
 * @param {number} id - number - the id of the merchant to be updated
 * @param {boolean} status - boolean - true if the merchant is blocked, false if the merchant is unblocked
 */
export const updateMerchantBlockStatus = (id: number, status: 1 | 0) => async (dispatch: ThunkDispatch<unknown, unknown, Action>) => {
  const merchantList = store.getState().merchantList.merchants;

  const setMerchantUpdatingStatus = (status: boolean) => {
    const updatedMerchantList = merchantList.map(merchant => {
      if (merchant.id === id) {
        merchant.isUpdating = status;
      }
      return merchant;
    });
    dispatch(setMerchantList(updatedMerchantList));
  };

  setMerchantUpdatingStatus(true);

  try {
    const response = USE_SERVER
      ? await MerchantService.updateMerchantBlockStatus(id, status)
      : await MerchantTestService.updateMerchantBlockStatus(id, status);

    if (!response.data.result) throw {response};

    const updatedMerchantList = merchantList.map(merchant => {
      if (!response.data.result) throw {response};
      if (merchant.id === id) {
        if (response.data.result){
          merchant.status = status;
        }
        merchant.isUpdating = false;
      }
      return merchant;
    });
    await dispatch(setMerchantList(updatedMerchantList));
    if (status === 0 && response.data.result) {
      notification.showWarningMessage(i18n.t("merchant.common.Merchant id successfully blocked/unblocked", {
        id,
        status: "deactivated"
      }));
    } else if (status === 1 && response.data.result){
      notification.showSuccessMessage(i18n.t("merchant.common.Merchant id successfully blocked/unblocked", {
        id,
        status: "activated"
      }));
    }
  } catch (e: unknown) {
    await dispatch(handleGeneralError(e));
    setMerchantUpdatingStatus(false);
  }
};