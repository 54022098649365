const ru = {
  translation: {
    common: {
      deposit: "Депозит",
      credit: "Кредит",
      investments: "Инвестиции",
      rows_per_page: "Строк на странице:",
      of: "из",
      btn_cancel: "Отменить",
      btn_decline: "Отклонить",
      btn_confirm: "Подтвердить",
      btn_more_info: "Больше инф",
      btn_open: "Открыть",
      btn_open_link: "Открыть ссылку",
    },
    transactions: {
      client_profile: {
        header: "Профиль клиента",
        full_name: "ФИО",
        birthday: "День рождения",
        sin: "SIN",
        contact: "Контакты",
        address: "Адрес",
        home: "Домашний телефон",
        mobile: "Мобильный телефон",
        email: "Email",
        employer: "Работодатель",
        job_title: "Название должности",
        employer_name: "Название компании-работодателя",
        start_date: "Дата начала",
        salary: "Зарплата",
        family: "Семья",
        marital_status: "Семейное положение",
        spouse_name: "Имя супруга (супруги)",
        spouse_birthday: "День рождения супруга (супруги)",
        children: "Дети"
      },
      account_activity: {
        header: "Активность счета",
        branch: "Branch",
        institution: "Institution",
        account_number: "Номер счета"
      },
      table: {
        id: "ID",
        title: "Транзакции",
        timestamp: "Дата",
        operation: "Операция",
        transactionId: "ID транзакции",
        operationType: "Операция",
        fromAccount: "Отправитель",
        toAccount: "Получатель",
        status: "Статус",
        amount: "Сумма",
        details: "Детали",
        all: "Все",
        transferType: "Transfer type",
        "transferType transfer": "Transfer",
        "transferType merchantTransfer": "Merchant Transfer"
      },
      info: {
        header: "Информация о транзакции",
        data: "Данные транзакции",
        origin: "Отправитель",
        destination: "Получатель"
      },
      modal: {
        confirm_header: "Одобрить транзакцию?",
        confirm_question: "Вы действительно хотите подтвердить транзакцию?",
        decline_header: "Отклонить транзакцию?",
        decline_question: "Вы действительно хотите отклонить транзакцию??",
        more_info_header: "Необходимо больше информации?",
        more_info_question: "Напишите, какие данные необходимы для успешной транзакции.",
        more_info_label: "Сообщение",
      }
    },
    account: {
      common: {
        unassigned: "Не назначен",
        declined: "Отклонено",
        confirmed: "Подтверждено",
        all: "Все"
      },
      table: {
        accounts: "Аккаунты",
        id: "ID",
        account_id: "ID Аккаунта",
        firstName: "First name",
        lastName: "Last name",
        uniqueIdentifier: "Unique identifier",
        status: "Статус",
        created_at: "Дата создания",
        details: "Детали",
        actions: "Actions",
        sanctionStatus: "Sanction status",
        email: "Email",
        phone: "Phone"
      },
      details: {
        header: "Детали Аккаунта",
        basic_info: "Общая информация",
        identity_verification: "Identity verification",
        verified_information: "Verified information",
        first_name: "Имя",
        last_name: "Фамилия",
        contact_info: "Контакты",
        email: "Email",
        phone_number: "Телефон",
        status: "Статус",
        account_ids: "ID счетов",
        btn_confirm: "Подтвердить",
        btn_decline: "Отклонить",
        sex: "Пол",
        nationality: "Национальность",
        birthDate: "Дата рождения",
        documents: "Документы",
        document: "Документ",
        sexF: "Женский",
        sexM: "Мужской",
        photo: "Фото",
        signedContract: "Подписанный контракт",
        sanctions: "Sanction List",
        check_result: "Check result",
        find_by: "Найдено по",
        sanction_link: "Ссылка санкций"
      },
      modal: {
        confirm_header: "Confirm account?",
        confirm_question: "Вы действительно хотите подтвердить этот счет?",
        decline_header: "Decline account?",
        decline_question: "Вы действительно хотите отклонить этот аккаунт? Опишите причину отказа в текстовом поле ниже.",
        decline_reason_label: "Причина"
      },
      alert: {
        error: "Ошибка",
        "Account ID not found in partner API": "Account ID not found in Processing API"
      },
      sanctions: {
        fullName: "Full Name"
      }
    },
    menu: {
      home: "Главная",
      applications: "Заяви",
      transactions: "Транзакции",
      accounts: "Аккаунты",
      profile: "Профиль",
      logout: "Выйти",
      management: "Управление",
      permissions: "Права доступа",
      roles: "Роли",
      users: "Пользователи",
      merchants: "Merchants",
      "Blockchain monitoring": "Blockchain monitoring",
      techEWallet: "Tech e-Wallet",
      cards: "Карты",
      "Sanction list": "Список санкций",
      "Search": "Поиск"
    },
    http: {
      errors: {
        "email incorrect": "Email не найден",
        "password incorrect": "Неверный пароль",
        "server not response": "Server not response",
        "server error": "Server error",
        "password must be longer than or equal to 4 characters": "Короткий пароль",
        "name is not unique": "Имя роли уже существует",
        "email is not unique": "Email уже существует",
        "QueryFailedError": "Ошибка сохранения",
        "account with this accountId not find in internal database": "Account with this accountId not find in internal database"
      }
    },
    form: {
      auth: {
        "Login": "Логин",
        "Password": "Пароль",
        "Email": "Email",
        "Sign In": "Войти",
      }
    },
    auth: {
      common: {
        "Password successfully changed!": "Password successfully changed!",
      }
    },
    profile: {
      header: "Профиль",
      change_password: "Сменить пароль",
      general_info: "Общая информация",
      form: {
        email: "Email",
        password: "Текущий пароль",
        passwordNew: "Новый пароль",
        passwordDup: "Подтверждение нового пароля",
        save: "Сохранить"
      }
    },
    validations: {
      "Login is required": "Логин обязателен к заполнению",
      "Login must be a an email": "Логин должен быть email",
      "Password is required": "Пароль обязателен к заполнению",
      "Validation error": "Ошибка валидации",
      "Wrong email": "Неверный email",
      "Min length": "Минимальная длина составляет {{value}} символов",
      "Max length": "Максимальная длина составляет {{value}} символов",
      "email incorrect": "Email не найден",
      "password incorrect": "Неверный пароль",
      "password must be longer than or equal to 4 characters": "Короткий пароль",
      "Name is required": "Имя обязателено к заполнению",
      "Email is required": "Email обязателено к заполнению",
      "Passwords do not match": "Пароли не совпадают",
      "name is not unique": "Имя роли уже существует",
      "email is not unique": "Email уже существует",
      "Role is required": "Роль обязательна",
      "You cannot delete your account": "Вы не можете удалить свой аккаунт",
      "Old password is required": "Старый пароль обязателен",
      "Field is required": "Обязательно к заполнению",
      "Allow only digits": "Разрешены только цифры",
    },
    error_page: {
      "Ops": "Ooops!",
      "Page not found": "That page doesn't exist or is unavailable.",
      "Go back to home": "Go Back to Home"
    },
    permissions: {
      table: {
        title: "Права доступа",
        id: "ID",
        name: "Название",
        description: "Описание",
      },
      names: {
        get_permissions: "Get permissions",
        get_roles: "Get role",
        get_role: "Get role",
        create_role: "Create role",
        update_role: "Update role",
        remove_role: "Remove role",
        get_users: "Get users",
        get_user: "Get user",
        create_user: "Create user",
        update_user: "Update user",
        remove_user: "Remove user",
        get_verification_list: "Get accounts",
        get_account_info: "Get account",
        set_verification_result: "Set account status",
        get_transactions_list: "Get transactions",
        get_transaction_info: "Get transaction",
        set_transaction_verification_result: "Set transaction status",
        get_transaction_additional_info: "Get transaction additional info",
        update_setting: "Update settings",
        get_merchants: "Get merchant list",
        get_merchant: "Get merchant",
        create_merchant: "Create merchant",
        update_merchant: "Update merchant",
        remove_merchant: "Remove merchant",
        get_wallets: "Get wallet list",
        get_wallet: "Get wallet",
        create_wallet: "Create wallet",
        update_wallet: "Update wallet",
        remove_wallet: "Remove wallet",
        get_cards_list: "Get card list",
        get_card_info: "Get card info"
      },
      descriptions: {
        get_permissions: "Позволяет получить список прав доступа",
        get_roles: "Позволяет получить список ролей",
        get_role: "Позволяет получить информацию о роли",
        create_role: "Позволяет создать новую роль",
        update_role: "Позволяет обновить информацию о роли",
        remove_role: "Позволяет удалить роль",
        get_users: "Позволяет получить список пользователей",
        get_user: "Позволяет получить данные пользователя",
        create_user: "Позволяет создать нового пользователя",
        update_user: "Позволяет обновить информацию о пользователе",
        remove_user: "Позволяет удалить пользователя",
        get_verification_list: "Позволяет получить список аккаунтов",
        get_account_info: "Позволяет получить детальную информацию аккаунта",
        set_verification_result: "Позволяет установить статус аккаунта",
        get_transactions_list: "Позволяет получить список транзакций",
        get_transaction_info: "Позволяет получить детальную информацию о транзакции",
        set_transaction_verification_result: "Позволяет установить статус транзакции",
        get_transaction_additional_info: "Позволяет получить дополнительную информациию о транзакции",
        update_setting: "Позволяет обновить настройки",
        get_merchants: "Позволяет получить список мерчантов",
        get_merchant: "Позволяет получить информацию о мерчанте",
        create_merchant: "Позволяет создать мерчанта",
        update_merchant: "Позволяет редактировать информацию о мерчанте",
        remove_merchant: "Позовляет удаление мерчанта",
        get_wallets: "Позволяет получить список кошельков",
        get_wallet: "Позовляет получить информацию колшелька",
        create_wallet: "Позволяет создать кошелек",
        update_wallet: "Позволяет обновить кошелек",
        remove_wallet: "Позволяет удалить кошелек",
        get_cards_list: "Allow to get card list",
        get_card_info: "Allow to get card info"
      },
    },
    role: {
      table: {
        title: "Роли",
        id: "ID",
        name: "Имя",
        permissions: "Права доступа",
        actions: "Действия",
        edit: "Изменить",
        delete: "Удалить",
        create: "Добавить",
      },
      edit: {
        header: "Именить роль"
      },
      add: {
        header: "Добавить новую роль"
      },
      form: {
        name: "Имя",
        permissions: "Права доступа",
        "check all permissions": "Выбрать все права",
        save: "Сохранить"
      },
      common: {
        "Role delete confirmation": "Role delete confirmation",
        "Are you sure that you want to delete role?": "Are you sure that you want to delete role?  Role id = {{id}}",
        "The role is assigned to users. All users with this role will be blocked.": "The role is assigned to users. All users with this role will be blocked. Are you sure that you want to delete role?  Role id = {{id}}",
        "Role updated!": "Role updated!",
        "Role created!": "Role created!",
        "Role id successfully deleted!": "Role id = {{id}} successfully deleted!",
      }
    },
    user: {
      table: {
        title: "Пользователи Backoffice",
        id: "ID",
        name: "Имя",
        email: "Email",
        role: "Роль",
        createdAt: "Дата создания",
        updatedAt: "Дана обновления",
        create: "Создать пользователя",
        details: "Детали",
        delete: "Удалить",
        block: "Заблокировать",
        unblock: "Разблокировать",
        status: "Статус",
        "find by email": "Find by email",
      },
      info: {
        header: "Редактрировать пользователя"
      },
      add: {
        header: "Создать пользователя"
      },
      form: {
        name: "Имя",
        email: "Email",
        oldPassword: "Old password",
        password: "Пароль",
        passwordConfirm: "Подтверждение пароля",
        role: "Роль",
        save: "Сохранить",
      },
      common: {
        "User block confirmation": "User block confirmation",
        "Are you sure that you want to block user?": "Are you sure that you want to block user? User id = {{id}}",
        blocked: "Заблокирован",
        active: "Активен",
        "User updated!": "User updated!",
        "User created!": "User created!",
        "User id successfully blocked/unblocked": "User id={{id}} successfully {{status}}!",
      },
    },
    management: {
      header: "Management"
    },
    merchant: {
      table: {
        id: "ID",
        name: "Название",
        type: "Тип",
        eWallet: "ID Счета",
        details: "Детали",
        title: "Merchants",
      },
      details: {
        header: "Merchant info"
      }
    },
    techEWallet: {
      header: "Tech e-Wallet"
    },
    card: {
      table: {
        title: "Cards",
        id: "ID",
        cardNumber: "Card Number",
        userId: "User ID",
        eMoneyAccountId: "eMoney Account",
        status: "Status",
        createdAt: "Date of create",
        details: "Details"
      },
      alert: {
        error: "",
        "Card ID not found in partner API": "Card ID not found in partner API"
      },
      details: {
        header: "Card information",
        basic_info: "Card info",
        additional_info: "Address info",
        fullName: "Full name",
        phoneNumber: "Phone number",
        city: "City",
        street: "Street",
        unit: "Unit",
        zip: "ZIP",
        cardNumber: "Card Number",
        userId: "User ID",
        emoneyAccountId: "eMoney Account",
        status: "Status",
        createdAt: "Date of create",
        set_card_num: "Set card number",
        "Cancel card request": "Cancel card request",
        "Are you sure that you want cancel card request?": "Are you sure that you want cancel card request?",
        "Confirm card request": "Confirm card request",
        "Are you sure that you want to confirm card request?": "Are you sure that you want to confirm card request?",
        "Set card number": "Set card number",
        "Input card number": "Input card number",
        cardNumber_not_defined: "Card number not defined",
      }
    },
    sanction: {
      search: {
        title: "Search person in sanction lists",
      },
      form: {
        firstName: "First name",
        lastName: "Last name",
        fullName: "Full name",
        find: "find",
        sanctionBase: "Sanction data base",
      },
      table: {
        id: "ID",
        publicationUrl: "Publication URL",
        title: "Sanction list",
        fullName: "Full name",
        firstName: "First name",
        lastName: "Last name",
        "Result is empty": "Result is empty.",
        link: "Link"
      }
    }
  }
};

export default ru;
