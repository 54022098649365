import React from "react";
import {Box, CircularProgress, circularProgressClasses, CircularProgressProps} from "@mui/material";

/** A general loading component. */
const Loading = () => {

  function CustomCircularProgress(props: CircularProgressProps) {
    return (
      <Box sx={{position: "relative"}}>
        <CircularProgress
          variant="determinate"
          sx={{
            color: (theme) =>
              theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
          }}
          size={40}
          thickness={4}
          {...props}
          value={100}
        />
        <CircularProgress
          variant="indeterminate"
          disableShrink
          sx={{
            animationDuration: "550ms",
            position: "absolute",
            left: 0,
            [`& .${circularProgressClasses.circle}`]: {
              strokeLinecap: "round",
            },
          }}
          size={40}
          thickness={4}
          {...props}
        />
      </Box>
    );
  }

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        width: "100vw",
        position: "fixed",
        top: "0",
        zIndex: 10,
        backgroundColor: "#fff"
      }}
    >
      <CustomCircularProgress/>
    </Box>
  );
};

export default Loading;
