import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import actions from "../store/actions";

/**
 * It returns an object with all the actions from the actions file, but with the dispatch function already bound to them
 * @returns An object with all the actions from the actions file.
 */
export const useActions = () => {
  const dispatch = useDispatch();
  return bindActionCreators(actions, dispatch);
};
