export interface IProfileFormFields {
    password: string,
    passwordNew: string,
    passwordDup: string,
}

export interface IProfileState {
    isLoading: boolean,
    errors: Partial<IProfileFormFields>,
    isSuccessSaved: boolean
}

export enum ProfileActions {
    SET_PROFILE_LOADING= "SET_PROFILE_LOADING",
    SET_PROFILE_ERRORS = "SET_PROFILE_ERRORS",
    SET_PROFILE_SAVED_STATUS = "SET_PROFILE_SAVED_STATUS"
}

export interface ISetProfileLoading {
    type: ProfileActions.SET_PROFILE_LOADING,
    loading: boolean
}

export interface ISetProfileErrors {
    type: ProfileActions.SET_PROFILE_ERRORS,
    errors: Partial<IProfileFormFields>
}

export interface ISetProfileSavedStatus {
    type: ProfileActions.SET_PROFILE_SAVED_STATUS,
    status: boolean
}

export type TProfileAction = ISetProfileErrors | ISetProfileSavedStatus | ISetProfileLoading;
