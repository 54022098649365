import React, {useEffect, useState} from "react";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Container, Grid,
  IconButton,
  MenuItem, Skeleton, Table, TableBody, TableCell, TableFooter,
  TableHead, TablePagination, TableRow,
  TextField
} from "@mui/material";
import {useTranslation} from "react-i18next";
import {Clear} from "@mui/icons-material";
import {useActions} from "../../../hooks/useActions";
import {sanctionList, TSanctionBaseKey} from "../../../types/store/sanction";
import {HeadCell} from "../../../types/table";
import {ISanctionListItem} from "../../../types/store/sanction/list";
import {useTypedSelector} from "../../../hooks/useTypedSelector";
import CustomTablePaginationActions from "../../../components/common/table-pagination";
import ability from "../../../utils/can";
import Error404 from "../../error-pages/error-404";

const SanctionListTable = () => {
  const {t} = useTranslation();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [fullName, setFullName] = useState("");
  const [sanctionBase, setSanctionBase] = useState<TSanctionBaseKey>(sanctionList[0]);
  const [page, setPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const {isLoading, sanctions, total} = useTypedSelector(state => state.sanctionList);
  const {fetchSanctionList} = useActions();
  const headCells: HeadCell<ISanctionListItem>[] = [
    {
      id: "id",
      label: t("sanction.table.id")
    },
    {
      id: "firstName",
      label: t("sanction.table.firstName")
    },
    {
      id: "lastName",
      label: t("sanction.table.lastName")
    },
    {
      id: "fullName",
      label: t("sanction.table.fullName")
    },
    {
      id: "publicationUrl",
      label: t("sanction.table.publicationUrl")
    }
  ];

  // const checkSanctionBaseField = (headCellId: keyof ISanctionListItem) => {
  //     const fullNameBases: TSanctionBaseKey[] = ["UK", "UN"];
  //     const notFullNameBases: TSanctionBaseKey[] = ["US", "EU"];
  //     const fullNameForbiddenFields: Array<keyof ISanctionListItem> = ["firstName", "lastName"];
  //     const notFullNameForbiddenFields: Array<keyof ISanctionListItem> = ["fullName"];
  //     if (fullNameBases.includes(sanctionBase) && fullNameForbiddenFields.includes(headCellId)) {
  //         return false;
  //     } else if (notFullNameBases.includes(sanctionBase) && notFullNameForbiddenFields.includes(headCellId)) {
  //         return false;
  //     }
  //     return true;
  //
  // };

  const handleSetSanctionBase = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSanctionBase(event.target.value as TSanctionBaseKey);
  };

  const handleSetFirstName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFirstName(event.target.value);
  };

  const handleSetLastName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLastName(event.target.value);
  };

  const handleSetFullName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFullName(event.target.value);
  };

  const handleGetSanctionList = () => {
    fetchSanctionList({sanctionBase, firstName, lastName, itemsPerPage, page, fullName});
  };

  const handleSetPage = (event: unknown, page: number) => {
    if (!isLoading) setPage(page);
  };

  const handleSetItemsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!isLoading) {
      setItemsPerPage(+event.target.value);
      setPage(0);
    }
  };

  useEffect(() => {
    if (ability.can("get", "sanctions")) {
      handleGetSanctionList();
    }
  }, [page, itemsPerPage, sanctionBase]);

  useEffect(() => {
    setPage(0);
  }, [firstName, lastName, fullName]);

  if (ability.cannot("get", "sanctions")) {
    return <Error404/>;
  }

  return (
    <Container maxWidth="xl">
      <Card>
        <CardHeader title={t("sanction.table.title")}/>
        <CardContent>
          <Grid container spacing={2} sx={{
            mb: 3
          }}>
            <Grid item xs={12} lg={12}>
              <TextField
                id="sanctionBase"
                select
                label={t("sanction.form.sanctionBase")}
                value={sanctionBase}
                onChange={handleSetSanctionBase}
                sx={{width: "inherit"}}
                SelectProps={{
                  MenuProps: {
                    PaperProps: {
                      style: {
                        borderRadius: "20px"
                      }
                    }
                  }
                }}
              >
                {sanctionList.map((dbKey) =>
                  <MenuItem key={dbKey} value={dbKey}>
                    {t(`sanction.list.${dbKey}`)}
                  </MenuItem>
                )}
              </TextField>
            </Grid>
            <Grid item xs={12} lg={3}>
              <TextField
                id="firstName"
                name="firstName"
                label={t("sanction.form.firstName")}
                value={firstName}
                InputProps={{
                  endAdornment: (
                    <IconButton onClick={() => setFirstName("")}>
                      <Clear/>
                    </IconButton>
                  )
                }}
                onChange={handleSetFirstName}
              />
            </Grid>
            <Grid item xs={12} lg={3}>
              <TextField
                id="lastName"
                name="lastName"
                label={t("sanction.form.lastName")}
                value={lastName}
                InputProps={{
                  endAdornment: (
                    <IconButton onClick={() => setLastName("")}>
                      <Clear/>
                    </IconButton>
                  )
                }}
                onChange={handleSetLastName}
              />
            </Grid>
            <Grid item xs={12} lg={3}>
              <TextField
                id="fullName"
                name="fullName"
                label={t("sanction.form.fullName")}
                value={fullName}
                InputProps={{
                  endAdornment: (
                    <IconButton onClick={() => setFullName("")}>
                      <Clear/>
                    </IconButton>
                  )
                }}
                onChange={handleSetFullName}
              />
            </Grid>
            <Grid item xs={12} lg={12} sx={{display: "flex", justifyContent: "flex-start", alignItems: "center"}}>
              <Button
                variant={"contained"}
                size="small"
                color="success"
                onClick={handleGetSanctionList}
              >
                {t("sanction.form.find")}
              </Button>
            </Grid>
          </Grid>
          <Table sx={{minWidth: 650}} aria-label="Accounts list table" size={"small"}>
            <TableHead>
              <TableRow sx={{"& .MuiTableCell-head": {fontWeight: 600}}}>
                {headCells.map((headCell) => {
                  return (
                    <TableCell
                      key={headCell.id}
                    >
                      {headCell.label}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading && (
                <TableRow
                  sx={{"&:last-child td, &:last-child th": {border: 0}}}
                >
                  {headCells.map((headCell, index) => {
                    return (
                      <TableCell key={index}><Skeleton/></TableCell>
                    );
                  })}
                </TableRow>
              )}
              {!isLoading && sanctions.map(sanction => (
                <TableRow
                  key={sanction.id}
                  sx={{"&:last-child td, &:last-child th": {border: 0}}}
                  hover
                >
                  <TableCell>{sanction.id}</TableCell>
                  <TableCell>{sanction.firstName}</TableCell>
                  <TableCell>{sanction.lastName}</TableCell>
                  <TableCell>{sanction.fullName}</TableCell>
                  <TableCell>
                    <Button variant={"outlined"}
                      size="small"
                      color={"primary"}
                      onClick={() => window.open(sanction.publicationUrl, "_blank")}
                      disabled={false}
                    >
                      {t("sanction.table.link")}
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  count={total}
                  page={page}
                  onPageChange={handleSetPage}
                  onRowsPerPageChange={handleSetItemsPerPage}
                  labelRowsPerPage={t("common.rows_per_page")}
                  rowsPerPage={itemsPerPage}
                  rowsPerPageOptions={[5, 10, 20]}
                  ActionsComponent={CustomTablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </CardContent>
      </Card>
    </Container>
  );
};

export default SanctionListTable;