import { useTranslation } from "react-i18next";
import {
  ITransaction, TransactionStatuses, TTransactionStatus,
} from "../types/store/transaction";
import { HeadCell } from "../types/table";
import { useTypedSelector } from "./useTypedSelector";
import {useTheme} from "@mui/material";
import i18n from "i18next";

interface Participant {
  [key: string]: string
}

/**
 * It returns an object with three properties:
 *
 * - `headCells` - an array of objects that describe the table's columns.
 * - `headCellsFullData` - an array of objects that describe the table's columns.
 * - `getStatusName` - a function that returns the name of the transaction status.
 * - `getStatusColor` - a function that returns the color of the transaction status
 */
export const useTransactionsList = () => {
  const { t } = useTranslation();
  const { statuses } = useTypedSelector((state) => state.transactionStatuses);
  const theme = useTheme();

  const headCells: HeadCell<ITransaction>[] = [
    {
      id: "id",
      numeric: false,
      label: t("transactions.table.id"),
      disablePadding: false,
    },
    {
      id: "type",
      numeric: false,
      label: t("transactions.table.operationType"),
      disablePadding: false,
    },
    {
      id: "amount",
      numeric: false,
      label: t("transactions.table.amount"),
      disablePadding: false,
    },
    {
      id: "timestamp",
      numeric: true,
      label: t("transactions.table.timestamp"),
      disablePadding: false,
    },
  ];

  const headCellsFullData: HeadCell<ITransaction & Participant>[] = [
    {
      id: "id",
      numeric: false,
      label: t("transactions.table.id"),
      disablePadding: false,
    },
    {
      id: "amount",
      numeric: false,
      label: t("transactions.table.amount"),
      disablePadding: false,
    },
    {
      id: "sender_account_number",
      numeric: true,
      label: t("transactions.table.fromAccount"),
      disablePadding: false,
    },
    {
      id: "beneficiary_account_number",
      numeric: true,
      label: t("transactions.table.toAccount"),
      disablePadding: false,
    },
    {
      id: "created_at",
      numeric: false,
      label: t("transactions.table.timestamp"),
      disablePadding: false,
    },
    {
      id: "action",
      numeric: false,
      label: "",
      disablePadding: false,
      isHide: true,
    },
  ];

  const getStatusName = (id: TTransactionStatus) => {
    if (i18n.exists("transactions.statuses." + statuses[id])){
      return i18n.t("transactions.statuses." + statuses[id]);
    } else if (i18n.exists("transactions.statuses." + String(id))){
      return i18n.t("transactions.statuses." + String(id));
    }

    return statuses[id] || String(id);
  };

  //TODO: define color for UNKNOWN status
  const statusColors: { [key in TransactionStatuses]: string } = {
    [TransactionStatuses.UNDEFINED]: theme.palette.error.main,
    [TransactionStatuses.REJECTED]: theme.palette.error.main,
    [TransactionStatuses.PENDING]: theme.palette.warning.main,
    [TransactionStatuses.AML_CHECK]: theme.palette.warning.main,
    [TransactionStatuses.AML_SUCCESS]: theme.palette.warning.main,
    [TransactionStatuses.BLOCKCHAIN_PENDING]: theme.palette.warning.main,
    [TransactionStatuses.CREATED]: theme.palette.warning.main,
    [TransactionStatuses.ACCEPTED]: theme.palette.success.main,
  };

  const getStatusColor = (id: TTransactionStatus) => statusColors[id];

  return {
    headCells,
    headCellsFullData,
    getStatusName,
    getStatusColor,
  };
};
