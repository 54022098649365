import {ITransaction, TTransferType} from "../../../types/store/transaction";
import {
  ISetTransactionsList,
  ISetTransactionsListError,
  ISetTransactionsListItemsPerPage,
  ISetTransactionsListLoading,
  ISetTransactionsListPage,
  ISetTransactionsListSort,
  ISetTransactionsListStatus,
  ISetTransactionsListTransferType,
  TransactionListActions
} from "../../../types/store/transaction/transactions-list";
import {Action} from "redux";
import {store} from "../../index";
import {TransactionsListRequestParams} from "../../../types/service/request/transactions-request";
import {TOrder} from "../../../types/table";
import TransactionsService from "../../../sevices/transactions-service";
import {USE_SERVER} from "../../../constants/constants";
import TransactionsTestService from "../../../sevices/test/transactions-test-service";
import {handleGeneralError} from "../error-handler";
import {ThunkDispatch} from "redux-thunk";

/**
 * It returns an object with a type property and a payload property
 * @param {ITransaction[]} transactions - ITransaction[] - the list of transactions to be set
 * @param {number} totalPages - number - the total pages of transactions in the database
 */
export const setTransactionsList = (transactions: ITransaction[], totalPages: number): ISetTransactionsList => ({
  type: TransactionListActions.SET_TRANSACTIONS_LIST,
  payload: {
    transactions,
    totalPages,
  }
});

/**
 * It returns an object with a type property and a loading property
 * @param {boolean} loading - boolean
 */
export const setTransactionsListLoading = (loading: boolean): ISetTransactionsListLoading => ({
  type: TransactionListActions.SET_TRANSACTIONS_LIST_LOADING,
  loading
});

/**
 * It returns an object with a type property and a page property
 * @param {number} page - number
 */
export const setTransactionsListPage = (page: number): ISetTransactionsListPage => ({
  type: TransactionListActions.SET_TRANSACTIONS_LIST_PAGE,
  page
});

/**
 * It returns an object with a type property and an itemsPerPage property
 * @param {number} perPage - number - The number of items per page to set.
 */
export const setTransactionsListItemsPerPage = (perPage: number): ISetTransactionsListItemsPerPage => ({
  type: TransactionListActions.SET_TRANSACTIONS_LIST_ITEMS_PER_PAGE,
  perPage
});

/**
 * It returns an object with a type property and a sort property
 * @param {TOrder} sort - TOrder
 */
export const setTransactionsListSort = (sort: TOrder): ISetTransactionsListSort => ({
  type: TransactionListActions.SET_TRANSACTIONS_LIST_SORT,
  sort
});

/**
 * It returns an object with a type property and a status property
 * @param {number} [status] - number - The status of the transaction list.
 */
export const setTransactionsListStatus = (status?: number): ISetTransactionsListStatus => ({
  type: TransactionListActions.SET_TRANSACTIONS_LIST_STATUS,
  status
});

/**
 * It returns an object with a type property and a transferType property
 * @param {TTransferType} transferType - TTransferType
 */
export const setTransactionsTransferType = (transferType: TTransferType): ISetTransactionsListTransferType => ({
  type: TransactionListActions.SET_TRANSACTIONS_LIST_TRANSFER_TYPE,
  transferType
});

/**
 * It returns an object with a type property and two other properties
 * @param {number} errorCode - number - The error code that was returned from the API.
 * @param {string} errorMessage - string - The error message to display to the user.
 */
export const setTransactionsListError = (errorCode: number, errorMessage: string): ISetTransactionsListError => ({
  type: TransactionListActions.SET_TRANSACTIONS_LIST_ERROR,
  errorCode,
  errorMessage
});

/**
 * It returns an object with the current page, items per page, sort, filter status, and transfer type
 * @returns A function that returns an object.
 */
const getFetchTransactionsListParams = (): TransactionsListRequestParams => {
  const {page, perPage} = store.getState().transactionsList;
  return {
    cur_page: page,
    per_page: perPage
  } as TransactionsListRequestParams;
};

/**
 * It fetches transactions list from the server and dispatches the result to the store
 * @param {TransactionsListRequestParams} params - TransactionsListRequestParams = getFetchTransactionsListParams()
 * @returns It is a function that returns a function that returns a promise.
 */
export const fetchTransactionsList = (params: TransactionsListRequestParams = getFetchTransactionsListParams()) => async (dispatch: ThunkDispatch<unknown, unknown, Action>) => {
  try {

    dispatch(setTransactionsListLoading(true));

    const response =
            USE_SERVER
              ?
              await TransactionsService.transactionsList(params)
              :
              await TransactionsTestService.transactionsList(params);

    const {
      list: transactions,
      totalPages: total_pages,
    } = response.data.data;

    dispatch(setTransactionsList(transactions, total_pages));
  } catch (e: unknown){
    await dispatch(handleGeneralError(e));
  } finally {
    dispatch(setTransactionsListLoading(false));
  }
};