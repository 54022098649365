import React from "react";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import {useTranslation} from "react-i18next";
import Loading from "../../../components/loading";
import {useParams} from "react-router-dom";
import {Can} from "@casl/react";
import ability from "../../../utils/can";
import useTransactionInfo from "../../../hooks/useTransactionInfo";
import {TransactionStatuses} from "../../../types/store/transaction";
import Error404 from "../../error-pages/error-404";

const STACK_SPACING = 0.33;

interface PropsStackData {
    data: (any)[][];
    title?: string;
}

/** A function that returns a Stack component with some data. */
const StackData = ({data, title}: PropsStackData): JSX.Element => {
  return (
    <Stack spacing={STACK_SPACING} direction={"column"} divider={<Divider/>}>
      {title && <Typography variant="h6">{title}:</Typography>}
      {data.map(([name, value], idx) => (
        <Box key={idx} sx={{pt: ".1rem", pb: ".1rem"}}>
          {name} {value}
        </Box>
      ))}
    </Stack>
  );
};

/** A React component that is used to display transaction information. */
const TransactionInfo = () => {
  const {t} = useTranslation();
  const URLParams = useParams();
  const transactionId = URLParams.id || "";
  const {
    isLoading,
    transaction,
    infoData,
    dialog,
    merchant
  } = useTransactionInfo(+transactionId);
  const {
    openConfirmDialog,
    setOpenConfirmDialog,
    openDeclineDialog,
    setOpenDeclineDialog,
    handleVerifyTransaction
  } = dialog;


  if (ability.cannot("get", "transaction_info"))
    return <Error404/>;

  if (isLoading) {
    return <Loading/>;
  }

  return (
    <Can I="get" a="transaction_info" ability={ability}>
      <Container>
        <Typography
          variant="h5"
          sx={{display: "flex", justifyContent: "center", mb: 2}}
        >
          {t("transactions.info.header")}
        </Typography>
        <Grid container spacing={3}>
          <Grid item md={12} xs={12}>
            <Card>
              {/* <CardHeader title={t("transactions.info.data")} /> */}
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={12}>
                    <StackData data={infoData}/>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Grid container rowSpacing={2}>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
              {/*<Can I={"set"} a={"transaction_verification_result"} ability={ability}>*/}
              {/*  {+transaction.status === 100 && (*/}
              {/*    <CardActions sx={{float: "right"}}>*/}
              {/*      {*/}
              {/*        isLoading*/}
              {/*          ? <Skeleton variant="rectangular" width={117} height={42}/>*/}
              {/*          : <Button*/}
              {/*            variant="contained"*/}
              {/*            size="large"*/}
              {/*            color={"success"}*/}
              {/*            onClick={() => setOpenConfirmDialog(true)}*/}
              {/*          >*/}
              {/*            {t("common.btn_confirm")}*/}
              {/*          </Button>*/}
              {/*      }*/}
              {/*      {*/}
              {/*        isLoading*/}
              {/*          ? <Skeleton variant="rectangular" width={117} height={42}/>*/}
              {/*          : <Button*/}
              {/*            variant="contained"*/}
              {/*            size="large"*/}
              {/*            color={"error"}*/}
              {/*            onClick={() => setOpenDeclineDialog(true)}*/}
              {/*          >*/}
              {/*            {t("common.btn_decline")}*/}
              {/*          </Button>*/}
              {/*      }*/}
              {/*    </CardActions>*/}
              {/*  )}*/}
              {/*</Can>*/}
            </Card>
          </Grid>
        </Grid>
        {/*TODO Next code need move to component*/}
        {/*<Dialog*/}
        {/*  open={openConfirmDialog}*/}
        {/*  onClose={() => setOpenConfirmDialog(false)}*/}
        {/*>*/}
        {/*  <DialogTitle>{t("transactions.modal.confirm_header")}</DialogTitle>*/}
        {/*  <DialogContent>*/}
        {/*    <DialogContentText>*/}
        {/*      {t("transactions.modal.confirm_question")}*/}
        {/*    </DialogContentText>*/}
        {/*  </DialogContent>*/}
        {/*  <DialogActions>*/}
        {/*    <Button onClick={() => setOpenConfirmDialog(false)}>*/}
        {/*      {t("common.btn_cancel")}*/}
        {/*    </Button>*/}
        {/*    <Button onClick={() => handleVerifyTransaction({*/}
        {/*      ...transaction,*/}
        {/*      status: TransactionStatuses.ACCEPTED*/}
        {/*    })}>*/}
        {/*      {t("common.btn_confirm")}*/}
        {/*    </Button>*/}
        {/*  </DialogActions>*/}
        {/*</Dialog>*/}
        {/*<Dialog*/}
        {/*  open={openDeclineDialog}*/}
        {/*  onClose={() => setOpenDeclineDialog(false)}*/}
        {/*>*/}
        {/*  <DialogTitle>{t("transactions.modal.decline_header")}</DialogTitle>*/}
        {/*  <DialogContent>*/}
        {/*    <DialogContentText>*/}
        {/*      {t("transactions.modal.decline_question")}*/}
        {/*    </DialogContentText>*/}
        {/*  </DialogContent>*/}
        {/*  <DialogActions>*/}
        {/*    <Button onClick={() => setOpenDeclineDialog(false)}>*/}
        {/*      {t("common.btn_cancel")}*/}
        {/*    </Button>*/}
        {/*    <Button onClick={() => handleVerifyTransaction({*/}
        {/*      ...transaction,*/}
        {/*      status: TransactionStatuses.REJECTED*/}
        {/*    })}>*/}
        {/*      {t("common.btn_confirm")}*/}
        {/*    </Button>*/}
        {/*  </DialogActions>*/}
        {/*</Dialog>*/}
      </Container>
    </Can>
  );
};

export default TransactionInfo;
