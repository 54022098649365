export interface ITransaction {
    id:number,
    satchelId: string,
    category: string,
    email: string | null,
    amount: string,
    currencyCode: string,
    fee: string,
    feeCurrencyCode: string,
    beneficiaryAccountNumber: string | null,
    beneficiaryBankSwift: string | null,
    beneficiaryIban: string,
    beneficiaryCardUuid: string | null,
    senderAccountNumber: string,
    narrative: string,
    status:TTransactionStatus,
    type: TTransferType,
    rejectionReason: string | null,
    processingTime:  string | null,
    finishedAt: string | null,
    createdAt: string,
    updatedAt: string
}

export enum TransactionStatuses {
  UNDEFINED = "000",
  PENDING = "100",
  ACCEPTED = "200",
  REJECTED = "400",
  AML_CHECK = "101",
  CREATED = "102",
  AML_SUCCESS = "103",
  BLOCKCHAIN_PENDING = "151",
}

export type TTransactionStatus =
  | TransactionStatuses.UNDEFINED
  | TransactionStatuses.PENDING
  | TransactionStatuses.ACCEPTED
  | TransactionStatuses.REJECTED
  | TransactionStatuses.AML_CHECK
  | TransactionStatuses.CREATED
  | TransactionStatuses.AML_SUCCESS
  | TransactionStatuses.BLOCKCHAIN_PENDING
  ;

export const transferTypes = [
  "transfer",
  "merchantTransfer"
];

export type TTransferType = "transfer" | "merchantTransfer" | undefined;
