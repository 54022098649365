import React, { useEffect, useState } from "react";
import { IAccountCorporateDetailsPerson } from "../../../types/store/account/account-details";
import { Card, CardContent, CardHeader } from "@mui/material";
import AccountDocumentsBlockCorporate from "../account-documents-block/account-documents-block-corporate";
import AccountDetailGridRow from "../account-detail-grid-row";
import { useTranslation } from "react-i18next";
import moment from "moment";

interface IProps {
  corporateDetailsPersons: IAccountCorporateDetailsPerson[];
}

export const AccountCorporateDetailsPersons = ({
  corporateDetailsPersons,
}: IProps) => {
  const corporateTypes: Record<string, string> = {
    authorizedSignatories: "Authorized signatories",
    directors: "Directors",
    shareholders: "Shareholders",
    finalBeneficiaries: "Beneficiaries",
  };
  const [persons, setPersons] = useState<
    Record<string, IAccountCorporateDetailsPerson[]>
  >({});
  const { t } = useTranslation();

  useEffect(() => {
    if (corporateDetailsPersons?.length > 0) {
      const localPersons: Record<string, IAccountCorporateDetailsPerson[]> = {};
      Object.keys(corporateTypes).map((key) => {
        localPersons[key] = corporateDetailsPersons.filter(
          (item) => item.type === key
        );
      });
      setPersons(localPersons);
    }
  }, [corporateDetailsPersons]);

  return (
    <>
      {corporateDetailsPersons.length > 0 && (
        <Card sx={{ mb: 2 }}>
          {/*<CardHeader title={t("account.details.persons")} />*/}
          <CardContent>
            {Object.keys(persons).map((corporateTypeKey, typeIndex) => (
              <React.Fragment key={typeIndex}>
                {persons[corporateTypeKey].length > 0 && (
                  <Card sx={{ mb: 2 }}>
                    <CardHeader title={corporateTypes[corporateTypeKey]} />
                    <CardContent>
                      {persons[corporateTypeKey].map((person, index) => (
                        <Card key={index} sx={{ mb: 2 }}>
                          <CardHeader
                            title={person.firstName + " " + person.lastName}
                          />
                          <CardContent>
                            <AccountDocumentsBlockCorporate
                              documents={person.documents}
                            />
                            <AccountDetailGridRow
                              title={t("account.details.firstName")}
                              value={person.firstName}
                            />
                            <AccountDetailGridRow
                              title={t("account.details.lastName")}
                              value={person.lastName}
                            />
                            <AccountDetailGridRow
                              title={t("account.details.type")}
                              value={person.type}
                            />
                            <AccountDetailGridRow
                              title={t("account.details.dateOfBirthday")}
                              value={moment(person.dateOfBirthday).format(
                                "YYYY-MM-DD"
                              )}
                            />
                            <AccountDetailGridRow
                              title={t("account.details.nationality")}
                              value={person.nationality}
                            />
                            <AccountDetailGridRow
                              title={t("account.details.personalNumber")}
                              value={person.personal_number}
                            />
                            <AccountDetailGridRow
                              title={t("account.details.jobTitle")}
                              value={person.job_title}
                            />
                            <AccountDetailGridRow
                              title={t("account.details.phoneNumber")}
                              value={person.phone_number}
                            />
                            <AccountDetailGridRow
                              title={t("account.details.email")}
                              value={person.email}
                            />
                            <AccountDetailGridRow
                              title={t("account.details.pep")}
                              value={person.pep}
                            />
                            <AccountDetailGridRow
                              title={t("account.details.addressCity")}
                              value={person.address_city}
                            />
                            <AccountDetailGridRow
                              title={t("account.details.addressCountryCode")}
                              value={person.address_country_code}
                            />
                            <AccountDetailGridRow
                              title={t("account.details.addressStreet")}
                              value={person.address_street}
                            />
                            <AccountDetailGridRow
                              title={t("account.details.addressZipCode")}
                              value={person.address_zip_code}
                            />
                          </CardContent>
                        </Card>
                      ))}
                    </CardContent>
                  </Card>
                )}
              </React.Fragment>
            ))}
          </CardContent>
        </Card>
      )}
    </>
  );
};
