import {IRole} from "./index";

export interface IRoleInfoState {
    role: IRole
    isLoading: boolean,
    roleValidationsErrors?: Pick<IRole, "name">
}

export enum RoleInfoActions {
    SET_ROLE_INFO = "SET_ROLE_INFO",
    SET_ROLE_INFO_LOADING = "SET_ROLE_INFO_LOADING",
    CLEAR_ROLE_INFO = "CLEAR_ROLE_INFO",
    SET_ROLE_VALIDATION_ERRORS = "SET_ROLE_VALIDATION_ERRORS"
}

export interface ISetRoleInfo {
    type: RoleInfoActions.SET_ROLE_INFO,
    role: IRole
}

export interface ISetRoleInfoLoading {
    type: RoleInfoActions.SET_ROLE_INFO_LOADING,
    loading: boolean
}

export interface IClearRoleInfo {
    type: RoleInfoActions.CLEAR_ROLE_INFO
}

export interface ISetRoleValidationErrors {
    type: RoleInfoActions.SET_ROLE_VALIDATION_ERRORS,
    errors: Pick<IRole, "name">
}

export type TRoleInfoAction = ISetRoleInfo | ISetRoleInfoLoading | IClearRoleInfo | ISetRoleValidationErrors;