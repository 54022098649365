import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from "./en.js";
import ru from "./ru.js";
import sk from "./sk.js";
import cz from "./cz.js";

export const languageLabels: { [key: string]: string } = {
  en: "English",
  sk: "Slovak",
  cz: "Czech",
  ru: "Russian",
};

const getInitialLang = (): string => {
  let lang: string | null = localStorage.getItem("lang");
  if (!lang) {
    lang = "en";
    localStorage.setItem("lang", lang);
  }
  return lang;
};

/**
 * It initializes the i18n library with the resources we've imported, sets the initial language to the one we've retrieved
 * from localStorage, and sets the fallback language to English
 */
export default function translate() {
  i18n.use(initReactI18next).init({
    resources: {
      en,
      sk,
      cz,
      ru,
    },
    lng: getInitialLang(),
    fallbackLng: ["en", "sk", "cz", "ru"],
    interpolation: {
      escapeValue: false,
    },
  });
}
