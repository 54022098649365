import {
  IProfileFormFields,
  ISetProfileErrors,
  ISetProfileLoading,
  ISetProfileSavedStatus,
  ProfileActions
} from "../../types/store/profile";
import {ThunkDispatch} from "redux-thunk";
import {Action} from "redux";
import {USE_SERVER} from "../../constants/constants";
import AuthService from "../../sevices/auth-service";
import i18n from "i18next";
import {handleNetworkError} from "./error-handler";
import {AxiosError} from "axios";
import {IGeneralResponse} from "../../types/service/response/general-response";
import {notification} from "../../utils/notification";

const setProfileLoading = (loading: boolean): ISetProfileLoading => ({
  type: ProfileActions.SET_PROFILE_LOADING,
  loading
});

export const setProfileErrors = (errors: Partial<IProfileFormFields>): ISetProfileErrors => ({
  type: ProfileActions.SET_PROFILE_ERRORS,
  errors
});

export const setProfileSavedStatus = (status: boolean): ISetProfileSavedStatus => ({
  type: ProfileActions.SET_PROFILE_SAVED_STATUS,
  status
});

/** Method that is used to change the password of the user. */
export const changePassword = (password: string, passwordNew: string, passwordDup: string) => async (dispatch: ThunkDispatch<unknown, unknown, Action>) => {
  dispatch(setProfileLoading(true));
  dispatch(setProfileSavedStatus(false));
  try {
    USE_SERVER && await AuthService.editProfile({password, passwordNew, passwordDup});
    notification.showSuccessMessage(i18n.t("Password successfully changed!"));
    dispatch(setProfileSavedStatus(true));
  } catch (e: unknown) {
    await dispatch(handleNetworkError(e));
    const {response} = e as AxiosError<IGeneralResponse<boolean>>;
    const errors: string[] | undefined = response?.data?.errors;

    const errorStrings: Partial<IProfileFormFields> = {password: "", passwordNew: "", passwordDup: ""};
    if (errors && errors?.length > 0) {
      errors.map(error => {
        notification.showErrorMessage(i18n.t("validations." + error));
        const key = error.split(" ")[0];
        if (key in errorStrings) {
          errorStrings[key as keyof Partial<IProfileFormFields>] = error;
        }
      });
    }
    dispatch(setProfileErrors(errorStrings));
  } finally {
    setProfileLoading(false);
  }
};