import { useTranslation } from "react-i18next";
import { HeadCell } from "../types/table";
import { IApplication } from "../types/store/applications";

/**
 * It returns an object with three properties:
 *
 * - `headCells` - an array of objects that describe the table's columns.
 */
export const useApplicationsList = () => {
  const { t } = useTranslation();

  const headCells: HeadCell<IApplication>[] = [
    {
      id: "id",
      numeric: false,
      label: t("applications.table.id"),
      disablePadding: false,
    },
    {
      id: "uuid",
      numeric: false,
      label: t("applications.table.uuid"),
      disablePadding: false,
    },
    {
      id: "account_id",
      numeric: false,
      label: t("applications.table.account"),
      disablePadding: false,
    },
    {
      id: "type",
      numeric: false,
      label: t("applications.table.type"),
      disablePadding: false,
    },
    {
      id: "status",
      numeric: false,
      label: t("applications.table.status"),
      disablePadding: false,
    },
    {
      id: "created",
      numeric: false,
      label: t("applications.table.created"),
      disablePadding: false,
    },
  ];

  const headCellsByAccount: HeadCell<IApplication>[] = [
    {
      id: "collapse",
      numeric: false,
      label: "",
      disablePadding: false,
    },
    {
      id: "id",
      numeric: false,
      label: t("applications.table.id"),
      disablePadding: false,
    },
    {
      id: "uuid",
      numeric: false,
      label: t("applications.table.uuid"),
      disablePadding: false,
    },
    {
      id: "status",
      numeric: false,
      label: t("applications.table.status"),
      disablePadding: false,
    },
    {
      id: "created",
      numeric: false,
      label: t("applications.table.created"),
      disablePadding: false,
    },
    {
      id: "actions",
      numeric: false,
      label: "",
      disablePadding: false,
    },
  ];

  return {
    headCells,
    headCellsByAccount
  };
};
