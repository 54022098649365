export const PERMISSIONS: Array<{id: number, name: string}> = [
  {"id": 1, "name": "get_permissions"},
  {"id": 2, "name": "get_roles"},
  {"id": 3, "name": "get_role"},
  {"id": 4, "name": "create_role"},
  {"id": 5, "name": "update_role"},
  {"id": 6,"name": "remove_role"},
  {"id": 7, "name": "get_users"},
  {"id": 8, "name": "get_user"},
  {"id": 9, "name": "create_user"},
  {"id": 10, "name": "update_user"},
  {"id": 11, "name": "remove_user"},
  {"id": 12, "name": "get_verification_list"},
  {"id": 13, "name": "get_account_info"},
  {"id": 14, "name": "set_verification_result"},
  {"id": 15, "name": "get_transactions_list"},
  {"id": 16, "name": "get_transaction_info"},
  {"id": 17, "name": "set_transaction_verification_result"},
  {"id": 18, "name": "get_transaction_additional_info"},
  {"id": 19, "name": "update_setting"},
  {"id": 20, "name": "get_merchants"},
  {"id": 21, "name": "get_merchant"},
  {"id": 22, "name": "create_merchant"},
  {"id": 23, "name": "update_merchant"},
  {"id": 24, "name": "remove_merchant"},
  {"id": 25, "name": "get_wallets"},
  {"id": 26, "name": "get_wallet"},
  {"id": 27, "name": "create_wallet"},
  {"id": 28, "name": "update_wallet"},
  {"id": 29, "name": "remove_wallet"},
  {"id": 30, "name": "get_cards_list"},
  {"id": 31, "name": "get_card_info"},
  {"id": 32, "name": "update_card_status"}
];

export const ACCOUNT_STATUSES: Record<number, string> = {0: "PENDING", 1: "ACCEPTED", 2: "REJECTED"};

export const TRANSACTION_STATUSES: Record<number, string> = {100: "PENDING", 200: "ACCEPTED", 400: "REJECTED"};