import {AuthActionTypeList, IAuthState, IAuthValuesSend, IUser, TAuthActions} from "../../types/store/auth";

const initialState: IAuthState = {
  isAuth: false,
  isLoading: false,
  user: {} as IUser,
  authErrors: {} as Partial<IAuthValuesSend>
};

export const authReducer = (state = initialState, action:TAuthActions):IAuthState => {
  const {type, payload} = action;
  switch (type){
  case AuthActionTypeList.SET_AUTH_LOADING:
    return {...state, isLoading: payload as boolean};
  case AuthActionTypeList.SET_AUTH_STATUS:
    return {...state, isAuth: payload as boolean};
  case AuthActionTypeList.SET_AUTH_USER:
    return {...state, user: payload as IUser};
  case AuthActionTypeList.SET_AUTH_ERRORS:
    return {...state, authErrors: {
      ...state.authErrors, ...payload as Partial<IAuthValuesSend>
    }};
  default:
    return state;
  }
};
