import {
  IApplicationsListByAccountIdRequestParams,
  IApplicationsListRequestParams, IUpdateBusinessApplicationBeneficiariesRequest
} from "../../types/service/request/applications-request";
import {
  IApplicationInfoResponse,
  IApplicationsListByAccountIdResponse,
  IApplicationsListResponse,
  IUpdateApplicationRequestResponse, IUpdateBusinessApplicationBeneficiariesResponse
} from "../../types/service/response/applications-response";
import {delay} from "../../utils/common";
import {IRequestResponseType} from "../../types/service/test/test-response";
import {TApplicationStatus} from "../../types/store/applications";

/** It's a wrapper around the API endpoints related to satchel account request */
export default class ApplicationsTestService {
  static async applicationsList(
    params: IApplicationsListRequestParams
  ): Promise<IRequestResponseType<unknown, IApplicationsListResponse>> {
    await delay(600);
    return {
      headers: undefined,
      data: {
        "result": true,
        "data": {
          "page": 1,
          "per_page": 10,
          "totalPages": 1,
          "list": [
            {
              "id": "10",
              "createdAt": "2023-03-15T19:11:53.000Z",
              "updatedAt": "2023-03-15T19:11:53.000Z",
              "uuid": "f83b4693-5418-4daf-8989-14a342480e18",
              "userId": "15",
              "accountType": "individual",
              "accountId": null,
              "status": "received",
              "rejectedReason": null,
            },
            {
              "id": "6",
              "createdAt": "2023-03-13T21:22:07.000Z",
              "updatedAt": "2023-03-13T21:22:07.000Z",
              "uuid": "0e5f8a82-0636-4833-b096-acacd167239b",
              "userId": "32",
              "accountType": "individual",
              "accountId": null,
              "status": "received",
              "rejectedReason": null,
            },
            {
              "id": "5",
              "createdAt": "2023-03-13T21:21:55.000Z",
              "updatedAt": "2023-03-13T21:21:55.000Z",
              "uuid": "98bf1d09-1c69-472f-9d68-1bff8baa4eee",
              "userId": "32",
              "accountType": "corporate",
              "accountId": null,
              "status": "received",
              "rejectedReason": null,
            }
          ]
        }
      }
    };
  }

  static async applicationsListByAccountId(
    params: IApplicationsListByAccountIdRequestParams
  ): Promise<IRequestResponseType<unknown, IApplicationsListByAccountIdResponse>> {
    await delay(600);
    return {
      headers: undefined,
      data: {
        "result": true,
        "data": {
          "list": [
            {
              "id": "10",
              "createdAt": "2023-03-15T19:11:53.000Z",
              "updatedAt": "2023-03-15T19:11:53.000Z",
              "uuid": "f83b4693-5418-4daf-8989-14a342480e18",
              "userId": "15",
              "accountType": "individual",
              "accountId": null,
              "status": "received",
              "rejectedReason": null,
            },
            {
              "id": "6",
              "createdAt": "2023-03-13T21:22:07.000Z",
              "updatedAt": "2023-03-13T21:22:07.000Z",
              "uuid": "0e5f8a82-0636-4833-b096-acacd167239b",
              "userId": "32",
              "accountType": "individual",
              "accountId": null,
              "status": "received",
              "rejectedReason": null,
            },
            {
              "id": "5",
              "createdAt": "2023-03-13T21:21:55.000Z",
              "updatedAt": "2023-03-13T21:21:55.000Z",
              "uuid": "98bf1d09-1c69-472f-9d68-1bff8baa4eee",
              "userId": "32",
              "accountType": "corporate",
              "accountId": null,
              "status": "received",
              "rejectedReason": null,
            }
          ]
        }
      }
    };
  }


  static async updateApplicationRequest(
    requestId: string, type: string, status: TApplicationStatus, userId: string, rejectedReason?: string
  ):Promise<IRequestResponseType<unknown, IUpdateApplicationRequestResponse>> {
    await delay(600);
    return {
      headers: undefined,
      data: {
        result: true
      }
    };
  }

  static async applicationDetailInfo(
    requestId: string
  ):Promise<IRequestResponseType<unknown, IApplicationInfoResponse>> {
    await delay(600);
    return {
      headers: undefined,
      data: {} as IApplicationInfoResponse
    };
  }

  static async updateBusinessApplicationBeneficiaries(
    data: IUpdateBusinessApplicationBeneficiariesRequest
  ):Promise<IRequestResponseType<unknown, IUpdateBusinessApplicationBeneficiariesResponse>> {
    await delay(600);
    return {
      headers: undefined,
      data: {
        result: true
      }
    };
  }
}
