import {AxiosResponse} from "axios";
import $api from "../http";
import {SettingsResponse} from "../types/service/response/settings-response";

/** It's a service that allows you to get and set the status of the parser switch */
export class SettingsService {
  static async getParserSwitchStatus(): Promise<AxiosResponse<SettingsResponse>> {
    return $api.get<SettingsResponse>("/setting", {params: {settingName: "parser_switch"}});
  }

  static async setParserSwitchStatus(status: boolean): Promise<AxiosResponse<SettingsResponse>> {
    const statusName = status ? "on" : "off";
    return $api.patch("/setting", {settingName: "parser_switch", settingValue: statusName});
  }
}