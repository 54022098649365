import React, {FC, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import useNotifierMessage from "../../hooks/useNotifierMessage";
import {IMerchant} from "../../types/store/merchant";
import {useTypedSelector} from "../../hooks/useTypedSelector";
import {useActions} from "../../hooks/useActions";
import {useFormik} from "formik";
import ability from "../../utils/can";
import Loading from "../loading";
import {
  Box,
  Card,
  CardContent,
  Collapse,
  Grid,
  MenuItem,
  TextField,
  Tooltip,
} from "@mui/material";
import {LoadingButton} from "@mui/lab";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";

interface IMerchantFormProps {
    type: "edit" | "add",
    merchantId?: number
}

type IMerchantFormValues = Omit<IMerchant, "id">

const imageLineSize = "200px";


/**
 * It's a React component that renders a form for creating or editing a merchant
 * @param e - React.ChangeEvent<HTMLInputElement>
 * @returns A React component
 */
const MerchantForm: FC<IMerchantFormProps> = ({type, merchantId}) => {
  const {t} = useTranslation();
  const {showNoPermissionsMessage} = useNotifierMessage();
  const {merchant, isLoading, validationErrors, merchantTypes} = useTypedSelector(state => state.merchant);
  const {createMerchant, updateMerchant, setMerchantValidationErrors, fetchMerchantTypes} = useActions();
  const [showImageUploadBox, setShowImageUploadBox] = useState(false);

  const formik = useFormik({
    initialValues: {
      name: type === "edit" && merchant?.name || "",
      image: type === "edit" && merchant?.image || "",
      type: type === "edit" && merchant?.type || merchantTypes[0]?.name || "",
      max_amount: type === "edit" && merchant?.max_amount || "0",
      min_amount: type === "edit" && merchant?.min_amount || "0",
      ewallet: type === "edit" && merchant?.ewallet || "",
      lang_key: type === "edit" && merchant?.lang_key || "",

    } as IMerchantFormValues,

    validate: (values) => {
      const errors: Partial<IMerchantFormValues> = {};
      const type = validateType(values.type);
      const langKey = validateType(values.lang_key);
      const name = validateName(values.name);
      const eWallet = validateEWallet(values.ewallet);
      const maxAmount = validateAmount(values.max_amount, values.min_amount, values.max_amount);
      const minAmount = validateAmount(values.min_amount, values.min_amount, values.max_amount);

      if (type) errors.type = type;
      if (langKey) errors.lang_key = langKey;
      if (name) errors.name = name;
      if (eWallet) errors.ewallet = eWallet;
      if (maxAmount) errors.max_amount = maxAmount;
      if (minAmount) errors.min_amount = minAmount;
      return errors;
    },

    onSubmit: async (values) => {
      const data: Partial<IMerchant> = {
        name: values.name,
        ewallet: values.ewallet,
        type: values.type,
        max_amount: values.max_amount,
        min_amount: values.min_amount,
        lang_key: values.lang_key
      };
      if (values.image !== merchant?.image) {
        data.image = values.image;
      }

      if (ability.can("update", "merchant") && type === "edit" && merchantId && merchantId > 0) {
        updateMerchant(merchantId, data);
      } else if (ability.can("create", "merchant") && type === "add") {
        createMerchant(data);
      } else {
        showNoPermissionsMessage();
      }
    }
  });

  const validateType = (string?: string): string | undefined => {
    if (!string) {
      return t("validations.Value is required");
    }
  };

  const validateName = (string: string): string | undefined => {
    if (!string) {
      return t("validations.Value is required");
    } else if (validationErrors?.name) {
      return t(`validations.${validationErrors.name}`);
    }
  };

  const validateEWallet = (string: string): string | undefined => {
    if (!string) {
      return t("validations.Value is required");
    } else if (validationErrors?.ewallet) {
      return t(`validations.${validationErrors.ewallet}`);
    }
  };

  const validateAmount = (amount?: string, minAmount?: string, maxAmount?: string): string | undefined => {
    const regex = /^\d*\.?\d*$/;
    if (!amount) {
      return t("validations.Value is required");
    } else if (!regex.test(amount)) {
      return t("validations.Not correct value");
    } else if (Number(maxAmount) < Number(minAmount) && amount === maxAmount) {
      return t("validations.Min amount more than max amount");
    } else if (amount === maxAmount && validationErrors?.max_amount) {
      return t(`validations.${validationErrors.max_amount}`);
    } else if (amount === minAmount && validationErrors?.min_amount) {
      return t(`validations.${validationErrors.min_amount}`);
    }
  };

  const {
    values,
    errors,
    handleChange,
    touched,
    handleSubmit,
    isSubmitting,
    handleBlur,
    validateForm,
    setFieldValue
  } = formik;

  function encodeImageFileAsURL(e: React.ChangeEvent<HTMLInputElement>) {
    const file = e.target.files?.[0];
    if (!file) return;
    const reader = new FileReader();
    reader.onloadend = () => {
      const imageString = (reader.result as string).replace(/^.*base64,/, "");
      setFieldValue("image", imageString);
    };
    reader.readAsDataURL(file);
  }

  useEffect((): () => void => {
    //Run validating one more time, because state updating is async
    let mounted = true;
    if (mounted) {
      validateForm();
    }
    return () => mounted = false;
  }, [validationErrors]);

  useEffect(() => {
    fetchMerchantTypes();
  }, []);

  if (isLoading) {
    return <Loading/>;
  }

  return (
    <Card>
      <CardContent>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                id="name"
                name="name"
                type="text"
                label={t("merchant.form.name")}
                error={touched.name && !!errors.name}
                helperText={touched.name && errors.name}
                sx={{my: 1, minHeight: 80}}
                fullWidth
                value={values.name}
                onChange={(e) => {
                  handleChange(e);
                  setMerchantValidationErrors({});
                }}
                onBlur={handleBlur}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                id="type"
                name="type"
                select
                label={t("merchant.form.type")}
                sx={{my: 1, minHeight: 80}}
                fullWidth
                value={values.type}
                onChange={(e) => {
                  handleChange(e);
                  setMerchantValidationErrors({});
                }}
                onBlur={handleBlur}
                error={touched.type && !!errors?.type}
                helperText={touched.type && errors.type}
              >
                {merchantTypes.map(merchantType => (
                  <MenuItem key={merchantType.id} value={merchantType.name}>
                    {merchantType.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%"
              }}>
                <Box
                  onMouseOver={() => {
                    setShowImageUploadBox(true);
                  }}
                  onMouseLeave={() => {
                    setShowImageUploadBox(false);
                  }}
                  sx={{
                    width: imageLineSize,
                    height: imageLineSize,
                    mb: 2,
                    display: "flex",
                    flexWrap: "wrap",
                    alignItems: "center",
                    justifyContent: "center",
                    position: "relative",
                    borderRadius: values.image ? 0 : "4px",
                    cursor: "pointer",
                    boxSizing: "border-box"
                  }}>
                  {values.image && (
                    <img src={"data:image/png;base64," + values.image} alt={"Merchant logo"}
                      style={{
                        maxWidth: imageLineSize,
                        maxHeight: imageLineSize,
                      }}/>
                  )}
                  {!values.image && !showImageUploadBox && (
                    <Box component={"span"}>
                      {t("merchant.form.Merchant without logo")}
                    </Box>
                  )}
                  <Box sx={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: imageLineSize,
                    height: imageLineSize,
                    zIndex: 2,
                  }}>
                    <Collapse in={showImageUploadBox}>
                      <Box
                        component="label"
                        sx={{
                          width: imageLineSize,
                          height: imageLineSize,
                          background: "rgba(0,0,0,.20)",
                          borderRadius: "4px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          cursor: "pointer",
                          color: "#FFFFFF",
                          position: "relative"
                        }}>
                        <Box sx={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          width: "40px",
                          height: "40px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          color: "#FFFFFF",
                        }}>
                          <AddIcon/>
                        </Box>
                        {t("merchant.form.Upload Image")}
                        <input
                          hidden
                          type="file"
                          accept="image/*"
                          onChange={encodeImageFileAsURL}
                        />
                      </Box>
                    </Collapse>
                  </Box>
                  <Box sx={{
                    position: "absolute",
                    top: 0,
                    right: 0,
                    zIndex: 3,
                  }}>
                    <Collapse in={showImageUploadBox}>
                      <Tooltip title={t("merchant.form.Delete") as string}>
                        <Box
                          onClick={() => {
                            setFieldValue("image", "");
                          }}
                          sx={{
                            width: "40px",
                            height: "40px",
                            background: "rgba(255,0,0,.25)",
                            borderTopRightRadius: "4px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            color: "#FFFFFF",
                            cursor: "pointer"
                          }}>
                          <DeleteIcon/>
                        </Box>
                      </Tooltip>
                    </Collapse>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                id="ewallet"
                name="ewallet"
                type="text"
                label={t("merchant.form.eWallet")}
                error={touched.ewallet && !!errors.ewallet}
                helperText={touched.ewallet && errors.ewallet}
                sx={{my: 1, minHeight: 80}}
                fullWidth
                value={values.ewallet}
                onChange={(e) => {
                  handleChange(e);
                  setMerchantValidationErrors({});
                }}
                onBlur={handleBlur}
              />

              <Grid container spacing={2} justifyContent={"space-between"}>
                <Grid item>
                  <TextField
                    id="min_amount"
                    name="min_amount"
                    type="text"
                    label={t("merchant.form.minAmount")}
                    error={touched.min_amount && !!errors.min_amount}
                    helperText={touched.min_amount && errors.min_amount}
                    sx={{my: 1, minHeight: 80}}
                    value={values.min_amount}
                    onChange={(e) => {
                      handleChange(e);
                      setMerchantValidationErrors({});
                    }}
                    onBlur={handleBlur}
                  />
                </Grid>
                <Grid item>
                  <TextField
                    id="max_amount"
                    name="max_amount"
                    type="text"
                    label={t("merchant.form.maxAmount")}
                    error={touched.max_amount && !!errors.max_amount}
                    helperText={touched.max_amount && errors.max_amount}
                    sx={{my: 1, minHeight: 80}}
                    value={values.max_amount}
                    onChange={(e) => {
                      handleChange(e);
                      setMerchantValidationErrors({});
                    }}
                    onBlur={handleBlur}
                  />
                </Grid>
              </Grid>

              <TextField
                id="lang_key"
                name="lang_key"
                type="text"
                label={t("merchant.form.lang_key")}
                error={touched.lang_key && !!errors.lang_key}
                helperText={touched.lang_key && errors.lang_key}
                sx={{my: 1, minHeight: 80}}
                fullWidth
                value={values.lang_key}
                onChange={(e) => {
                  handleChange(e);
                  setMerchantValidationErrors({});
                }}
                onBlur={handleBlur}
              />
            </Grid>
            <Grid item xs={12} display={"flex"} justifyContent={"flex-end"}>
              <LoadingButton
                type="submit"
                variant="contained"
                color="success"
                loading={isSubmitting}
                disabled={(type === "add" && ability.cannot("create", "merchant")) ||
                                    (type === "edit" && ability.cannot("update", "merchant"))}
              >
                {t("merchant.form.save")}
              </LoadingButton>
            </Grid>
          </Grid>
        </form>
      </CardContent>
    </Card>
  );
};

export default MerchantForm;