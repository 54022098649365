import {createTheme, experimental_sx as sx} from "@mui/material";
import type {} from "@mui/lab/themeAugmentation";

/** Creating a theme for the app. */
export const theme = createTheme(
  {
    palette: {
      mode: "light",
      primary: {
        main: "#4885ed",
        light: "#66B2FF",
        dark: "#006CC6",
      },
      secondary: {
        main: "#f50057",
      },
      background: {
        default: "#f6f6f6",
        paper: "#ffffff"
      },
      text: {
        disabled: "rgba(255, 255, 255, 0.5)",
        primary: "#000",
        secondary: "#969ba0",
      },
      success: {
        main: "#6cc070"
      },
      error: {
        main: "#f72b50"
      },
      warning: {
        main: "#E2A72E"
      }
    },
    components: {
      MuiCard: {
        styleOverrides: {
          root: sx({
            borderRadius: "25px",
            overflowX: "auto"
          })
        }
      },
      MuiCardContent: {
        styleOverrides: {
          root: sx({
            overflowX: "auto"
          })
        }
      },
      MuiChip: {
        styleOverrides: {
          root: sx({
            borderRadius: "20px"
          })
        }
      },
      MuiDrawer: {
        styleOverrides: {
          paper: {
            borderRight: "unset"
          },
          root: ({theme}) => ({
            "*::-webkit-scrollbar": {
              width: "10px"
            },
            "*::-webkit-scrollbar-track": {
              width: "5px",
              background: theme.palette.background.default
            },
            "*::-webkit-scrollbar-thumb": {
              background: theme.palette.primary.main + "44",
              borderRadius: "2px"
            }
          })
        }
      },
      MuiMenuItem: {
        styleOverrides: {
          root: ({theme}) => ({
            "&:hover": {
              background: "transparent",
              color: theme.palette.primary.main
            },
          })
        }
      },
      MuiInput: {
        styleOverrides: {
          root: {
            borderRadius: "20px"
          }
        }
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            borderRadius: "20px"
          }
        }
      },
      MuiSelect: {
        styleOverrides: {
          select: {
            borderRadius: "20px"
          }
        }
      },
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: 20,
            fontWeight: 600
          },
          contained: ({theme}) => ({
            color: theme.palette.background.default,
          })
        }
      },
      MuiTableCell: {
        styleOverrides: {
          root: ({theme}) =>({
            border: "0 transparent",
            color: theme.palette.text.secondary,
            borderBottom: "1px solid " + theme.palette.background.default,
          }),
          head: ({theme}) => ({
            border: "0 transparent",
            color: theme.palette.text.primary,
            borderBottom: "1px solid " + theme.palette.background.default,
          })
        }
      },
      MuiDialog: {
        styleOverrides: {
          paper: {
            borderRadius: "20px"
          }
        }
      },
      MuiTreeItem: {
        styleOverrides: {
          label: {
            fontSize: "1.5rem"
          }
        }
      }
    },
    mixins: {
      toolbar: {
        minHeight: 112
      },
    }
  }
);
