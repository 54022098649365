import {
  IClearMerchant,
  IMerchant, IMerchantType,
  IMerchantValidationErrors,
  ISetMerchant,
  ISetMerchantError,
  ISetMerchantLoading, ISetMerchantTypeList,
  ISetMerchantValidationError,
  MerchantActions
} from "../../../types/store/merchant";
import {ThunkDispatch} from "redux-thunk";
import {Action} from "redux";
import {handleGeneralError} from "../error-handler";
import {AxiosError} from "axios";
import {AccountDetailsResponse} from "../../../types/service/response/accounts-response";
import {USE_SERVER} from "../../../constants/constants";
import MerchantService from "../../../sevices/merchant-service";
import MerchantTestService from "../../../sevices/test/merchant-test-service";
import {notification} from "../../../utils/notification";
import i18n from "i18next";
import {IGeneralResponse} from "../../../types/service/response/general-response";
import {store} from "../../index";

/**
 * It takes an IMerchant object and returns an ISetMerchant object
 * @param {IMerchant} merchant - IMerchant - this is the type of the parameter that is passed into the function.
 */
const setMerchant = (merchant: IMerchant): ISetMerchant => ({
  type: MerchantActions.SET_MERCHANT,
  merchant
});

/**
 * It returns an object with a type of SET_MERCHANT_LOADING and a loading property
 * @param {boolean} loading - boolean
 */
const setMerchantLoading = (loading: boolean): ISetMerchantLoading => ({
  type: MerchantActions.SET_MERCHANT_LOADING,
  loading
});

/**
 * It returns an object with a type property and two other properties
 * @param {number} errorCode - number - The error code that was returned from the API.
 * @param {string} errorMessage - string - The error message to display to the user.
 */
const setMerchantError = (errorCode: number, errorMessage: string): ISetMerchantError => ({
  type: MerchantActions.SET_MERCHANT_ERROR,
  errorCode,
  errorMessage
});

/** Returning an object with a type property and a validationErrors property. */
export const setMerchantValidationErrors = (errors: IMerchantValidationErrors): ISetMerchantValidationError => ({
  type: MerchantActions.SET_MERCHANT_VALIDATION_ERRORS,
  validationErrors: errors
});

/**
 * It returns an object with a type property set to the value of the CLEAR_MERCHANT constant
 */
export const clearMerchant = (): IClearMerchant => ({
  type: MerchantActions.CLEAR_MERCHANT
});

export const setMerchantTypeList = (list: IMerchantType[]): ISetMerchantTypeList => ({
  type: MerchantActions.SET_MERCHANT_TYPES,
  list
});

/**
 * It fetches a merchant from the server, and then dispatches the result to the Redux store
 * @param {string} id - string - the id of the merchant to fetch
 */
export const fetchMerchant = (id: number) => async (dispatch: ThunkDispatch<unknown, unknown, Action>) => {
  try {
    dispatch(clearMerchant());
    dispatch(setMerchantLoading(true));
    const response =
            USE_SERVER
              ? await MerchantService.merchantInfo(id)
              : await MerchantTestService.merchantInfo(id);
    if (!response.data.result) throw {response};
    await dispatch(setMerchant(response.data.result));
  } catch (e: unknown) {
    await dispatch(handleGeneralError(e));
    const {response} = e as AxiosError<AccountDetailsResponse>;
    if (response?.status) {
      dispatch(setMerchantError(response.status, response?.data?.errors?.[0] ?? ""));
    }
  } finally {
    dispatch(setMerchantLoading(false));
  }
};

/**
 * "This function fetches a merchant by account id and dispatches the result to the redux store."
 *
 * The first thing we do is clear the merchant from the redux store. This is because we want to make sure that the merchant
 * we are about to fetch is the only merchant in the store
 * @param {string} accountId - string - the account id of the merchant
 */
export const fetchMerchantByAccountId = (accountId: string) => async (dispatch: ThunkDispatch<unknown, unknown, Action>) => {
  try {
    dispatch(clearMerchant());
    dispatch(setMerchantLoading(true));
    const response =
            USE_SERVER
              ? await MerchantService.merchantInfoByAccountId(accountId)
              :
              await MerchantTestService.merchantInfoByAccountId(accountId);
    if (!response.data.result) throw {response};
    await dispatch(setMerchant(response.data.result));
  } catch (e: unknown) {
    await dispatch(handleGeneralError(e));
    const {response} = e as AxiosError<AccountDetailsResponse>;
    if (response?.status) {
      dispatch(setMerchantError(response.status, response?.data?.errors?.[0] ?? ""));
    }
  } finally {
    dispatch(setMerchantLoading(false));
  }
};

/**
 * Updates a merchant
 * @param {number} merchantId - number - the id of the merchant to update
 * @param data - Partial<IMerchant>
 */
export const updateMerchant = (merchantId: number, data: Partial<IMerchant>) => async (dispatch: ThunkDispatch<unknown, unknown, Action>) => {
  try {
    const response = USE_SERVER
      ? await MerchantService.updateMerchant(merchantId, data)
      : await MerchantTestService.updateMerchant(merchantId, data);
    if (!response.data.result) throw {response};
    notification.showSuccessMessage(i18n.t("merchant.common.Merchant updated!"));
    await dispatch(fetchMerchant(merchantId));
  } catch (e: unknown) {
    const {response} = e as AxiosError<IGeneralResponse<boolean>>;
    await dispatch(handleGeneralError(e));

    const responseErrors: string[] | undefined = response?.data?.errors;
    const errorStrings: IMerchantValidationErrors = {name: "", ewallet: "", min_amount: "", max_amount: ""};
    if (responseErrors && responseErrors?.length > 0) {
      responseErrors.map(string => {
        const key = string.split(" ")[0];
        if (key in errorStrings) {
          errorStrings[key as keyof IMerchantValidationErrors] = string;
        }
      });
    }
    await dispatch(setMerchantValidationErrors(errorStrings));
  }
};

/**
 * It creates a merchant and dispatches the created merchant to the store
 * @param data - Partial<IMerchant> - this is the data that we want to send to the server.
 */
export const createMerchant = (data: Partial<IMerchant>) => async (dispatch: ThunkDispatch<unknown, unknown, Action>) => {
  try {
    const response = USE_SERVER
      ? await MerchantService.createMerchant(data)
      : await MerchantTestService.createMerchant(data);
    if (!response.data.result) throw {response};
    notification.showSuccessMessage(i18n.t("merchant.common.Merchant created!"));
    await dispatch(fetchMerchant(+response.data.result.id));
  } catch (e: unknown) {
    const {response} = e as AxiosError<IGeneralResponse<boolean>>;
    await dispatch(handleGeneralError(e));

    const responseErrors: string[] | undefined = response?.data?.errors;
    const errorStrings: IMerchantValidationErrors = {name: "", ewallet: "", min_amount: "", max_amount: ""};
    if (responseErrors && responseErrors?.length > 0) {
      responseErrors.map(string => {
        const key = string.split(" ")[0];
        if (key in errorStrings) {
          errorStrings[key as keyof IMerchantValidationErrors] = string;
        }
      });
    }
    dispatch(setMerchantValidationErrors(errorStrings));
  }
};

/**
 * It updates the merchant block status
 * @param {number} id - number - the id of the merchant to be updated
 * @param {boolean} status - boolean - true if you want to block the merchant, false if you want to unblock the merchant
 */
export const updateMerchantBlockStatusSingle = (id: number, status: 1 | 0) => async (dispatch: ThunkDispatch<unknown, unknown, Action>) => {
  const merchant = store.getState().merchant.merchant;
  await dispatch(setMerchant({...merchant, isUpdating: true}));

  try {
    const response = USE_SERVER
      ? await MerchantService.updateMerchantBlockStatus(id, status)
      : await MerchantTestService.updateMerchantBlockStatus(id, status);
    if (!response.data.result) throw {response};

    if (status === 0 && response.data.result) {
      notification.showWarningMessage(i18n.t("merchant.common.Merchant id successfully blocked/unblocked", {
        id,
        status: "blocked"
      }));
    } else if (status === 1 && response.data.result){
      notification.showSuccessMessage(i18n.t("merchant.common.Merchant id successfully blocked/unblocked", {
        id,
        status: "unblocked"
      }));
    }
    await dispatch(fetchMerchant(merchant.id));
  } catch (e: unknown) {
    await dispatch(handleGeneralError(e));
    dispatch(setMerchant({...merchant, isUpdating: false}));
  }
};

export const fetchMerchantTypes = () => async (dispatch: ThunkDispatch<unknown, unknown, Action>) => {
  try {
    const response = USE_SERVER
      ? await MerchantService.getMerchantTypes()
      : await MerchantTestService.getMerchantTypes();
    if (!response.data.result) throw {response};
    await dispatch(setMerchantTypeList(response.data.result));
  } catch (e: unknown) {
    await dispatch(handleGeneralError(e));
  }
};