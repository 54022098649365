import * as React from "react";
import Toolbar from "@mui/material/Toolbar";
import {useLocation, useNavigate} from "react-router-dom";
import {useActions} from "../../../hooks/useActions";
import {
  AppBar,
  Box, ClickAwayListener, Collapse,
  CSSObject,
  Divider, Grid, Grow,
  IconButton,
  List,
  ListItem,
  ListItemButton, ListItemIcon, ListItemText, MenuList, Paper, Popper,
  styled,
  Switch,
  useTheme
} from "@mui/material";
import {useTranslation} from "react-i18next";
import {IMenuItems} from "../../../types/components/menu";
import UserSettings from "./user-settings";
import BasicLanguageSwitcher from "../../common/language-switchers/basic";
import {useEffect, useState} from "react";
import {useTypedSelector} from "../../../hooks/useTypedSelector";
import {Can} from "@casl/react";
import ability, {Action, Subject} from "../../../utils/can";
import Typography from "@mui/material/Typography";
import {ChevronRight as ChevronRightIcon} from "@mui/icons-material";
import {Theme} from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import MailIcon from "@mui/icons-material/Mail";
import MenuIcon from "@mui/icons-material/Menu";
import ArticleIcon from "@mui/icons-material/Article";
import TransformIcon from "@mui/icons-material/Transform";
import HomeIcon from "@mui/icons-material/Home";
import SwitchAccountIcon from "@mui/icons-material/SwitchAccount";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import ApartmentIcon from "@mui/icons-material/Apartment";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import MenuItem from "@mui/material/MenuItem";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import GavelIcon from "@mui/icons-material/Gavel";
import {detectMobScreen} from "../../../utils/common";

const drawerWidth = 300;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
  boxSizing: "border-box"
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: 0,
  [theme.breakpoints.up("lg")]: {
    width: `calc(${theme.spacing(10)} + 1px)`,
  },
});

const Drawer = styled(MuiDrawer, {shouldForwardProp: (prop) => prop !== "open"})(
  ({theme, open}) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    boxSizing: "border-box",
    ...(open && {
      ...openedMixin(theme),
      "& .MuiDrawer-paper": openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      "& .MuiDrawer-paper": closedMixin(theme),
    }),
  }),
);

/**
 * General component to generate application header menu.
 * It renders a menu bar with a list of menu items, a language switcher, a user settings menu, and a switch settings.
 * @returns A React component
 */
const MenuBar = () => {
  const {logout} = useActions();
  const navigate = useNavigate();
  const location = useLocation();
  const {t} = useTranslation();
  const {switchStatus, isSwitchDisabled} = useTypedSelector(state => state.headerMenu);
  const {isAuth} = useTypedSelector(state => state.auth);
  const {setHeaderMenuSwitchStatus, getHeaderMenuSwitchStatus} = useActions();

  const isSidebarOpen = localStorage.getItem("isSidebarOpen");

  const theme = useTheme();
  const [open, setOpen] = useState<boolean>(Boolean(isSidebarOpen && isSidebarOpen === "true"));
  const [focusedMenuItem, setFocusedMenuItem] = useState<null | string>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [nestedListOpened, setNestedListOpened] = useState<null | string>(null);

  const handleDrawerOpen = () => {
    setOpen(true);
    localStorage.setItem("isSidebarOpen", "true");
  };

  const handleDrawerClose = () => {
    setOpen(false);
    localStorage.setItem("isSidebarOpen", "false");
  };

  const handleSwitch = () => {
    if (isAuth) setHeaderMenuSwitchStatus(!switchStatus);
  };

  const generalMenuItemAction = function (navigateTo?: string) {
    if (!navigateTo) return;
    if (location.pathname !== navigateTo) navigate(navigateTo);
  };

  const handleOpenPopperMenu = (item: string) => (event: React.MouseEvent<HTMLElement>) => {
    if (item !== focusedMenuItem) handleClosePopperMenu();
    if (event.currentTarget) {
      setAnchorEl(event.currentTarget);
      setFocusedMenuItem(item);
    }
  };

  const handleClosePopperMenu = () => {
    setAnchorEl(null);
    setFocusedMenuItem(null);
  };

  const handleCollapseExpandMenuItem = (item: string) => {
    if (item === nestedListOpened) {
      setNestedListOpened(null);
    } else {
      setNestedListOpened(item);
    }
  };

  function setFirstNestedListOpened(items: IMenuItems[], parentItemName: null | string = null) {
    items.some(item => {
      if (location.pathname === item.navigateTo) return setNestedListOpened(parentItemName ?? item.name);
      else if (item.subMenuItems?.length) return setFirstNestedListOpened(item.subMenuItems, parentItemName ?? item.name);
      // else setNestedListOpened(null);
    });
  }

  useEffect(() => {
    if (isAuth) getHeaderMenuSwitchStatus();
    setAnchorEl(null);
    setFocusedMenuItem(null);
  }, [location.pathname, isAuth]);

  useEffect(() => {
    setFirstNestedListOpened(pages);
  }, []);

  const pages: IMenuItems[] = [
    {
      name: t("menu.home"),
      navigateTo: "/home",
      action() {
        generalMenuItemAction(this.navigateTo);
      },
      iAction: "is",
      aSubject: "auth",
      icon: HomeIcon
    },
    {
      name: t("menu.applications"),
      navigateTo: "/applications",
      action() {
        generalMenuItemAction(this.navigateTo);
      },
      iAction: "get",
      aSubject: "satchel_accounts",
      icon: ArticleIcon,
    },
    {
      name: t("menu.transactions"),
      navigateTo: "/transactions",
      action() {
        generalMenuItemAction(this.navigateTo);
      },
      iAction: "get",
      aSubject: "transactions_list",
      icon: TransformIcon
    },
    {
      name: t("menu.accounts"),
      navigateTo: "/accounts",
      action() {
        generalMenuItemAction(this.navigateTo);
      },
      iAction: "get",
      aSubject: "verification_list",
      icon: SwitchAccountIcon
    },
    {
      name: t("menu.cards"),
      navigateTo: "/cards",
      action() {
        generalMenuItemAction(this.navigateTo);
      },
      iAction: "get",
      aSubject: "cards_list",
      icon: CreditCardIcon
    },
    // {
    //   name: t("menu.merchants"),
    //   navigateTo: "/merchants",
    //   action() {
    //     generalMenuItemAction(this.navigateTo);
    //   },
    //   iAction: "get",
    //   aSubject: "merchants",
    //   icon: ApartmentIcon,
    //   subMenuItems: [
    //     {
    //       name: t("menu.List"),
    //       navigateTo: "/merchants",
    //       action() {
    //         generalMenuItemAction(this.navigateTo);
    //       },
    //       iAction: "get",
    //       aSubject: "merchants",
    //     },
    //     {
    //       name: t("menu.Create new"),
    //       navigateTo: "/merchant/add",
    //       action() {
    //         generalMenuItemAction(this.navigateTo);
    //       },
    //       iAction: "create",
    //       aSubject: "merchant",
    //     }
    //   ]
    // },
    {
      name: t("menu.techEWallet"),
      navigateTo: "/tech-e-wallet",
      action() {
        generalMenuItemAction(this.navigateTo);
      },
      iAction: "get",
      aSubject: "wallets",
      icon: AccountBalanceWalletIcon,
    },
    {
      name: t("menu.management"),
      navigateTo: "/management",
      action() {
        generalMenuItemAction(this.navigateTo);
      },
      icon: ManageAccountsIcon,
      subMenuItems: [
        {
          name: t("menu.permissions"),
          navigateTo: "/permissions",
          action() {
            generalMenuItemAction(this.navigateTo);
          },
          iAction: "get",
          aSubject: "permissions"
        },
        {
          name: t("menu.roles"),
          navigateTo: "/roles",
          action() {
            generalMenuItemAction(this.navigateTo);
          },
          iAction: "get",
          aSubject: "roles"
        },
        {
          name: t("menu.users"),
          navigateTo: "/users",
          action() {
            generalMenuItemAction(this.navigateTo);
          },
          iAction: "get",
          aSubject: "users"
        },
        // {
        //   name: t("menu.Blockchain monitoring"),
        //   action() {
        //     window.open("http://18.185.238.13:3000/", "_blank");
        //   },
        //   iAction: "is",
        //   aSubject: "auth"
        // }
      ],
      iAction: "is",
      aSubject: "auth"
    },
    {
      name: t("menu.Sanction list"),
      iAction: "get",
      aSubject: "sanctions",
      action() {
        return undefined;
      },
      icon: GavelIcon,
      subMenuItems: [
        {
          name: t("menu.Search"),
          navigateTo: "/sanction-search",
          action() {
            generalMenuItemAction(this.navigateTo);
          },
          iAction: "get",
          aSubject: "sanctions"
        },
        {
          name: t("menu.Search List"),
          navigateTo: "/sanction-list",
          action() {
            generalMenuItemAction(this.navigateTo);
          },
          iAction: "get",
          aSubject: "sanctions"
        },
        {
          name: t("menu.Search Countries"),
          navigateTo: "/sanction-countries",
          action() {
            generalMenuItemAction(this.navigateTo);
          },
          iAction: "get",
          aSubject: "sanctions"
        }
      ]
    }
  ];

  const userSettings: IMenuItems[] = [
    {
      name: t("menu.profile"),
      navigateTo: "/profile",
      action() {
        generalMenuItemAction(this.navigateTo);
      },
      divider: true
    },
    {
      name: t("menu.logout"),
      action: () => {
        logout();
      }
    }
  ];

  return (
    <>
      <AppBar position={"fixed"} sx={(theme) => ({
        backgroundColor: theme.palette.background.paper,
        zIndex: theme.zIndex.drawer + 1,
        boxShadow: "unset"
      })}>
        <Toolbar>
          <Grid container justifyContent={"space-between"}>
            <Grid item md={6} sx={{display: "flex", justifyContent: "flex-start", alignItems: "center"}}>
              <Typography
                variant="h1"
                noWrap
                component="div"
                sx={[(theme) => ({
                  color: theme.palette.primary.main,
                  mr: 2,
                  fontSize: "2.5rem"
                })]}
                onClick={() => {
                  if (open) handleDrawerClose();
                  else handleDrawerOpen();
                }}
              >
                {detectMobScreen() ? "B" : "BACKOFFICE"}
              </Typography>
              {
                isAuth &&
                                <>
                                  <IconButton
                                    aria-label="open drawer"
                                    onClick={handleDrawerOpen}
                                    edge="start"
                                    sx={(theme) => ({
                                      ...(open && { display: "none" }),
                                      color: theme.palette.primary.main,
                                      fontSize: "3rem"
                                    })}
                                  >
                                    <ChevronRightIcon />
                                  </IconButton>
                                  <IconButton
                                    aria-label="open drawer"
                                    onClick={handleDrawerClose}
                                    edge="start"
                                    sx={{
                                      ...(!open && { display: "none" }),
                                      color: theme.palette.primary.main,
                                      fontSize: "3rem"
                                    }}
                                  >
                                    <MenuIcon />
                                  </IconButton>
                                </>
              }
            </Grid>
            <Grid item md={6} sx={{display: "flex", justifyContent: "flex-end", alignItems: "center"}}>
              <BasicLanguageSwitcher />
              {
                isAuth && 
                                <>
                                  <UserSettings pages={userSettings} />
                                  {/*<Can I={"update"} a={"setting"} ability={ability}>*/}
                                  {/*  <Switch*/}
                                  {/*    color="secondary"*/}
                                  {/*    checked={switchStatus}*/}
                                  {/*    disabled={isSwitchDisabled}*/}
                                  {/*    onChange={handleSwitch}*/}
                                  {/*  />*/}
                                  {/*</Can>*/}
                                </>
              }
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      {
        isAuth &&
                <Drawer variant="permanent" open={isAuth && open}>
                  <Toolbar />
                  <Box sx={{ overflowY: "auto", overflowX: "hidden" }}>
                    <List>
                      {pages.map((page) => {
                        const subMenu = page.subMenuItems && page.subMenuItems.filter(item => ability.can(item.iAction as Action, item.aSubject as Subject));
                        if (!page.subMenuItems || subMenu?.length) {
                          if (!page.iAction || !page.aSubject || ability.can(page.iAction as Action, page.aSubject as Subject)) {
                            return (
                              <ListItem
                                key={page.name}
                                sx={(theme) => ({
                                  display: "block",
                                  color: theme.palette.text.secondary,
                                })}
                              >
                                <ListItemButton
                                  onClick={() => {
                                    if (!subMenu?.length) {
                                      page.action();
                                      setNestedListOpened(null);
                                      return;
                                    }
                                    handleCollapseExpandMenuItem(page.name);
                                  }}
                                  onMouseOver={handleOpenPopperMenu(`${page.name}`)}
                                  onMouseLeave={handleClosePopperMenu}
                                  className={
                                    page.navigateTo === location.pathname ? "active" : ""
                                    || page.navigateTo?.split("/")[1] === location.pathname.split("/")[1] ? "active" : ""
                                    || focusedMenuItem === `${page.name}` ? "active" : ""
                                    || nestedListOpened === page.name ? "active" : ""
                                  }
                                  sx={{
                                    height: 48,
                                    width: open ? "auto" : 48,
                                    mx: "auto",
                                    justifyContent: open ? "initial" : "center",
                                    px: 2.5,
                                    borderRadius: open ? "20px" : "50%",
                                    border: "0 solid",
                                    ["&:hover, &.active"]: {
                                      color: theme.palette.primary.main,
                                      backgroundColor: theme.palette.primary.main + "22"
                                    }
                                  }}
                                >
                                  <ListItemIcon
                                    className={
                                      page.navigateTo === location.pathname ? "active" : ""
                                      || page.navigateTo?.split("/")[1] === location.pathname.split("/")[1] ? "active" : ""
                                      || focusedMenuItem === `${page.name}` ? "active" : ""
                                      || nestedListOpened === page.name ? "active" : ""
                                    }
                                    sx={{
                                      width: 48,
                                      justifyContent: "center",
                                      "&:hover, &.active": {
                                        color: theme.palette.primary.main,
                                      }
                                    }}
                                  >
                                    {page?.icon ? <page.icon /> : <MailIcon />}
                                  </ListItemIcon>
                                  <ListItemText primary={page.name} sx={{ opacity: open ? 1 : 0 }} />

                                  {subMenu?.length && open && (
                                    <ListItemIcon
                                      className={
                                        page.navigateTo === location.pathname ? "active" : ""
                                        || page.navigateTo?.split("/")[1] === location.pathname.split("/")[1] ? "active" : ""
                                        || focusedMenuItem === `${page.name}` ? "active" : ""
                                        || nestedListOpened === page.name ? "active" : ""
                                      }
                                      sx={{
                                        width: 48,
                                        justifyContent: "center",
                                        "&:hover, &.active": {
                                          color: theme.palette.primary.main,
                                        }
                                      }}
                                    >
                                      {nestedListOpened === page.name ? <ExpandLess /> : <ExpandMore />}
                                    </ListItemIcon>
                                  )}

                                  {subMenu?.length && !open && anchorEl && (
                                    <Popper
                                      open={focusedMenuItem === `${page.name}`}
                                      anchorEl={anchorEl}
                                      role={undefined}
                                      placement="right-start"
                                      transition
                                      disablePortal={false}
                                      style={{ zIndex: theme.zIndex.drawer + 1, paddingLeft: ".25rem" }}
                                    >
                                      {({ TransitionProps, placement }) => (
                                        <Grow
                                          {...TransitionProps}
                                          style={{
                                            transformOrigin:
                                                                            placement === "right-start" ? "right top" : "right bottom",
                                          }}
                                        >
                                          <Paper sx={{ mt: ".2rem" }}>
                                            <ClickAwayListener
                                              onClickAway={handleClosePopperMenu}>
                                              <MenuList
                                                autoFocusItem={open}
                                                id="composition-menu"
                                                aria-labelledby="composition-button"
                                              >
                                                {subMenu.map(element => {
                                                  const item = [
                                                    <MenuItem
                                                      key={element.name}
                                                      onClick={(e) => {
                                                        element.action();
                                                        handleClosePopperMenu();
                                                      }}
                                                      className={element.navigateTo === location.pathname ? "active" : ""}
                                                      sx={(theme) => ({
                                                        "&:hover, &.active": {
                                                          color: theme.palette.primary.main,
                                                        }
                                                      })}
                                                    >
                                                      <Typography
                                                        textAlign="center">{element.name}</Typography>
                                                    </MenuItem>
                                                  ];
                                                  if (element.divider) item.push(
                                                    <Divider />);
                                                  return item;
                                                })}
                                              </MenuList>
                                            </ClickAwayListener>
                                          </Paper>
                                        </Grow>
                                      )}
                                    </Popper>
                                  )}
                                </ListItemButton>
                                {subMenu?.length && open && (
                                  <Collapse in={nestedListOpened === `${page.name}`} timeout="auto"
                                    unmountOnExit>
                                    {subMenu.map((item, index) => (
                                      <List key={index} component="div" disablePadding>
                                        <ListItemButton

                                          sx={{
                                            height: 48,
                                            mx: "auto",
                                            justifyContent: open ? "initial" : "center",
                                            pl: 9.5,
                                            borderRadius: open ? "20px" : "50%",
                                            border: "0 solid",
                                            ["&:hover, &.active"]: {
                                              color: theme.palette.primary.main,
                                              backgroundColor: "transparent"
                                            }
                                          }}
                                          className={
                                            item.navigateTo === location.pathname ? "active" : ""
                                          }
                                          onClick={() => {
                                            item.action();
                                          }}
                                        >
                                          <ListItemText primary={item.name} />
                                        </ListItemButton>
                                      </List>
                                    ))}
                                  </Collapse>
                                )}
                              </ListItem>
                            );
                          }
                        }
                      }
                      )}
                    </List>
                  </Box>
                </Drawer>
      }
    </>
  );
};
export default MenuBar;
