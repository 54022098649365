import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import {useActions} from "../../../hooks/useActions";
import ability from "../../../utils/can";
import Error404 from "../../error-pages/error-404";
import Loading from "../../../components/loading";
import {Alert, AlertTitle, Container, Stack, Typography} from "@mui/material";
import {useTypedSelector} from "../../../hooks/useTypedSelector";
import AccountDocumentsBlock from "../../../components/account/account-documents-block";
import AccountPhotos from "../../../components/account/account-photos";
import AccountOndatoDetails from "../../../components/account/account-ondato-details";
import AccountIdentityVerification
  from "../../../components/account/account-identity-verification/account-identity-verification";
import AccountApplications from "../../../components/account/account-applications/account-applications";
import AccountBasicInfo from "../../../components/account/account-basic-info/account-basic-info";

const AccountDetails = () => {
  const { t } = useTranslation();
  const URLParams = useParams();
  const accountId = URLParams.id || "";
  const { fetchAccountDetails, fetchAccountStatuses, clearAccountDetails } = useActions();
  const { isLoading, errorCode } = useTypedSelector((state) => state.accountDetails);
  const [updatedAccountDetails, setUpdatedAccountDetails] = useState(Date.now());
  const makeRequestsToGetAccountDetais = async () => {
    await fetchAccountDetails(accountId);
  };

  useEffect(() => {
    fetchAccountStatuses();
    if (ability.can("get", "account_info")) {
      makeRequestsToGetAccountDetais();
    }
  }, [accountId, updatedAccountDetails]);

  useEffect(() => {
    return () => {
      clearAccountDetails();
    };
  }, []);

  if (ability.cannot("get", "account_info")) return <Error404 />;

  //Return information by presence of errors or loading status
  if (isLoading) {
    return <Loading />;
  } else if (errorCode === 424) {
    return (
      <Container sx={{ mt: 2 }}>
        <Alert severity="error">
          <AlertTitle>{t("account.alert.error")}</AlertTitle>
          {t("account.alert.Account ID not found in partner API")}
        </Alert>
      </Container>
    );
  } else if (errorCode === 404) {
    return <Error404 />;
  }

  return (
    <Container>

      {/*<pre>*/}
      {/*  {JSON.stringify(account, null, 2)}*/}
      {/*</pre>*/}

      <Typography
        variant="h5"
        sx={{ display: "flex", justifyContent: "center", mb: 2 }}
      >
        {t("account.details.header")}
      </Typography>
      <Stack>
        <AccountBasicInfo/>

        <AccountIdentityVerification/>

        <AccountDocumentsBlock cardTitle={t("account.details.documents")}/>

        <AccountPhotos cardHeader={t("account.details.photos")}/>

        <AccountOndatoDetails updateAccountDetails={() => {setUpdatedAccountDetails(Date.now());}}/>

        <AccountApplications/>

      </Stack>
    </Container>
  );
};

export default AccountDetails;