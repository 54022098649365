import {LayoutActionList, TLayoutActions} from "../../types/store/layout";

export enum layoutList {
  MAIN = "main",
  AUTH = "auth",
}

export type TLayout = layoutList.MAIN | layoutList.AUTH;

export interface ILayoutState {
  currentLayout: TLayout
}

const initialState: ILayoutState = {
  currentLayout: layoutList.AUTH
};

export function LayoutReducer(state: ILayoutState = initialState, action: TLayoutActions){
  const {type, payload} = action;
  switch (type){
  case LayoutActionList.CHANGE_LAYOUT:
    return {...state, currentLayout: payload};
  default:
    return state;
  }
}
