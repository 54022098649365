import {IUserListState, TUserListAction, UserListActions} from "../../../types/store/user/user-list";

const initialState: IUserListState = {
  users: [],
  isLoading: false,
  totalItems: 0
};

export const userListReducer = (state = initialState, action: TUserListAction): IUserListState => {
  switch (action.type) {
  case UserListActions.SET_USER_LIST:
    return {...state, users: action.users, totalItems: action.totalItems ?? state.totalItems};
  case UserListActions.SET_USER_LIST_LOADING:
    return {...state, isLoading: action.loading};
  default:
    return state;
  }
};