import React, {FC, useEffect, useState} from "react";
import {IRole} from "../../types/store/role";
import {
  Box,
  Card,
  CardContent, Checkbox,
  Chip, FormControl, FormControlLabel,
  Grid, InputLabel, ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  TextField, Tooltip, Typography,
} from "@mui/material";
import {useTranslation} from "react-i18next";
import {useFormik} from "formik";
import {useTypedSelector} from "../../hooks/useTypedSelector";
import {LoadingButton} from "@mui/lab";
import {useActions} from "../../hooks/useActions";
import {Cancel} from "@mui/icons-material";
import {without} from "lodash";
import {IPermission} from "../../types/store/permissions";
import ability from "../../utils/can";
import useNotifierMessage from "../../hooks/useNotifierMessage";
import {Can} from "@casl/react";

interface RoleFormProps {
    type: "add" | "edit",
    roleId?: number
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

/** A React component that is used to create and edit roles. */
const RoleForm: FC<RoleFormProps> = ({type, roleId}) => {
  const {t, i18n} = useTranslation();
  const {showNoPermissionsMessage} = useNotifierMessage();
  const {permissions: allPermissionObjectList} = useTypedSelector(state => state.permissionsList);
  const {role, roleValidationsErrors} = useTypedSelector(state => state.roleInfo);
  const {fetchPermissionsList, updateRole, createRole, setRoleValidationErrors} = useActions();
  const [rolePermissionIdList, setRolePermissionIdList] = useState<number[]>(role?.permissions && type === "edit" ? role.permissions.map(permission => (permission.id)) : []);

  const allPermissionObject: { [key: string | number]: string } = {}; //Permission {id: name}

  const allPermissionIdList: number[] = allPermissionObjectList.map(permission => {
    allPermissionObject[permission.id] = permission.name || "";
    return permission.id;
  });

  const formik = useFormik({
    initialValues: {
      name: role?.name ?? "",
    } as IRole,

    validate: (values) => {
      const errors: Partial<IRole> = {};

      const name = validateName(values.name);
      if (name) {
        errors.name = name;
      }

      return errors;
    },

    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true);
      const permissionsToUpdate: Pick<IPermission, "id">[] = [];
      rolePermissionIdList.map(id => {
        permissionsToUpdate.push({id: id});
      });
      if (type === "edit" && roleId && roleId > 0 && ability.can("update", "role")) {
        updateRole(roleId, values.name, permissionsToUpdate);
      } else if (type === "add" && ability.can("create", "role")) {
        createRole(values.name, permissionsToUpdate);
      } else {
        showNoPermissionsMessage();
      }
      setSubmitting(false);
    }
  });

  const validateName = (name: string): string | undefined => {
    if (!name) {
      return t("validations.Name is required");
    } else if (roleValidationsErrors?.name) {
      return t(`validations.${roleValidationsErrors.name}`);
    }
  };

  const {
    values,
    errors,
    handleChange,
    touched,
    handleSubmit,
    isSubmitting,
    handleBlur,
    validateForm
  } = formik;

  const handleDeletePermission = (e: React.MouseEvent, value: number) => {
    e.preventDefault();
    setRolePermissionIdList((current) => {
      return without(current, value);
    });
  };

  const handleChangePermissions = (e: SelectChangeEvent<typeof rolePermissionIdList>) => {
    const {
      target: {value},
    } = e;
    const selectedPermissions = allPermissionIdList.filter(permission => {
      if (Array.isArray(value) && value.indexOf(permission) !== -1) {
        return permission;
      }
    });
    setRolePermissionIdList(selectedPermissions);
  };

  const handleCheckAllPermissions = () => {
    setRolePermissionIdList([]);
    if (allPermissionIdList.length === rolePermissionIdList.length) {
      setRolePermissionIdList([]);
    } else {
      setRolePermissionIdList(allPermissionIdList);
    }
  };

  const handleChangeValue = () => {
    setRoleValidationErrors({name: ""});
  };

  useEffect(() => {
    if (ability.can("get", "permissions"))
      fetchPermissionsList();
  }, []);

  useEffect((): () => void => {
    //Run validating one more time, because state updating is async
    let mounted = true;
    if (mounted) {
      validateForm();
    }
    return () => mounted = false;
  }, [roleValidationsErrors]);

  return (
    <Card>
      <CardContent>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                id="name"
                name="name"
                type="text"
                label={t("role.form.name")}
                error={touched.name && !!errors.name}
                helperText={touched.name && errors.name}
                sx={{my: 1, minHeight: 80}}
                fullWidth
                value={values.name}
                onChange={(e) => {
                  handleChangeValue();
                  handleChange(e);
                }}
                onBlur={handleBlur}
                disabled={isSubmitting}
              />
            </Grid>
            <Grid item xs={12} md={8}>
              <FormControl sx={{width: "100%"}}>
                <InputLabel id="permissions-label">{t("role.form.permissions")}</InputLabel>
                <Select
                  input={<OutlinedInput label={t("role.form.permissions")}/>}
                  id="permissions"
                  name="permissions"
                  multiple
                  disabled={isSubmitting || ability.cannot("get", "permissions")}
                  value={rolePermissionIdList}
                  onChange={handleChangePermissions}
                  MenuProps={MenuProps}
                  renderValue={(selected) => (
                    <Box sx={{display: "flex", flexWrap: "wrap", gap: 0.5}}>
                      {selected.map((value) => {
                        if (value !== undefined) {
                          return (
                            <Tooltip
                              key={value}
                              arrow
                              title={
                                (<Typography
                                  variant={"body2"}>{(i18n.exists("permissions.descriptions." + allPermissionObject[value]) ? t("permissions.descriptions." + allPermissionObject[value]) : allPermissionObject[value]) as string}</Typography>)
                              }
                            >
                              <Chip
                                label={(i18n.exists("permissions.names." + allPermissionObject[value]) ? t("permissions.names." + allPermissionObject[value]) : allPermissionObject[value]) as string}
                                clickable
                                deleteIcon={
                                  <Cancel
                                    onMouseDown={(event) => event.stopPropagation()}
                                  />
                                }
                                onDelete={(e) => handleDeletePermission(e, value)}
                                disabled={ability.cannot("get", "permissions")}
                              />
                            </Tooltip>
                          );
                        }
                      })}
                    </Box>
                  )}
                >
                  {allPermissionIdList.map((id) => (
                    <MenuItem
                      key={id}
                      value={id}
                    >
                      <Checkbox checked={rolePermissionIdList.indexOf(id) > -1}/>
                      <ListItemText primary={
                        <Grid container columnSpacing={3}>
                          <Grid item>
                            <Typography textAlign={"left"} variant={"body1"}>
                              {i18n.exists("permissions.names." + allPermissionObject[id]) ? t("permissions.names." + allPermissionObject[id]) : allPermissionObject[id]}
                            </Typography>
                          </Grid>
                          <Grid item display={"flex"} alignItems={"center"} justifyContent={"center"}>
                            <Typography textAlign={"right"} variant={"body2"}
                              color={"#cccccc"}>
                              {i18n.exists("permissions.descriptions." + allPermissionObject[id]) ? t("permissions.descriptions." + allPermissionObject[id]) : ""}
                            </Typography>
                          </Grid>
                        </Grid>
                      }/>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Can I={"get"} a={"permissions"} ability={ability}>
              <Grid item xs={12} md={4}>
                <FormControlLabel
                  label={t("role.form.check all permissions") as string}
                  control={
                    <Checkbox
                      checked={allPermissionIdList.length === rolePermissionIdList.length}
                      indeterminate={rolePermissionIdList.length > 0 && allPermissionIdList.length > rolePermissionIdList.length}
                      onChange={handleCheckAllPermissions}
                    />
                  }
                />
              </Grid>
            </Can>
            <Grid item xs={12}>
              <LoadingButton
                type="submit"
                variant="contained"
                color="success"
                loading={isSubmitting}
                disabled={(type === "add" && ability.cannot("create", "role")) ||
                                    (type === "edit" && ability.cannot("update", "role"))}
              >
                {t("role.form.save")}
              </LoadingButton>
            </Grid>
          </Grid>
        </form>
      </CardContent>
    </Card>
  );
};

export default RoleForm;