import {CardActions, ICard, ICardState, TCardAction} from "../../../types/store/card";

const initialState: ICardState = {
  card: {} as ICard,
  isLoading: false,
};

export const cardReducer = (state = initialState, action: TCardAction): ICardState => {
  switch (action.type) {
  case CardActions.SET_CARD:
    return {...state, card: action.card};
  case CardActions.SET_CARD_ERROR:
    return {...state, errorCode: action.errorCode, errorMessage: action.errorMessage};
  case CardActions.SET_CARD_FORM_ERRORS:
    return {...state, formErrors: action.formErrors};
  case CardActions.SET_CARD_LOADING:
    return {...state, isLoading: action.loading};
  case CardActions.CLEAR_CARD:
    return initialState;
  default:
    return state;
  }
};