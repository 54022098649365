import React, {useEffect} from "react";
import {Alert, AlertTitle, Container, Typography} from "@mui/material";
import MerchantForm from "../../../components/merchant/merchant-form";
import {useTranslation} from "react-i18next";
import {useTypedSelector} from "../../../hooks/useTypedSelector";
import {useActions} from "../../../hooks/useActions";
import {useParams} from "react-router-dom";
import ability from "../../../utils/can";
import Error404 from "../../error-pages/error-404";
import Loading from "../../../components/loading";

/** A React component that is used to edit merchant. */
const MerchantEdit = () => {
  const {t} = useTranslation();
  const {isLoading, errorCode} = useTypedSelector(state => state.merchant);
  const {fetchMerchant, clearMerchant} = useActions();
  const merchantId = useParams()?.id;

  useEffect(() => {
    if (merchantId && +merchantId > 0 && ability.can("get", "merchant")) {
      fetchMerchant(+merchantId);
    }
    return () => {
      clearMerchant();
    };
  }, []);

  if (isLoading) {
    return <Loading/>;
  } else if (errorCode === 424) {
    return (
      <Container sx={{mt: 2}}>
        <Alert severity="error">
          <AlertTitle>{t("merchant.alert.error")}</AlertTitle>
          {t("merchant.alert.Merchant ID not found in partner API")}
        </Alert>
      </Container>
    );
  } else if (errorCode === 404 || !merchantId || +merchantId <= 0 || ability.cannot("get", "merchant")) {
    return (<Error404/>);
  }

  return (
    <Container>
      <Typography
        variant="h5"
        sx={{display: "flex", justifyContent: "center", mb: 4}}
      >
        {t("merchant.edit.header")}
      </Typography>
      <MerchantForm type={"edit"} merchantId={+merchantId}/>
    </Container>
  );
};

export default MerchantEdit;