import {IMerchant} from "./index";

export interface IMerchantListState {
    isLoading: boolean,
    merchants: Omit<IMerchant, "image">[]
}

export enum MerchantListActions {
    SET_MERCHANT_LIST_LOADING = "SET_MERCHANT_LIST_LOADING",
    SET_MERCHANT_LIST = "SET_MERCHANT_LIST",
}

export interface ISetMerchantList {
    type: MerchantListActions.SET_MERCHANT_LIST,
    merchants: Omit<IMerchant, "image">[]
}

export interface ISetMerchantListLoading {
    type: MerchantListActions.SET_MERCHANT_LIST_LOADING,
    loading: boolean
}

export type TMerchantListAction = ISetMerchantList | ISetMerchantListLoading