import { Box } from "@mui/material";
import React, {FC} from "react";

interface IProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const AccountSatchelWalletTabPanel: FC<IProps> = ({index, value, children}) => {
  return (
    <Box
      component={"div"}
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      sx={{width: "100%"}}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </Box>
  );
};

export default AccountSatchelWalletTabPanel;