import React from "react";
import {Card, CardContent, CardHeader, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import {useTypedSelector} from "../../../hooks/useTypedSelector";
import AccountDetailGridRow from "../account-detail-grid-row";
import {IAccountIdentityVerificationInfo} from "../../../types/store/account/account-details";
import {getDateTime} from "../../../utils/time";

const AccountIdentityVerification = () => {
  const { t } = useTranslation();
  const { identityVerification } = useTypedSelector((state) => state.accountDetails);
  const getValue = (key: keyof IAccountIdentityVerificationInfo["recognized"]) => {
    if (key === "createdAt" || key === "updatedAt") {
      return getDateTime(identityVerification.recognized[key]);
    }
    return identityVerification.recognized[key];
  };

  return (
	  <Card sx={{ mb: 2 }}>
		  <CardHeader title={t("account.details.identity_verification")} />
		  <CardContent>
			  {identityVerification?.recognized && (
          <>
            {Object.keys(identityVerification.recognized).map((key, index) => (
              <AccountDetailGridRow
	              key={index}
                title={t(`account.details.${key}`)}
                value={getValue(key as keyof IAccountIdentityVerificationInfo["recognized"])}
              />
            ))}
          </>
			  )}
			  {!identityVerification?.recognized && (
          <Typography>
            {t("account.details.nodata")}
          </Typography>
			  )}
		  </CardContent>
    </Card>
  );
};

export default AccountIdentityVerification;