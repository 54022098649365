import React from "react";
import { render } from "react-dom";
// import { environment } from './environment';
import { App } from "./app";
import i18n from "./locale/config";

i18n();

// const { api } = environment;

render(<App />, document.getElementById("root"));
