import $api from "../http";
import { AxiosResponse } from "axios";
import {
  IApplicationsListByAccountIdRequestParams,
  IApplicationsListRequestParams,
  IUpdateBusinessApplicationBeneficiariesRequest,
} from "../types/service/request/applications-request";
import {
  IApplicationInfoResponse,
  IApplicationsListResponse,
  IUpdateApplicationRequestResponse, IUpdateBusinessApplicationBeneficiariesResponse,
} from "../types/service/response/applications-response";
import {
  TApplicationStatus,
} from "../types/store/applications";

/** It's a wrapper around the API endpoints related to satchel account request */
export default class ApplicationsService {
  static async applicationsList(
    params: IApplicationsListRequestParams
  ): Promise<AxiosResponse<IApplicationsListResponse>> {
    let i: keyof IApplicationsListRequestParams;
    for (i in params) {
      if (params[i] === undefined || params[i] === null) delete params[i];
    }
    return $api.get<IApplicationsListResponse>("/satchel/accounts/requests", {
      params,
    });
  }

  static async applicationsListByAccountId(
    params: IApplicationsListByAccountIdRequestParams
  ): Promise<AxiosResponse<IApplicationsListResponse>> {
    let i: keyof IApplicationsListByAccountIdRequestParams;
    for (i in params) {
      if (params[i] === undefined || params[i] === null) delete params[i];
    }
    return $api.get<IApplicationsListResponse>(
      "/satchel/accounts/requests-by-account-id",
      {
        params,
      }
    );
  }

  static async applicationDetailInfo(
    requestId: string,
    type: "individual" | "corporate"
  ): Promise<AxiosResponse<IApplicationInfoResponse>> {
    return $api.get<IApplicationInfoResponse>(
      `/satchel/accounts/requests/${requestId}?type=${type}`
    );
  }

  static async updateApplicationRequest(
    requestId: string,
    type: "individual" | "corporate",
    status: Exclude<TApplicationStatus, "received">,
    userId: string,
    rejectedReason?: string
  ): Promise<AxiosResponse<IUpdateApplicationRequestResponse>> {
    return $api.put<IUpdateApplicationRequestResponse>(
      `/satchel/accounts/requests/${requestId}`,
      {
        userId,
        type,
        status,
        rejectedReason,
      }
    );
  }

  static async updateBusinessApplicationBeneficiaries(
    data: IUpdateBusinessApplicationBeneficiariesRequest
  ): Promise<AxiosResponse<IUpdateBusinessApplicationBeneficiariesResponse>> {
    return $api.post<IUpdateBusinessApplicationBeneficiariesResponse>(
      "/satchel/accounts/requests/change-data",
      data
    );
  }
}
