import React, { useRef, useState } from "react";
import { languageLabels } from "../../../locale/config";
import {
  Button,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { KeyboardArrowUp, KeyboardArrowDown } from "@mui/icons-material";

/**
 * A React component that renders a language switcher and allow to switch language.
 */
export default function BasicLanguageSwitcher() {
  const { i18n } = useTranslation();

  const { language, languages } = i18n;

  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);

  const handleClick = () => {
    setOpen((prevOpen) => !prevOpen);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleMenuItemClick = (lang: string) => {
    i18n.changeLanguage(lang);
    localStorage.setItem("lang", lang);
    handleClose();
  };

  return (
    <>
      <Button
        sx={(theme) => ({
          mx: "1rem",
          color: theme.palette.text.secondary,
          "&:hover, &.active": {
            color: theme.palette.primary.main,
          },
        })}
        className={open ? "active" : ""}
        id="basic-button"
        ref={anchorRef}
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        endIcon={open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
      >
        {language.toUpperCase()}
      </Button>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        placement="bottom-end"
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom-end" ? "right top" : "right bottom",
            }}
          >
            <Paper sx={{ mt: ".2rem" }}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id="composition-menu"
                  aria-labelledby="composition-button"
                >
                  {languages.map((lang, index) => {
                    if (languageLabels[language] !== languageLabels[lang]) {
                      return (
                        <MenuItem
                          key={index}
                          onClick={() => handleMenuItemClick(lang)}
                        >
                          {languageLabels[lang]}
                        </MenuItem>
                      );
                    }
                    return undefined;
                  })}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
}
