import {TLayout} from "../../store/reducers/layout";

export type TLayoutActions = IChangeLayoutAction

export enum LayoutActionList {
  CHANGE_LAYOUT = "CHANGE_LAYOUT"
}

export interface IChangeLayoutAction {
  type: LayoutActionList.CHANGE_LAYOUT
  payload: TLayout
}
