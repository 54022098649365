import {IRequestResponseType} from "../../types/service/test/test-response";
import {PermissionsResponse} from "../../types/service/response/permissions-response";
import {PERMISSIONS} from "../../constants/statuses";

/** It's a mock for the API */
export default class PermissionsTestService {
  static async permissionsList(): Promise<IRequestResponseType<unknown, PermissionsResponse>> {
    return {
      headers: undefined,
      data: {
        result: PERMISSIONS,
      }
    };
  }
}