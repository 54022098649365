import {useTranslation} from "react-i18next";
import {notification} from "../utils/notification";

/** Helper hook to show toast messages. */
const useNotifierMessage = () => {
  const {t} = useTranslation();

  const showNoPermissionsMessage = () => {
    notification.showErrorMessage(t("No permissions"));
  };

  return {
    showNoPermissionsMessage: showNoPermissionsMessage
  };
};

export default useNotifierMessage;