import React, { useEffect, useState } from "react";
import { IAccountCorporateDetailsCompany } from "../../../types/store/account/account-details";
import { Card, CardContent, CardHeader } from "@mui/material";
import AccountDocumentsBlockCorporate from "../account-documents-block/account-documents-block-corporate";
import AccountDetailGridRow from "../account-detail-grid-row";
import { useTranslation } from "react-i18next";

interface IProps {
  corporateDetailsCompanies: IAccountCorporateDetailsCompany[];
}

export const AccountCorporateDetailsCompanies = ({
  corporateDetailsCompanies,
}: IProps) => {
  const corporateTypes: Record<string, string> = {
    authorizedSignatories: "Authorized signatories",
    directors: "Directors",
    shareholders: "Shareholders",
    finalBeneficiaries: "Beneficiaries",
  };
  const [companies, setCompanies] = useState<
    Record<string, IAccountCorporateDetailsCompany[]>
  >({});
  const { t } = useTranslation();

  useEffect(() => {
    if (corporateDetailsCompanies?.length > 0) {
      const localCompanies: Record<string, IAccountCorporateDetailsCompany[]> =
        {};
      Object.keys(corporateTypes).map((key) => {
        localCompanies[key] = corporateDetailsCompanies.filter(
          (item) => item.type === key
        );
      });
      setCompanies(localCompanies);
    }
  }, [corporateDetailsCompanies]);

  useEffect(() => {
    console.log(companies);
    console.log(corporateDetailsCompanies);
  }, [companies]);

  return (
    <>
      {corporateDetailsCompanies?.length > 0 && (
        <Card sx={{ mb: 2 }}>
          {/*<CardHeader title={t("account.details.companies")} />*/}
          <CardContent>
            {Object.keys(companies).map((corporateTypeKey, typeIndex) => (
              <React.Fragment key={typeIndex}>
                {companies[corporateTypeKey].length > 0 && (
                  <Card sx={{ mb: 2 }}>
                    <CardHeader title={corporateTypes[corporateTypeKey]} />
                    <CardContent>
                      {companies[corporateTypeKey].map((company, index) => (
                        <Card key={index} sx={{ mb: 2 }}>
                          <CardHeader
                            sx={{ fontSize: "1.3rem" }}
                            title={company.name}
                          />
                          <CardContent>
                            <AccountDocumentsBlockCorporate
                              documents={company.documents}
                            />
                            <AccountDetailGridRow
                              title={t("account.details.companyName")}
                              value={company.name}
                            />
                            <AccountDetailGridRow
                              title={t("account.details.registrationNumber")}
                              value={company.registrationNumber}
                            />
                            <AccountDetailGridRow
                              title={t("account.details.type")}
                              value={company.type}
                            />
                            <AccountDetailGridRow
                              title={t("account.details.addressCity")}
                              value={company.addressCity}
                            />
                            <AccountDetailGridRow
                              title={t("account.details.addressCountryCode")}
                              value={company.addressCountryCode}
                            />
                            <AccountDetailGridRow
                              title={t("account.details.addressStreet")}
                              value={company.addressStreet}
                            />
                            <AccountDetailGridRow
                              title={t("account.details.addressZipCode")}
                              value={company.addressZipCode}
                            />
                            <AccountDetailGridRow
                              title={t(
                                "account.details.countryOfIncorporation"
                              )}
                              value={company.countryOfIncorporation}
                            />
                            <AccountDetailGridRow
                              title={t("account.details.countryRegion")}
                              value={company.countryRegion}
                            />
                            <AccountDetailGridRow
                              title={t("account.details.email")}
                              value={company.email}
                            />
                            <AccountDetailGridRow
                              title={t("account.details.phoneNumber")}
                              value={company.phoneNumber}
                            />
                            <AccountDetailGridRow
                              title={t("account.details.website")}
                              value={company.website}
                            />
                            <AccountDetailGridRow
                              title={t("account.details.addressCity")}
                              value={company.addressCity}
                            />
                          </CardContent>
                        </Card>
                      ))}
                    </CardContent>
                  </Card>
                )}
              </React.Fragment>
            ))}
          </CardContent>
        </Card>
      )}
    </>
  );
};

export default AccountCorporateDetailsCompanies;
