import {ITransaction} from "../../../types/store/transaction";
import {
  ITransactionInfoState,
  TransactionInfoActions,
  TTransactionInfoAction
} from "../../../types/store/transaction/transaction-info";

const initialState: ITransactionInfoState = {
  isLoading: false,
  transaction: {} as ITransaction
};

export const transactionInfoReducer = (state: ITransactionInfoState = initialState, action: TTransactionInfoAction): ITransactionInfoState => {
  switch (action.type) {
  case TransactionInfoActions.SET_TRANSACTION_INFO:
    return {...state, transaction: action.transaction};
  case TransactionInfoActions.SET_TRANSACTION_LOADING:
    return {...state, isLoading: action.loading};
  case TransactionInfoActions.SET_TRANSACTION_ERROR:
    return {...state, error: action.error};
  case TransactionInfoActions.CLEAR_TRANSACTION_INFO:
    return {...state, transaction: initialState.transaction};
  default:
    return state;
  }
};