import {Card, CardContent, CardHeader, Typography} from "@mui/material";
import React, {FC} from "react";
import { useTypedSelector } from "../../../hooks/useTypedSelector";
import AccountDetailGridRow from "../account-detail-grid-row";
import {useTranslation} from "react-i18next";

interface IAccountDocumentsBlockProps {
  cardTitle?: string;
}

const AccountDocumentsBlock: FC<IAccountDocumentsBlockProps> = ({cardTitle}) => {
  const { documents } = useTypedSelector((state) => state.accountDetails);

  const {t} = useTranslation();

  return (
    <Card sx={{mb: 2}}>
      <CardHeader title={cardTitle || ""}/>
      <CardContent>
        {!!document && documents.map((document, documentIndex) => (
          <Card key={documentIndex} sx={{mb: 2}}>
            <CardHeader sx={{fontSize: "1.3rem"}} title={t("account.details.document.type") + ": " + document.type}/>
            <CardContent>
              {document.images.map((image, imageIndex) => (
                <AccountDetailGridRow
                  key={imageIndex}
                  title={"File"}
                  value={image.s3Link}
                  contentType={image.contentType || ""}
                />
              ))}
              {("type" in document) && <AccountDetailGridRow title={t("account.details.document.type")} value={document.type}/>}
              {(document?.type === "ID" || document?.type === "PASSPORT") && (
                <>
                  {("number" in document) && <AccountDetailGridRow title={t("account.details.document.number")} value={document.number}/>}
                  {("citizenCountry" in document) && <AccountDetailGridRow title={t("account.details.document.citizenCountry")} value={document.citizenCountry}/>}
                  {("issueCountry" in document) &&<AccountDetailGridRow title={t("account.details.document.issueCountry")} value={document.issueCountry}/>}
                  {("issueDate" in document) && <AccountDetailGridRow title={t("account.details.document.issueDate")} value={document.issueDate}/>}
                  {("expiryDate" in document) && <AccountDetailGridRow title={t("account.details.document.expiryDate")} value={document.expiryDate}/>}
                  {("issuing" in document) && <AccountDetailGridRow title={t("account.details.document.issuing")} value={document.issuing}/>}
                </>
              )}
            </CardContent>
          </Card>
        ))}
        {!documents || documents.length === 0 && (
          <Typography>
            {t("account.details.nodata")}
          </Typography>
        )}
      </CardContent>
    </Card>
  );
};

export default AccountDocumentsBlock;
