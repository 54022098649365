import {IRole} from "../role";

export interface IUserInfo {
    id: number,
    name: string
    email: string,
    email_verified_at?: string | null,
    password: string,
    createdAt?: string,
    updatedAt?: string,
    role: IRole,
    isBlocked: boolean
}

export interface IUserDataToUpdate {
    name: string,
    email: string,
    password?: string,
    role: Pick<IRole, "id">
}

export interface IUserDataToUpdateBlockStatus {
    isBlocked: boolean
}

export type UserValidationErrors = Pick<IUserInfo, "email" | "password">;

export interface IUserInfoState {
    user: IUserInfo,
    isLoading: boolean,
    userValidationsErrors?: UserValidationErrors
}

export enum UserInfoActions {
    SET_USER_INFO = "SET_USER_INFO",
    SET_USER_INFO_LOADING = "SET_USER_INFO_LOADING",
    CLEAR_USER_INFO = "CLEAR_USER_INFO",
    SET_USER_VALIDATION_ERRORS = "SET_USER_VALIDATION_ERRORS",
}

export interface ISetUserInfo {
    type: UserInfoActions.SET_USER_INFO,
    user: IUserInfo
}

export interface ISetUserInfoLoading {
    type: UserInfoActions.SET_USER_INFO_LOADING,
    loading: boolean
}

export interface IClearUserInfo {
    type: UserInfoActions.CLEAR_USER_INFO,
}

export interface ISetUserValidationErrors {
    type: UserInfoActions.SET_USER_VALIDATION_ERRORS,
    errors: UserValidationErrors
}

export type TUserInfoAction = ISetUserInfo | ISetUserInfoLoading | IClearUserInfo | ISetUserValidationErrors;