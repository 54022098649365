import {
  ITransactionsListState,
  TransactionListActions,
  TTransactionsListAction
} from "../../../types/store/transaction/transactions-list";
import {ITransaction} from "../../../types/store/transaction";

const initialState: ITransactionsListState = {
  transactions: [] as ITransaction[],
  isLoading: false,
  totalPages: 0,
  perPage: 10,
  page: 1,
  filterStatus: undefined,
  sort: "DESC",
  transferType: undefined,
  errorCode: undefined,
  errorMessage: "",
};

export const transactionsListReducer = (state: ITransactionsListState = initialState, action: TTransactionsListAction): ITransactionsListState => {
  switch (action.type) {
  case TransactionListActions.SET_TRANSACTIONS_LIST:
    return {
      ...state,
      transactions: action.payload.transactions,
      totalPages: action.payload.totalPages
    };
  case TransactionListActions.CLEAR_TRANSACTIONS_LIST:
    return initialState;
  case TransactionListActions.SET_TRANSACTIONS_LIST_PAGE:
    return {
      ...state,
      page: action.page
    };
  case TransactionListActions.SET_TRANSACTIONS_LIST_LOADING:
    return {
      ...state,
      isLoading: action.loading
    };
  case TransactionListActions.SET_TRANSACTIONS_LIST_ITEMS_PER_PAGE:
    return {
      ...state,
      perPage: action.perPage
    };
  case TransactionListActions.SET_TRANSACTIONS_LIST_SORT:
    return {
      ...state,
      sort: action.sort
    };
  case TransactionListActions.SET_TRANSACTIONS_LIST_STATUS:
    return {
      ...state,
      filterStatus: action.status
    };
  case TransactionListActions.SET_TRANSACTIONS_LIST_ERROR:
    return {
      ...state,
      errorMessage: action.errorMessage,
      errorCode: action.errorCode
    };
  case TransactionListActions.SET_TRANSACTIONS_LIST_TRANSFER_TYPE:
    return {
      ...state,
      transferType: action.transferType
    };
  default:
    return state;
  }
};