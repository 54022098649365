const cz = {
  translation: {
    common: {
      deposit: "Vklad",
      credit: "Kredit",
      investments: "Investice",
      rows_per_page: "Počet řádků na stránku:",
      of: "z",
      btn_cancel: "Zrušit",
      btn_decline: "Zamítnout",
      btn_confirm: "Potvrdit",
      btn_more_info: "Více detailů",
      btn_open: "Otevřít",
      btn_open_link: "Otevřít link",
    },
    transactions: {
      client_profile: {
        header: "Profil Klienta",
        full_name: "Jméno a příjmení",
        birthday: "Datum narození",
        sin: "Rodné číslo",
        contact: "Kontakt",
        address: "Adresa",
        home: "Domů",
        mobile: "Telefonní číslo",
        email: "Email",
        employer: "Zaměstnavatel",
        job_title: "Pracovní pozice",
        employer_name: "Název zaměstnavatele",
        start_date: "Datum vytvoření",
        salary: "Plat",
        family: "Rodina",
        marital_status: "Rodinný stav",
        spouse_name: "Jméno manžela/ky",
        spouse_birthday: "Datum narození manžela/ky",
        children: "Děti"
      },
      account_activity: {
        header: "Aktivity na účtu",
        branch: "Pobočka",
        institution: "Instituce",
        account_number: "Číslo účtu"
      },
      table: {
        id: "ID",
        title: "Transakce",
        timestamp: "Datum",
        operation: "Operace",
        transactionId: "ID transakce",
        operationType: "Operace",
        fromAccount: "Z účtu",
        toAccount: "Na účet",
        status: "Stav",
        amount: "Suma",
        details: "Detaily",
        all: "Vše",
        transferType: "Typ převodu",
        "transferType transfer": "Převod peněz",
        "transferType merchantTransfer": "Převod obchodníkovi"
      },
      info: {
        header: "Informace o transakci",
        data: "Údaje o transakci",
        origin: "Z eWallet účtu",
        destination: "Na eWallet účet",
        "Transaction id": "ID transakce",
        "Time & date": "Datum a čas",
        "Amount": "Suma",
        "Status": "Stav",
        "Processsing status": "Stav procesování",
        "Source": "Místo vytvoření",
        "Type of operation": "Typ operace",
        "Confirm time": "Doba potvrzení",
        "End time": "Čas ukončení",
        "Account": "Číslo eWallet účtu",
        "Country": "Země",
        "Bank code": "Bankovní kód",
      },
      modal: {
        confirm_header: "Potvrdit transakci?",
        confirm_question: "Opravdu si přejete potvrdit tuto transakci?",
        decline_header: "Zamítnout účet?",
        decline_question: "Opravdu si přejete zamítnout tento účet?",
        more_info_header: "Potřebuji více informací",
        more_info_question: "Prosím napište, jaké informace jsou potřebné pro úspěšné zpracování této transakce.",
        more_info_label: "Zpráva",
      },
      data: {
        merchant: "Informace o obchodníkovi"
      },
      statuses: {
        "UNDEFINED": "NEDEFINOVÁNO",
        "PENDING": "ČEKAJÍCÍ",
        "ACCEPTED": "AKCEPTOVÁNO",
        "REJECTED": "ZAMÍTNUTO",
        "AML_CHECK": "AML KONTROLA",
        "CREATED": "VYTVOŘENO",
        "AML_SUCCESS": "AML SCHVÁLENO",
        "BLOCKCHAIN_PENDING": "ČEKAJÍCÍ NA BLOCKCHAIN"
      }
    },
    account: {
      common: {
        unassigned: "Nepřiřazeno",
        declined: "Zamítnuto",
        confirmed: "Potvrzeno",
        all: "Vše"
      },
      table: {
        accounts: "Klientské Účty",
        id: "ID",
        account_id: "Číslo klientského účtu",
        firstName: "Jméno",
        lastName: "Příjmení",
        uniqueIdentifier: "Rodní číslo",
        status: "Stav",
        created_at: "Datum",
        details: "Detaily",
        actions: "Akce",
        sanctionStatus: "Sankční stav"
      },
      details: {
        header: "Detaily klientského účtu",
        basic_info: "Základní informace",
        first_name: "Jméno",
        last_name: "Příjmení",
        contact_info: "Kontaktní informace",
        email: "Emailová adresa",
        phone_number: "Telefonní číslo",
        status: "Stav",
        account_ids: "Přehled eWallet účtů",
        btn_confirm: "Potvrdit",
        btn_decline: "Zamítnout",
        sex: "Pohlaví",
        nationality: "Národnost",
        birthDate: "Datum narození",
        documents: "Doklady",
        document: "Dokument",
        sexF: "Žena",
        sexM: "Muž",
        photo: "Fotografie",
        signedContract: "Smluvní dokumentace",
        sanctions: "Sankční seznam",
        check_result: "Zkontrolovat výsledek",
        find_by: "Hledat podle",
        sanction_link: "Link na sankční seznam"
      },
      modal: {
        confirm_header: "Schválit účet?",
        confirm_question: "Opravdu si přejete schválit tento účet?",
        decline_header: "Zamítnout účet?",
        decline_question: "Opravdu si přejete zamítnout tento účet? Prosím popište důvod zamítnutí do textového políčka níže.",
        decline_reason_label: "Důvod"
      },
      alert: {
        error: "Chyba",
        "Account ID not found in partner API": "Číslo účtu nebylo nalezeno během procesování API"
      },
      sanctions: {
        fullName: "Jméno a příjmení"
      },
      statuses: {
        "UNDEFINED": "NEDEFINOVÁNO",
        "PENDING": "ČEKAJÍCÍ",
        "ACCEPTED": "AKCEPTOVÁNO",
        "REJECTED": "ZAMÍTNUTO"
      },
      metadata: {
        "CompareResult": "Porovnat výsledek",
        "AgeRange": "Věkový pásmo",
        "years old": "Stáří",
        "AgeRangeHigh": "Maximální věk",
        "AgeRangeLow": "Minimální věk",
        "Beard_true": "Má bradu",
        "Beard_false": "Nemá bradu",
        "Confidence": "Vypadá jako tvář",
        "Emotions (CALM)": "Emoce KLIDNÝ",
        "Emotions (SURPRISED)": "Emoce PŘEKVAPEN",
        "Emotions (FEAR)": "Emoce VYSTRAŠENÝ",
        "Emotions (SAD)": "Emoce SMUTNÝ",
        "Emotions (HAPPY)": "Emoce ŠŤASTNÝ",
        "Emotions (CONFUSED)": "Emoce ZMETEN",
        "Emotions (ANGRY)": "Emoce nazlobený",
        "Emotions (DISGUSTED)": "Emoce ZNECHUTĚN",
        "Eyeglasses_true": "Má brýle",
        "Eyeglasses_false": "Nemá brýle",
        "EyesOpen_true": "Oči jsou otevřené",
        "EyesOpen_false": "Oči jsou zavřené",
        "Male": "Vypadá jako muž",
        "Female": "Vypadá jako žena",
        "MouthOpen_true": "Ústa jsou otevřená",
        "MouthOpen_false": "Ústa jsou zavřená",
        "Mustache_true": "Má vousy",
        "Mustache_false": "Nemá vousy",
        "PosePitch": "Kývání hlavy",
        "PoseRoll": "Naklánění hlavy",
        "PoseYaw": "Kroucení hlavy",
        "QualityBrightness": "Kvalita jasu",
        "QualitySharpness": "Kvalita ostrosti",
        "Smile_true": "Usmívá se",
        "Smile_false": "Neusmívá se",
        "Sunglasses_true": "Má sluneční brýle",
        "Sunglasses_false": "Nemá sluneční brýle",
      }
    },
    menu: {
      home: "Domů",
      applications: "Prohlášení",
      transactions: "Transakce",
      accounts: "Účty",
      profile: "Profil",
      logout: "Odhlášení",
      management: "Manažment",
      permissions: "Oprávnění",
      roles: "Role",
      users: "Uživatelé",
      merchants: "Obchodníci",
      "Blockchain monitoring": "Blockchain monitoring",
      techEWallet: "Technický eWallet",
      cards: "Karty",
      "Sanction list": "Sankční seznam",
      "Search": "Vyhledat",
      "Search List": "Vyhledávací seznam",
    },
    http: {
      errors: {
        "email incorrect": "Email adresa není správná",
        "password incorrect": "Heslo není správné",
        "server not response": "Server neodpovídá",
        "server error": "Chyba serveru",
        "password must be longer than or equal to 4 characters": "Heslo je příliš krátké",
        "name is not unique": "Toto jméno již existuje",
        "email is not unique": "Tento email již existuje",
        "QueryFailedError": "Uložit chybu",
        "account with this accountId not find in internal database": "Toto číslo účtu nebylo nalezeno v databázi"
      }
    },
    form: {
      auth: {
        "Login": "Přihlášení",
        "Password": "Heslo",
        "Email": "Email",
        "Sign In": "Přihlásit se",
      }
    },
    auth: {
      common: {
        "Password successfully changed!": "Heslo bylo úspěšně změněno.",
      }
    },
    profile: {
      header: "Profil",
      change_password: "Změnit heslo",
      general_info: "Všeobecné informace",
      form: {
        email: "Email",
        password: "Současné heslo",
        passwordNew: "Nové heslo",
        passwordDup: "Potvrdit nové heslo",
        save: "Uložit"
      }
    },
    validations: {
      "Login is required": "Je třeba se přihlásit",
      "Login must be a an email": "Pro přihlášení je třeba zadat email",
      "Password is required": "Heslo je povinné",
      "Validation error": "Chyba ověření",
      "Wrong email": "Nesprávný email",
      "Min length": "Minimální délka je {{hodnota}} znaků",
      "Max length": "Maximální délka je {{hodnota}} znaků",
      "email incorrect": "Email nebyl nalezen",
      "password incorrect": "Heslo je nesprávné",
      "password must be longer than or equal to 4 characters": "Heslo je příliš krátké",
      "Name is required": "Jméno je povinné",
      "Email is required": "Email je povinen",
      "Passwords do not match": "Hesla se neshodují",
      "email is not unique": "Tato emailová adresa již existuje",
      "Role is required": "Role je povinná",
      "name is not unique": "Toto jméno již existuje",
      "You cannot delete your account": "Nemůžete smazat Váš účet",
      "Old password is required": "Původní heslo je potřebné",
      "Field is required": "Pole je povinné",
      "Allow only digits": "Povoleny jsou jen číslice",
    },
    error_page: {
      "Ops": "Chyba",
      "Page not found": "Tato stránka neexistuje nebo je nedostupná.",
      "Go back to home": "Vraťte se zpět do hlavního menu"
    },
    permissions: {
      table: {
        title: "Povolení",
        id: "Identifikační číslo",
        name: "Jméno",
        description: "Popis"
      },
      names: {
        get_permissions: "Zobrazit seznam povolení",
        get_roles: "Zobrazit seznam rolí",
        get_role: "Zobrazit detaily role",
        create_role: "Vytvořit roli",
        update_role: "Upravit roli",
        remove_role: "Odstranit roli",
        get_users: "Zobrazit seznam uživatelů",
        get_user: "Zobrazit detaily uživatele",
        create_user: "Vytvořit uživatele",
        update_user: "Upravit uživatele",
        remove_user: "Odstranit uživatele",
        get_verification_list: "Zobrazit seznam účtů",
        get_account_info: "Zobrazit detaily na účtu",
        set_verification_result: "Nastavit stav výsledku ověření účtu",
        get_transactions_list: "Zobrazit seznam transakcí",
        get_transaction_info: "Zobrazit detail transakce",
        set_transaction_verification_result: "Nastavit stav výsledku ověření transakce",
        get_transaction_additional_info: "Zobrazit dodatečné informace o transakci",
        update_setting: "Upravit nastavení",
        get_merchants: "Zobrazit seznam obchodníků",
        get_merchant: "Zobrazit detaily obchodníka",
        create_merchant: "Vytvořit obchodníka",
        update_merchant: "Upravit obchodníka",
        remove_merchant: "Odstranit obchodníka",
        get_wallets: "Zobrazit seznam eWallet účtů",
        get_wallet: "Zobrazit detaily na eWallet účtu",
        create_wallet: "Vytvořit eWallet",
        update_wallet: "Upravit eWallet",
        remove_wallet: "Odstranit eWallet",
        get_cards_list: "Zobrazit seznam karet",
        get_card_info: "Zobrazit detail karty"
      },
      descriptions: {
        get_permissions: "Povolit zobrazit seznam povolení",
        get_roles: "Povolit zobrazit seznam rolí",
        get_role: "Povolit zobrazit informace k roli",
        create_role: "Povolit vytvořit novou roli",
        update_role: "Povolit upravit existující roli",
        remove_role: "Povolit odstranit roli",
        get_users: "Povolit zobrazit seznam uživatelů",
        get_user: "Povolit zobrazit informace o uživateli",
        create_user: "Povolit vytvořit nového uživatele",
        update_user: "Povolit upravit stávajícího uživatele",
        remove_user: "Povolit odstranit uživatele",
        get_verification_list: "Povolit zobrazit seznam účtů",
        get_account_info: "Povolit zobrazit detaily na účtu",
        set_verification_result: "Povolit nastavit stav výsledku ověření účtu",
        get_transactions_list: "Povolit zobrazit seznam transakcí",
        get_transaction_info: "Povolit zobrazit detaily transakce",
        set_transaction_verification_result: "Nastavit stav výsledku ověření transakce",
        get_transaction_additional_info: "Povolit zobrazit dodatečné informace o transakci",
        update_setting: "Povolit upravit nastavení",
        get_merchants: "Povolit zobrazit seznam obchodníků",
        get_merchant: "Povolit zobrazit detaily obchodníka",
        create_merchant: "Povolit vytvořit nového obchodníka",
        update_merchant: "Povolit upravit stávajícího obchodníka",
        remove_merchant: "Povolit odstranit obchodníka",
        get_wallets: "Povolit zobrazit seznam eWallet účtů",
        get_wallet: "Povolit zobrazit detaily na eWallet účtu",
        create_wallet: "Povolit vytvořit nový eWallet účet",
        update_wallet: "Povolit upravit stávající eWallet",
        remove_wallet: "Povolit odstranit eWallet",
        get_cards_list: "Povolit zobrazit seznam karet",
        get_card_info: "Povolit zobrazit detail karty"
      },
    },
    role: {
      table: {
        title: "Role",
        id: "ID",
        name: "Název",
        permissions: "Povolení",
        actions: "Akce",
        edit: "Upravit",
        delete: "Vymazat",
        create: "Vytvořit",
      },
      edit: {
        header: "Upravit roli"
      },
      add: {
        header: "Vytvořit novou roli"
      },
      form: {
        name: "Název",
        permissions: "Povolení",
        "check all permissions": "Zkontroluj všechna povolení",
        save: "Uložit",
      },
      common: {
        "Role delete confirmation": "Potvrzení vymazání role",
        "Are you sure that you want to delete role?": "Opravdu si přejete vymazat roli? ID role = {{id}}",
        "The role is assigned to users. All users with this role will be blocked.": "Tato role již byla přiřazena uživatelům. Všichni uživatelé s přiřazenou touto rolí budou zablokováni. Opravdu si přejete vymazat tuto roli? Identifikační číslo role = {{ id}}",
        "Role updated!": "Role byla upravena.",
        "Role created!": "Role byla vytvořena.",
        "Role id successfully deleted!": "Role s ID = {{id}} byla úspěšně vymazána.",
      }
    },
    user: {
      table: {
        title: "Back Office uživatelé",
        id: "ID",
        name: "Jméno",
        email: "Email",
        role: "Role",
        createdAt: "Datum vytvoření",
        updatedAt: "Datum změny",
        create: "Vytvořit uživatele",
        details: "Detaily",
        delete: "Vymazat",
        block: "Zablokovat",
        unblock: "Odblokovat",
        status: "Stav",
        "find by email": "Vyhledat podle emailu",
      },
      info: {
        header: "Informace o uživateli"
      },
      add: {
        header: "Vytvořit nového uživatele"
      },
      form: {
        name: "Jméno",
        email: "Email",
        oldPassword: "Původní heslo",
        password: "Heslo",
        passwordConfirm: "Potvrdit heslo",
        role: "Role",
        save: "Uložit",
      },
      common: {
        "User block confirmation": "Potvrzení zablokování uživatele",
        "Are you sure that you want to block user?": "Opravdu si přejete zablokovat uživatele? ID uživatele = {{id}}",
        blocked: "Zablokován",
        active: "Aktivní",
        "User updated!": "Uživatel byl změněn.",
        "User created!": "Uživatel byl vytvořen.",
        "User id successfully blocked/unblocked": "Uživatel s ID ={{id}} byl úspěšně {{status}}",
      },
    },
    management: {
      header: "Manažment"
    },
    merchant: {
      table: {
        id: "ID",
        name: "Název",
        type: "Typ",
        eWallet: "Číslo účtu",
        details: "Detaily",
        title: "Obchodníci",
      },
      details: {
        header: "Detaily obchodníka",
        balance: "Zůstatek",
      }
    },
    techEWallet: {
      header: "Technický eWallet účet"
    },
    card: {
      table: {
        title: "Karty",
        id: "ID",
        cardNumber: "Číslo karty",
        userId: "Číslo klientského účtu",
        eMoneyAccountId: "Číslo eWallet účtu",
        status: "Stav",
        createdAt: "Datum vytvoření",
        details: "Detaily"
      },
      alert: {
        error: "",
        "Card ID not found in partner API": "Číslo karty nebylo nalezeno během procesování API"
      },
      details: {
        header: "Informace o kartě",
        basic_info: "Základní informace",
        additional_info: "Dodatečné informace",
        fullName: "Jméno a příjmení",
        phoneNumber: "Telefonní číslo",
        city: "Město",
        street: "Ulice",
        unit: "Číslo domu",
        zip: "PSČ",
        cardNumber: "Číslo karty",
        userId: "Číslo klientského účtu",
        emoneyAccountId: "Číslo eWallet účtu",
        status: "Stav",
        createdAt: "Datum vytvoření",
        set_card_num: "Nastavit číslo karty",
        "Cancel card request": "Zrušit žádost o vytvoření karty",
        "Are you sure that you want cancel card request?": "Opravdu si přejete zrušit žádost o vytvoření karty?",
        "Confirm card request": "Potvrdit žádost o vytvoření karty",
        "Are you sure that you want to confirm card request?": "Opravdu si přejete schválit žádost o vytvoření karty?",
        "Set card number": "Nastavit číslo karty",
        "Input card number": "Zadání čísla karty",
        cardNumber_not_defined: "Číslo karty není definováno",
      },
      statuses: {
        "NEW_REQUEST": "NOVÁ POŽADAVKA",
        "CARD_ASSIGNED": "PŘIDĚLENÁ KARTA",
        "CARD_SENT": "POSLANÁ KARTA",
        "REQUEST_CANCELED": "ZAMÍTNUTÝ POŽADAVK"
      }
    },
    sanction: {
      search: {
        title: "Vyhledat osobu v sankčním seznamu",
      },
      form: {
        firstName: "Jméno",
        lastName: "Příjmení",
        fullName: "Jméno a příjmení",
        find: "Vyhledat",
        sanctionBase: "Databáze sankcí",
      },
      table: {
        id: "ID",
        publicationUrl: "URL Publikace",
        title: "Sankční seznam",
        fullName: "Jméno a příjmení",
        firstName: "Jméno",
        lastName: "Příjmení",
        "Result is empty": "Na seznamu se nenachází.",
        link: "Link"
      },
      status: {
        found: "Nalezen",
        not_found: "Nenalezen",
      }
    }
  }
};

export default cz;
