import {IUserInfo} from "../../../types/store/user";
import {ISetUserList, ISetUserListLoading, UserListActions} from "../../../types/store/user/user-list";
import {Action} from "redux";
import {USE_SERVER} from "../../../constants/constants";
import UserService from "../../../sevices/user-service";
import UserTestService from "../../../sevices/test/user-test-service";
import {ThunkDispatch} from "redux-thunk";
import {store} from "../../index";
import {IUserListRequestParams} from "../../../types/service/request/users-request";
import {handleGeneralError} from "../error-handler";
import i18n from "i18next";
import {notification} from "../../../utils/notification";

/**
 * It takes an array of users and an optional totalItems number and returns an object with a type of SET_USER_LIST and the
 * users and totalItems as properties
 * @param {IUserInfo[]} users - IUserInfo[] - this is the list of users that we want to set in the store.
 * @param {number} [totalItems] - The total number of items in the list.
 */
const setUserList = (users: IUserInfo[], totalItems?: number): ISetUserList => ({
  type: UserListActions.SET_USER_LIST,
  users,
  totalItems
});

/**
 * It returns an object with a type property and a loading property
 * @param {boolean} loading - boolean
 */
const setUserListLoading = (loading: boolean): ISetUserListLoading => ({
  type: UserListActions.SET_USER_LIST_LOADING,
  loading
});

/**
 * It fetches a list of users from the server, and then dispatches the result to the Redux store
 * @param {IUserListRequestParams} params - IUserListRequestParams - this is the type of the parameters that will be passed
 * to the action.
 */
export const fetchUserList = (params: IUserListRequestParams) => async (dispatch: ThunkDispatch<unknown, unknown, Action>) => {
  try {
    dispatch(setUserListLoading(true));
    const response = USE_SERVER ?
      await UserService.userList(params) :
      await UserTestService.userList(params);

    dispatch(setUserList(response.data.users, response.data.total));
  } catch (e: unknown) {
    await dispatch(handleGeneralError(e));
  } finally {
    dispatch(setUserListLoading(false));
  }
};

/**
 * It updates the user's block status in the database and in the redux store
 * @param {number} id - number - the id of the user to be blocked/unblocked
 * @param {boolean} isBlocked - boolean - the new status of the user
 */
export const updateUserBlockStatus = (id: number, isBlocked: boolean) => async (dispatch: ThunkDispatch<unknown, unknown, Action>) => {
  const userList = store.getState().userList.users;
  try {
    const response = USE_SERVER
      ? await UserService.updateUserBlockStatus(id, isBlocked)
      : await UserTestService.updateUserBlockStatus(id, isBlocked);
    const updatedUserList = userList.map(user => {
      if (user.id === response.data.user.id){
        return response.data.user;
      }
      return user;
    });
    dispatch(setUserList(updatedUserList));
    if (response.data.user.isBlocked){
      notification.showWarningMessage(i18n.t("common.User id successfully blocked/unblocked", {id, status: "blocked"}));
    } else {
      notification.showSuccessMessage(i18n.t("common.User id successfully blocked/unblocked", {id, status: "unblocked"}));
    }
  } catch (e: unknown){
    await dispatch(handleGeneralError(e));
  }
};