import {TOrder} from "../../table";
import {IAccountDetail} from "./account-details";

export interface IAccountsListState {
  accounts: IAccountDetail[],
  isLoading: boolean,
  page: number,
  totalPages: number,
  perPage: number,
  errorCode?: number,
  errorMessage?: string,
  filterStatus?: string,
  sort?: TOrder,
}

export enum AccountsListActions {
  SET_ACCOUNTS_LIST_SUCCESS = "SET_ACCOUNTS_LIST_SUCCESS",
  SET_ACCOUNTS_LIST_FAILURE = "SET_ACCOUNTS_LIST_FAILURE",
  CLEAR_ACCOUNTS_LIST = "CLEAR_ACCOUNTS_LIST",
  SET_ACCOUNTS_LIST_LOADING = "SET_ACCOUNTS_LIST_LOADING",
  SET_ACCOUNTS_LIST_STATUS = "SET_ACCOUNTS_LIST_STATUS",
  SET_ACCOUNTS_LIST_SORT = "SET_ACCOUNTS_LIST_SORT",
  SET_ACCOUNTS_LIST_PAGE = "SET_ACCOUNTS_LIST_PAGE",
  SET_ACCOUNTS_LIST_ITEMS_PER_PAGE = "SET_ACCOUNTS_LIST_ITEMS_PER_PAGE"
}

type TAccount = {
  accounts: IAccountDetail[],
  totalPages: number,
}

export interface ISetAccountsListFailure {
  type: AccountsListActions.SET_ACCOUNTS_LIST_FAILURE,
  payload: {
    errorCode: number,
    errorMessage: string,
  },
}
export interface ISetAccountsListSuccess {
  type: AccountsListActions.SET_ACCOUNTS_LIST_SUCCESS,
  payload: TAccount,
}

export interface ISetAccountsListLoading {
  type: AccountsListActions.SET_ACCOUNTS_LIST_LOADING,
  loading: boolean
}

export interface IClearAccountsList {
  type: AccountsListActions.CLEAR_ACCOUNTS_LIST;
}

export interface ISetAccountsListStatus {
  type: AccountsListActions.SET_ACCOUNTS_LIST_STATUS,
  status?: string,
}

export interface ISetAccountsListPage {
  type: AccountsListActions.SET_ACCOUNTS_LIST_PAGE,
  page: number
}

export interface ISetAccountsListItemsPerPage {
  type: AccountsListActions.SET_ACCOUNTS_LIST_ITEMS_PER_PAGE,
  perPage: number
}

export interface ISetAccountsListSort {
  type: AccountsListActions.SET_ACCOUNTS_LIST_SORT,
  sort: TOrder
}

export type TAccountsListAction =
    ISetAccountsListFailure
  | ISetAccountsListSuccess
  | IClearAccountsList
  | ISetAccountsListLoading
  | ISetAccountsListPage
  | ISetAccountsListItemsPerPage
  | ISetAccountsListSort
  | ISetAccountsListStatus;
