import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import React, { FC } from "react";
import AccountSatchelWalletTabPanel from "./account-satchel-wallet-tab-panel";
import { useTypedSelector } from "../../../hooks/useTypedSelector";
import AccountDetailGridRow from "../account-detail-grid-row";
import { getDateTime } from "../../../utils/time";
import { SxProps } from "@mui/system";
import { useTranslation } from "react-i18next";
import { AccountCorporateDetails } from "../account-corporate-details/account-corporate-details";
import { AccountIndividualDetails } from "../account-individual-details/account-individual-details";

interface IAccountSatchelWalletsProps {
  type: "individual" | "corporate";
  sx?: SxProps;
}

function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-AccountSatchelWalletTabPanel-${index}`,
  };
}

const AccountSatchelWallets: FC<IAccountSatchelWalletsProps> = ({
  type,
  sx,
}) => {
  const { t } = useTranslation();
  const [value, setValue] = React.useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const { satchelWallets } = useTypedSelector((state) => state.accountDetails);
  const wallets = satchelWallets?.[type]?.list;
  const satchelId = satchelWallets?.[type]?.satchelId;
  if (!wallets || !satchelId) return <></>;

  return (
    <Card sx={{ mb: 2, ...sx }}>
      <CardHeader title={`${type.toUpperCase()} Satchel id - ${satchelId}`} />
      <CardContent>
        {wallets.length === 0 && (
          <Typography>{t("account.details.nodata")}</Typography>
        )}
        {wallets.length > 0 && (
          <Box>
            <Tabs
              variant="scrollable"
              value={value}
              onChange={handleChange}
              aria-label="Vertical tabs example"
              sx={{ borderBottom: 3, borderColor: "divider" }}
            >
              {wallets.map((wallet, index) => (
                <Tab
                  key={index}
                  label={wallet.iban}
                  {...a11yProps(index)}
                  sx={{
                    fontWeight: 600,
                    borderTopLeftRadius: 8,
                    borderTopRightRadius: 8,
                  }}
                />
              ))}
            </Tabs>

            {wallets.map((wallet, index) => (
              <AccountSatchelWalletTabPanel
                key={index}
                value={value}
                index={index}
              >
                <AccountDetailGridRow title={"ID"} value={wallet.id} />
                <AccountDetailGridRow
                  title={"Account number"}
                  value={wallet.accountNumber}
                />
                <AccountDetailGridRow title={"IBAN"} value={wallet.iban} />
                <AccountDetailGridRow
                  title={"Account name"}
                  value={wallet.accountName}
                />
                <AccountDetailGridRow
                  title={"Availiable balance"}
                  value={wallet.availableBalance + " " + wallet.currencyCode}
                />
                <AccountDetailGridRow
                  title={"Current balance"}
                  value={wallet.currentBalance + " " + wallet.currencyCode}
                />
                <AccountDetailGridRow
                  title={"Created at"}
                  value={getDateTime(wallet.createdAt)}
                />
                <AccountDetailGridRow
                  title={"Updated at"}
                  value={getDateTime(wallet.updatedAt)}
                />
              </AccountSatchelWalletTabPanel>
            ))}
          </Box>
        )}

        {type === "corporate" && <AccountCorporateDetails />}
        {type === "individual" && <AccountIndividualDetails />}
      </CardContent>
    </Card>
  );
};

export default AccountSatchelWallets;
