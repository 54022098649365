import {ICard} from "./index";

export interface ICardListState {
    cards: ICard[],
    isLoading: boolean,
    page: number,
    totalPages: number,
    perPage: number,
}

export enum CardListActions {
    SET_CARD_LIST = "SET_CARD_LIST",
    SET_CARD_CUR_PAGE = "SET_CARD_CUR_PAGE",
    SET_CARD_PER_PAGE = "SET_CARD_PER_PAGE",
    CLEAR_CARD_LIST = "CLEAR_CARD_LIST",
    SET_CARD_LIST_LOADING = "SET_CARD_LIST_LOADING"
}

export interface ISetCardList {
    type: CardListActions.SET_CARD_LIST,
    cards: ICard[],
    totalPages: number
}

export interface ISetCardCurPage {
    type: CardListActions.SET_CARD_CUR_PAGE,
    page: number,
}

export interface ISetCardPerPage {
    type: CardListActions.SET_CARD_PER_PAGE,
    perPage: number,
}

export interface ISetCardListLoading {
    type: CardListActions.SET_CARD_LIST_LOADING,
    loading: boolean
}

export interface IClearCardList {
    type: CardListActions.CLEAR_CARD_LIST
}

export type TCardListAction = ISetCardList | ISetCardCurPage | ISetCardPerPage | ISetCardListLoading | IClearCardList;