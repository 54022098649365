import $api from "../http";
import {AxiosResponse} from "axios";
import {RoleInfoResponse, RoleListResponse} from "../types/service/response/roles-response";
import {IPermission} from "../types/store/permissions";
import {IGeneralResponse} from "../types/service/response/general-response";
import {IRole} from "../types/store/role";

/** It's a class that contains static methods that make requests to the API */
export default class RoleService {
  static async roleList(): Promise<AxiosResponse<RoleListResponse>>{
    return $api.get("/roles");
  }

  static async roleInfo(id: string | number): Promise<AxiosResponse<RoleInfoResponse>>{
    return $api.get("/roles/" + id);
  }

  static async createRole(name: string, permissions: Pick<IPermission, "id">[]): Promise<AxiosResponse<IGeneralResponse<IRole>>> {
    return $api.post("/roles", {name, permissions});
  }

  static async updateRole(id: number, name: string, permissions: Pick<IPermission, "id">[]): Promise<AxiosResponse<IGeneralResponse<IRole>>> {
    return $api.patch("/roles/" + id, {name, permissions});
  }

  static async deleteRole(id: number, confirmDelete: boolean): Promise<AxiosResponse<IGeneralResponse<Pick<IRole, "id">>>> {
    const params = confirmDelete ? {confirm_delete: confirmDelete} : {};
    return $api.delete("/roles/" + id, {params});
  }
}