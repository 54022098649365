import {Ability, AbilityBuilder} from "@casl/ability";
import {store} from "../store";

export type Action = "is" | "set" | "get" | "create" | "update" | "remove";
export type Subject =
    "auth" |
    "permissions" |
    "roles" |
    "role" |
    "users" |
    "user" |
    "transactions_list" |
    "transaction_info" |
    "transaction_verification_result" |
    "verification_list" |
    "account_info" |
    "verification_result" |
    "transaction_additional_info" |
    "setting" |
    "merchant" |
    "merchants" |
    "wallet" |
    "wallets" |
    "cards_list" |
    "card_info" |
    "sanctions" |
    "sanctions_countries_list" | 
    "satchel_accounts"

type AppAbility = Ability<[Action, Subject]>

const ability = new Ability<[Action, Subject]>();

store.subscribe(() => {
  const permissions = store.getState().auth.user.permissions;
  ability.update(defineRulesFor(permissions));
});

const defineRulesFor = (permissions: string[]) => {
  const {can, rules} = new AbilityBuilder<AppAbility>(Ability);
  if (permissions) {
    permissions.forEach((p) => {
      const perWords = p.split("_");
      const action = perWords.shift();
      const subject = perWords.join("_");
      can(action as Action, subject as Subject);
    });
  }

  return rules;
};

export default ability;