import React, {FC, useContext} from "react";
import {Box, Card, CardContent, CardHeader, Stack} from "@mui/material";
import {IAccountOndatoImageData} from "../../../types/store/account/account-details";
import {ModalImageWindowContext} from "../../../providers/modalImageWindowProvider";

interface IProps {
  photos: IAccountOndatoImageData[],
  cardHeader?: string
}

const AccountOndatoPhotos: FC<IProps> = ({photos, cardHeader}) => {
  const {handleOpenImage} = useContext(ModalImageWindowContext);

  return (
    <Card sx={{mb: 2}}>
      {cardHeader && (<CardHeader title={cardHeader}/>)}
      <CardContent>
        <Stack
          component={"ol"}
          direction={{ xs: "column", sm: "row" }}
          alignItems={{ xs: "center" }}
          spacing={{ xs: 1, sm: 2, md: 4 }}
          padding={0}
          margin={0}
        >
          {photos && photos.map((photo, index) => (
            <Box
              key={index}
              component={"img"}
              alt={"photo " + (index + 1)}
              src={photo.s3Link}
              sx={{
                maxWidth: "200px",
                height: "100px",
                objectFit: "contain",
                lineHeight: "100%",
                cursor: "pointer",
                marginRight: "2rem",
              }}
              onClick={handleOpenImage && handleOpenImage(photo.s3Link)}
            />
          ))}
        </Stack>
      </CardContent>
    </Card>
  );
};

export default AccountOndatoPhotos;