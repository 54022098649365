const en = {
  translation: {
    common: {
      deposit: "Deposit",
      credit: "Credit",
      investments: "Investments",
      rows_per_page: "Rows per page:",
      of: "of",
      btn_cancel: "Cancel",
      btn_reject: "Reject",
      btn_decline: "Decline",
      btn_confirm: "Confirm",
      btn_more_info: "More info",
      btn_open: "Open",
      btn_open_link: "Open Link",
      "Rows per page": "Rows per page",
    },
    applications: {
      table: {
        title: "Applications",
        id: "ID",
        uuid: "UUID",
        account: "Account",
        type: "Type",
        status: "Status",
        created: "Created",
      },
      "Application status successfully updated": "Application status successfully updated!",
      "Reject application": "Reject application",
      "Reject application question": "Are you sure you want to reject the application? Write the reason for the rejection.",
    },
    transactions: {
      client_profile: {
        header: "Client Profile",
        full_name: "Full Name",
        birthday: "Date of birth",
        sin: "Personal No",
        contact: "Contact",
        address: "Address",
        home: "Home",
        mobile: "Phone number",
        email: "Email",
        employer: "Employer",
        job_title: "Job Title",
        employer_name: "Employer Name",
        start_date: "Start Date",
        salary: "Salary",
        family: "Family",
        marital_status: "Marital Status",
        spouse_name: "Spouse's Name",
        spouse_birthday: "Spouse's Birthday",
        children: "Children"
      },
      account_activity: {
        header: "Account Activity",
        branch: "Branch",
        institution: "Institution",
        account_number: "Account number"
      },
      table: {
        id: "ID",
        title: "Transactions",
        timestamp: "Date",
        operation: "Operation",
        type: "Type",
        transactionId: "Transaction ID",
        operationType: "Operation",
        fromAccount: "From account",
        toAccount: "To account",
        status: "Status",
        amount: "Amount",
        details: "Details",
        all: "All",
        transferType: "Transfer type",
        "transferType transfer": "Money transfer",
        "transferType merchantTransfer": "Merchant Transfer"
      },
      info: {
        header: "Transaction info",
        data: "Transaction data",
        origin: "From eWallet account",
        destination: "To eWallet account",
        "Transaction id": "Transaction ID",
        "Time & date": "Date and time",
        "Amount": "Amount",
        "Status": "Status",
        "Processing status": "Processing status",
        "Source": "Source",
        "Type of operation": "Type of operation",
        "Confirm time": "Confirm time",
        "End time": "End time",
        "Account": "eWallet account no",
        "Country": "Country",
        "Bank code": "Bank code",
        "Id": "Id",
        "Satchel id": "Satchel id",
        "Category": "Category",
        "Email": "Email",
        "Fee": "Fee",
        "Beneficiary account number": "Beneficiary account number",
        "Beneficiary bank swift": "Beneficiary bank swift",
        "Beneficiary card uuid": "Beneficiary card uuid",
        "Beneficiary iban": "Beneficiary iban",
        "Sender account number": "Sender account number",
        "Narrative": "Narrative",
        "Type": "Type",
        "Rejection reason": "Rejection reason",
        "Processing time": "Processing time",
        "Finished at": "Finished at",
        "Created at": "Created at",
        "Updated at": "Updated at"
      },
      modal: {
        confirm_header: "Confirm transaction?",
        confirm_question: "Do you really want to confirm this transaction?",
        decline_header: "Decline account?",
        decline_question: "Do you really want to decline this account?",
        more_info_header: "Need more information",
        more_info_question: "Please write more information which are needed to successful proces this transaction.",
        more_info_label: "Message",
      },
      data: {
        merchant: "Merchant information"
      },
      statuses: {
        "UNDEFINED": "UNDEFINED",
        "PENDING": "PENDING",
        "ACCEPTED": "ACCEPTED",
        "REJECTED": "REJECTED",
        "AML_CHECK": "AML CHECK",
        "CREATED": "CREATED",
        "AML_SUCCESS": "AML SUCCESS",
        "BLOCKCHAIN_PENDING": "BLOCKCHAIN PENDING"
      }
    },
    account: {
      common: {
        unassigned: "Unassigned",
        declined: "Declined",
        confirmed: "Confirmed",
        all: "All"
      },
      table: {
        accounts: "Client's Accounts",
        id: "ID",
        account_id: "Client's Account ID",
        firstName: "First name",
        lastName: "Last name",
        uniqueIdentifier: "Personal No",
        status: "Status",
        created_at: "Date",
        details: "Details",
        actions: "Actions",
        sanctionStatus: "Sanction status",
        keycloackId: "Keycloak ID",
        type: "Type",
        name: "Name",
        number: "Number",
        email: "Email",
        phone: "Phone"
      },
      details: {
        header: "Client's Account Details",
        basic_info: "Basic information",
        identity_verification: "Identity verification",
        verified_information: "Verified information",
        first_name: "First Name",
        last_name: "Last Name",
        companyName: "CompanyName",
        contact_info: "Contact Information",
        email: "Email",
        phone_number: "Phone Number",
        status: "Status",
        photos: "The list of photos",
        account_ids: "The list of eWallet accounts",
        btn_confirm: "Confirm",
        btn_decline: "Decline",
        sex: "Sex",
        nationality: "Nationality",
        birthDate: "Date of birth",
        documents: "Documents",
        document: {
          title: "Document",
          id: "Id",
          passport: "Passport",
          driverLicense: "Driver livense",
          type: "Document type",
          side: "side",
          citizenCountry: "Citizen country",
          issueCountry: "Issue country",
          issueDate: "Issue date",
          expiryDate: "Expiry date",
          issuing: "Issuing",
          number: "Document nubmer"
        },
        sexF: "Female",
        sexM: "Male",
        photo: "Photo",
        signedContract: "Contract documentation",
        sanctions: "Sanction List",
        check_result: "Check result",
        find_by: "Find by",
        sanction_link: "Sanction link",
        createdAt: "Created at",
        updatedAt: "Updated at",
        keycloackId: "Keycloack ID",
        satchelId: "Satchel ID",
        type: "Type",
        personal_number: "Personal number",
        dateOfBirthday: "Date of birthday",
        birthPlace: "Birth place",
        taxCountry: "Tax country",
        employment: "Employment",
        sourceInitialFunding: "Source Initial Funding",
        pep: "PEP",
        usCitizenship: "US citizenship",
        termsAndConditions: "Terms And Conditions",
        privacyPolicy: "Privacy Policy",
        usePersonalData: "Use Personal Data",
        selfieImage: "Selfie Image",
        privacyPolicyArbitas: "Privacy Policy Arbitas",
        privacyPolicySatchel: "Privacy Policy Satchel",
        termsAndConditionsArbitas: "Terms And Conditions Arbitas",
        termsAndConditionsSatchel: "Terms And Conditions Satchel",
        addressCountryCode: "Address Country Code",
        addressCity: "Address City",
        addressStreet: "Address Street",
        addressZipCode: "Address Zip Code",
        phoneNumber: "Phone Number",
        emailAddress: "Email Address",
        companies: "Companies",
        persons: "Persons",
        legalDocuments: "Legal documents",
        name: "Name",
        dateOfRegistrations: "Date of registrations",
        registrationNumber: "Registration number",
        businessCategories: "Business categories",
        website: "Website",
        planedMonthlyTurnoverIncoming: "Planed monthly turnover incoming",
        planedMonthlyTurnoverOutgoing: "Planed monthly turnover outgoing",
        documentType: "Document type",
        sendPassword: "Send password",
        countryOfIncorporation: "Country of incorporation",
        countryRegion: "Country region",
        firstName: "First name",
        lastName: "Last name",
        personalNumber: "Personal number",
        jobTitle: "Job Title",
        id: "ID",
        userId: "User ID",
        sessionId: "Session ID",
        middleName: "Middle name",
        documentNumber : "Document number",
        issueDate : "Issue date",
        expiryDate : "Expiry date",
        address: "Address",
        countryCode: "countryCode",
        gender: "Gender",
        docType: "Document type",
        mrzVerified: "MRZ verified",
        category: "Category",
        stateCode: "State code",
        nodata: "No data",
        "The user did not try to pass verification": "The user did not try to pass verification",
        "Satchel bank accounts": "Satchel bank accounts",
        "Personal account applications": "Personal account applications",
        "Business account applications": "Business account applications",
        "Account deleting": "Account deleting",
        "Account deleting confirmation": "Are you sure that you want to delete this account (Account ID - {{accountId}})?"
      },
      modal: {
        confirm_header: "Confirm account?",
        confirm_question: "Do you really want to confirm this account?",
        decline_header: "Decline account?",
        decline_question: "Do you really want to decline this account? Please describe the reason for declining in the text field below.",
        decline_reason_label: "Reason"
      },
      alert: {
        error: "Error",
        "Account ID not found in partner API": "Account ID not found in Processing API"
      },
      sanctions: {
        fullName: "Full Name"
      },
      statuses: {
        "UNDEFINED": "UNDEFINED",
        "PENDING": "PENDING",
        "ACCEPTED": "ACCEPTED",
        "REJECTED": "REJECTED"
      },
      metadata: {
        "CompareResult": "Compare Result",
        "AgeRange": "Age range",
        "years old": "years old",
        "AgeRangeHigh": "Max Age View",
        "AgeRangeLow": "Min Age View",
        "Beard_true": "Have a beard",
        "Beard_false": "Doesn't have a beard",
        "Confidence": "Looks like a face",
        "Emotions (CALM)": "Emotions (CALM)",
        "Emotions (SURPRISED)": "Emotions (SURPRISED)",
        "Emotions (FEAR)": "Emotions (FEAR)",
        "Emotions (SAD)": "Emotions (SAD)",
        "Emotions (HAPPY)": "Emotions (HAPPY)",
        "Emotions (CONFUSED)": "Emotions (CONFUSED)",
        "Emotions (ANGRY)": "Emotions (ANGRY)",
        "Emotions (DISGUSTED)": "Emotions (DISGUSTED)",
        "Eyeglasses_true": "Wearing eye glasses",
        "Eyeglasses_false": "Doesn't have a eye glasses",
        "EyesOpen_true": "Eyes is open",
        "EyesOpen_false": "Eyes is closed",
        "Male": "Appears to be male",
        "Female": "Appears to be female",
        "MouthOpen_true": "Mouth is open",
        "MouthOpen_false": "Mouth is closed",
        "Mustache_true": "Have a mustache",
        "Mustache_false": "Doesn't have a mustache",
        "PosePitch": "Pose Pitch",
        "PoseRoll": "Pose Roll",
        "PoseYaw": "Pose Yaw",
        "QualityBrightness": "Quality Brightness",
        "QualitySharpness": "Quality Sharpness",
        "Smile_true": "Smiling",
        "Smile_false": "Not smiling",
        "Sunglasses_true": "Wearing sunglasses",
        "Sunglasses_false": "Doesn't have a sunglasses",
      }
    },
    eWallet: {
      balance: "Balance",
    },
    menu: {
      home: "Home",
      applications: "Applications",
      transactions: "Transactions",
      accounts: "Accounts",
      profile: "Profile",
      logout: "Logout",
      management: "Management",
      permissions: "Permissions",
      roles: "Roles",
      users: "Users",
      merchants: "Merchants",
      "List": "List",
      "Create new": "Create new",
      "Blockchain monitoring": "Blockchain monitoring",
      techEWallet: "Technical e-Wallet",
      cards: "Cards",
      "Sanction list": "Sanction list",
      "Search": "Search",
      "Search List": "Search List",
      "Search Countries": "Search Countries",
    },
    http: {
      errors: {
        "email incorrect": "Email not found",
        "password incorrect": "Password is incorrect",
        "server not response": "Server not responded",
        "server error": "Server error",
        "password must be longer than or equal to 4 characters": "Password is too short",
        "name is not unique": "Name is not unique",
        "email is not unique": "Email is not unique",
        "QueryFailedError": "Error to save",
        "account with this accountId not find in internal database": "This account number was not found in the database"
      }
    },
    form: {
      auth: {
        "Login": "Login",
        "Password": "Password",
        "User ID": "User ID",
        "Passcode": "Passcode",
        "Email": "Email",
        "Send passcode": "Send passcode",
        "Sign In": "Sign In",
        "Back": "Back",
      }
    },
    auth: {
      common: {
        "Password successfully changed!": "Password was successfully changed",
      }
    },
    profile: {
      header: "Profile",
      change_password: "Change password",
      general_info: "General information",
      form: {
        email: "Email",
        password: "Current password",
        passwordNew: "New password",
        passwordDup: "Confirm new password",
        save: "Save"
      }
    },
    validations: {
      "Login is required": "Login is required",
      "Login must be a an email": "Login must be an email",
      "Password is required": "Password is required",
      "Validation error": "Validation error",
      "Wrong email": "Wrong email",
      "Min length": "Min length is {{value}} symbols",
      "Max length": "Max length is {{value}} symbols",
      "email incorrect": "Email not found",
      "password incorrect": "Password is invalid",
      "password must be longer than or equal to 4 characters": "Password is too short",
      "Name is required": "Name is required",
      "Email is required": "Email is required",
      "Passwords do not match": "Passwords do not match",
      "email is not unique": "This email already exists",
      "User ID is required": "User ID is required",
      "Passcode is required": "Passcode is required",
      "Passcode is 6 digit": "Passcode is 6 digit",
      "Role is required": "Role is required",
      "name is not unique": "This name already exists",
      "You cannot delete your account": "You can not delete your account",
      "Old password is required": "Old password is required",
      "Field is required": "Field is required",
      "Allow only digits": "Allow only digits",
      "user is unauthorized": "user is unauthorized",
      "Value is required": "Value is required",
      "Not correct value": "Not correct value",
      "Min amount more than max amount": "Min amount more than max amount",
    },
    error_page: {
      "Ops": "Error",
      "Page not found": "This page doesn't exist or is unavailable.",
      "Go back to home": "Go back to Home menu"
    },
    permissions: {
      table: {
        title: "Permissions",
        id: "ID",
        name: "Name",
        description: "Description"
      },
      names: {
        get_permissions: "Get the list of permissions",
        get_roles: "Get the list of roles",
        get_role: "Get the role",
        create_role: "Create the role",
        update_role: "Update the role",
        remove_role: "Remove the role",
        get_users: "Get the list of users",
        get_user: "Get user",
        create_user: "Create user",
        update_user: "Update user",
        remove_user: "Remove user",
        get_verification_list: "Get the list of accounts",
        get_account_info: "Get account",
        set_verification_result: "Set account status",
        get_transactions_list: "Get the list of transactions",
        get_transaction_info: "Get the transaction",
        set_transaction_verification_result: "Set transaction status",
        get_transaction_additional_info: "Get transaction additional info",
        update_setting: "Update settings",
        get_merchants: "Get the list of merchants",
        get_merchant: "Get merchant",
        create_merchant: "Create merchant",
        update_merchant: "Update merchant",
        remove_merchant: "Remove merchant",
        get_wallets: "Get the list of eWallet accounts",
        get_wallet: "Get eWallet",
        create_wallet: "Create eWallet",
        update_wallet: "Update eWallet",
        remove_wallet: "Remove eWallet",
        get_cards_list: "Get the list of cards",
        get_card_info: "Get card information",
        update_card_status: "Update card status",
        get_sanctions: "Get sanctions",
        get_sanctions_countries_list: "Get sanctions countries list",
      },
      descriptions: {
        get_permissions: "Allow to get the list of permissions",
        get_roles: "Allow to get the list of roles",
        get_role: "Allow to get the role information",
        create_role: "Allow to create a new role",
        update_role: "Allow to update the existing role",
        remove_role: "Allow to delete the role",
        get_users: "Allow to get the list of users",
        get_user: "Allow to get the user information",
        create_user: "Allow to create a new user",
        update_user: "Allow to update the existing user",
        remove_user: "Allow to delete the user",
        get_verification_list: "Allow to get the list of accounts",
        get_account_info: "Allow to get the account information",
        set_verification_result: "Allow to set account status",
        get_transactions_list: "Allow to get the list of transactions",
        get_transaction_info: "Allow to get the transaction information",
        set_transaction_verification_result: "Allow to set transaction status",
        get_transaction_additional_info: "Allow to get transaction additional info",
        update_setting: "Allow to update settings",
        get_merchants: "Allow to get the list of merchants",
        get_merchant: "Allow to get the merchant information",
        create_merchant: "Allow to create a new merchant",
        update_merchant: "Allow to update the existing merchant",
        remove_merchant: "Allow to delete the merchant",
        get_wallets: "Allow to get the list of eWallet accounts",
        get_wallet: "Allow to get the eWallet information",
        create_wallet: "Allow to create new eWallet account",
        update_wallet: "Allow to update the existing eWallet",
        remove_wallet: "Allow to delete eWallet",
        get_cards_list: "Allow to get the list of cards",
        get_card_info: "Allow to get the card information",
        update_card_status: "Allow to update card status",
        get_sanctions: "Allow to get sanctions",
        get_sanctions_countries_list: "Allow to get sanctions countries list",
      },
    },
    role: {
      table: {
        title: "Roles",
        id: "ID",
        name: "Name",
        permissions: "Permissions",
        actions: "Actions",
        edit: "Edit",
        delete: "Delete",
        create: "Create a new role",
      },
      edit: {
        header: "Edit Role"
      },
      add: {
        header: "Created a new role"
      },
      form: {
        name: "Name",
        permissions: "Permissions",
        "check all permissions": "Check all permissions",
        save: "Save",
      },
      common: {
        "Role delete confirmation": "Role delete confirmation",
        "Are you sure that you want to delete role?": "Do you really want to delete this role?  Role id = {{id}}",
        "The role is assigned to users. All users with this role will be blocked.": "This role is already assigned to users. All users with this role will be blocked. Do you really want to delete this role?  Role id = {{id}}",
        "Role updated!": "Role was updated",
        "Role created!": "Role was created",
        "Role id successfully deleted!": "Role id = {{id}} was successfully deleted",
      }
    },
    user: {
      table: {
        title: "Back Office users",
        id: "ID",
        name: "Name",
        email: "Email",
        role: "Role",
        createdAt: "Date of creation",
        updatedAt: "Date of update",
        create: "Create user",
        details: "Details",
        delete: "Delete",
        block: "Block",
        unblock: "Unblock",
        status: "Status",
        "find by email": "Find by email",
      },
      info: {
        header: "User information"
      },
      add: {
        header: "Create a new user"
      },
      form: {
        name: "Name",
        email: "Email",
        oldPassword: "Old password",
        password: "Password",
        passwordConfirm: "Password confirmation",
        role: "Role",
        save: "Save",
      },
      common: {
        "User block confirmation": "User block confirmation",
        "Are you sure that you want to block user?": "Do you really want to block this user? User id = {{id}}",
        blocked: "Blocked",
        active: "Active",
        "User updated!": "User was updated",
        "User created!": "User was created",
        "User id successfully blocked/unblocked": "User id={{id}} was successfully {{status}}",
      },
    },
    management: {
      header: "Management"
    },
    merchant: {
      table: {
        id: "ID",
        name: "Name",
        type: {
          title: "Type",
          PhoneOperator: "Phone operator",
          POS: "POS",
        },
        eWallet: "eWallet account",
        details: "Details",
        title: "Merchants",
        block: "Deactivate",
        unblock: "Activate",
        edit: "Edit"
      },
      details: {
        header: "Merchant information",
        balance: "Balance",
      },
      add: {
        header: "Add new merchant",
      },
      edit: {
        header: "Edit merchant",
      },
      form: {
        name: "Name",
        type: "Type",
        eWallet: "eWallet",
        minAmount: "Min amount",
        maxAmount: "Max amount",
        save: "Save",
        "Merchant without logo": "Merchant without logo",
        "Upload Image": "Upload Image",
        "Delete": "Delete",
        lang_key: "Lang key"
      },
      common: {
        "Merchant id successfully blocked/unblocked": "Merchant id={{id}} was successfully {{status}}",
        "Merchant updated!": "Merchant updated!",
        "Merchant created!": "Merchant created!",
      }
    },
    techEWallet: {
      header: "Technical e-Wallet account"
    },
    card: {
      table: {
        title: "Cards",
        id: "ID",
        balance: "Balance",
        pan: "Card number",
        expiryAt: "Date of expiry",
        createdAt: "Date of creation",
        details: "Details"
      },
      alert: {
        error: "",
        "Card ID not found in partner API": "Card ID was not found in a partner API"
      },
      details: {
        header: "Card information",
        basic_info: "Basic information",
        additional_info: "Additional information",
        id: "ID",
        balance: "Balance",
        fullName: "Full name",
        phoneNumber: "Phone number",
        city: "City",
        street: "Street",
        unit: "Unit",
        zip: "ZIP",
        cardNumber: "Card Number",
        userId: "User ID",
        emoneyAccountId: "eWallet Account",
        processingType: "Processing type",
        embossing: "Embossing",
        status: "Status",
        expiryAt: "Date of expiry",
        createdAt: "Date of creation",
        updatedAt: "Date of updation",
        set_card_num: "Set card number",
        "Cancel card request": "Cancel card request",
        "Are you sure that you want cancel card request?": "Do you really want to cancel card request?",
        "Confirm card request": "Confirm card request",
        "Are you sure that you want to confirm card request?": "Do you really want to confirm card request?",
        "Set card number": "Set card number",
        "Input card number": "Input card number",
        cardNumber_not_defined: "Card number not defined",
      },
      statuses: {
        "NEW_REQUEST": "NEW REQUEST",
        "CARD_ASSIGNED":  "CARD ASSIGNED",
        "CARD_SENT": "CARD SENT",
        "REQUEST_CANCELED": "REQUEST CANCELED"
      }
    },
    sanction: {
      countries: {
        table: {
          title: "Sanction countries",
          id: "ID",
          name: "Name",
          iso2: "iso code 2",
          iso3: "iso code 3",
        },
      },
      list: {
        EU: "EU Sanction List",
        UN: "United Nations Sanctions List",
        US: "United States of America Sanctions List",
        UK: "United Kingdom Sanctions List",
      },
      search: {
        title: "Search person in the sanction list",
      },
      form: {
        firstName: "First name",
        lastName: "Last name",
        fullName: "Full name",
        find: "find",
        sanctionBase: "Sanction database",
      },
      table: {
        id: "ID",
        publicationUrl: "Publication URL",
        title: "Sanction list",
        fullName: "Full name",
        firstName: "First name",
        lastName: "Last name",
        "Result is empty": "Not found in the list.",
        link: "Link"
      },
      status: {
        found: "FOUND",
        not_found: "NOT FOUND",
      }
    },
    wallet: {
      table: {
        Id: "Id",
        Amount: "Amount",
        fromAccount: "From account",
        toAccount: "To account",
        date: "Date",
      }
    }
  }
};

export default en;
